import { ThirdPartyData } from "views/layouts/auth/entity";
import {
  AWS_CREDENTIALS_STATUS_RECEIVED,
  AWS_CREDENTIALS_STATUS_RESET,
  AWS_VALIDATION_DETAILS_INFLIGHT,
  OKTA_DETAIL_RECEIVED,
  SLACK_AUTH_URL,
  VERCEL_DETAILS_INFLIGHT,
  VERCEL_DETAILS_RECEIVED,
  VERCEL_PROJECTS_RECEIVED,
  VERCEL_STATUS_RECEIVED,
} from "./constant";

export const requestSlackUrl = (arg: any = {}) => {
  return {
    type: SLACK_AUTH_URL,
    ...arg,
  };
};

export const vercelDetailsReceived = (arg: any = {}) => {
  return {
    type: VERCEL_DETAILS_RECEIVED,
    ...arg,
  };
};

export const vercelProjectsReceived = (arg: any = {}) => {
  return {
    type: VERCEL_PROJECTS_RECEIVED,
    ...arg,
  };
};

export const vercelStatusReset = (arg: any = {}) => {
  return {
    type: VERCEL_STATUS_RECEIVED,
    status_code: "vercel_integration_not_found",
  };
};

export const vercelStatusReceived = (arg: any = {}) => {
  return {
    type: VERCEL_STATUS_RECEIVED,
    ...arg,
  };
};

export const vercelDetailsInflight = (arg: any = {}) => {
  return {
    type: VERCEL_DETAILS_INFLIGHT,
    ...arg,
  };
};

export const AWSInflightActive = () => {
  return {
    type: AWS_VALIDATION_DETAILS_INFLIGHT,
  };
};

export const AWSCredentialsStatusReceived = (arg?: {
  valid: boolean;
  message: string;
}) => {
  return {
    type: AWS_CREDENTIALS_STATUS_RECEIVED,
    ...arg,
  };
};

export const AWSCredentialsStatusReset = () => {
  return {
    type: AWS_CREDENTIALS_STATUS_RESET,
  };
};
export const oktaDetailReceived = (arg?: ThirdPartyData) => {
  return {
    type: OKTA_DETAIL_RECEIVED,
    ...arg,
  };
};
