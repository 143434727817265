import { TimeRangeOption } from "core/components/datepicker/model";
import { TypographyHeadingPrimary } from "core/components/typography";
import Button from "core/components/v2/button";
import DropdownSelection from "core/components/v2/dropdown-selection";
import FormTextInput from "core/components/v2/form/form-text-input";
import { PlusIcon } from "core/components/v2/svg/icons";
import TabSelection from "core/components/v2/tab-selection";
import Tooltip from "core/components/v2/tooltip";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastType, showToast } from "store/toast-alerts/actions";
import {
  receivedBuilderFormattedData,
  recievedBuilderMetricList,
  removeItemFromDashboard,
  resetWidget,
} from "store/widgets/actions";
import {
  fetchMetricList,
  requestBuilderFormattedData,
  requestReportBuilderCreate,
} from "store/widgets/api";
import { DataType, MetricsAPIResp } from "store/widgets/entities";
import { ReportReducer } from "store/widgets/reducers";
import { getBuilderFormattedData2 } from "store/widgets/selectors";
import GlobalCenterDialog from "views/layouts/app/components/global-center-dialog";
import { AvailableDateRange } from "views/layouts/app/components/global-date-picker/model";
import GlobalDatePicker from "views/layouts/app/components/v2/global-date-picker";
import MwRouteContext from "views/layouts/app/routes/MWRouteContext";
import {
  AllRouteParams,
  DateRange,
  GlobalRouteParams,
} from "views/layouts/app/routes/model";
import {
  addKeysToQueries,
  extractFormulas,
  getFreshLayout,
  getMetricDetails,
  insertFormulas,
  isNameValid,
  isValidFormula,
  validateAddFormulaAction,
  validateAddQueryAction,
  validateDeleteFormulaAction,
  validateDeleteQueryAction,
  validateWidgetConfig,
} from "../common/utils";
import WidgetAppView from "../core/widget-app-view";
import { WidgetsApp, getChartTypeFromAppId } from "../core/widgets.app";
import {
  AddUpdateWidgetBuilder,
  BarChartType,
  BuilderNestedProps,
  ChartType,
  CustomWidget,
  Query,
  RequestParam,
  SelectOneMetric,
  TimeseriesChartType,
  defaultQuery,
  defaultWidgetBuilder,
  getWidgetType,
  mapAppKey,
  toKpiType,
} from "../entities/builder.entities";
import Formula from "../widget/expression";
import QuerySelectionV2 from "../widget/query-selection-v2";
import "./_stylev2.scss";

interface ManageWidgetProps {
  existWidgetData?: CustomWidget;
  nestedProps?: BuilderNestedProps;
  onWidgetUpsert: (upsertedWidget?: CustomWidget) => void;
}

const newWidgetBuilder = {
  ...(JSON.parse(JSON.stringify(defaultWidgetBuilder)) as CustomWidget),
  label: "",
};

const ManageWidget = (props: ManageWidgetProps) => {
  const dispatch = useDispatch();
  const queryString = useContext(MwRouteContext);

  const existWidgetData = useMemo(
    () => extractFormulas(props.existWidgetData),
    [JSON.stringify(props.existWidgetData)]
  );

  const [widgetBuilder, setWidgetBuilder] = useState<CustomWidget>(
    existWidgetData
      ? existWidgetData
      : (JSON.parse(JSON.stringify(newWidgetBuilder)) as CustomWidget)
  );
  const [widgetBuilderBackup, setWidgetBuilderBackup] = useState<CustomWidget>(
    existWidgetData
      ? (JSON.parse(JSON.stringify(existWidgetData)) as CustomWidget)
      : ({} as CustomWidget)
  );
  const [dateRange, setDateRange] = useState<DateRange>(
    queryString.params.dateRange
  );

  const widgetData = useSelector(getBuilderFormattedData2) as Record<
    string,
    unknown
  >;
  const reports: ReportReducer = widgetData.reports as ReportReducer;
  const widgets = reports?.builders.widgets || {};

  const chartType = useMemo(
    () => getChartTypeFromAppId(widgetBuilder.widgetAppId),
    [widgetBuilder.widgetAppId]
  );
  const queries = widgetBuilder.builderConfig as Query[];

  // Fetch the details of all the metrics in the query
  useEffect(() => {
    const qryMetricNames = queries.reduce((prev: string[], qry: Query) => {
      if (qry.columns.length <= 0) return prev;

      const col = qry.columns[0] as string;
      if (col === SelectOneMetric) return prev;

      const mDetails = getMetricDetails(col);
      return [...prev, mDetails.metricName];
    }, [] as string[]);

    dispatch(
      fetchMetricList(
        {
          // All the queries in the builder will have the same source
          kpiType: toKpiType(queries.length > 0 ? queries[0].source.name : ""),
          widgetType: getWidgetType(chartType),
          dataType: DataType.Metrics,
          mandatoryMetrics: JSON.stringify(qryMetricNames),
        },
        (resp: MetricsAPIResp) =>
          recievedBuilderMetricList(DataType.Metrics, resp)
      )
    );
  }, [
    getWidgetType(chartType),
    toKpiType(queries.length > 0 ? queries[0].source.name : ""),
  ]);

  useEffect(() => {
    const copy = { ...widgetBuilder };

    if (copy.widgetAppId) {
      let builderConfig = copy.builderConfig;
      if (!Array.isArray(builderConfig)) return;

      switch (chartType) {
        case ChartType.QueryValueChart: {
          // If formulas exists, there can be many queries, but only one formula
          if (copy.formulas && copy.formulas.length > 0) {
            if (copy.formulas.length > 1) {
              copy.formulas = [copy.formulas[0]];
            }
            break;
          }

          // If formulas don't exists, there can be at most one query
          if (builderConfig.length < 1) {
            builderConfig.push(
              JSON.parse(JSON.stringify(defaultQuery)) as Query
            );
          } else if (builderConfig.length > 1) {
            builderConfig = [builderConfig[0]];
          }
          break;
        }

        case ChartType.ScatterPlotChart: {
          if (copy.formulas && copy.formulas.length > 0) {
            if (copy.formulas.length > 2) {
              // There can be only two formulas and many queries
              copy.formulas = copy.formulas.slice(0, 3);
            } else if (copy.formulas.length < 2) {
              // There can be one formula and one query
              if (builderConfig.length > 1) builderConfig = [builderConfig[0]];
            }
            break;
          }

          // There must be two queries
          if (builderConfig.length < 1) {
            builderConfig.push(
              JSON.parse(JSON.stringify(defaultQuery)) as Query,
              JSON.parse(JSON.stringify(defaultQuery)) as Query
            );
          } else if (builderConfig.length == 1) {
            const newQuery = JSON.parse(JSON.stringify(defaultQuery)) as Query;
            newQuery.source.name = builderConfig[0].source.name;
            builderConfig.push(newQuery);
          } else if (builderConfig.length > 2) {
            builderConfig.push(...builderConfig.slice(0, 2));
          }
          break;
        }

        case ChartType.TopListChart: {
          // There can be at most one formula
          if (copy.formulas && copy.formulas.length > 1) {
            copy.formulas = [copy.formulas[0]];
          }

          // There can be at most one query
          if (builderConfig.length < 1) {
            builderConfig.push(
              JSON.parse(JSON.stringify(defaultQuery)) as Query
            );
          } else if (builderConfig.length > 1) {
            builderConfig = [builderConfig[0]];
          }

          break;
        }
      }

      copy.builderConfig = builderConfig;
    }

    setWidgetBuilder(copy);
    if (!validateWidgetConfig(copy).isValid) return;
    requestRuntimeFormatted(true, false, copy);
  }, [widgetBuilder.widgetAppId, dateRange]);

  const displyNotificaion = (type: ToastType, msg: string) => {
    dispatch(showToast(type, msg));
  };

  const resetBuilder = (isCancel: boolean, widget?: CustomWidget) => {
    if (widgetBuilder?.builderId === -1) {
      dispatch(removeItemFromDashboard(-1));
    }
    if (
      isCancel &&
      widgetBuilder?.builderId !== -1 &&
      widgetBuilderBackup &&
      widgetBuilderBackup?.builderId !== -1
    ) {
      dispatch(
        resetWidget({
          builderId: widgetBuilderBackup?.builderId,
          widget: widgetBuilderBackup,
        })
      );
      setWidgetBuilderBackup({} as CustomWidget);
    }
    setWidgetBuilder(
      JSON.parse(JSON.stringify(newWidgetBuilder)) as CustomWidget
    );
    props.onWidgetUpsert?.(widget);
  };

  const isUpdate = () => {
    return widgetBuilder && widgetBuilder.builderId !== -1;
  };

  const isClone = () => {
    return widgetBuilder?.isClone;
  };

  const requestRuntimeFormatted = (
    showError = true,
    fromSave?: boolean,
    widget?: CustomWidget
  ) => {
    let body: CustomWidget;

    if (widget) {
      body = widget;
    } else {
      const widgetBuilderCopy = JSON.parse(
        JSON.stringify(widgetBuilder)
      ) as CustomWidget;
      body = widgetBuilderCopy;
    }

    // add unique key identifier to queries for formula
    body = addKeysToQueries(body);

    const validation = validateWidgetConfig(body);
    if (!validation.isValid) {
      if (showError) displyNotificaion("error", validation.msg);
      return;
    }

    // add formulas into the builder config
    body = insertFormulas(body);

    const data: AddUpdateWidgetBuilder = {
      action: "add",
      inflight: true,
      body,
    };
    data.body.params = getParams();
    if (fromSave) {
      dispatch(requestBuilderFormattedData(data, undefined, true));
      return;
    }
    dispatch(requestBuilderFormattedData(data));
  };

  const getParams = (): RequestParam[] => {
    const params: RequestParam[] = [
      {
        key: GlobalRouteParams.FromTs,
        value: dateRange.fromTs,
      },
      {
        key: GlobalRouteParams.ToTs,
        value: dateRange.toTs,
      },
    ];
    if (queryString.params.debug) {
      params.push({
        key: "debug",
        value: "true",
      });
    }
    Object.keys(queryString.params.allParams).forEach((key) => {
      if (AllRouteParams.includes(key)) {
        return;
      }
      params.push({
        key: key,
        value: queryString.params.allParams[key].values.join(""),
      });
    });
    return params;
  };

  const getBuilderDataToCreate = () => {
    const params = getParams();
    let requestWidgetBuilder: CustomWidget = {
      key:
        widgetBuilder.label.replace(/[^A-Z\d]/gi, "_").toLocaleLowerCase() +
        "_" +
        Math.random().toString(36).substring(2),
      ...widgetBuilder,
      params,
    };
    if (!widgetBuilder?.layout || widgetBuilder.isClone) {
      requestWidgetBuilder.layout = getFreshLayout(Object.values(widgets));
    }
    // For Clone, set the layout width and height same as the cloned widget
    if (
      widgetBuilder.isClone &&
      widgetBuilder.layout &&
      requestWidgetBuilder.layout
    ) {
      requestWidgetBuilder.layout.w = widgetBuilder.layout?.w;
      requestWidgetBuilder.layout.h = widgetBuilder.layout?.h;
    }

    // add unique key identifier to queries for formula
    requestWidgetBuilder = addKeysToQueries(requestWidgetBuilder);

    return requestWidgetBuilder;
  };

  const saveOrUpdateWidget = (showError = true) => {
    const nameValidation = isNameValid(widgetBuilder);
    if (!nameValidation.isValid) return;

    const configValidation = validateWidgetConfig(widgetBuilder);
    if (!configValidation.isValid) {
      if (showError) displyNotificaion("error", configValidation.msg);
      return;
    }

    let widgetToCreateOrUpdate = getBuilderDataToCreate();
    widgetToCreateOrUpdate = insertFormulas(widgetToCreateOrUpdate);

    dispatch(
      requestReportBuilderCreate(
        widgetToCreateOrUpdate,
        (status: boolean, res) => {
          if (status && !res.inflight) {
            const msg = isClone()
              ? "Builder Cloned!"
              : isUpdate()
                ? "Builder Updated!"
                : "Builder Created!";
            dispatch(showToast("success", msg));
            if (!res?.error) {
              const builderId = res?.widget?.id || -1;
              const builderItem = widgets[builderId];
              let isError: boolean | undefined = false;
              if (builderItem) {
                isError = builderItem.isError;
              }
              widgetToCreateOrUpdate.builderId = builderId;
              const scopeId = res?.widget?.scope?.id || -1;
              widgetToCreateOrUpdate.scopeId = scopeId;
              widgetToCreateOrUpdate.isError = isError;
              widgetToCreateOrUpdate.isClone = false;

              if (
                (isUpdate() || isClone()) &&
                widgetToCreateOrUpdate.widgetData
              ) {
                delete widgetToCreateOrUpdate.widgetData;
              }
              if (res.widget?.scope?.meta_data?.RawMessage?.layouts) {
                widgetToCreateOrUpdate.layout =
                  res.widget?.scope.meta_data.RawMessage.layouts;
              }
              dispatch(
                receivedBuilderFormattedData({
                  action: isUpdate() ? "update" : "add",
                  body: widgetToCreateOrUpdate,
                  inflight: false,
                })
              );
              queryString.actions.handleGoOffline();
              queryString.actions.handleTimeRange(
                dateRange.fromTs,
                dateRange.toTs,
                dateRange.range
              );
              resetBuilder(false, widgetToCreateOrUpdate);
            }
          } else if (!res.inflight) {
            dispatch(showToast("error", res?.error || "Something went wrong!"));
          }
        }
      )
    );
  };

  const queryFormulaSelection = () => {
    let queriesLenth = 0;
    if (Array.isArray(widgetBuilder.builderConfig))
      queriesLenth = widgetBuilder.builderConfig.length;

    return (
      <div>
        <div className="metric-graph-title">
          <label>Metrics</label>
        </div>
        <div
          className="query-selection-container"
          key={"queries-" + String(queriesLenth)}
        >
          {queryViews()}
        </div>
        <div
          className="formula-selection-container"
          // This is kept to re-rendered all the formulas on add/delete operation.
          // Otherwise, updated formula list won't be reflected in the UI.
          key={widgetBuilder.formulas?.length}
        >
          {formulaViews()}
        </div>
        {addQueryOrFormulaButtons()}
      </div>
    );
  };

  // Prepare list of query input control
  const queryViews = () => {
    const views: React.ReactNode[] = [];
    const builderConfig = widgetBuilder.builderConfig;
    if (Array.isArray(builderConfig)) {
      builderConfig.forEach((query: Query, index: number) => {
        const view = (
          <div key={index}>
            <QuerySelectionV2
              queryIndex={index}
              query={query}
              onQueryChange={(updatedQuery) => {
                const newWidgetBuilder = { ...widgetBuilder };
                if (Array.isArray(newWidgetBuilder.builderConfig)) {
                  newWidgetBuilder.builderConfig[index] = updatedQuery;
                } else {
                  newWidgetBuilder.builderConfig = updatedQuery;
                }

                const kpiType = toKpiType(updatedQuery.source.name);
                const app = getChartTypeFromAppId(newWidgetBuilder.widgetAppId);
                const appKey = mapAppKey(app);
                if (
                  WidgetsApp[appKey]?.disabledResourceType?.includes(kpiType)
                ) {
                  newWidgetBuilder.widgetAppId = ChartType.TimeseriesChart;
                }

                setWidgetBuilder(newWidgetBuilder);
                requestRuntimeFormatted(false, false, newWidgetBuilder);
              }}
              chartType={chartType}
              onQueryDelete={() => {
                const queryDeleteAction =
                  validateDeleteQueryAction(widgetBuilder);
                if (!queryDeleteAction.isValid) {
                  dispatch(showToast("error", queryDeleteAction.msg));
                  return;
                }

                const copy = { ...widgetBuilder };
                if (Array.isArray(copy.builderConfig)) {
                  copy.builderConfig.splice(index, 1);
                }
                setWidgetBuilder(copy);
                requestRuntimeFormatted(false, false, copy);
              }}
              builderConfig={builderConfig}
              onQueryCopy={(queryToCopy) => {
                const copy = { ...widgetBuilder };
                if (Array.isArray(copy.builderConfig)) {
                  const newQuery: Query = JSON.parse(
                    JSON.stringify(queryToCopy)
                  ) as Query;
                  copy.builderConfig.push(newQuery);
                }
                setWidgetBuilder(copy);
                requestRuntimeFormatted(false, false, copy);
              }}
            />
          </div>
        );
        views.push(view);
      });
    } else {
      const view = (
        <div key={0}>
          <QuerySelectionV2
            query={defaultQuery}
            onQueryChange={(updatedQry) => {
              const newWidgetBuilder = { ...widgetBuilder };
              newWidgetBuilder.builderConfig = [updatedQry];
              setWidgetBuilder(newWidgetBuilder);
              requestRuntimeFormatted(false, false, newWidgetBuilder);
            }}
            chartType={chartType}
            queryIndex={0}
          />
        </div>
      );
      views.push(view);
    }

    return views;
  };

  // Prepare list of formula input control
  const formulaViews = () =>
    widgetBuilder.formulas?.map((formula, idx) => (
      <Formula
        key={idx}
        formula={formula}
        formulaIdx={idx}
        isValidFormula={(formula: string) =>
          isValidFormula(formula, widgetBuilder.builderConfig)
        }
        onFormulaChange={(newFormula) => {
          const copy = JSON.parse(
            JSON.stringify(widgetBuilder)
          ) as CustomWidget;
          if (copy.formulas) copy.formulas[idx] = newFormula;
          setWidgetBuilder(copy);
          requestRuntimeFormatted(false, false, copy);
        }}
        onFormulaClone={(cloneFormula) => {
          const copy = JSON.parse(
            JSON.stringify(widgetBuilder)
          ) as CustomWidget;
          copy.formulas = [...(copy.formulas || []), cloneFormula];
          setWidgetBuilder(copy);
          requestRuntimeFormatted(false, false, copy);
        }}
        onFormulaDelete={() => {
          const deleteAction = validateDeleteFormulaAction(widgetBuilder);
          if (!deleteAction.isValid) {
            dispatch(showToast("error", deleteAction.msg));
            return;
          }

          const copy = JSON.parse(
            JSON.stringify(widgetBuilder)
          ) as CustomWidget;
          if (copy.formulas) copy.formulas.splice(idx, 1);
          setWidgetBuilder(copy);
          requestRuntimeFormatted(false, false, copy);
        }}
      />
    ));

  const isValid = validateWidgetConfig(widgetBuilder);

  const addQueryOrFormulaButtons = () => {
    const addQueryAction = validateAddQueryAction(widgetBuilder);
    const addFormulaAction = validateAddFormulaAction(widgetBuilder);

    return (
      <div className="add-query-expr-container">
        <Tooltip content={addQueryAction.msg}>
          <div>
            <Button
              prefixicon={<PlusIcon color="var(--color-text)" />}
              onClick={() => {
                const copy = { ...widgetBuilder };
                const newQuery: Query = JSON.parse(
                  JSON.stringify(defaultQuery)
                ) as Query;

                if (Array.isArray(copy.builderConfig)) {
                  newQuery.source.name = copy.builderConfig[0].source.name;
                  copy.builderConfig.push(newQuery);
                } else {
                  copy.builderConfig = [newQuery];
                }
                setWidgetBuilder(copy);
              }}
              disabled={!addQueryAction.isValid}
            >
              Add Query
            </Button>
          </div>
        </Tooltip>

        <Tooltip content={addFormulaAction.msg}>
          <div>
            <Button
              prefixicon={<PlusIcon color="var(--color-text)" />}
              onClick={() => {
                const copy = JSON.parse(
                  JSON.stringify(widgetBuilder)
                ) as CustomWidget;
                copy.formulas = [...(copy.formulas || []), ""];
                setWidgetBuilder(copy);
              }}
              disabled={!addFormulaAction.isValid}
            >
              Add Formula
            </Button>
          </div>
        </Tooltip>
      </div>
    );
  };

  const renderWidget = () => {
    const validation = validateWidgetConfig(widgetBuilder);
    if (validation.isValid) {
      return (
        <WidgetAppView
          nestedProps={props?.nestedProps}
          builderViewOptions={{
            builderView: {
              builderId: widgetBuilder.builderId,
              scopeId: widgetBuilder.scopeId,
            },
          }}
          onSortingChange={() => {
            requestRuntimeFormatted();
          }}
          preview={true}
          refreshData={() => {
            requestRuntimeFormatted();
          }}
          onDateChange={(range) => {
            setDateRange(range);
          }}
        />
      );
    } else {
      return (
        <div className="widget-builder-placeholder">
          <TypographyHeadingPrimary>{validation.msg}</TypographyHeadingPrimary>
        </div>
      );
    }
  };

  const renderGroupSelection = () => {
    return (
      <FormTextInput
        id="groupname"
        value={widgetBuilder.builderMetaData?.group_name || ""}
        onChange={(value: string) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const ty = value;
          const copy = { ...widgetBuilder };
          if (!copy.builderMetaData) {
            copy.builderMetaData = {};
          }
          copy.builderMetaData.group_name = ty;
          setWidgetBuilder(copy);
        }}
      />
    );
  };

  const renderGroupOrder = () => {
    return (
      <FormTextInput
        id="grouporder"
        value={String(widgetBuilder.builderMetaData?.group_order ?? 0)}
        type="number"
        onChange={(value: string) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const ty = parseInt(value);
          const copy = { ...widgetBuilder };
          if (!copy.builderMetaData) {
            copy.builderMetaData = {};
          }
          copy.builderMetaData.group_order = ty;
          setWidgetBuilder(copy);
        }}
      />
    );
  };

  const renderFooter = () => {
    return (
      <div className="footer-buttons">
        <Button
          secondary
          error
          onClick={() => {
            resetBuilder(true);
          }}
        >
          Cancel
        </Button>
        <Tooltip content={isValid.msg}>
          <div>
            <Button
              secondary
              disabled={!isValid.isValid}
              onClick={requestRuntimeFormatted}
            >
              Preview Data
            </Button>
          </div>
        </Tooltip>
        <Tooltip content={isValid.msg || isNameValid(widgetBuilder).msg}>
          <div>
            <Button
              disabled={
                !(isValid.isValid && isNameValid(widgetBuilder).isValid)
              }
              primary
              onClick={saveOrUpdateWidget}
            >
              {isClone()
                ? "Clone Widget"
                : isUpdate()
                  ? "Update Widget"
                  : "Create Widget"}
            </Button>
          </div>
        </Tooltip>
      </div>
    );
  };

  const graphTypeList = () => {
    if (
      !widgetBuilder?.builderConfig ||
      !Array.isArray(widgetBuilder.builderConfig)
    )
      return null;
    const kpiType = toKpiType(
      widgetBuilder?.builderConfig?.[0]?.source?.name || ""
    );

    return (
      <div className={"graph-type-grid"}>
        {Object.values(WidgetsApp)
          .filter((a) => !a.disabled)
          .map((item, key: number) => {
            return (
              <div
                key={key}
                className={`graph-type-grid-item ${chartType === item.key ? "selected" : "deselected"} ${item.disabledResourceType?.includes(kpiType) ? "disabled" : ""}`}
                onClick={() => {
                  if (chartType !== item.key) {
                    setWidgetBuilder({
                      ...widgetBuilder,
                      widgetAppId: item.id,
                      ...((item.id === ChartType.TimeseriesChart ||
                        item.id === ChartType.BarChart) && {
                        builderMetaData: {
                          chartType:
                            item.id === ChartType.TimeseriesChart
                              ? TimeseriesChartType
                              : BarChartType,
                        },
                      }),
                    });
                  }
                }}
              >
                <div className="item__icon">{item.icon}</div>
                <span className="label">{item.label}</span>
              </div>
            );
          })}
      </div>
    );
  };

  const graphTreatment = () => (
    <div className="chart-type-container">
      <TabSelection
        selectedTab={
          chartType === ChartType.TimeseriesChart
            ? widgetBuilder.builderMetaData?.chartType === "area_chart"
              ? 2
              : 1
            : widgetBuilder.builderMetaData?.chartType === "stacked_bar_chart"
              ? 4
              : 3
        }
        tabs={
          chartType === ChartType.TimeseriesChart
            ? [
                {
                  label: "Timeseries",
                  value: 1,
                },
                {
                  label: "Area Chart",
                  value: 2,
                },
              ]
            : [
                {
                  label: "Bar Chart",
                  value: 3,
                },
                {
                  label: "Stacked Bar Chart",
                  value: 4,
                },
              ]
        }
        onTabChange={(selectedTab) => {
          const selectedChart =
            chartType === ChartType.TimeseriesChart
              ? selectedTab.value === 1
                ? "timeseries_chart"
                : "area_chart"
              : selectedTab.value === 3
                ? "bar_chart"
                : "stacked_bar_chart";

          const copy = { ...widgetBuilder };
          if (!copy.builderMetaData) {
            copy.builderMetaData = {};
          }
          copy.builderMetaData.chartType = selectedChart;
          setWidgetBuilder(copy);
          requestRuntimeFormatted(false, false, copy);
        }}
      />
    </div>
  );

  const displayPreference = () => (
    <DropdownSelection
      options={AvailableDateRange.filter((o) => o.range !== undefined).map(
        (o) => ({
          label: `${o.range !== TimeRangeOption.Empty ? "Past " : ""}${o.label}`,
          value: o.range as string, // Ensure value is not undefined
        })
      )}
      onChange={(value: string | string[]) => {
        const selectedValue = value as TimeRangeOption;
        const copy = { ...widgetBuilder };
        if (!copy.builderMetaData) {
          copy.builderMetaData = {};
        }
        copy.builderMetaData.display_preference = selectedValue;
        setWidgetBuilder(copy);
        requestRuntimeFormatted(false, false, copy);
      }}
      defaultValue={widgetBuilder.builderMetaData?.display_preference || ""}
      notFoundContent="No options found"
    />
  );

  const renderContent = () => {
    return (
      <div className="widget-builder-wrapper-v2">
        <div className="content">
          <div className="left-section">
            <div className="left-section-item">
              <label>Graph Type</label>
              {graphTypeList()}
            </div>
            {(chartType === ChartType.TimeseriesChart ||
              chartType === ChartType.BarChart) && (
              <div className="left-section-item">
                <label>Graph Treatment</label>
                {graphTreatment()}
              </div>
            )}
            <div className="left-section-item">
              <label>Display Preference</label>
              {displayPreference()}
            </div>
            {queryString.params.debug && (
              <>
                <div className="left-section-item">
                  <label>Group Selection</label>
                  {renderGroupSelection()}
                </div>
                <div className="left-section-item">
                  <label>Group Order</label>
                  {renderGroupOrder()}
                </div>
              </>
            )}
          </div>
          <div className="right-section">
            <div className="upper-row">
              <div className="widget-builder-widget">{renderWidget()}</div>
            </div>
            <div className="chart-seprator"></div>
            <div className="lower-row">{queryFormulaSelection()}</div>
          </div>
        </div>
      </div>
    );
  };

  const renderHeaderElements = () => (
    <FormTextInput
      isError={!isNameValid(widgetBuilder).isValid}
      id="widgetname"
      onChange={(value) => {
        setWidgetBuilder({ ...widgetBuilder, label: value });
      }}
      value={widgetBuilder.label}
      placeholder="Enter Widget Name"
    />
  );

  return (
    <GlobalCenterDialog
      size="full"
      isOpen={true}
      onClose={() => {
        resetBuilder(true);
      }}
      headerElements={renderHeaderElements()}
      bodyPadding={false}
      footer={renderFooter()}
      dialogContentClassName="create-new-widget-class"
      rightAlignedHeaderElements={
        <GlobalDatePicker
          onDateChange={(range) => {
            setDateRange(range);
          }}
        />
      }
    >
      {renderContent()}
    </GlobalCenterDialog>
  );
};

export default ManageWidget;
