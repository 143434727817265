import { TimeRangeOption } from "core/components/datepicker/model";
import DatePicker from "core/components/v2/date-picker";
import { defaultPresetOptions } from "core/components/v2/date-picker/presetOptions";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import MwRouteContext from "views/layouts/app/routes/MWRouteContext";
import { DateRange } from "views/layouts/app/routes/model";

interface Props {
  dataTestId?: string;
  onDateChange?: (range: DateRange) => void;
}
const GlobalDatePicker = (props: Props) => {
  const { dataTestId, onDateChange } = props;
  const routeData = useContext(MwRouteContext);
  const dateRange = routeData.params.dateRange;
  const { fromTs, toTs, interval, range } = dateRange;
  const getBtnText = (selectedRange: DateRange) => {
    const option = defaultPresetOptions.filter(
      (option) => option?.timeRangeOption === selectedRange?.range
    )[0];
    return option?.timeRangeOption === TimeRangeOption.Custom
      ? `${dayjs.unix(selectedRange.fromTs / 1000).format("MMM DD HH:mm")} - ${dayjs.unix(selectedRange.toTs / 1000).format("MMM DD HH:mm")}`
      : option?.label;
  };
  const sampleDefaultValue: DateRange = {
    fromTs: fromTs,
    toTs: toTs,
    interval: interval,
    range: range,
  };

  const [isDatePickerVisible, setIsDatePickerVisible] = React.useState(false);
  const [btnText, setBtnText] = useState<string>(
    getBtnText(sampleDefaultValue)
  );

  const handleDatePickerVisibility = (value: boolean) => {
    setIsDatePickerVisible(value);
  };
  const onChange = (range: DateRange) => {
    setIsDatePickerVisible(false);
    if (onDateChange) {
      onDateChange(range);
    } else {
      routeData.actions.handleTimeRange(range.fromTs, range.toTs, range.range);
      routeData.actions.handleGoOffline();
    }
    setBtnText(getBtnText(range));
  };

  useEffect(() => {
    setBtnText(getBtnText(sampleDefaultValue));
  }, [routeData]);

  return (
    <DatePicker
      isDatePickerVisible={isDatePickerVisible}
      handleDatePickerVisibility={handleDatePickerVisibility}
      datePickerBtnText={btnText}
      onChange={onChange}
      defaultValue={sampleDefaultValue}
      disableFutureDates={true}
      dataTestId={dataTestId}
    />
  );
};
export default GlobalDatePicker;
