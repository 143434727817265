import {
  EscapeKeyIcon,
  KeyboardArrowDownKeyIcon,
  KeyboardArrowUpKeyIcon,
  KeyboardReturnKeyIcon,
} from "core/components/v2/svg/icons";
import React from "react";
import "./style.scss";

export default function MetricMetadataFooterView() {
  return (
    <div className="metric-metadata-footer-container">
      <div className="section">
        <span>Use arrow keys</span>
        <KeyboardArrowUpKeyIcon />
        <KeyboardArrowDownKeyIcon />
        <span>to navigate &</span>
        <KeyboardReturnKeyIcon />
        <span>to select</span>
      </div>

      <div className="section">
        <EscapeKeyIcon />
        <span className="ins-title">to dismiss</span>
      </div>
    </div>
  );
}
