import React from "react";
import { Resizable } from "react-resizable";
import { InfoIconSvg } from "core/components/svg/icons";
import Tooltip from "core/components/v2/tooltip";

export const ResizableTitle: React.FC<any> = (props) => {
  const { onResize, width, tooltipInfo, children, ...restProps } = props;

  return (
    <Resizable
      width={width ?? 100}
      height={0}
      handle={
        <span
          className="react-resizable-handle"
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th
        {...restProps}
        className={`${tooltipInfo ? `header-resizable-title ${restProps?.style?.textAlign ?? "left"}` : ""} `}
      >
        <span className={"dragHandler"}>
          {Array.isArray(children) && typeof children?.[1] === "string" ? (
            <span className="ant-table-column-title">{children}</span>
          ) : (
            children
          )}
        </span>
        {typeof tooltipInfo == "string" && (
          <span className={"column-info"}>
            <Tooltip content={tooltipInfo}>
              <i>
                <InfoIconSvg />
              </i>
            </Tooltip>
          </span>
        )}
      </th>
    </Resizable>
  );
};
