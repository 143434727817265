import Tooltip from "core/components/v2/tooltip";
import * as React from "react";
import { Align } from "../v2/enums";
import CircularProgressbar from "../v2/progress-bar/circular-progressbar";

export interface ProgressBarPercentProps {
  error?: boolean;
  tooltipId?: string;
  tooltipContent?: React.JSX.Element;
  progressValue: number;
  align?: Align;
}

export const ProgressBarWithPercent = function (
  props: ProgressBarPercentProps
) {
  let _val = props.progressValue;
  if (_val > 100) _val = 99.99;
  return (
    <React.Fragment>
      {props.tooltipId ? (
        <Tooltip content={props.tooltipContent}>
          <div
            className={`progress-bar__with_percentage__wrapper ${props.align?.toString()}`}
          >
            <CircularProgressbar progress={_val} />
          </div>
        </Tooltip>
      ) : (
        <div
          className={`progress-bar__with_percentage__wrapper ${props.align?.toString()}`}
        >
          <CircularProgressbar progress={_val} />
        </div>
      )}
    </React.Fragment>
  );
};
