import {
  METRICS_KEY_CONTAINER,
  METRICS_KEY_HOST,
  METRICS_KEY_K8S_CLUSTER,
  METRICS_KEY_K8S_CLUSTER_ROLES,
  METRICS_KEY_K8S_CLUSTER_ROLE_BINDINGS,
  METRICS_KEY_K8S_CONTAINER,
  METRICS_KEY_K8S_CRONJOB,
  METRICS_KEY_K8S_DAEMONSET,
  METRICS_KEY_K8S_DEPLOYMENT,
  METRICS_KEY_K8S_HPA,
  METRICS_KEY_K8S_INGRESSES,
  METRICS_KEY_K8S_JOB,
  METRICS_KEY_K8S_NAMESPACE,
  METRICS_KEY_K8S_NODE,
  METRICS_KEY_K8S_PERSISTENT_VOLUMES,
  METRICS_KEY_K8S_PERSISTENT_VOLUME_CLAIMS,
  METRICS_KEY_K8S_POD,
  METRICS_KEY_K8S_REPLICASET,
  METRICS_KEY_K8S_ROLES,
  METRICS_KEY_K8S_ROLE_BINDINGS,
  METRICS_KEY_K8S_SERVICE,
  METRICS_KEY_K8S_SERVICE_ACCOUNTS,
  METRICS_KEY_K8S_STATEFULSET,
  METRICS_KEY_PROCESS,
  getDarkColor,
} from "views/modules/builder/common/utils";
import { ColumnConfigDetails } from "views/modules/builder/entities/builder.entities";
export enum InfraDialogTabType {
  METRICS = "metrics",
  LOGS = "logs",
  TABLE_LIST = "table_list",
  INFO = "info",
  APM = "apm",
}
export enum InfoListCardType {
  NORMAL_CARD = "normal_card",
  IMAGE_CARD = "image_card",
  COLOR_CARD = "color_card",
  ERROR_CARD = "error_card",
  WARNING_CARD = "warning_card",
  COPY_CARD = "copy_card",
  GRID_CARD = "grid_card",
  EXTRA_CARD = "extra_card",
  MULTI_CARDS = "multi_cards",
  GRAPH_CARD = "graph_card",
  EXTRA_GRAPH_CARD = "extra_graph_card",
  K8s_EVENT_CARD = "k8s_event_card",
  K8s_EVENT_LIST_CARD = "k8s_event_list_card",
  K8s_YAML = "k8s_yaml",
  K8s_LABEL = "k8s_label",
  K8s_ANNOTATION = "k8s_annotation",
  CUSTOM_CARD_COMMA_SEPERATED = "custom_card_comma_seperated",
  CUSTOM_CARD_AMPERSAND_SEPERATED = "custom_card_ampersand_seperated",
  CUSTOM_CARD_SEMICOLON_SEP_TABLE = "custom_card_semicolon_sep_table",
}

export enum InfoListGridCardType {
  CUSTOM = "custom",
  NORMAL = "normal",
}

export interface TableListContentType {
  label: string;
  locationMetrics: string;
  locationScope: string;
  subLocationMetrics?: string;
}

export type InfoListContentType = {
  type: InfoListCardType;
  card?: {
    title: string;
    titleInfo: string;
    key: string;
    additionalProperties?: {
      type: string;
      value: string;
    };
  };
  graphCard?: MetricChartItem[];
  cardWithCopy?: {
    title: string;
    titleInfo: string;
    key: string;
  };
  gridCard?: {
    type: InfoListGridCardType; //normalitem , customitem
    title: string;
    titleInfo: string;
    subItems?: {
      title: string;
      key: string;
      type?: string;
      linkResource?: string;
    }[];
    subItemsCustom?: {
      title: string;
      key: string;
      columns: string[];
      filterByKey: string;
      resourceType: string;
      filters?: {
        key: string;
        value: string[];
      }[];
      dataByColumn?: string;
    }[];
  };
}[];

export interface AdditionalResourceItem {
  columns: string[];
  resourceType: string;
  with?: {
    key: string;
    value: string[];
    is_arg: boolean;
  }[];
  columnConfig?: Record<string, ColumnConfigDetails>;
  primaryColumnKey: string;
}
export type CustomObject = Record<
  string,
  | string
  | number
  | number[]
  | string[]
  | Record<string, string | number | string[]>
>;
export interface MetricChartItem {
  label: string;
  yAxisLabel?: string;
  description?: string;
  resource?: {
    columns: string[];
    resourceType?: string;
    with?: {
      key: string;
      value:
        | string[]
        | Record<
            string,
            | string
            | number[]
            | number
            | string[]
            | CustomObject[]
            | CustomObject
          >;
      is_arg: boolean;
    }[];
    columnConfig?: Record<string, ColumnConfigDetails>;
  };
  color?: string;
  widgetAppId?: number;
  additional_resource?: AdditionalResourceItem[];
}
export type MetricChartTabType = {
  type: string;
  items: MetricChartItem[];
}[];
export type InfraDialogTabContent =
  | MetricChartTabType
  | TableListContentType
  | InfoListContentType;
export interface InfraDialogConfig {
  header: {
    key: string;
    type: string;
    label?: string;
  }[];
  secondary?: {
    key: string;
    type?: string;
    label?: string;
    linkResource?: string;
    description?: string;
  }[];
  cards?: {
    key: string;
    type: string;
    label?: string;
    bgColor?: string;
    description?: string;
  }[];
  rowCard?: {
    key: string;
    type: string;
    label?: string;
    bgColor?: string;
  }[];
  tabs?: {
    label: string;
    key: string;
    tabType: InfraDialogTabType;
    content: InfraDialogTabContent;
  }[];
  // custom columns
  customColumns?: {
    title: string;
    key: string;
    columns: string[];
    filterByKey: string;
    resourceType: string;
    filters?: {
      key: string;
      value: string[];
    }[];
    dataByColumn?: string;
  }[];
  subResource?: string;
  dataByColumnForDetails?: string;
}
export type DialogConfig = Record<string, InfraDialogConfig>;

export const K8sDialogConfig: DialogConfig = {
  [METRICS_KEY_HOST]: {
    header: [
      {
        label: "Host",
        key: "host.name",
        type: "simple_card",
      },
      {
        label: "OS",
        key: "os.type",
        type: "simple_card",
      },
      {
        label: "CPU Usage",
        key: "system.cpu.utilization",
        type: "simple_card",
      },
      {
        label: "Memory Usage",
        key: "system.memory.utilization",
        type: "simple_card",
      },
      {
        label: "Avg Load Time (15m)",
        key: "system.cpu.load_average.15m",
        type: "simple_card",
      },
    ],
    dataByColumnForDetails: "host.name",
    tabs: [
      {
        label: "Metrics",
        key: "tab_metrics",
        tabType: InfraDialogTabType.METRICS,
        content: [
          {
            type: "metrics_chart",
            items: [
              {
                resource: {
                  columns: ["system.cpu.utilization"],
                  with: [
                    {
                      is_arg: true,
                      key: "SELECT_DATA_BY",
                      value: ["cpu"],
                    },
                  ],
                },
                label: "CPU usage (%)",
              },
              {
                resource: {
                  columns: ["system.cpu.utilization"],
                  with: [
                    {
                      is_arg: true,
                      key: "SELECT_DATA_BY",
                      value: ["state"],
                    },
                  ],
                },
                label: "CPU usage by State (%)",
              },
              {
                label: "CPU Load Average",
                resource: {
                  columns: [
                    "system.cpu.load_average.15m",
                    "system.cpu.load_average.1m",
                    "system.cpu.load_average.5m",
                  ],
                  with: [
                    {
                      is_arg: true,
                      key: "SELECT_DATA_BY",
                      value: ["host.id"],
                    },
                  ],
                },
              },
              {
                label: "Swap Memory by State (Bytes)",
                resource: {
                  columns: ["system.paging.usage"],
                  with: [
                    {
                      is_arg: true,
                      key: "SELECT_DATA_BY",
                      value: ["state"],
                    },
                  ],
                },
              },
              {
                label: "Memory usage by State (Bytes)",
                resource: {
                  columns: ["system.memory.usage"],
                  with: [
                    {
                      is_arg: true,
                      key: "SELECT_DATA_BY",
                      value: ["state"],
                    },
                  ],
                },
              },
              {
                label: "Memory usage by State (%)",
                resource: {
                  columns: ["system.memory.utilization"],
                  with: [
                    {
                      is_arg: true,
                      key: "SELECT_DATA_BY",
                      value: ["state"],
                    },
                  ],
                },
              },
              {
                label: "Network IO by Direction (Bytes)",
                resource: {
                  columns: ["system.network.io"],
                  with: [
                    {
                      is_arg: true,
                      key: "SELECT_DATA_BY",
                      value: ["direction"],
                    },
                  ],
                },
              },
              {
                label: "Disk IO speed by Direction (Bytes/Second)",
                resource: {
                  columns: ["system.disk.io.speed"],
                  with: [
                    {
                      is_arg: true,
                      key: "SELECT_DATA_BY",
                      value: ["direction"],
                    },
                  ],
                },
              },
              {
                label: "Network connections (Protocol)",
                resource: {
                  columns: ["system.network.connections"],
                  with: [
                    {
                      is_arg: true,
                      key: "SELECT_DATA_BY",
                      value: ["protocol"],
                    },
                  ],
                },
              },
              {
                label: "Network connections by State",
                resource: {
                  columns: ["system.network.connections"],
                  with: [
                    {
                      is_arg: true,
                      key: "SELECT_DATA_BY",
                      value: ["state"],
                    },
                  ],
                },
              },
              {
                label: "Network Error Rate by Direction (Bytes)",
                resource: {
                  columns: ["system.network.errors.rate"],
                  with: [
                    {
                      is_arg: true,
                      key: "SELECT_DATA_BY",
                      value: ["direction"],
                    },
                  ],
                },
              },
              {
                label: "Network Dropped Rate by Direction (Packets)",
                resource: {
                  columns: ["system.network.dropped.rate"],
                  with: [
                    {
                      is_arg: true,
                      key: "SELECT_DATA_BY",
                      value: ["direction"],
                    },
                  ],
                },
              },
              {
                label: "Network packets by Direction (Packets)",
                resource: {
                  columns: ["system.network.packets"],
                  with: [
                    {
                      is_arg: true,
                      key: "SELECT_DATA_BY",
                      value: ["direction"],
                    },
                  ],
                },
              },
              {
                label: "Network IO Rate by Direction (Bytes)",
                resource: {
                  columns: ["system.network.io.rate"],
                  with: [
                    {
                      is_arg: true,
                      key: "SELECT_DATA_BY",
                      value: ["direction"],
                    },
                  ],
                },
              },
              {
                label: "Disk operations by Direction (Bytes)",
                resource: {
                  columns: ["system.disk.operations.rate"],
                  with: [
                    {
                      is_arg: true,
                      key: "SELECT_DATA_BY",
                      value: ["direction"],
                    },
                  ],
                },
              },
              {
                label: "Network packets Rate by Direction (Packets)",
                resource: {
                  columns: ["system.network.packets.rate"],
                  with: [
                    {
                      is_arg: true,
                      key: "SELECT_DATA_BY",
                      value: ["direction"],
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },
  [METRICS_KEY_CONTAINER]: {
    header: [
      {
        key: "container.name",
        type: "simple_card",
      },
      {
        label: "Started on",
        key: "container.started_on",
        type: "badge_date_card",
      },
    ],
    secondary: [
      {
        label: "Host",
        key: "host.id",
      },
      {
        label: "Container Host",
        key: "container.hostname",
      },
      {
        label: "Image Name",
        key: "container.image.name",
      },
      {
        label: "ID",
        key: "container.id",
      },
      {
        label: "OS",
        key: "os.type",
      },
    ],
    cards: [
      {
        label: "CPU",
        key: "container.cpu.percent",
        type: "simple_card",
        bgColor: "rgb(255, 220, 229)",
      },
      {
        label: "Memory",
        key: "container.memory.percent",
        type: "simple_card",
        bgColor: "rgb(255, 234, 182)",
      },
      {
        label: "Memory Limit",
        key: "container.memory.usage.limit",
        type: "simple_card",
        bgColor: "rgb(254, 226, 218)",
      },
      {
        label: "Memory Total",
        key: "container.memory.usage.total",
        type: "simple_card",
        bgColor: "rgb(254, 206, 195)",
      },
    ],
    tabs: [
      {
        label: "Container Info",
        key: "tab_info",
        tabType: InfraDialogTabType.INFO,
        content: [
          {
            type: InfoListCardType.NORMAL_CARD,
            card: {
              title: "CPU",
              titleInfo: "CPU usage by container",
              key: "container.cpu.utilization",
            },
          },
          {
            type: InfoListCardType.COLOR_CARD,
            card: {
              title: "MEMORY",
              titleInfo: "Memory usage by container",
              key: "container.memory.usage",
            },
          },
          {
            type: InfoListCardType.NORMAL_CARD,
            card: {
              title: "MEMORY LIMIT",
              titleInfo: "container cpu memory limit",
              key: "container.memory.usage.limit",
            },
          },
          {
            type: InfoListCardType.NORMAL_CARD,
            card: {
              title: "MEMORY TOTAL",
              titleInfo: "container total memory",
              key: "container.memory.usage.total",
            },
          },
          {
            type: InfoListCardType.GRID_CARD,
            gridCard: {
              type: InfoListGridCardType.NORMAL,
              title: "CONTAINER INFORMATION",
              titleInfo: "Container related information",
              subItems: [
                {
                  title: "Host",
                  key: "host.id",
                },
                {
                  title: "Container Host",
                  key: "container.hostname",
                },
                {
                  title: "Image Name",
                  key: "container.image.name",
                },
                {
                  title: "ID",
                  key: "container.id",
                },
                {
                  title: "OS",
                  key: "os.type",
                },
              ],
            },
          },
        ],
      },
      {
        label: "Metrics",
        tabType: InfraDialogTabType.METRICS,
        key: "tab_metrics",
        content: [
          {
            type: "metrics_chart",
            items: [
              {
                label: "CPU usage (%)",
                resource: {
                  columns: ["container.cpu.percent"],
                },
                color: "#FFC154",
              },
              {
                label: "Memory usage (%)",
                resource: {
                  columns: ["container.memory.percent"],
                },
                color: "#47B39C",
              },
              {
                label: "Network RX (Packets)",
                resource: {
                  columns: ["container.network.io.usage.rx_bytes"],
                },
                color: "#61c1e8",
              },
              {
                label: "Network RX Dropped (Packets)",
                resource: {
                  columns: ["container.network.io.usage.rx_dropped"],
                },
                color: "#a3a3d3",
              },
              {
                label: "Network TX (Bytes)",
                resource: {
                  columns: ["container.network.io.usage.tx_bytes"],
                },
                color: "#FFFF99",
              },
              {
                label: "Network TX Dropped (Packets)",
                resource: {
                  columns: ["container.network.io.usage.tx_dropped"],
                },
                color: "#affec5",
              },
            ],
          },
        ],
      },
      {
        label: "Host",
        tabType: InfraDialogTabType.TABLE_LIST,
        key: "tab_host",
        content: {
          label: "Host list",
          locationMetrics: "host.id",
          locationScope: METRICS_KEY_HOST,
        },
      },
      {
        label: "Logs",
        key: "tab_logs",
        tabType: InfraDialogTabType.LOGS,
        content: [
          {
            type: "logs",
            items: [],
          },
        ],
      },
    ],
  },
  [METRICS_KEY_PROCESS]: {
    header: [
      {
        key: "process.executable.name",
        type: "simple_card",
      },
      {
        key: "process.started_on",
        type: "badge_date_ago",
      },
    ],
    secondary: [
      {
        label: "Host",
        key: "host.id",
      },
      {
        label: "Owner",
        key: "process.owner",
      },
      {
        label: "PID",
        key: "process.pid",
      },
    ],
    cards: [
      {
        label: "OPERATING SYSTEM",
        key: "os.type",
        type: "simple_card",
        bgColor: "#FEDBE5",
        description: "Operating System Type",
      },
      {
        label: "CPU UTILIZATION",
        key: "process.cpu.percent",
        type: "simple_card",
        bgColor: "#FEDBE5",
        description: "Process CPU Usage",
      },
      {
        label: "MEMORY UTILIZATION",
        key: "process.memory.percent",
        type: "simple_card",
        bgColor: "#FFEAB7",
        description: "Process Memory Usage",
      },
      {
        label: "DISK I/O",
        key: "process.disk.io",
        type: "simple_card",
        bgColor: "#FEE2D9",
        description: "Process Disk I/O Usage",
      },
      {
        label: "Physical Usage",
        key: "process.memory.usage",
        type: "simple_card",
        bgColor: "#FECFC4",
      },
      {
        label: "Virtual Usage",
        key: "process.memory.virtual",
        type: "simple_card",
        bgColor: "#FFEAB7",
        description: "Process Virtual Memory Usage",
      },
    ],
    rowCard: [
      {
        label: "Full Command",
        key: "process.command_line",
        type: "card_code_editor",
      },
    ],
    tabs: [
      {
        label: "Process Info",
        key: "tab_info",
        tabType: InfraDialogTabType.INFO,
        content: [
          {
            type: InfoListCardType.IMAGE_CARD,
            card: {
              title: "OPERATING SYSTEM",
              titleInfo: "Operating System Type",
              key: "os.type",
              additionalProperties: {
                type: "image",
                value: "os.type",
              },
            },
          },
          {
            type: InfoListCardType.COLOR_CARD,
            card: {
              title: "CPU UTILIZATION",
              titleInfo: "CPU Utilization",
              key: "process.cpu.percent",
              additionalProperties: {
                type: "color",
                value: "#079455",
              },
            },
          },
          {
            type: InfoListCardType.COLOR_CARD,
            card: {
              title: "MEMORY UTILIZATION",
              titleInfo: "Memory Utilization",
              key: "process.memory.percent",
              additionalProperties: {
                type: "color",
                value: "#079455",
              },
            },
          },
          {
            type: InfoListCardType.NORMAL_CARD,
            card: {
              title: "DISK I/O",
              titleInfo: "Process Disk I/O Usage",
              key: "process.disk.io",
            },
          },
          {
            type: InfoListCardType.NORMAL_CARD,
            card: {
              title: "PHYSICAL USAGE",
              titleInfo: "Physical usage",
              key: "process.memory.usage",
            },
          },
          {
            type: InfoListCardType.NORMAL_CARD,
            card: {
              title: "VIRTUAL USAGE",
              titleInfo: "Virtual memory usage",
              key: "process.memory.virtual",
            },
          },
          {
            type: InfoListCardType.GRID_CARD,
            gridCard: {
              type: InfoListGridCardType.NORMAL,
              title: "PROCESS INFORMATION",
              titleInfo: "Process related information",
              subItems: [
                {
                  title: "Host",
                  key: "host.id",
                },
                {
                  title: "PID",
                  key: "process.pid",
                },
                {
                  title: "OWNER",
                  key: "process.owner",
                },
              ],
            },
          },
          {
            type: InfoListCardType.COPY_CARD,
            card: {
              title: "Full Command",
              titleInfo: "Full command of the process",
              key: "process.command_line",
            },
          },
        ],
      },
      {
        label: "Metrics",
        key: "tab_metrics",
        tabType: InfraDialogTabType.METRICS,
        content: [
          {
            type: "metrics_chart",
            items: [
              {
                label: "CPU usage (%)",
                resource: {
                  columns: ["process.cpu.percent"],
                },
                color: "#FFC154",
              },
              {
                label: "CPU Time (Second)",
                resource: {
                  columns: ["process.cpu.time"],
                  with: [
                    {
                      key: "SELECT_DATA_BY",
                      value: ["state"],
                      is_arg: true,
                    },
                  ],
                },
              },
              {
                label: "Memory usage (%)",
                resource: {
                  columns: ["process.memory.percent"],
                },
                color: "#61c1e8",
              },
              {
                label: "Physical Memory Usage (Bytes)",
                resource: {
                  columns: ["process.memory.usage"],
                },
                color: "#a3a3d3",
              },
              {
                label: "Virtual Memory Usage (Bytes)",
                resource: {
                  columns: ["process.memory.virtual"],
                },
                color: "#FFFF99",
              },
            ],
          },
        ],
      },
      {
        label: "Host",
        key: "tab_host",
        tabType: InfraDialogTabType.TABLE_LIST,
        content: {
          label: "Host list",
          locationMetrics: "host.id",
          locationScope: METRICS_KEY_HOST,
        },
      },
      {
        label: "Logs",
        key: "tab_logs",
        tabType: InfraDialogTabType.LOGS,
        content: [
          {
            type: "logs",
            items: [],
          },
        ],
      },
    ],
  },
  [METRICS_KEY_K8S_CLUSTER]: {
    header: [
      {
        label: "Cluster",
        key: "k8s.cluster.name",
        type: "simple_card",
      },
      // {
      //     "label": "Host",
      //     "key": "host.id",
      //     "type": "simple_card"
      // },
      // {
      //     "label": "OS",
      //     "key": "os.type",
      //     "type": "simple_card"
      // },
    ],
    cards: [
      {
        label: "CPU",
        key: "k8s.node.cpu.utilization",
        type: "simple_card",
        bgColor: "rgb(255, 220, 229)",
      },
      {
        label: "Memory",
        key: "k8s.node.memory.usage",
        type: "simple_card",
        bgColor: "rgb(255, 234, 182)",
      },
      {
        label: "Network I/O",
        key: "k8s.node.network.io",
        type: "simple_card",
        bgColor: "rgb(254, 226, 218)",
      },
    ],
    subResource: METRICS_KEY_K8S_NODE,
    dataByColumnForDetails: "k8s.cluster.name",
    customColumns: [
      {
        columns: ["uniq(k8s.node.name)"],
        filterByKey: "k8s.cluster.name",
        resourceType: METRICS_KEY_K8S_NODE,
        title: "Total Nodes",
        key: "total_nodes",
      },
      {
        columns: ["uniq(k8s.namespace.name)"],
        filterByKey: "k8s.cluster.name",
        resourceType: METRICS_KEY_K8S_NAMESPACE,
        title: "Total Namespaces",
        key: "total_namespaces",
      },
      {
        columns: ["uniq(k8s.pod.name)"],
        filterByKey: "k8s.cluster.name",
        resourceType: METRICS_KEY_K8S_POD,
        title: "Total Pods",
        key: "total_pods",
      },
      {
        columns: ["uniq(k8s.container.name)"],
        filterByKey: "k8s.cluster.name",
        resourceType: METRICS_KEY_K8S_CONTAINER,
        title: "Total Containers",
        key: "total_containers",
      },
      {
        columns: ["uniq(k8s.pod.name)"],
        filterByKey: "k8s.cluster.name",
        resourceType: METRICS_KEY_K8S_POD,
        title: "Running Pods",
        key: "running_pods",
      },
      {
        columns: ["uniq(k8s.pod.name)"],
        filterByKey: "k8s.cluster.name",
        resourceType: METRICS_KEY_K8S_POD,
        title: "Pending or Failed Pods",
        key: "running_pods",
        filters: [
          {
            key: "k8s.pod.phase",
            value: ["5"],
          },
        ],
      },
    ],
    tabs: [
      {
        label: "Overview",
        key: "tab_host",
        tabType: InfraDialogTabType.INFO,
        content: [
          {
            type: InfoListCardType.GRAPH_CARD,
            graphCard: [
              {
                label: "CPU Usage",
                description: "Average of CPU Usage of nodes in cluster",
                resource: {
                  columns: ["avg(k8s.node.cpu.utilization,value(avg))"],
                },
                color: "#FFC154",
              },
              {
                label: "Memory Usage",
                description: "Average of Memory Usage of nodes in cluster",
                resource: {
                  columns: ["avg(k8s.node.memory.usage,value(avg))"],
                },
                color: "#47B39C",
              },
              {
                label: "Events",
                description: "Events occured in cluster",
                resource: {
                  columns: ["total_logs"],
                  columnConfig: {
                    total_info: {
                      color: "blue",
                    },
                    total_error: {
                      color: "red",
                    },
                    total_warnings: {
                      color: "orange",
                      bgColor: "orange",
                    },
                  },
                },
                color: "#47B39C",
              },
            ],
          },
          {
            type: InfoListCardType.EXTRA_GRAPH_CARD,
            graphCard: [
              {
                label: "Pod Phase",
                description: "Phase of Pods in cluster",
                resource: {
                  resourceType: "k8s.pod",
                  columns: ["uniq(k8s.pod.uid)"],
                  with: [
                    {
                      key: "SELECT_DATA_BY",
                      value: ["k8s.pod.phase"],
                      is_arg: true,
                    },
                  ],
                },
                color: "blue",
                widgetAppId: 3,
              },
              {
                label: "Container Phase",
                description: "Phase of Containers in cluster",
                resource: {
                  resourceType: "k8s.container",
                  columns: ["uniq(k8s.container.name)"],
                  with: [
                    {
                      key: "SELECT_DATA_BY",
                      value: ["k8s.container.ready"],
                      is_arg: true,
                    },
                  ],
                },
                color: "#47B39C",
                widgetAppId: 3,
              },
            ],
          },
          {
            type: InfoListCardType.GRID_CARD,
            gridCard: {
              type: InfoListGridCardType.CUSTOM,
              title: "CLUSTER INFORMATION",
              titleInfo: "Cluster related information",
              subItemsCustom: [
                {
                  columns: ["uniq(k8s.node.name)"],
                  filterByKey: "k8s.cluster.name",
                  resourceType: METRICS_KEY_K8S_NODE,
                  title: "Total Nodes",
                  key: "total_nodes",
                },
                {
                  columns: ["uniq(k8s.namespace.name)"],
                  filterByKey: "k8s.cluster.name",
                  resourceType: METRICS_KEY_K8S_NAMESPACE,
                  title: "Total Namespaces",
                  key: "total_namespaces",
                },
                {
                  columns: ["uniq(k8s.pod.name)"],
                  filterByKey: "k8s.cluster.name",
                  resourceType: METRICS_KEY_K8S_POD,
                  title: "Total Pods",
                  key: "total_pods",
                },
                {
                  columns: ["uniq(k8s.container.name)"],
                  filterByKey: "k8s.cluster.name",
                  resourceType: METRICS_KEY_K8S_CONTAINER,
                  title: "Total Containers",
                  key: "total_containers",
                },
                {
                  columns: ["uniq(k8s.pod.name)"],
                  filterByKey: "k8s.cluster.name",
                  resourceType: METRICS_KEY_K8S_POD,
                  title: "Running Pods",
                  key: "running_pods",
                },
                {
                  columns: ["uniq(k8s.pod.name)"],
                  filterByKey: "k8s.cluster.name",
                  resourceType: METRICS_KEY_K8S_POD,
                  title: "Pending or Failed Pods",
                  key: "running_pods",
                  filters: [
                    {
                      key: "k8s.pod.phase",
                      value: ["5"],
                    },
                  ],
                },
              ],
            },
          },
          {
            type: InfoListCardType.K8s_EVENT_CARD,
            card: {
              title: "Top Events",
              titleInfo: "List of top 5 K8s Events occured in cluster",
              key: "top_5_k8s_events",
            },
          },
        ],
      },
      {
        label: "Events",
        key: "tab_events",
        tabType: InfraDialogTabType.LOGS,
        content: [
          {
            type: "events",
            items: [],
          },
        ],
      },
      {
        label: "Nodes",
        key: "tab_nodes",
        tabType: InfraDialogTabType.TABLE_LIST,
        content: {
          label: "Nodes list",
          locationMetrics: "k8s.cluster.name",
          locationScope: METRICS_KEY_K8S_NODE,
        },
      },
      // namespaces
      {
        label: "Namespaces",
        key: "tab_namespaces",
        tabType: InfraDialogTabType.TABLE_LIST,
        content: {
          label: "Namespaces list",
          locationMetrics: "k8s.cluster.name",
          locationScope: METRICS_KEY_K8S_NAMESPACE,
        },
      },
      {
        label: "Pods",
        key: "tab_pods",
        tabType: InfraDialogTabType.TABLE_LIST,
        content: {
          label: "Pods list",
          locationMetrics: "k8s.cluster.name",
          locationScope: METRICS_KEY_K8S_POD,
        },
      },
      {
        label: "Containers",
        key: "tab_containers",
        tabType: InfraDialogTabType.TABLE_LIST,
        content: {
          label: "Containers list",
          locationMetrics: "k8s.cluster.name",
          locationScope: METRICS_KEY_K8S_CONTAINER,
        },
      },
      {
        label: "Deployments",
        key: "tab_deployments",
        tabType: InfraDialogTabType.TABLE_LIST,
        content: {
          label: "Deployments list",
          locationMetrics: "k8s.cluster.name",
          locationScope: METRICS_KEY_K8S_DEPLOYMENT,
        },
      },
      // replicasets
      {
        label: "ReplicaSets",
        key: "tab_replicasets",
        tabType: InfraDialogTabType.TABLE_LIST,
        content: {
          label: "ReplicaSets list",
          locationMetrics: "k8s.cluster.name",
          locationScope: METRICS_KEY_K8S_REPLICASET,
        },
      },
      // cronjobs
      {
        label: "CronJobs",
        key: "tab_cronjobs",
        tabType: InfraDialogTabType.TABLE_LIST,
        content: {
          label: "CronJobs list",
          locationMetrics: "k8s.cluster.name",
          locationScope: METRICS_KEY_K8S_CRONJOB,
        },
      },
      // jobs
      {
        label: "Jobs",
        key: "tab_jobs",
        tabType: InfraDialogTabType.TABLE_LIST,
        content: {
          label: "Jobs list",
          locationMetrics: "k8s.cluster.name",
          locationScope: METRICS_KEY_K8S_JOB,
        },
      },
      // daemonsets
      {
        label: "DaemonSets",
        key: "tab_daemonsets",
        tabType: InfraDialogTabType.TABLE_LIST,
        content: {
          label: "DaemonSets list",
          locationMetrics: "k8s.cluster.name",
          locationScope: METRICS_KEY_K8S_DAEMONSET,
        },
      },
      // statefulsets
      {
        label: "StatefulSets",
        key: "tab_statefulsets",
        tabType: InfraDialogTabType.TABLE_LIST,
        content: {
          label: "StatefulSets list",
          locationMetrics: "k8s.cluster.name",
          locationScope: METRICS_KEY_K8S_STATEFULSET,
        },
      },
    ],
  },
  [METRICS_KEY_K8S_NODE]: {
    header: [
      {
        key: "k8s.node.name",
        type: "simple_card",
      },
      {
        label: "Cluster",
        key: "k8s.cluster.name",
        type: "simple_card",
      },
      {
        label: "Namespace",
        key: "k8s.namespace.name",
        type: "simple_card",
      },
      {
        label: "Host",
        key: "host.id",
        type: "simple_card",
      },
      {
        label: "Started At",
        key: "k8s.node.start_time",
        type: "badge_date_card",
      },
      {
        label: "YAML",
        key: "yaml",
        type: "",
      },
    ],
    cards: [
      {
        label: "CPU",
        key: "k8s.node.cpu.utilization",
        type: "simple_card",
        bgColor: "rgb(255, 220, 229)",
      },
      {
        label: "Memory",
        key: "k8s.node.memory.usage",
        type: "simple_card",
        bgColor: "rgb(255, 234, 182)",
      },
      {
        label: "Allocatable CPU",
        key: "k8s.node.allocatable_cpu",
        type: "simple_card",
        bgColor: "rgb(254, 226, 213)",
      },
      {
        label: "Filesystem Usage",
        key: "k8s.node.filesystem.usage",
        type: "simple_card",
        bgColor: "rgb(209, 247, 196)",
      },
    ],
    tabs: [
      {
        label: "Node Info",
        key: "tab_host",
        tabType: InfraDialogTabType.INFO,
        content: [
          {
            type: InfoListCardType.GRAPH_CARD,
            graphCard: [
              {
                label: "CPU Usage",
                description: "Average of CPU Usage of node",
                resource: {
                  columns: ["k8s.node.cpu.utilization"],
                },
                color: "#FFC154",
              },
              {
                label: "Memory Usage",
                description: "Average of Memory Usage of node",
                resource: {
                  columns: ["k8s.node.memory.usage"],
                },
                color: "#47B39C",
              },
              {
                label: "Events",
                description: "Events occured in node",
                resource: {
                  columns: ["total_logs"],
                  columnConfig: {
                    total_info: {
                      color: "blue",
                    },
                    total_error: {
                      color: "red",
                    },
                    total_warnings: {
                      color: "orange",
                      bgColor: "orange",
                    },
                  },
                },
                color: "#47B39C",
              },
            ],
          },
          {
            type: InfoListCardType.GRID_CARD,
            gridCard: {
              type: InfoListGridCardType.NORMAL,
              title: "K8s NODE INFORMATION",
              titleInfo: "Node related information",
              subItems: [
                {
                  title: "Node",
                  key: "k8s.node.name",
                },
                {
                  title: "Cluster",
                  key: "k8s.cluster.name",
                  type: "link",
                  linkResource: "k8s.cluster",
                },
                {
                  title: "Host",
                  key: "host.id",
                },
                {
                  title: "Age",
                  key: "k8s.node.start_time",
                  type: "badge_date_ago",
                },
              ],
            },
          },
          {
            type: InfoListCardType.K8s_LABEL,
            card: {
              title: "Labels",
              titleInfo: "Labels of k8s node",
              key: "k8s-label",
            },
          },
          {
            type: InfoListCardType.K8s_ANNOTATION,
            card: {
              title: "Annotations",
              titleInfo: "Annotations of k8s node",
              key: "k8s-annotation",
            },
          },
          {
            type: InfoListCardType.K8s_YAML,
            card: {
              title: "YAML",
              titleInfo: "YAML information of k8s node",
              key: "k8s-node-yaml",
            },
          },
        ],
      },
      {
        label: "Metrics",
        key: "tab_metrics",
        tabType: InfraDialogTabType.METRICS,
        content: [
          {
            type: "metrics_chart",
            items: [
              {
                label: "CPU Utilization (mCores)",
                description: "CPU Utilization of a node in mCores",
                resource: {
                  columns: ["k8s.node.cpu.utilization"],
                },
                color: "#FFC154",
              },
              {
                label: "Memory Usage (Bytes)",
                resource: {
                  columns: ["k8s.node.memory.usage"],
                },
                color: "#47B39C",
              },
              {
                label: "CPU Usage by Pod (mCores)",
                description: "CPU Usage by Pods(mCores) in a node",
                resource: {
                  resourceType: "k8s.pod",
                  columns: ["avg(k8s.pod.cpu.utilization,value(avg))"],
                },
                color: "#FFC154",
              },
              {
                label: "Memory Usage by Pod (bytes)",
                description: "Memory Usage by Pods(bytes) in a node",
                resource: {
                  resourceType: "k8s.pod",
                  columns: ["avg(k8s.pod.memory.usage,value(avg))"],
                },
                color: "#FFC154",
              },
              {
                label: "Ready Container in Node",
                description: "Containers in Ready/Not Ready state in node",
                resource: {
                  resourceType: "k8s.container",
                  columns: ["uniq(k8s.container.name)"],
                  with: [
                    {
                      is_arg: true,
                      key: "SELECT_DATA_BY",
                      value: ["k8s.container.ready"],
                    },
                  ],
                },
                color: "#FFC154",
              },
              {
                label: "Node in ready Condition",
                resource: {
                  columns: ["k8s.node.condition_ready"],
                },
                color: "#61c1e8",
              },
              {
                label: "CPU Capacity (Allocatable)",
                resource: {
                  columns: ["k8s.node.allocatable_cpu"],
                },
                color: "#a3a3d3",
              },
              {
                label: "Filesystem Usage (Bytes)",
                resource: {
                  columns: ["k8s.node.filesystem.usage"],
                },
                color: "#FFFF99",
              },
              {
                label: "Network by direction (Bytes)",
                resource: {
                  columns: ["k8s.node.network.io"],
                },
                color: "#FFDAB9",
              },
              {
                label: "Network errors in node",
                resource: {
                  columns: ["k8s.node.network.errors#direction=receive"],
                },
                color: "#FF6F61",
              },
              {
                label: "Network errors out node",
                resource: {
                  columns: ["k8s.node.network.errors#direction=transmit"],
                },
                color: "#98FB98",
              },
              {
                label: "Network Error Rate",
                resource: {
                  columns: ["k8s.node.network.errors.rate"],
                },
                color: "#98FB98",
              },
            ],
          },
        ],
      },
      {
        label: "Events",
        key: "tab_events",
        tabType: InfraDialogTabType.LOGS,
        content: [
          {
            type: "events",
            items: [],
          },
        ],
      },
      {
        label: "Pods",
        key: "tab_pods",
        tabType: InfraDialogTabType.TABLE_LIST,
        content: {
          label: "Pods list",
          locationMetrics: "k8s.node.name",
          locationScope: METRICS_KEY_K8S_POD,
        },
      },
      {
        label: "Logs",
        key: "tab_logs",
        tabType: InfraDialogTabType.LOGS,
        content: [
          {
            type: "logs",
            items: [],
          },
        ],
      },
    ],
  },
  [METRICS_KEY_K8S_SERVICE]: {
    header: [
      {
        label: "Cluster",
        key: "k8s.cluster.name",
        type: "simple_card",
      },
      {
        label: "Name",
        key: "k8s.service.name",
        type: "simple_card",
      },
      {
        label: "Namespace",
        key: "service.namespace",
        type: "simple_card",
      },
    ],
    cards: [
      {
        label: "Port Count",
        key: "k8s.service.port_count",
        type: "simple_card",
        bgColor: "rgb(255, 220, 229)",
      },
      {
        label: "Service Type",
        key: "k8s.service.type",
        type: "simple_card",
        bgColor: "rgb(255, 220, 229)",
      },
    ],
    tabs: [
      {
        label: "Service Info",
        key: "tab_host",
        tabType: InfraDialogTabType.INFO,
        content: [
          {
            type: InfoListCardType.GRAPH_CARD,
            graphCard: [
              {
                label: "CPU Usage",
                description: "Average of CPU Usage of pods of a service",
                resource: {
                  columns: ["k8s.pod.cpu.utilization"],
                  resourceType: "k8s.pod",
                },
                color: "#FFC154",
              },
              {
                label: "Memory Usage",
                description: "Average of Memory Usage of pods of a service",
                resource: {
                  columns: ["k8s.pod.memory.usage"],
                  resourceType: "k8s.pod",
                },
                color: "#47B39C",
              },
              {
                label: "Events",
                description: "Events occured in service",
                resource: {
                  columns: ["total_logs"],
                  columnConfig: {
                    total_info: {
                      color: "blue",
                    },
                    total_error: {
                      color: "red",
                    },
                    total_warnings: {
                      color: "orange",
                      bgColor: "orange",
                    },
                  },
                },
                color: "#47B39C",
              },
            ],
          },
          {
            type: InfoListCardType.GRID_CARD,
            gridCard: {
              type: InfoListGridCardType.NORMAL,
              title: "K8s SERVICE INFORMATION",
              titleInfo: "Network service related information",
              subItems: [
                {
                  title: "Name",
                  key: "k8s.service.name",
                },
                {
                  title: "Port Count",
                  key: "k8s.service.port_count",
                },
                {
                  title: "Service Type",
                  key: "k8s.service.type",
                },
                {
                  title: "Cluster",
                  key: "k8s.cluster.name",
                  type: "link",
                  linkResource: "k8s.cluster",
                },
              ],
            },
          },
        ],
      },
      {
        label: "Metrics",
        key: "tab_metrics",
        tabType: InfraDialogTabType.METRICS,
        content: [
          {
            type: "metrics_chart",
            items: [
              {
                label: "CPU Utilization",
                resource: {
                  columns: ["k8s.pod.cpu.utilization"],
                  resourceType: "k8s.pod",
                },
                color: "#FFC154",
              },
              {
                label: "Memory Usage (Bytes)",
                resource: {
                  columns: ["k8s.pod.memory.usage"],
                  resourceType: "k8s.pod",
                },
                color: "#47B39C",
              },
              {
                label: "Network by direction (Bytes)",
                resource: {
                  columns: ["k8s.pod.network.io"],
                  resourceType: "k8s.pod",
                },
                color: "#FFDAB9",
              },
              {
                label: "Network Rate (Bytes)",
                resource: {
                  columns: ["k8s.pod.network.io"],
                  resourceType: "k8s.pod",
                },
                color: "#FF6F61",
              },
              {
                label: "Network Errors",
                resource: {
                  columns: ["k8s.pod.network.errors"],
                  resourceType: "k8s.pod",
                },
                color: "#98FB98",
              },
            ],
          },
        ],
      },
      {
        label: "Events",
        key: "tab_events",
        tabType: InfraDialogTabType.LOGS,
        content: [
          {
            type: "events",
            items: [],
          },
        ],
      },
      {
        label: "Logs",
        key: "tab_logs",
        tabType: InfraDialogTabType.LOGS,
        content: [
          {
            type: "logs",
            items: [],
          },
        ],
      },
    ],
  },
  [METRICS_KEY_K8S_POD]: {
    header: [
      {
        key: "k8s.pod.name",
        type: "simple_card",
      },
      {
        label: "Cluster",
        key: "k8s.cluster.name",
        type: "simple_card",
      },
      {
        label: "Pod UID",
        key: "k8s.pod.uid",
        type: "simple_card",
      },
      {
        label: "Node",
        key: "k8s.node.name",
        type: "simple_card",
      },
      {
        label: "Namespace",
        key: "k8s.namespace.name",
        type: "simple_card",
      },
      {
        label: "Service",
        key: "k8s.service.name",
        type: "simple_card",
      },
      {
        label: "Age",
        key: "k8s.pod.start_time",
        type: "badge_date_ago",
      },
      {
        label: "YAML",
        key: "yaml",
        type: "",
      },
    ],
    cards: [
      {
        label: "CPU",
        key: "k8s.pod.cpu.utilization",
        type: "simple_card",
        bgColor: "rgb(255, 220, 229)",
      },
      {
        label: "Memory",
        key: "k8s.pod.memory.usage",
        type: "simple_card",
        bgColor: "rgb(255, 234, 182)",
      },
      {
        label: "Phase",
        key: "k8s.pod.phase",
        type: "simple_card",
        bgColor: "rgb(254, 226, 218)",
      },
      {
        label: "Memory RSS",
        key: "k8s.pod.memory.rss",
        type: "simple_card",
        bgColor: "rgb(254, 226, 213)",
      },
      {
        label: "Working Set",
        key: "k8s.pod.memory.working_set",
        type: "simple_card",
        bgColor: "rgb(209, 247, 196)",
      },
    ],
    tabs: [
      {
        label: "Pod Info",
        key: "tab_host",
        tabType: InfraDialogTabType.INFO,
        content: [
          {
            type: InfoListCardType.GRAPH_CARD,
            graphCard: [
              {
                label: "CPU Usage",
                description: "CPU Usage of pod",
                resource: {
                  columns: ["k8s.pod.cpu.utilization"],
                },
                color: "#FFC154",
              },
              {
                label: "Memory Usage",
                description: "Memory Usage of node",
                resource: {
                  columns: ["k8s.pod.memory.usage"],
                },
                color: "#47B39C",
              },
              {
                label: "Events",
                description: "Events occured in pod",
                resource: {
                  columns: ["total_info", "total_error", "total_warnings"],
                  columnConfig: {
                    total_info: {
                      color: "blue",
                    },
                    total_error: {
                      color: "red",
                    },
                    total_warnings: {
                      title: "Warning",
                      color: "orange",
                    },
                  },
                },
                color: "#47B39C",
              },
            ],
          },
          {
            type: InfoListCardType.GRID_CARD,
            gridCard: {
              type: InfoListGridCardType.NORMAL,
              title: "K8s POD INFORMATION",
              titleInfo: "Node related information",
              subItems: [
                {
                  title: "Name",
                  key: "k8s.pod.name",
                },
                {
                  title: "Namespace",
                  key: "k8s.namespace.name",
                  type: "link",
                  linkResource: "k8s.namespace",
                },
                {
                  title: "Age",
                  key: "k8s.pod.start_time",
                  type: "badge_date_ago",
                },
                {
                  title: "Cluster",
                  key: "k8s.cluster.name",
                  type: "link",
                  linkResource: "k8s.cluster",
                },
                {
                  title: "Node",
                  key: "k8s.node.name",
                  type: "link",
                  linkResource: "k8s.node",
                },
              ],
            },
          },
          {
            type: InfoListCardType.K8s_LABEL,
            card: {
              title: "Labels",
              titleInfo: "Labels of k8s pod",
              key: "k8s-label",
            },
          },
          {
            type: InfoListCardType.K8s_ANNOTATION,
            card: {
              title: "Annotations",
              titleInfo: "Annotations of k8s node",
              key: "k8s-annotation",
            },
          },
          {
            type: InfoListCardType.K8s_YAML,
            card: {
              title: "YAML",
              titleInfo: "YAML information of k8s pod",
              key: "k8s-pod-yaml",
            },
          },
        ],
      },
      {
        label: "Metrics",
        key: "tab_metrics",
        tabType: InfraDialogTabType.METRICS,
        content: [
          {
            type: "metrics_chart",
            items: [
              {
                label: "CPU Utilization (Cores)",
                resource: {
                  columns: ["k8s.pod.cpu.utilization"],
                },
                color: "#FFC154",
              },
              {
                label: "Memory Usage (Bytes)",
                resource: {
                  columns: ["k8s.pod.memory.usage"],
                },
                color: "#47B39C",
              },
              {
                label: "File System Usage (Bytes)",
                resource: {
                  columns: ["k8s.pod.filesystem.usage"],
                },
                color: getDarkColor(2),
              },
              {
                label: "Network by direction (Bytes)",
                resource: {
                  columns: ["k8s.pod.network.io"],
                },
                color: "#FFDAB9",
              },
              {
                label: "Network Rate (Bytes)",
                resource: {
                  columns: ["k8s.pod.network.io"],
                },
                color: "#FF6F61",
              },
              {
                label: "Network Errors",
                resource: {
                  columns: ["k8s.pod.network.errors"],
                },
                color: "#98FB98",
              },
              {
                label: "Network Error Rate (Bytes/Second)",
                resource: {
                  columns: ["k8s.pod.network.errors.rate"],
                },
                color: "#98FB98",
              },
              {
                label: "Restarts",
                resource: {
                  columns: ["k8s.container.restarts"],
                  resourceType: METRICS_KEY_K8S_CONTAINER,
                },
                color: getDarkColor(7),
              },
            ],
          },
        ],
      },
      {
        label: "Events",
        key: "tab_events",
        tabType: InfraDialogTabType.LOGS,
        content: [
          {
            type: "events",
            items: [],
          },
        ],
      },
      {
        label: "Containers",
        key: "tab_containers",
        tabType: InfraDialogTabType.TABLE_LIST,
        content: {
          label: "Containers",
          locationMetrics: "k8s.container.name",
          locationScope: METRICS_KEY_K8S_CONTAINER,
        },
      },
      {
        label: "K8s Services",
        key: "tab_services",
        tabType: InfraDialogTabType.TABLE_LIST,
        content: {
          label: "Service list",
          locationMetrics: "k8s.service.name",
          locationScope: METRICS_KEY_K8S_SERVICE,
          subLocationMetrics: "service.name",
        },
      },
      {
        label: "DaemonSets",
        key: "tab_daemonsets",
        tabType: InfraDialogTabType.TABLE_LIST,
        content: {
          label: "DaemonSets list",
          locationMetrics: "k8s.daemonset.name",
          locationScope: METRICS_KEY_K8S_DAEMONSET,
          subLocationMetrics: "k8s.namespace.name",
        },
      },
      {
        label: "Logs",
        key: "tab_logs",
        tabType: InfraDialogTabType.LOGS,
        content: [
          {
            type: "logs",
            items: [],
          },
        ],
      },
      {
        label: "APM Services",
        key: "apm_service",
        tabType: InfraDialogTabType.APM,
        content: [
          {
            type: "apm_service",
            items: [],
          },
        ],
      },
    ],
  },
  [METRICS_KEY_K8S_CONTAINER]: {
    header: [
      {
        key: "k8s.container.name",
        type: "simple_card",
      },
      {
        key: "k8s.pod.start_time",
        type: "simple_card",
      },
      {
        label: "Cluster",
        key: "k8s.cluster.name",
        type: "simple_card",
      },
      {
        label: "Node",
        key: "k8s.node.name",
        type: "simple_card",
      },
      {
        label: "Namespace",
        key: "k8s.namespace.name",
        type: "simple_card",
      },
    ],
    secondary: [
      {
        label: "Image Name",
        key: "container.image.name",
      },
      {
        label: "Image Tag",
        key: "container.image.tag",
      },
      {
        label: "Image ID",
        key: "container.image.id",
      },
      {
        label: "Deployment Name",
        key: "k8s.deployment.name",
      },
      {
        label: "Pod Name",
        key: "k8s.pod.name",
      },
      {
        label: "Account Name",
        key: "k8s.service_account.name",
      },
    ],
    cards: [
      {
        label: "CPU",
        key: "container.cpu.utilization",
        type: "simple_card",
        bgColor: "rgb(255, 220, 229)",
      },
      {
        label: "Memory",
        key: "container.memory.usage",
        type: "simple_card",
        bgColor: "rgb(255, 234, 182)",
      },
      {
        label: "CPU Request",
        key: "k8s.container.cpu_request",
        type: "simple_card",
        bgColor: "rgb(254, 226, 218)",
      },
      {
        label: "Memory Request",
        key: "k8s.container.memory_request",
        type: "simple_card",
        bgColor: "rgb(209, 247, 196)",
      },
      {
        label: "Filesystem Usage",
        key: "container.filesystem.usage",
        type: "simple_card",
        bgColor: "rgb(254, 226, 213)",
      },
    ],
    tabs: [
      {
        label: "Container Info",
        key: "tab_host",
        tabType: InfraDialogTabType.INFO,
        content: [
          {
            type: InfoListCardType.GRAPH_CARD,
            graphCard: [
              {
                label: "CPU Usage",
                description: "CPU Usage of container",
                resource: {
                  columns: ["container.cpu.utilization"],
                },
                color: "#FFC154",
              },
              {
                label: "Memory Usage",
                description: "Memory Usage of container",
                resource: {
                  columns: ["container.memory.usage"],
                },
                color: "#47B39C",
              },
            ],
          },
          {
            type: InfoListCardType.GRID_CARD,
            gridCard: {
              type: InfoListGridCardType.NORMAL,
              title: "K8s CONTAINER INFORMATION",
              titleInfo: "K8s Container related information",
              subItems: [
                {
                  title: "Name",
                  key: "k8s.container.name",
                },
                {
                  title: "Cluster",
                  key: "k8s.cluster.name",
                  type: "link",
                  linkResource: "k8s.cluster",
                },
                {
                  title: "Node",
                  key: "k8s.node.name",
                  type: "link",
                  linkResource: "k8s.node",
                },
                {
                  title: "Namespace",
                  key: "k8s.namespace.name",
                  type: "link",
                  linkResource: "k8s.namespace",
                },
                {
                  title: "Pod name",
                  key: "k8s.pod.name",
                  type: "link",
                  linkResource: "k8s.pod",
                },
                {
                  title: "Age",
                  key: "k8s.pod.start_time",
                  type: "badge_date_ago",
                },
              ],
            },
          },
        ],
      },
      {
        label: "Metrics",
        key: "tab_metrics",
        tabType: InfraDialogTabType.METRICS,
        content: [
          {
            type: "metrics_chart",
            items: [
              {
                label: "CPU Utilization (Cores)",
                resource: {
                  columns: ["container.cpu.utilization"],
                },
                color: "#87eaa2",
              },
              {
                label: "Memory Usage (Bytes)",
                resource: {
                  columns: ["container.memory.usage"],
                },
                color: "#618bd7",
              },
              {
                label: "Containers States",
                resource: {
                  columns: ["k8s.container.ready"],
                },
                color: "#FFC154",
              },
              {
                label: "Containers Restarts",
                resource: {
                  columns: ["k8s.container.restarts"],
                },
                color: "#47B39C",
              },
              {
                label: "CPU Request",
                resource: {
                  columns: ["k8s.container.cpu_request"],
                },
                color: "#FFDAB9",
              },
              {
                label: "Memory Request (Bytes)",
                resource: {
                  columns: ["k8s.container.memory_request"],
                },
                color: "#FF6F61",
              },
              {
                label: "CPU Limit (Bytes)",
                resource: {
                  columns: ["k8s.container.cpu_limit"],
                },
                color: "#98FB98",
              },
              {
                label: "Memory Limit (Bytes)",
                resource: {
                  columns: ["k8s.container.memory_limit"],
                },
                color: "#47B39C",
              },
            ],
          },
        ],
      },
      {
        label: "Logs",
        key: "tab_logs",
        tabType: InfraDialogTabType.LOGS,
        content: [
          {
            type: "logs",
            items: [],
          },
        ],
      },
    ],
  },
  [METRICS_KEY_K8S_DEPLOYMENT]: {
    header: [
      {
        key: "k8s.deployment.name",
        type: "simple_card",
      },
      {
        key: "k8s.deployment.failed",
        type: "simple_card",
      },
      {
        key: "k8s.deployment.uid",
        type: "simple_card",
      },
      {
        label: "Cluster",
        key: "k8s.cluster.name",
        type: "simple_card",
      },
      {
        label: "Namespace",
        key: "k8s.namespace.name",
        type: "simple_card",
      },
      {
        label: "Age",
        key: "k8s.deployment.start_time",
        type: "badge_date_ago",
      },
      {
        label: "YAML",
        key: "yaml",
        type: "",
      },
    ],
    cards: [
      {
        label: "Available",
        key: "k8s.deployment.available",
        type: "simple_card",
        bgColor: "rgb(255, 220, 229)",
      },
      {
        label: "Desired",
        key: "k8s.deployment.desired",
        type: "simple_card",
        bgColor: "rgb(255, 234, 182)",
      },
    ],
    tabs: [
      {
        label: "Deployment Info",
        key: "tab_host",
        tabType: InfraDialogTabType.INFO,
        content: [
          {
            type: InfoListCardType.GRAPH_CARD,
            graphCard: [
              {
                label: "CPU Usage",
                description: "CPU Usage of pods in deployment",
                resource: {
                  columns: ["avg(k8s.pod.cpu.utilization,value(avg))"],
                  resourceType: "k8s.pod",
                },

                color: "#FFC154",
              },
              {
                label: "Memory Usage",
                description: "Memory Usage of pods in deployment",
                resource: {
                  columns: ["avg(k8s.pod.memory.usage,value(avg))"],
                  resourceType: "k8s.pod",
                },
                color: "#47B39C",
              },
              {
                label: "Events",
                description: "Events occured in deployment",
                resource: {
                  columns: ["total_logs"],
                  columnConfig: {
                    total_info: {
                      color: "blue",
                    },
                    total_error: {
                      color: "red",
                    },
                    total_warnings: {
                      color: "orange",
                      bgColor: "orange",
                    },
                  },
                },
                color: "#47B39C",
              },
            ],
          },
          {
            type: InfoListCardType.NORMAL_CARD,
            card: {
              title: "Available",
              titleInfo: "Available pods in deployment",
              key: "k8s.deployment.available",
            },
          },
          {
            type: InfoListCardType.NORMAL_CARD,
            card: {
              title: "Desired",
              titleInfo: "Desired pods in deployment",
              key: "k8s.deployment.desired",
            },
          },
          {
            type: InfoListCardType.ERROR_CARD,
            card: {
              title: "Fail",
              titleInfo: "Failed pods in deployment",
              key: "k8s.deployment.failed",
            },
          },
          {
            type: InfoListCardType.GRID_CARD,
            gridCard: {
              type: InfoListGridCardType.NORMAL,
              title: "K8s DEPLOYMENT INFORMATION",
              titleInfo: "K8s deployment related information",
              subItems: [
                {
                  title: "Name",
                  key: "k8s.deployment.name",
                },
                {
                  title: "Cluster",
                  key: "k8s.cluster.name",
                  type: "link",
                  linkResource: "k8s.cluster",
                },
                {
                  title: "Namespace",
                  key: "k8s.namespace.name",
                  type: "link",
                  linkResource: "k8s.namespace",
                },
                {
                  title: "Age",
                  key: "k8s.deployment.start_time",
                  type: "badge_date_ago",
                },
              ],
            },
          },
          {
            type: InfoListCardType.K8s_LABEL,
            card: {
              title: "Labels",
              titleInfo: "Labels of k8s deployment",
              key: "k8s-label",
            },
          },
          {
            type: InfoListCardType.K8s_ANNOTATION,
            card: {
              title: "Annotations",
              titleInfo: "Annotations of k8s deployment",
              key: "k8s-annotation",
            },
          },
          {
            type: InfoListCardType.K8s_YAML,
            card: {
              title: "YAML",
              titleInfo: "YAML information of k8s deployment",
              key: "k8s-deployment-yaml",
            },
          },
        ],
      },
      {
        label: "Metrics",
        key: "tab_metrics",
        tabType: InfraDialogTabType.METRICS,
        content: [
          {
            type: "metrics_chart",
            items: [
              {
                label: "CPU Usage",
                description: "CPU Usage of pod",
                resource: {
                  columns: ["avg(k8s.pod.cpu.utilization,value(avg))"],
                  resourceType: "k8s.pod",
                },
                color: "#FFC154",
              },
              {
                label: "Memory Usage",
                description: "Memory Usage of node",
                resource: {
                  columns: ["avg(k8s.pod.memory.usage,value(avg))"],
                  resourceType: "k8s.pod",
                },
                color: "#47B39C",
              },
              {
                label: "Deployment Available",
                resource: {
                  columns: ["k8s.deployment.available"],
                },
                color: "#FFC154",
              },
              {
                label: "Deployment Desired",
                resource: {
                  columns: ["k8s.deployment.desired"],
                },
                color: "#47B39C",
              },
              {
                label: "Deployment Available and Unavailable",
                resource: {
                  columns: [
                    "k8s.deployment.available",
                    "k8s.deployment.k8s.deployment.failed",
                  ],
                  resourceType: "k8s.deployment",
                },
                color: "#FFDAB9",
              },
            ],
          },
        ],
      },
      {
        label: "Events",
        key: "tab_events",
        tabType: InfraDialogTabType.LOGS,
        content: [
          {
            type: "events",
            items: [],
          },
        ],
      },
      {
        label: "Pods",
        key: "tab_pods",
        tabType: InfraDialogTabType.TABLE_LIST,
        content: {
          label: "Pods list",
          locationMetrics: "k8s.pod.name",
          locationScope: METRICS_KEY_K8S_POD,
        },
      },
    ],
  },
  [METRICS_KEY_K8S_REPLICASET]: {
    header: [
      {
        key: "k8s.replicaset.name",
        type: "simple_card",
      },
      {
        label: "Cluster",
        key: "k8s.cluster.name",
        type: "simple_card",
      },
      {
        label: "Namespace",
        key: "k8s.namespace.name",
        type: "simple_card",
      },
      {
        label: "Age",
        key: "k8s.replicaset.start_time",
        type: "badge_date_card",
      },
      {
        key: "k8s.replicaset.uid",
        type: "simple_card",
      },
      {
        label: "YAML",
        key: "yaml",
        type: "",
      },
    ],
    cards: [
      {
        label: "Available",
        key: "k8s.replicaset.available",
        type: "simple_card",
        bgColor: "rgb(255, 220, 229)",
      },
      {
        label: "Desired",
        key: "k8s.replicaset.desired",
        type: "simple_card",
        bgColor: "rgb(255, 234, 182)",
      },
      {
        label: "Failed",
        key: "k8s.replicaset.failed",
        type: "simple_card",
        bgColor: "rgb(255, 234, 182)",
      },
    ],
    tabs: [
      {
        label: "Replicaset Info",
        key: "tab_host",
        tabType: InfraDialogTabType.INFO,
        content: [
          {
            type: InfoListCardType.GRAPH_CARD,
            graphCard: [
              {
                label: "CPU Usage",
                description: "CPU Usage of pods in replicaset",
                resource: {
                  columns: ["avg(k8s.pod.cpu.utilization,value(avg))"],
                  resourceType: "k8s.pod",
                },

                color: "#FFC154",
              },
              {
                label: "Memory Usage",
                description: "Memory Usage of pods in replicaset",
                resource: {
                  columns: ["avg(k8s.pod.memory.usage,value(avg))"],
                  resourceType: "k8s.pod",
                },
                color: "#47B39C",
              },
              {
                label: "Events",
                description: "Events occured in replicaset",
                resource: {
                  columns: ["total_logs"],
                  columnConfig: {
                    total_info: {
                      color: "blue",
                    },
                    total_error: {
                      color: "red",
                    },
                    total_warnings: {
                      color: "orange",
                      bgColor: "orange",
                    },
                  },
                },
                color: "#47B39C",
              },
            ],
          },
          {
            type: InfoListCardType.NORMAL_CARD,
            card: {
              title: "Available",
              titleInfo: "Available replicasets",
              key: "k8s.replicaset.available",
            },
          },
          {
            type: InfoListCardType.NORMAL_CARD,
            card: {
              title: "Desired",
              titleInfo: "Desired replicasets",
              key: "k8s.replicaset.desired",
            },
          },
          {
            type: InfoListCardType.ERROR_CARD,
            card: {
              title: "Fail",
              titleInfo: "Failed replicasets",
              key: "k8s.replicaset.failed",
            },
          },
          {
            type: InfoListCardType.GRID_CARD,
            gridCard: {
              type: InfoListGridCardType.NORMAL,
              title: "K8s REPLICASET INFORMATION",
              titleInfo: "K8s replicaset related information",
              subItems: [
                {
                  title: "Name",
                  key: "k8s.replicaset.name",
                },
                {
                  title: "Cluster",
                  key: "k8s.cluster.name",
                  type: "link",
                  linkResource: "k8s.cluster",
                },
                {
                  title: "Namespace",
                  key: "k8s.namespace.name",
                  type: "link",
                  linkResource: "k8s.namespace",
                },
                {
                  title: "Age",
                  key: "k8s.replicaset.start_time",
                  type: "badge_date_ago",
                },
              ],
            },
          },
          {
            type: InfoListCardType.K8s_LABEL,
            card: {
              title: "Labels",
              titleInfo: "Labels of k8s replicaset",
              key: "k8s-label",
            },
          },
          {
            type: InfoListCardType.K8s_ANNOTATION,
            card: {
              title: "Annotations",
              titleInfo: "Annotations of k8s replicaset",
              key: "k8s-annotation",
            },
          },
          {
            type: InfoListCardType.K8s_YAML,
            card: {
              title: "YAML",
              titleInfo: "YAML information of k8s replicaset",
              key: "k8s-replicaset-yaml",
            },
          },
        ],
      },
      {
        label: "Metrics",
        key: "tab_metrics",
        tabType: InfraDialogTabType.METRICS,
        content: [
          {
            type: "metrics_chart",
            items: [
              {
                label: "Replicaset Available",
                resource: {
                  columns: ["k8s.replicaset.available"],
                },
                color: "#FFC154",
              },
              {
                label: "Replicaset Desired",
                resource: {
                  columns: ["k8s.replicaset.desired"],
                },
                color: "#47B39C",
              },
              {
                label: "Replicas Available and Unavailable",
                resource: {
                  columns: [
                    "k8s.replicaset.available",
                    "k8s.replicaset.k8s.replicaset.failed",
                  ],
                  resourceType: "k8s.replicaset",
                },
                color: "#FFDAB9",
              },
            ],
          },
        ],
      },
      {
        label: "Pods",
        key: "tab_pods",
        tabType: InfraDialogTabType.TABLE_LIST,
        content: {
          label: "Pods list",
          locationMetrics: "k8s.pod.name",
          locationScope: METRICS_KEY_K8S_POD,
        },
      },
    ],
  },
  [METRICS_KEY_K8S_JOB]: {
    header: [
      {
        key: "k8s.job.name",
        type: "simple_card",
      },
      {
        label: "Cluster",
        key: "k8s.cluster.name",
        type: "simple_card",
      },
      {
        label: "Namespace",
        key: "k8s.namespace.name",
        type: "simple_card",
      },
      {
        label: "Age",
        key: "k8s.job.start_time",
        type: "badge_date_card",
      },
    ],
    cards: [
      {
        label: "Active Pods",
        key: "k8s.job.active_pods",
        type: "simple_card",
        bgColor: "rgb(255, 220, 229)",
      },
      {
        label: "Desired Pods",
        key: "k8s.job.desired.successful.pods",
        type: "simple_card",
        bgColor: "rgb(255, 234, 182)",
      },
      {
        label: "Failed Pods",
        key: "k8s.job.failed_pods",
        type: "simple_card",
        bgColor: "rgb(254, 226, 218)",
      },
      {
        label: "Parallel Pods",
        key: "k8s.job.max_parallel_pods",
        type: "simple_card",
        bgColor: "rgb(254, 226, 213)",
      },
      {
        label: "Successful Pods",
        key: "k8s.job.successful_pods",
        type: "simple_card",
        bgColor: "rgb(209, 247, 196)",
      },
    ],
    tabs: [
      {
        label: "Jobs Info",
        key: "tab_host",
        tabType: InfraDialogTabType.INFO,
        content: [
          {
            type: InfoListCardType.GRAPH_CARD,
            graphCard: [
              {
                label: "CPU Usage",
                description: "CPU Usage of pods of job",
                resource: {
                  columns: ["avg(k8s.pod.cpu.utilization,value(avg))"],
                  resourceType: "k8s.pod",
                },

                color: "#FFC154",
              },
              {
                label: "Memory Usage",
                description: "Memory Usage of pods of job",
                resource: {
                  columns: ["avg(k8s.pod.memory.usage,value(avg))"],
                  resourceType: "k8s.pod",
                },
                color: "#47B39C",
              },
              {
                label: "Events",
                description: "Events occured in job",
                resource: {
                  columns: ["total_logs"],
                  columnConfig: {
                    total_info: {
                      color: "blue",
                    },
                    total_error: {
                      color: "red",
                    },
                    total_warnings: {
                      color: "orange",
                      bgColor: "orange",
                    },
                  },
                },
                color: "#47B39C",
              },
            ],
          },
          {
            type: InfoListCardType.GRID_CARD,
            gridCard: {
              type: InfoListGridCardType.NORMAL,
              title: "K8s JOB INFORMATION",
              titleInfo: "K8s job related information",
              subItems: [
                {
                  title: "Name",
                  key: "k8s.job.name",
                },
                {
                  title: "Cluster",
                  key: "k8s.cluster.name",
                  type: "link",
                  linkResource: "k8s.cluster",
                },
                {
                  title: "Namespace",
                  key: "k8s.namespace.name",
                  type: "link",
                  linkResource: "k8s.namespace",
                },
                {
                  title: "Age",
                  key: "k8s.job.start_time",
                  type: "badge_date_ago",
                },
              ],
            },
          },
          {
            type: InfoListCardType.K8s_EVENT_LIST_CARD,
            card: {
              title: "Events",
              titleInfo: "List of K8s Events in Job",
              key: "top_list_k8s_events",
            },
          },
        ],
      },
      {
        label: "Metrics",
        key: "tab_metrics",
        tabType: InfraDialogTabType.METRICS,
        content: [
          {
            type: "metrics_chart",
            items: [
              {
                label: "CPU Usage",
                description: "CPU Usage of pods of job",
                resource: {
                  columns: ["avg(k8s.pod.cpu.utilization,value(avg))"],
                  resourceType: "k8s.pod",
                },

                color: "#FFC154",
              },
              {
                label: "Memory Usage",
                description: "Memory Usage of pods of job",
                resource: {
                  columns: ["avg(k8s.pod.memory.usage,value(avg))"],
                  resourceType: "k8s.pod",
                },
                color: "#47B39C",
              },
              {
                label: "Active Job",
                resource: {
                  columns: ["k8s.job.active_pods"],
                },
                color: "#FFC154",
              },
              {
                label: "Failed Job",
                resource: {
                  columns: ["k8s.job.failed_pods"],
                },
                color: "#47B39C",
              },
              {
                label: "Desired Successful Pods",
                resource: {
                  columns: ["k8s.job.desired_successful_pods"],
                },
                color: "#FFDAB9",
              },
            ],
          },
        ],
      },
      {
        label: "Pods",
        key: "tab_pods",
        tabType: InfraDialogTabType.TABLE_LIST,
        content: {
          label: "Pods list",
          locationMetrics: "k8s.pod.name",
          locationScope: METRICS_KEY_K8S_POD,
        },
      },
    ],
  },
  [METRICS_KEY_K8S_CRONJOB]: {
    header: [
      {
        key: "k8s.cronjob.name",
        type: "simple_card",
      },
      {
        label: "Cluster",
        key: "k8s.cluster.name",
        type: "simple_card",
      },
      {
        label: "Namespace",
        key: "k8s.namespace.name",
        type: "simple_card",
      },
      {
        label: "Age",
        key: "k8s.cronjob.start_time",
        type: "badge_date_card",
      },
    ],
    cards: [
      {
        label: "Active Pods",
        key: "k8s.cronjob.active_jobs",
        type: "simple_card",
        bgColor: "rgb(255, 220, 229)",
      },
    ],
    tabs: [
      {
        label: "Cron Job Info",
        key: "tab_host",
        tabType: InfraDialogTabType.INFO,
        content: [
          {
            type: InfoListCardType.GRID_CARD,
            gridCard: {
              type: InfoListGridCardType.NORMAL,
              title: "K8s CRON JOB INFORMATION",
              titleInfo: "K8s cron job related information",
              subItems: [
                {
                  title: "Name",
                  key: "k8s.cronjob.name",
                },
                {
                  title: "Cluster",
                  key: "k8s.cluster.name",
                  type: "link",
                  linkResource: "k8s.cluster",
                },
                {
                  title: "Namespace",
                  key: "k8s.namespace.name",
                  type: "link",
                  linkResource: "k8s.namespace",
                },
                {
                  title: "Age",
                  key: "k8s.cronjob.start_time",
                  type: "badge_date_ago",
                },
              ],
            },
          },
          {
            type: InfoListCardType.K8s_EVENT_LIST_CARD,
            card: {
              title: "Events",
              titleInfo: "List of K8s Events in Cron Job",
              key: "top_list_k8s_events",
            },
          },
        ],
      },
      {
        label: "Metrics",
        key: "tab_metrics",
        tabType: InfraDialogTabType.METRICS,
        content: [
          {
            type: "metrics_chart",
            items: [
              {
                label: "Active Job",
                resource: {
                  columns: ["k8s.cronjob.active_jobs"],
                },
                color: "#FFC154",
              },
            ],
          },
        ],
      },
    ],
  },
  [METRICS_KEY_K8S_DAEMONSET]: {
    header: [
      {
        key: "k8s.daemonset.name",
        type: "simple_card",
      },
      {
        label: "Cluster",
        key: "k8s.cluster.name",
        type: "simple_card",
      },
      {
        label: "Namespace",
        key: "k8s.namespace.name",
        type: "simple_card",
      },
      {
        label: "Age",
        key: "k8s.daemonset.start_time",
        type: "badge_date_card",
      },
      {
        key: "k8s.daemonset.uid",
        type: "simple_card",
      },
      {
        label: "YAML",
        key: "yaml",
        type: "",
      },
    ],
    cards: [
      {
        label: "Current Nodes",
        key: "k8s.daemonset.current_scheduled_nodes",
        type: "simple_card",
        bgColor: "rgb(255, 220, 229)",
      },
      {
        label: "Desired Nodes",
        key: "k8s.daemonset.desired_scheduled_nodes",
        type: "simple_card",
        bgColor: "rgb(255, 220, 209)",
      },
      {
        label: "MisScheduled Nodes",
        key: "k8s.daemonset.misscheduled_nodes",
        type: "simple_card",
        bgColor: "rgb(255, 220, 180)",
      },
      {
        label: "Ready Nodes",
        key: "k8s.daemonset.ready_nodes",
        type: "simple_card",
        bgColor: "rgb(255, 220, 120)",
      },
      {
        label: "Failed Nodes",
        key: "k8s.daemonset.failed_scheduled_nodes",
        type: "simple_card",
        bgColor: "rgb(255, 220, 120)",
      },
    ],
    tabs: [
      {
        label: "Daemonset Info",
        key: "tab_host",
        tabType: InfraDialogTabType.INFO,
        content: [
          {
            type: InfoListCardType.GRAPH_CARD,
            graphCard: [
              {
                label: "CPU Usage",
                description: "CPU Usage of pods in daemonset",
                resource: {
                  columns: ["avg(k8s.pod.cpu.utilization,value(avg))"],
                  resourceType: "k8s.pod",
                },

                color: "#FFC154",
              },
              {
                label: "Memory Usage",
                description: "Memory Usage of pods in daemonset",
                resource: {
                  columns: ["avg(k8s.pod.memory.usage,value(avg))"],
                  resourceType: "k8s.pod",
                },
                color: "#47B39C",
              },
              {
                label: "Events",
                description: "Events occured in daemonset",
                resource: {
                  columns: ["total_logs"],
                  columnConfig: {
                    total_info: {
                      color: "blue",
                    },
                    total_error: {
                      color: "red",
                    },
                    total_warnings: {
                      color: "orange",
                      bgColor: "orange",
                    },
                  },
                },
                color: "#47B39C",
              },
            ],
          },
          {
            type: InfoListCardType.NORMAL_CARD,
            card: {
              title: "Current nodes",
              titleInfo: "Current nodes in the daemonset",
              key: "k8s.daemonset.current_scheduled_nodes",
            },
          },
          {
            type: InfoListCardType.NORMAL_CARD,
            card: {
              title: "Desired nodes",
              titleInfo: "Desired nodes in the daemonset",
              key: "k8s.daemonset.desired_scheduled_nodes",
            },
          },
          {
            type: InfoListCardType.WARNING_CARD,
            card: {
              title: "Mis-scheduled nodes",
              titleInfo: "Mis-scheduled nodes in the daemonset",
              key: "k8s.daemonset.misscheduled_nodes",
            },
          },
          {
            type: InfoListCardType.NORMAL_CARD,
            card: {
              title: "Ready nodes",
              titleInfo: "Ready nodes in the daemonset",
              key: "k8s.daemonset.ready_nodes",
            },
          },
          {
            type: InfoListCardType.ERROR_CARD,
            card: {
              title: "Failed nodes",
              titleInfo: "Failed nodes in the daemonset",
              key: "k8s.daemonset.failed_scheduled_nodes",
            },
          },
          {
            type: InfoListCardType.GRID_CARD,
            gridCard: {
              type: InfoListGridCardType.NORMAL,
              title: "K8s DAEMONSET INFORMATION",
              titleInfo: "K8s daemonset related information",
              subItems: [
                {
                  title: "Name",
                  key: "k8s.daemonset.name",
                },
                {
                  title: "Cluster",
                  key: "k8s.cluster.name",
                  type: "link",
                  linkResource: "k8s.cluster",
                },
                {
                  title: "Namespace",
                  key: "k8s.namespace.name",
                  type: "link",
                  linkResource: "k8s.namespace",
                },
                {
                  title: "Age",
                  key: "k8s.daemonset.start_time",
                  type: "badge_date_ago",
                },
              ],
            },
          },
          {
            type: InfoListCardType.K8s_LABEL,
            card: {
              title: "Labels",
              titleInfo: "Labels of k8s daemonset",
              key: "k8s-label",
            },
          },
          {
            type: InfoListCardType.K8s_ANNOTATION,
            card: {
              title: "Annotations",
              titleInfo: "Annotations of k8s daemonset",
              key: "k8s-annotation",
            },
          },
          {
            type: InfoListCardType.K8s_YAML,
            card: {
              title: "YAML",
              titleInfo: "YAML information of k8s daemonset",
              key: "k8s-daemonset-yaml",
            },
          },
        ],
      },
      {
        label: "Metrics",
        key: "tab_metrics",
        tabType: InfraDialogTabType.METRICS,
        content: [
          {
            type: "metrics_chart",
            items: [
              {
                label: "Ready Nodes",
                resource: {
                  columns: ["k8s.daemonset.ready_nodes"],
                },
                color: "#FFC154",
              },
              {
                label: "Misscheduled Nodes",
                resource: {
                  columns: ["k8s.daemonset.misscheduled_nodes"],
                },
                color: "#47B39C",
              },
              {
                label: "Current Scheduled Nodes",
                resource: {
                  columns: ["k8s.daemonset.current_scheduled_nodes"],
                },
                color: "#61c1e8",
              },
              {
                label: "Desired Scheduled Nodes",
                resource: {
                  columns: ["k8s.daemonset.desired_scheduled_nodes"],
                },
                color: "#a3a3d3",
              },
            ],
          },
        ],
      },
      {
        label: "Events",
        key: "tab_events",
        tabType: InfraDialogTabType.LOGS,
        content: [
          {
            type: "events",
            items: [],
          },
        ],
      },
      {
        label: "Pod",
        key: "tab_pod",
        tabType: InfraDialogTabType.TABLE_LIST,
        content: {
          label: "Pod list",
          locationMetrics: "k8s.pod.name",
          locationScope: METRICS_KEY_K8S_POD,
        },
      },
    ],
  },
  [METRICS_KEY_K8S_STATEFULSET]: {
    header: [
      {
        key: "k8s.statefulset.name",
        type: "simple_card",
      },
      {
        key: "k8s.statefulset.uid",
        type: "simple_card",
      },
      {
        label: "Cluster",
        key: "k8s.cluster.name",
        type: "simple_card",
      },
      {
        label: "Namespace",
        key: "k8s.namespace.name",
        type: "simple_card",
      },
      {
        key: "k8s.statefulset.start_time",
        type: "badge_date_card",
        label: "Started At",
      },
      {
        label: "YAML",
        key: "yaml",
        type: "",
      },
    ],
    cards: [
      {
        label: "Current Pods",
        key: "k8s.statefulset.current_pods",
        type: "simple_card",
        bgColor: "rgb(255, 220, 229)",
      },
      {
        label: "Desired Pods",
        key: "k8s.statefulset.desired_pods",
        type: "simple_card",
        bgColor: "rgb(255, 220, 209)",
      },
      {
        label: "Updated Pods",
        key: "k8s.statefulset.updated_pods",
        type: "simple_card",
        bgColor: "rgb(255, 220, 180)",
      },
      {
        label: "Ready Pods",
        key: "k8s.statefulset.ready_pods",
        type: "simple_card",
        bgColor: "rgb(255, 220, 120)",
      },
      {
        label: "Failed Pods",
        key: "k8s.statefulset.failed_pods",
        type: "simple_card",
        bgColor: "rgb(255, 220, 120)",
      },
    ],
    tabs: [
      {
        label: "Statefulset Info",
        key: "tab_host",
        tabType: InfraDialogTabType.INFO,
        content: [
          {
            type: InfoListCardType.GRAPH_CARD,
            graphCard: [
              {
                label: "CPU Usage",
                description: "CPU Usage of pods in statefulset",
                resource: {
                  columns: ["avg(k8s.pod.cpu.utilization,value(avg))"],
                  resourceType: "k8s.pod",
                },

                color: "#FFC154",
              },
              {
                label: "Memory Usage",
                description: "Memory Usage of pods in statefulset",
                resource: {
                  columns: ["avg(k8s.pod.memory.usage,value(avg))"],
                  resourceType: "k8s.pod",
                },
                color: "#47B39C",
              },
              {
                label: "Events",
                description: "Events occured in statefulset",
                resource: {
                  columns: ["total_logs"],
                  columnConfig: {
                    total_info: {
                      color: "blue",
                    },
                    total_error: {
                      color: "red",
                    },
                    total_warnings: {
                      color: "orange",
                      bgColor: "orange",
                    },
                  },
                },
                color: "#47B39C",
              },
            ],
          },
          {
            type: InfoListCardType.NORMAL_CARD,
            card: {
              title: "Current pods",
              titleInfo: "Current pods in the statefulset",
              key: "k8s.statefulset.current_pods",
            },
          },
          {
            type: InfoListCardType.NORMAL_CARD,
            card: {
              title: "Desired pods",
              titleInfo: "Desired pods in the statefulset",
              key: "k8s.statefulset.desired_pods",
            },
          },
          {
            type: InfoListCardType.NORMAL_CARD,
            card: {
              title: "Updated pods",
              titleInfo: "Updated pods in the statefulset",
              key: "k8s.statefulset.updated_pods",
            },
          },
          {
            type: InfoListCardType.NORMAL_CARD,
            card: {
              title: "Ready pods",
              titleInfo: "Ready pods in the statefulset",
              key: "k8s.statefulset.ready_pods",
            },
          },
          {
            type: InfoListCardType.ERROR_CARD,
            card: {
              title: "Failed pods",
              titleInfo: "Failed pods in the statefulset",
              key: "k8s.statefulset.failed_pods",
            },
          },
          {
            type: InfoListCardType.GRID_CARD,
            gridCard: {
              type: InfoListGridCardType.NORMAL,
              title: "K8s STATEFUL SET JOB INFORMATION",
              titleInfo: "K8s stateful set related information",
              subItems: [
                {
                  title: "Name",
                  key: "k8s.statefulset.name",
                },
                {
                  title: "Cluster",
                  key: "k8s.cluster.name",
                  type: "link",
                  linkResource: "k8s.cluster",
                },
                {
                  title: "Namespace",
                  key: "k8s.namespace.name",
                  type: "link",
                  linkResource: "k8s.namespace",
                },
                {
                  title: "Age",
                  key: "k8s.statefulset.start_time",
                  type: "badge_date_ago",
                },
              ],
            },
          },
          {
            type: InfoListCardType.K8s_LABEL,
            card: {
              title: "Labels",
              titleInfo: "Labels of k8s statefulset",
              key: "k8s-label",
            },
          },
          {
            type: InfoListCardType.K8s_ANNOTATION,
            card: {
              title: "Annotations",
              titleInfo: "Annotations of k8s statefulset",
              key: "k8s-annotation",
            },
          },
          {
            type: InfoListCardType.K8s_YAML,
            card: {
              title: "YAML",
              titleInfo: "YAML information of k8s statefulset",
              key: "k8s-statefulset-yaml",
            },
          },
        ],
      },
      {
        label: "Metrics",
        key: "tab_metrics",
        tabType: InfraDialogTabType.METRICS,
        content: [
          {
            type: "metrics_chart",
            items: [
              {
                label: "Ready Pods",
                resource: {
                  columns: ["k8s.statefulset.ready_pods"],
                },
                color: "#FFC154",
              },
              {
                label: "Updated Pods",
                resource: {
                  columns: ["k8s.statefulset.updated_pods"],
                },
                color: "#47B39C",
              },
              {
                label: "Current Pods",
                resource: {
                  columns: ["k8s.statefulset.current_pods"],
                },
                color: "#61c1e8",
              },
              {
                label: "Desired Pods",
                resource: {
                  columns: ["k8s.statefulset.desired_pods"],
                },
                color: "#a3a3d3",
              },
            ],
          },
        ],
      },
      {
        label: "Pods",
        key: "tab_pods",
        tabType: InfraDialogTabType.TABLE_LIST,
        content: {
          label: "Pods list",
          locationMetrics: "k8s.pod.name",
          locationScope: METRICS_KEY_K8S_POD,
        },
      },
    ],
  },
  [METRICS_KEY_K8S_NAMESPACE]: {
    header: [
      {
        key: "k8s.namespace.name",
        type: "simple_card",
      },
      {
        label: "YAML",
        key: "yaml",
        type: "",
      },
    ],
    secondary: [
      {
        label: "Cluster",
        key: "k8s.cluster.name",
        type: "simple_card",
      },
      {
        key: "k8s.namespace.start_time",
        type: "badge_date_ago",
        label: "Started At",
      },
    ],
    customColumns: [
      {
        columns: ["uniq(k8s.pod.name)"],
        filterByKey: "k8s.namespace.name",
        resourceType: METRICS_KEY_K8S_POD,
        title: "Total Pods",
        key: "total_pods",
      },
      {
        columns: ["uniq(k8s.container.name)"],
        filterByKey: "k8s.namespace.name",
        resourceType: METRICS_KEY_K8S_CONTAINER,
        title: "Total Containers",
        key: "total_containers",
      },
    ],
    dataByColumnForDetails: "k8s.namespace.uid",
    tabs: [
      {
        label: "Overview",
        key: "tab_host",
        tabType: InfraDialogTabType.INFO,
        content: [
          {
            type: InfoListCardType.GRAPH_CARD,
            graphCard: [
              {
                label: "CPU Usage",
                description: "CPU Usage of pods in namespace",
                resource: {
                  columns: ["avg(k8s.pod.cpu.utilization,value(avg))"],
                  resourceType: "k8s.pod",
                },

                color: "#FFC154",
              },
              {
                label: "Memory Usage",
                description: "Memory Usage of pods in namespace",
                resource: {
                  columns: ["avg(k8s.pod.memory.usage,value(avg))"],
                  resourceType: "k8s.pod",
                },
                color: "#47B39C",
              },
              {
                label: "Events",
                description: "Events occured in node",
                resource: {
                  columns: ["total_logs"],
                  columnConfig: {
                    total_info: {
                      color: "blue",
                    },
                    total_error: {
                      color: "red",
                    },
                    total_warnings: {
                      color: "orange",
                      bgColor: "orange",
                    },
                  },
                },
                color: "#47B39C",
              },
            ],
          },
          {
            type: InfoListCardType.GRID_CARD,

            gridCard: {
              type: InfoListGridCardType.CUSTOM,
              title: "NAMESPACE INFORMATION",
              titleInfo: "Namespace related information",
              subItems: [
                {
                  title: "Name",
                  key: "k8s.namespace.name",
                },
                {
                  title: "Cluster",
                  key: "k8s.cluster.name",
                  type: "link",
                  linkResource: "k8s.cluster",
                },
                {
                  title: "Age",
                  key: "k8s.namespace.start_time",
                  type: "badge_date_ago",
                },
              ],
              subItemsCustom: [
                {
                  columns: ["uniq(k8s.pod.name)"],
                  filterByKey: "k8s.namespace.name",
                  resourceType: METRICS_KEY_K8S_POD,
                  title: "Total Pods",
                  key: "total_pods",
                },
                {
                  columns: ["uniq(k8s.container.name)"],
                  filterByKey: "k8s.namespace.name",
                  resourceType: METRICS_KEY_K8S_CONTAINER,
                  title: "Total Containers",
                  key: "total_containers",
                },
              ],
            },
          },
          {
            type: InfoListCardType.K8s_LABEL,
            card: {
              title: "Labels",
              titleInfo: "Labels of k8s namespace",
              key: "k8s-label",
            },
          },
          {
            type: InfoListCardType.K8s_ANNOTATION,
            card: {
              title: "Annotations",
              titleInfo: "Annotations of k8s namespace",
              key: "k8s-annotation",
            },
          },
          {
            type: InfoListCardType.K8s_YAML,
            card: {
              title: "YAML",
              titleInfo: "YAML information of k8s namespace",
              key: "k8s-pod-yaml",
            },
          },
        ],
      },
      {
        label: "Events",
        key: "tab_events",
        tabType: InfraDialogTabType.LOGS,
        content: [
          {
            type: "events",
            items: [],
          },
        ],
      },
      {
        label: "Pods",
        key: "tab_pods",
        tabType: InfraDialogTabType.TABLE_LIST,
        content: {
          label: "Pods list",
          locationMetrics: "k8s.cluster.name",
          locationScope: METRICS_KEY_K8S_POD,
        },
      },
      {
        label: "Containers",
        key: "tab_containers",
        tabType: InfraDialogTabType.TABLE_LIST,
        content: {
          label: "Containers list",
          locationMetrics: "k8s.cluster.name",
          locationScope: METRICS_KEY_K8S_CONTAINER,
        },
      },
      {
        label: "Deployments",
        key: "tab_deployments",
        tabType: InfraDialogTabType.TABLE_LIST,
        content: {
          label: "Deployments list",
          locationMetrics: "k8s.cluster.name",
          locationScope: METRICS_KEY_K8S_DEPLOYMENT,
        },
      },
      // replicasets
      {
        label: "ReplicaSets",
        key: "tab_replicasets",
        tabType: InfraDialogTabType.TABLE_LIST,
        content: {
          label: "ReplicaSets list",
          locationMetrics: "k8s.cluster.name",
          locationScope: METRICS_KEY_K8S_REPLICASET,
        },
      },
      // cronjobs
      {
        label: "CronJobs",
        key: "tab_cronjobs",
        tabType: InfraDialogTabType.TABLE_LIST,
        content: {
          label: "CronJobs list",
          locationMetrics: "k8s.cluster.name",
          locationScope: METRICS_KEY_K8S_CRONJOB,
        },
      },
      // jobs
      {
        label: "Jobs",
        key: "tab_jobs",
        tabType: InfraDialogTabType.TABLE_LIST,
        content: {
          label: "Jobs list",
          locationMetrics: "k8s.cluster.name",
          locationScope: METRICS_KEY_K8S_JOB,
        },
      },
      // daemonsets
      {
        label: "DaemonSets",
        key: "tab_daemonsets",
        tabType: InfraDialogTabType.TABLE_LIST,
        content: {
          label: "DaemonSets list",
          locationMetrics: "k8s.cluster.name",
          locationScope: METRICS_KEY_K8S_DAEMONSET,
        },
      },
      // statefulsets
      {
        label: "StatefulSets",
        key: "tab_statefulsets",
        tabType: InfraDialogTabType.TABLE_LIST,
        content: {
          label: "StatefulSets list",
          locationMetrics: "k8s.cluster.name",
          locationScope: METRICS_KEY_K8S_STATEFULSET,
        },
      },
    ],
  },
  [METRICS_KEY_K8S_HPA]: {
    header: [],
    secondary: [
      {
        key: "k8s.hpa.uid",
        type: "simple_card",
        label: "UID",
      },
      {
        key: "k8s.hpa.name",
        type: "simple_card",
        label: "Name",
      },
      {
        key: "k8s.namespace.name",
        type: "simple_card",
        label: "Namespace",
      },
      {
        key: "k8s.hpa.current_replicas",
        type: "simple_card",
        label: "Current Replicas",
      },
      {
        key: "k8s.hpa.desired_replicas",
        type: "simple_card",
        label: "Desired Replicas",
      },
      {
        key: "k8s.hpa.max_replicas",
        type: "simple_card",
        label: "Max Replicas",
      },
      {
        key: "k8s.hpa.min_replicas",
        type: "simple_card",
        label: "Min Replicas",
      },
      {
        label: "YAML",
        key: "yaml",
        type: "",
      },
    ],
    tabs: [
      {
        label: "Horizontal Pod Auto Scalers Info",
        key: "tab_host",
        tabType: InfraDialogTabType.INFO,
        content: [
          {
            type: InfoListCardType.GRAPH_CARD,
            graphCard: [
              {
                label: "Events",
                description: "Events occured in hpa",
                resource: {
                  columns: ["total_logs"],
                  columnConfig: {
                    total_info: {
                      color: "blue",
                    },
                    total_error: {
                      color: "red",
                    },
                    total_warnings: {
                      color: "orange",
                      bgColor: "orange",
                    },
                  },
                },
                color: "#47B39C",
              },
            ],
          },
          {
            type: InfoListCardType.GRID_CARD,
            gridCard: {
              type: InfoListGridCardType.NORMAL,
              title: "HORIZONTAL POD AUTOSCALERS INFORMATION",
              titleInfo: "Horizontal Pod Auto Scalers related information",
              subItems: [
                {
                  key: "k8s.hpa.name",
                  title: "Name",
                },
                {
                  key: "k8s.namespace.name",
                  title: "Namespace",
                  type: "link",
                  linkResource: "k8s.namespace",
                },
                {
                  key: "k8s.hpa.current_replicas",
                  title: "Current Replicas",
                },
                {
                  key: "k8s.hpa.desired_replicas",
                  title: "Desired Replicas",
                },
                {
                  key: "k8s.hpa.max_replicas",
                  title: "Max Replicas",
                },
                {
                  key: "k8s.hpa.min_replicas",
                  title: "Min Replicas",
                },
              ],
            },
          },
          {
            type: InfoListCardType.K8s_LABEL,
            card: {
              title: "Labels",
              titleInfo: "Labels of k8s hpa",
              key: "k8s-label",
            },
          },
          {
            type: InfoListCardType.K8s_ANNOTATION,
            card: {
              title: "Annotations",
              titleInfo: "Annotations of k8s hpa",
              key: "k8s-annotation",
            },
          },
          {
            type: InfoListCardType.K8s_YAML,
            card: {
              title: "YAML",
              titleInfo: "YAML information of k8s hpa",
              key: "k8s-hpa-yaml",
            },
          },
        ],
      },
      {
        label: "Events",
        key: "tab_events",
        tabType: InfraDialogTabType.LOGS,
        content: [
          {
            type: "events",
            items: [],
          },
        ],
      },
    ],
  },
  [METRICS_KEY_K8S_INGRESSES]: {
    header: [],
    secondary: [
      {
        key: "k8s.ingress.uid",
        type: "simple_card",
        label: "UID",
      },
      {
        key: "k8s.ingress.name",
        type: "simple_card",
        label: "Name",
      },
      {
        key: "k8s.ingress.namespace",
        type: "link",
        linkResource: METRICS_KEY_K8S_NAMESPACE,
        label: "Namespace",
      },
      {
        key: "k8s.ingress.type",
        type: "simple_card",
        label: "Type",
      },
      {
        key: "k8s.ingress.ports",
        type: "simple_card",
        label: "Ports",
      },
      {
        key: "k8s.cluster.name",
        type: "link",
        linkResource: METRICS_KEY_K8S_CLUSTER,
        label: "Cluster Name",
      },
      {
        key: "k8s.ingress.start_time",
        type: "badge_date_card",
        label: "Started At",
      },
    ],
    cards: [
      {
        key: "k8s.ingress.rule_count",
        type: "simple_card",
        label: "Rule Count",
      },
    ],
    rowCard: [
      {
        key: "k8s.ingress.labels",
        type: "ampersand_sep_arr",
        label: "Labels",
      },
      {
        key: "k8s.ingress.annotations",
        type: "ampersand_sep_arr",
        label: "Annotations",
      },
      // MTODO: We will display this card once the agent team finalize the format of the value and how it should be displayed.
      // {
      //     key: "k8s.ingress.rule",
      //     type: "ampersand_sep_arr",
      //     label: "Hosts"
      // }
    ],
  },
  [METRICS_KEY_K8S_PERSISTENT_VOLUME_CLAIMS]: {
    header: [],
    secondary: [
      {
        key: "k8s.persistentvolumeclaim.uid",
        type: "simple_card",
        label: "UID",
      },
      {
        key: "k8s.persistentvolumeclaim.name",
        type: "simple_card",
        label: "Name",
      },
      {
        key: "k8s.persistentvolumeclaim.namespace",
        type: "link",
        linkResource: METRICS_KEY_K8S_NAMESPACE,
        label: "Namespace",
      },
      {
        key: "k8s.persistentvolumeclaim.phase",
        type: "simple_card",
        label: "Phase",
      },
      {
        key: "k8s.persistentvolumeclaim.selector",
        type: "simple_card",
        label: "Selector",
      },
      {
        key: "k8s.persistentvolumeclaim.type",
        type: "simple_card",
        label: "Type",
      },
      {
        key: "k8s.persistentvolumeclaim.storage_class",
        type: "simple_card",
        label: "Storage Class",
      },
      {
        key: "k8s.persistentvolumeclaim.volume_mode",
        type: "simple_card",
        label: "Volume Mode",
      },
      {
        key: "k8s.persistentvolumeclaim.volume_name",
        type: "link",
        linkResource: METRICS_KEY_K8S_PERSISTENT_VOLUMES,
        label: "Volume Name",
      },
      {
        key: "k8s.cluster.name",
        type: "link",
        linkResource: METRICS_KEY_K8S_CLUSTER,
        label: "Cluster Name",
      },
      {
        key: "k8s.persistentvolumeclaim.start_time",
        type: "badge_date_card",
        label: "Started At",
      },
    ],
    cards: [
      {
        key: "k8s.persistentvolumeclaim.allocated",
        type: "simple_card",
        label: "Allocated",
      },
      {
        key: "k8s.persistentvolumeclaim.capacity",
        type: "simple_card",
        label: "Capacity",
      },
    ],
    rowCard: [
      {
        key: "k8s.persistentvolumeclaim.access_modes",
        type: "comma_sep_arr",
        label: "Access Modes",
      },
      {
        key: "k8s.persistentvolumeclaim.labels",
        type: "ampersand_sep_arr",
        label: "Labels",
      },
      {
        key: "k8s.persistentvolumeclaim.annotations",
        type: "ampersand_sep_arr",
        label: "Annotations",
      },
      {
        key: "k8s.persistentvolumeclaim.finalizers",
        type: "comma_sep_arr",
        label: "Finalizers",
      },
    ],
    tabs: [
      {
        label: "Persistent Volume Claims Info",
        key: "tab_host",
        tabType: InfraDialogTabType.INFO,
        content: [
          {
            type: InfoListCardType.NORMAL_CARD,
            card: {
              title: "Allocated",
              titleInfo: "Allocated persistent volume claims",
              key: "k8s.persistentvolumeclaim.allocated",
            },
          },
          {
            type: InfoListCardType.NORMAL_CARD,
            card: {
              title: "Capacity",
              titleInfo: "Capacity of persistent volume claims",
              key: "k8s.persistentvolumeclaim.capacity",
            },
          },
          {
            type: InfoListCardType.GRID_CARD,
            gridCard: {
              type: InfoListGridCardType.NORMAL,
              title: "PERSISTENT VOLUME CLAIMS INFORMATION",
              titleInfo: "Persistent volume claims related information",
              subItems: [
                {
                  key: "k8s.persistentvolumeclaim.uid",
                  title: "UID",
                },
                {
                  key: "k8s.persistentvolumeclaim.name",
                  title: "Name",
                },
                {
                  key: "k8s.persistentvolumeclaim.namespace",
                  title: "Namespace",
                },
                {
                  key: "k8s.persistentvolumeclaim.phase",
                  title: "Phase",
                },
                {
                  key: "k8s.persistentvolumeclaim.selector",
                  title: "Selector",
                },
                {
                  key: "k8s.persistentvolumeclaim.type",
                  title: "Type",
                },
                {
                  key: "k8s.persistentvolumeclaim.storage_class",
                  title: "Storage Class",
                },
                {
                  key: "k8s.persistentvolumeclaim.volume_mode",
                  title: "Volume Mode",
                },
                {
                  key: "k8s.persistentvolumeclaim.volume_name",
                  title: "Volume Name",
                },
                {
                  key: "k8s.cluster.name",
                  title: "Cluster Name",
                },
                {
                  key: "k8s.persistentvolumeclaim.start_time",
                  type: "badge_date_card",
                  title: "Started At",
                },
              ],
            },
          },
          {
            type: InfoListCardType.CUSTOM_CARD_COMMA_SEPERATED,
            card: {
              titleInfo: "Access Modes of persistent volume claims",
              key: "k8s.persistentvolumeclaim.access_modes",
              title: "Access Modes",
            },
          },
          {
            type: InfoListCardType.CUSTOM_CARD_AMPERSAND_SEPERATED,
            card: {
              titleInfo: "Label of persistent volume claims",
              key: "k8s.persistentvolumeclaim.labels",
              title: "Labels",
            },
          },
          {
            type: InfoListCardType.CUSTOM_CARD_AMPERSAND_SEPERATED,
            card: {
              titleInfo: "Annotation of persistent volume claims",
              key: "k8s.persistentvolumeclaim.annotations",
              title: "Annotation",
            },
          },
          {
            type: InfoListCardType.CUSTOM_CARD_AMPERSAND_SEPERATED,
            card: {
              titleInfo: "Finalizers of persistent volume claims",
              key: "k8s.persistentvolumeclaim.finalizers",
              title: "Finalizers",
            },
          },
        ],
      },
    ],
  },
  [METRICS_KEY_K8S_PERSISTENT_VOLUMES]: {
    header: [],
    secondary: [
      {
        key: "k8s.persistentvolume.uid",
        type: "simple_card",
        label: "UID",
      },
      {
        key: "k8s.persistentvolume.name",
        type: "simple_card",
        label: "Name",
      },
      {
        key: "k8s.persistentvolume.namespace",
        type: "link",
        linkResource: METRICS_KEY_K8S_NAMESPACE,
        label: "Namespace",
      },
      {
        key: "k8s.persistentvolume.reclaim_policy",
        type: "simple_card",
        label: "Reclaim Policy",
      },
      {
        key: "k8s.persistentvolume.storage_class",
        type: "simple_card",
        label: "Storage Class",
      },
      {
        key: "k8s.persistentvolume.phase",
        type: "simple_card",
        label: "Phase",
      },
      {
        key: "k8s.persistentvolume.type",
        type: "simple_card",
        label: "Type",
      },
      {
        key: "k8s.persistentvolume.volume_mode",
        type: "simple_card",
        label: "Volume Mode",
      },
      {
        key: "k8s.persistentvolumeclaim.name",
        type: "link",
        linkResource: METRICS_KEY_K8S_PERSISTENT_VOLUME_CLAIMS,
        label: "Persistent Volume Claim Name",
      },
      {
        key: "k8s.persistentvolumeclaim.namespace",
        type: "simple_card",
        label: "Persistent Volume Claim Namespace",
      },
      {
        key: "k8s.cluster.name",
        type: "link",
        linkResource: METRICS_KEY_K8S_CLUSTER,
        label: "Cluster Name",
      },
      {
        key: "k8s.persistentvolume.start_time",
        type: "badge_date_card",
        label: "Started At",
      },
    ],
    cards: [
      {
        key: "k8s.persistentvolume.capacity",
        type: "simple_card",
        label: "Capacity",
      },
    ],
    rowCard: [
      {
        key: "k8s.persistentvolume.access_modes",
        type: "comma_sep_arr",
        label: "Access Modes",
      },
      {
        key: "k8s.persistentvolume.labels",
        type: "ampersand_sep_arr",
        label: "Labels",
      },
      {
        key: "k8s.persistentvolume.annotations",
        type: "ampersand_sep_arr",
        label: "Annotations",
      },
      {
        key: "k8s.persistentvolume.finalizers",
        type: "comma_sep_arr",
        label: "Finalizers",
      },
    ],
    tabs: [
      {
        label: "Persistent Volumes Info",
        key: "tab_host",
        tabType: InfraDialogTabType.INFO,
        content: [
          {
            type: InfoListCardType.NORMAL_CARD,
            card: {
              title: "Capacity",
              titleInfo: "Capacity of persistent volume",
              key: "k8s.persistentvolume.capacity",
            },
          },
          {
            type: InfoListCardType.GRID_CARD,
            gridCard: {
              type: InfoListGridCardType.NORMAL,
              title: "PERSISTENT VOLUME CLAIMS INFORMATION",
              titleInfo: "Persistent volume claims related information",
              subItems: [
                {
                  key: "k8s.persistentvolume.uid",
                  title: "UID",
                },
                {
                  key: "k8s.persistentvolume.name",
                  title: "Name",
                },
                {
                  key: "k8s.persistentvolume.namespace",
                  title: "Namespace",
                },
                {
                  key: "k8s.persistentvolume.phase",
                  title: "Phase",
                },
                {
                  key: "k8s.persistentvolume.reclaim_policy",
                  title: "Reclaim Policy",
                },
                {
                  key: "k8s.persistentvolume.storage_class",
                  title: "Storage Class",
                },
                {
                  key: "k8s.persistentvolume.type",
                  title: "Type",
                },
                {
                  key: "k8s.persistentvolume.volume_mode",
                  title: "Volume Mode",
                },
                {
                  key: "k8s.persistentvolumeclaim.name",
                  title: "Persistent Volume Claim Name",
                },
                {
                  key: "k8s.persistentvolumeclaim.namespace",
                  title: "Persistent Volume Claim Namespace",
                },
                {
                  key: "k8s.cluster.name",
                  title: "Cluster Name",
                },
                {
                  key: "k8s.persistentvolume.start_time",
                  type: "badge_date_card",
                  title: "Started At",
                },
              ],
            },
          },
          {
            type: InfoListCardType.CUSTOM_CARD_COMMA_SEPERATED,
            card: {
              titleInfo: "Access Modes of persistent volume",
              key: "k8s.persistentvolume.access_modes",
              title: "Access Modes",
            },
          },
          {
            type: InfoListCardType.CUSTOM_CARD_AMPERSAND_SEPERATED,
            card: {
              titleInfo: "Label of persistent volume",
              key: "k8s.persistentvolume.labels",
              title: "Labels",
            },
          },
          {
            type: InfoListCardType.CUSTOM_CARD_AMPERSAND_SEPERATED,
            card: {
              titleInfo: "Annotation of persistent volume",
              key: "k8s.persistentvolume.annotations",
              title: "Annotation",
            },
          },
          {
            type: InfoListCardType.CUSTOM_CARD_AMPERSAND_SEPERATED,
            card: {
              titleInfo: "Finalizers of persistent volume",
              key: "k8s.persistentvolume.finalizers",
              title: "Finalizers",
            },
          },
        ],
      },
    ],
  },
  [METRICS_KEY_K8S_SERVICE_ACCOUNTS]: {
    header: [],
    secondary: [
      {
        key: "k8s.serviceaccount.uid",
        type: "simple_card",
        label: "UID",
      },
      {
        key: "k8s.serviceaccount.name",
        type: "simple_card",
        label: "Name",
      },
      {
        key: "k8s.serviceaccount.namespace",
        type: "link",
        linkResource: METRICS_KEY_K8S_NAMESPACE,
        label: "Namespace",
      },
      {
        key: "k8s.serviceaccount.automount_serviceaccount_token",
        type: "simple_card",
        label: "Automount Service Account Token",
      },
      {
        key: "k8s.serviceaccount.type",
        type: "simple_card",
        label: "Type",
      },
      {
        key: "k8s.serviceaccount.secrets",
        type: "simple_card",
        label: "Secrets",
      },
      {
        key: "k8s.cluster.name",
        type: "link",
        linkResource: METRICS_KEY_K8S_CLUSTER,
        label: "Cluster Name",
      },
      {
        key: "k8s.serviceaccount.start_time",
        type: "badge_date_card",
        label: "Started At",
      },
    ],
    cards: [
      {
        key: "k8s.serviceaccount.secret_count",
        type: "simple_card",
        label: "Secret Count",
      },
    ],
    rowCard: [
      {
        key: "k8s.serviceaccount.image_pull_secrets",
        type: "comma_sep_arr",
        label: "Image Pull Secrets",
      },
      {
        key: "k8s.serviceaccount.labels",
        type: "ampersand_sep_arr",
        label: "Labels",
      },
      {
        key: "k8s.serviceaccount.annotations",
        type: "ampersand_sep_arr",
        label: "Annotations",
      },
      {
        key: "k8s.clusterrole.rules",
        type: "semicolon_sep_table",
        label: "Rules",
      },
    ],
    tabs: [
      {
        label: "Service Accounts Info",
        key: "tab_host",
        tabType: InfraDialogTabType.INFO,
        content: [
          {
            type: InfoListCardType.NORMAL_CARD,
            card: {
              key: "k8s.serviceaccount.secret_count",
              titleInfo: "Secret Count",
              title: "Secret Count",
            },
          },
          {
            type: InfoListCardType.GRID_CARD,
            gridCard: {
              type: InfoListGridCardType.NORMAL,
              title: "SERVICE ACCOUNTS INFORMATION",
              titleInfo: "Service account related information",
              subItems: [
                {
                  key: "k8s.serviceaccount.uid",
                  title: "UID",
                },
                {
                  key: "k8s.serviceaccount.name",
                  title: "Name",
                },
                {
                  key: "k8s.serviceaccount.namespace",
                  title: "Namespace",
                },
                {
                  key: "k8s.serviceaccount.automount_serviceaccount_token",
                  title: "Automount Service Account Token",
                },
                {
                  key: "k8s.serviceaccount.type",
                  title: "Type",
                },
                {
                  key: "k8s.serviceaccount.secrets",
                  title: "Secrets",
                },
                {
                  key: "k8s.cluster.name",
                  title: "Cluster Name",
                },
                {
                  key: "k8s.serviceaccount.start_time",
                  type: "badge_date_card",
                  title: "Started At",
                },
              ],
            },
          },
          {
            type: InfoListCardType.CUSTOM_CARD_COMMA_SEPERATED,
            card: {
              titleInfo: "Image pull secrets",
              key: "k8s.serviceaccount.image_pull_secrets",
              title: "Image Pull Secrets",
            },
          },
          {
            type: InfoListCardType.CUSTOM_CARD_AMPERSAND_SEPERATED,
            card: {
              titleInfo: "Label of service account",
              key: "k8s.serviceaccount.labels",
              title: "Labels",
            },
          },
          {
            type: InfoListCardType.CUSTOM_CARD_AMPERSAND_SEPERATED,
            card: {
              titleInfo: "Annotation of service account",
              key: "k8s.serviceaccount.annotations",
              title: "Annotation",
            },
          },
          {
            type: InfoListCardType.CUSTOM_CARD_SEMICOLON_SEP_TABLE,
            card: {
              titleInfo: "Service Account Rules",
              key: "k8s.clusterrole.rules",
              title: "Rules",
            },
          },
        ],
      },
    ],
  },
  [METRICS_KEY_K8S_CLUSTER_ROLES]: {
    header: [],
    secondary: [
      {
        key: "k8s.clusterrole.uid",
        type: "simple_card",
        label: "UID",
      },
      {
        key: "k8s.clusterrole.name",
        type: "simple_card",
        label: "Name",
      },
      {
        key: "k8s.clusterrole.type",
        type: "simple_card",
        label: "Type",
      },
      {
        key: "k8s.cluster.name",
        type: "link",
        linkResource: METRICS_KEY_K8S_CLUSTER,
        label: "Cluster Name",
      },
      {
        key: "k8s.clusterrole.start_time",
        type: "badge_date_card",
        label: "Started At",
      },
    ],
    cards: [
      {
        key: "k8s.clusterrole.rule_count",
        type: "simple_card",
        label: "Rule Count",
      },
    ],
    rowCard: [
      {
        key: "k8s.clusterrole.labels",
        type: "ampersand_sep_arr",
        label: "Labels",
      },
      {
        key: "k8s.clusterrole.annotations",
        type: "ampersand_sep_arr",
        label: "Annotations",
      },
      {
        key: "k8s.clusterrole.rules",
        type: "semicolon_sep_table",
        label: "Rules",
      },
    ],
    tabs: [
      {
        label: "Cluster Role Info",
        key: "tab_host",
        tabType: InfraDialogTabType.INFO,
        content: [
          {
            type: InfoListCardType.NORMAL_CARD,
            card: {
              key: "k8s.clusterrole.rule_count",
              titleInfo: "Rule Count",
              title: "Rule Count",
            },
          },
          {
            type: InfoListCardType.GRID_CARD,
            gridCard: {
              type: InfoListGridCardType.NORMAL,
              title: "CLUSTER ROLES INFORMATION",
              titleInfo: "Cluster roles related information",
              subItems: [
                {
                  key: "k8s.clusterrole.uid",
                  type: "simple_card",
                  title: "UID",
                },
                {
                  key: "k8s.clusterrole.name",
                  title: "Name",
                },
                {
                  key: "k8s.clusterrole.type",
                  title: "Type",
                },
                {
                  key: "k8s.cluster.name",
                  title: "Cluster Name",
                },
                {
                  key: "k8s.clusterrole.start_time",
                  type: "badge_date_card",
                  title: "Started At",
                },
              ],
            },
          },
          {
            type: InfoListCardType.CUSTOM_CARD_AMPERSAND_SEPERATED,
            card: {
              titleInfo: "Label of cluster role",
              key: "k8s.clusterrole.labels",
              title: "Labels",
            },
          },
          {
            type: InfoListCardType.CUSTOM_CARD_AMPERSAND_SEPERATED,
            card: {
              titleInfo: "Annotation of cluster role",
              key: "k8s.clusterrole.annotations",
              title: "Annotation",
            },
          },
          {
            type: InfoListCardType.CUSTOM_CARD_SEMICOLON_SEP_TABLE,
            card: {
              titleInfo: "Cluster Role Rules",
              key: "k8s.clusterrole.rules",
              title: "Rules",
            },
          },
        ],
      },
    ],
  },
  [METRICS_KEY_K8S_ROLES]: {
    header: [],
    secondary: [
      {
        key: "k8s.role.uid",
        type: "simple_card",
        label: "UID",
      },
      {
        key: "k8s.role.name",
        type: "simple_card",
        label: "Name",
      },
      {
        key: "k8s.role.namespace",
        type: "link",
        linkResource: METRICS_KEY_K8S_NAMESPACE,
        label: "Namespace",
      },
      {
        key: "k8s.role.type",
        type: "simple_card",
        label: "Type",
      },
      {
        key: "k8s.cluster.name",
        type: "link",
        linkResource: METRICS_KEY_K8S_CLUSTER,
        label: "Cluster Name",
      },
      {
        key: "k8s.role.start_time",
        type: "badge_date_card",
        label: "Started At",
      },
    ],
    cards: [
      {
        key: "k8s.role.rule_count",
        type: "simple_card",
        label: "Rule Count",
      },
    ],
    rowCard: [
      {
        key: "k8s.role.labels",
        type: "ampersand_sep_arr",
        label: "Labels",
      },
      {
        key: "k8s.role.annotations",
        type: "ampersand_sep_arr",
        label: "Annotations",
      },
      {
        key: "k8s.role.rules",
        type: "semicolon_sep_table",
        label: "Rules",
      },
    ],
    tabs: [
      {
        label: "Role Info",
        key: "tab_host",
        tabType: InfraDialogTabType.INFO,
        content: [
          {
            type: InfoListCardType.NORMAL_CARD,
            card: {
              key: "k8s.role.rule_count",
              titleInfo: "Rule Count",
              title: "Rule Count",
            },
          },
          {
            type: InfoListCardType.GRID_CARD,
            gridCard: {
              type: InfoListGridCardType.NORMAL,
              title: "ROLES INFORMATION",
              titleInfo: "Roles related information",
              subItems: [
                {
                  key: "k8s.role.uid",
                  title: "UID",
                },
                {
                  key: "k8s.role.name",
                  title: "Name",
                },
                {
                  key: "k8s.role.type",
                  title: "Type",
                },
                {
                  key: "k8s.cluster.name",
                  title: "Cluster Name",
                },
                {
                  key: "k8s.role.start_time",
                  type: "badge_date_card",
                  title: "Started At",
                },
              ],
            },
          },
          {
            type: InfoListCardType.CUSTOM_CARD_AMPERSAND_SEPERATED,
            card: {
              titleInfo: "Label of role",
              key: "k8s.role.labels",
              title: "Labels",
            },
          },
          {
            type: InfoListCardType.CUSTOM_CARD_AMPERSAND_SEPERATED,
            card: {
              titleInfo: "Annotation of role",
              key: "k8s.role.annotations",
              title: "Annotation",
            },
          },
          {
            type: InfoListCardType.CUSTOM_CARD_SEMICOLON_SEP_TABLE,
            card: {
              titleInfo: "Role Rules",
              key: "k8s.role.rules",
              title: "Rules",
            },
          },
        ],
      },
    ],
  },
  [METRICS_KEY_K8S_CLUSTER_ROLE_BINDINGS]: {
    header: [],
    secondary: [
      {
        key: "k8s.clusterrolebinding.uid",
        type: "simple_card",
        label: "UID",
      },
      {
        key: "k8s.clusterrolebinding.name",
        type: "simple_card",
        label: "Name",
      },
      {
        key: "k8s.clusterrolebinding.type",
        type: "simple_card",
        label: "Type",
      },
      {
        key: "k8s.cluster.name",
        type: "link",
        linkResource: METRICS_KEY_K8S_CLUSTER,
        label: "Cluster Name",
      },
      {
        key: "k8s.clusterrolebinding.start_time",
        type: "badge_date_card",
        label: "Started At",
      },
    ],
    cards: [
      {
        key: "k8s.clusterrolebinding.subject_count",
        type: "simple_card",
        label: "Subject Count",
      },
    ],
    rowCard: [
      {
        key: "k8s.clusterrolebinding.labels",
        type: "ampersand_sep_arr",
        label: "Labels",
      },
      {
        key: "k8s.clusterrolebinding.annotations",
        type: "ampersand_sep_arr",
        label: "Annotations",
      },
      {
        key: "k8s.clusterrolebinding.role_ref",
        type: "semicolon_sep_table",
        label: "Reference",
      },
      {
        key: "k8s.clusterrolebinding.subjects",
        type: "semicolon_sep_table",
        label: "Bindings",
      },
    ],
    tabs: [
      {
        label: "Cluster Role Binding Info",
        key: "tab_host",
        tabType: InfraDialogTabType.INFO,
        content: [
          {
            type: InfoListCardType.NORMAL_CARD,
            card: {
              key: "k8s.clusterrolebinding.subject_count",
              titleInfo: "Subject Count",
              title: "Subject Count",
            },
          },
          {
            type: InfoListCardType.GRID_CARD,
            gridCard: {
              type: InfoListGridCardType.NORMAL,
              title: "CLUSTER ROLES BINDING INFORMATION",
              titleInfo: "Cluster Roles Binding related information",
              subItems: [
                {
                  key: "k8s.clusterrolebinding.uid",
                  title: "UID",
                },
                {
                  key: "k8s.clusterrolebinding.name",
                  title: "Name",
                },
                {
                  key: "k8s.clusterrolebinding.type",
                  title: "Type",
                },
                {
                  key: "k8s.cluster.name",
                  title: "Cluster Name",
                },
                {
                  key: "k8s.clusterrolebinding.start_time",
                  type: "badge_date_card",
                  title: "Started At",
                },
              ],
            },
          },
          {
            type: InfoListCardType.CUSTOM_CARD_AMPERSAND_SEPERATED,
            card: {
              titleInfo: "Label of cluster role binding",
              key: "k8s.clusterrolebinding.labels",
              title: "Labels",
            },
          },
          {
            type: InfoListCardType.CUSTOM_CARD_AMPERSAND_SEPERATED,
            card: {
              titleInfo: "Annotation of cluster role binding",
              key: "k8s.clusterrolebinding.annotations",
              title: "Annotation",
            },
          },
          {
            type: InfoListCardType.CUSTOM_CARD_SEMICOLON_SEP_TABLE,
            card: {
              titleInfo: "Cluster Role Binding Reference",
              key: "k8s.clusterrolebinding.role_ref",
              title: "Reference",
            },
          },
          {
            type: InfoListCardType.CUSTOM_CARD_SEMICOLON_SEP_TABLE,
            card: {
              titleInfo: "Cluster Role Binding Subjects",
              key: "k8s.clusterrolebinding.subjects",
              title: "Subjects",
            },
          },
        ],
      },
    ],
  },
  [METRICS_KEY_K8S_ROLE_BINDINGS]: {
    header: [],
    secondary: [
      {
        key: "k8s.rolebinding.uid",
        type: "simple_card",
        label: "UID",
      },
      {
        key: "k8s.rolebinding.name",
        type: "simple_card",
        label: "Name",
      },
      {
        key: "k8s.rolebinding.namespace",
        type: "link",
        linkResource: METRICS_KEY_K8S_NAMESPACE,
        label: "Namespace",
      },
      {
        key: "k8s.rolebinding.type",
        type: "simple_card",
        label: "Type",
      },
      {
        key: "k8s.cluster.name",
        type: "link",
        linkResource: METRICS_KEY_K8S_CLUSTER,
        label: "Cluster Name",
      },
      {
        key: "k8s.rolebinding.start_time",
        type: "badge_date_card",
        label: "Started At",
      },
    ],
    cards: [
      {
        key: "k8s.rolebinding.subject_count",
        type: "simple_card",
        label: "Subject Count",
      },
    ],
    rowCard: [
      {
        key: "k8s.rolebinding.labels",
        type: "ampersand_sep_arr",
        label: "Labels",
      },
      {
        key: "k8s.rolebinding.annotations",
        type: "ampersand_sep_arr",
        label: "Annotations",
      },
      {
        key: "k8s.rolebinding.role_ref",
        type: "semicolon_sep_table",
        label: "Reference",
      },
      {
        key: "k8s.rolebinding.subjects",
        type: "semicolon_sep_table",
        label: "Bindings",
      },
    ],
    tabs: [
      {
        label: "Role Binding Info",
        key: "tab_host",
        tabType: InfraDialogTabType.INFO,
        content: [
          {
            type: InfoListCardType.NORMAL_CARD,
            card: {
              key: "k8s.rolebinding.subject_count",
              titleInfo: "Subject Count",
              title: "Subject Count",
            },
          },
          {
            type: InfoListCardType.GRID_CARD,
            gridCard: {
              type: InfoListGridCardType.NORMAL,
              title: "ROLE BINDING INFORMATION",
              titleInfo: "Role Binding related information",
              subItems: [
                {
                  key: "k8s.rolebinding.uid",
                  title: "UID",
                },
                {
                  key: "k8s.rolebinding.name",
                  title: "Name",
                },
                {
                  key: "k8s.rolebinding.type",
                  title: "Type",
                },
                {
                  key: "k8s.cluster.name",
                  title: "Cluster Name",
                },
                {
                  key: "k8s.rolebinding.start_time",
                  type: "badge_date_card",
                  title: "Started At",
                },
              ],
            },
          },
          {
            type: InfoListCardType.CUSTOM_CARD_AMPERSAND_SEPERATED,
            card: {
              titleInfo: "Label of role binding",
              key: "k8s.rolebinding.labels",
              title: "Labels",
            },
          },
          {
            type: InfoListCardType.CUSTOM_CARD_AMPERSAND_SEPERATED,
            card: {
              titleInfo: "Annotation of role binding",
              key: "k8s.rolebinding.annotations",
              title: "Annotation",
            },
          },
          {
            type: InfoListCardType.CUSTOM_CARD_SEMICOLON_SEP_TABLE,
            card: {
              titleInfo: "Role Binding Reference",
              key: "k8s.rolebinding.role_ref",
              title: "Reference",
            },
          },
          {
            type: InfoListCardType.CUSTOM_CARD_SEMICOLON_SEP_TABLE,
            card: {
              titleInfo: "Role Binding Subjects",
              key: "k8s.rolebinding.subjects",
              title: "Subjects",
            },
          },
        ],
      },
    ],
  },
};
