import { Align } from "core/components/v2/enums";
import { SortDirection } from "views/modules/builder/entities/builder.entities";
export type UnionRecordType =
  | string
  | number
  | object
  | string[]
  | object[]
  | boolean;

export interface ColumnProps {
  columnKey: string;
  title: string;
  width?: number;
  render?: (text: UnionRecordType, record?: UnionRecordType) => void;
  fixed?: boolean;
  tooltipInfo?: string;
  defaultSortOrder?:
    | SortDirection.Asc
    | SortDirection.Desc
    | SortDirection.None;
  align?: Align;
  renderTooltipContent?: string;
  sorterTooltip?: string;
}
export interface PaginationProps {
  totalRecords: number;
  page: number;
  onChange?: (page: number, recordsPerPage?: number) => void;
  recordsPerPage?: number;
  recordsLabel?: string;
  internalPaginatedData?: boolean;
}
export interface TableProps {
  columns: ColumnProps[];
  dataProvider: Record<string, UnionRecordType>[];
  rowOnClick?: (
    record: Record<string, UnionRecordType>,
    rowIndex: number
  ) => void;
  pagination?: PaginationProps;
  uniqId: string;
  maxHeight?: number | string;
  onSortingChange?: (
    sortingColumnName: string,
    sortingType: SortDirection.Asc | SortDirection.Desc | SortDirection.None
  ) => void;
  loading: boolean;
  expandable?: {
    expandedRowRender: (
      record: Record<string, UnionRecordType>,
      index: number,
      intend: number,
      expanded: boolean
    ) => React.ReactNode;
    rowExpandable?: (record: Record<string, UnionRecordType>) => boolean;
    defaultExpandedRowKeys?: string[];
  };
  noDataLabel?: string;
  rowClassName?: string;
  onSelectRow?: (
    selectedRows: { key: string; record: UnionRecordType }[]
  ) => void;
}
export interface LocalStorageType {
  key: string;
  width: number;
}
export interface Sorter {
  column: ColumnProps;
  columnKey: number;
  field: string;
  order: "ascend" | "descend" | "none";
}

export interface Size {
  width: number;
  height: number;
}
export const RECORDS_PER_PAGE = 50;
export const IS_ACTION_COLUMN = "actions";

export const paginatedTableData = (
  data: Record<string, UnionRecordType>[],
  page: number,
  recordsPerPage: number
) => {
  const startIdx = (page - 1) * recordsPerPage;
  const endIdx = startIdx + recordsPerPage;
  return data.slice(startIdx, endIdx);
};
