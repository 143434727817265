import { WithExpr } from "views/modules/builder/entities/extra.entities";
import { QueryParams } from "../../routes/model";
import {
  ATTRIBUTE_FILTER,
  builderFiltersInstance,
  FilterDataType,
  FilterOpEnum,
} from "views/modules/builder/entities/builder.entities";

export function getFilters(dataFilters: QueryParams) {
  const finalExpression: WithExpr[] = [];
  const filters: FilterDataType[] = [];

  if (dataFilters) {
    for (const key in dataFilters) {
      const filterData = dataFilters[key];
      const { operator, values } = filterData;
      // console.log("param", operator === FilterOpEnum.GreaterThan);
      if (operator === FilterOpEnum.Equal) {
        const f = builderFiltersInstance.Equal(key, values[0]);
        if (f) {
          filters.push(f);
        }
      } else if (
        operator === FilterOpEnum.NotEqual ||
        operator === FilterOpEnum.NotIn
      ) {
        const f = builderFiltersInstance.NotIn(key, values);
        if (f) {
          filters.push(f);
        }
      } else if (
        (operator === FilterOpEnum.ILIKE || operator === FilterOpEnum.Like) &&
        values.length
      ) {
        const f = builderFiltersInstance.ILike(key, values[0]);
        if (f) {
          filters.push(f);
        }
      } else if (operator === FilterOpEnum.NotLike && values.length) {
        const f = builderFiltersInstance.NotLike(key, values[0]);
        if (f) {
          filters.push(f);
        }
      } else if (operator === FilterOpEnum.Regex && values.length) {
        const f = builderFiltersInstance.Regex(key, values[0]);
        if (f) {
          filters.push(f);
        }
      } else if (operator === FilterOpEnum.GreaterThan && values.length) {
        const f = builderFiltersInstance.GreaterThan(
          key,
          parseFloat(values[0])
        );
        if (f) {
          filters.push(f);
        }
      } else if (operator === FilterOpEnum.GreaterThanEqual && values.length) {
        const f = builderFiltersInstance.GreaterThanEqual(
          key,
          parseFloat(values[0])
        );
        if (f) {
          filters.push(f);
        }
      } else if (operator === FilterOpEnum.LessThan && values.length) {
        const f = builderFiltersInstance.LessThan(key, parseFloat(values[0]));
        if (f) {
          filters.push(f);
        }
      } else if (operator === FilterOpEnum.LessThanEqual && values.length) {
        const f = builderFiltersInstance.LessThanEqual(
          key,
          parseFloat(values[0])
        );
        if (f) {
          filters.push(f);
        }
      }
    }
    if (filters.length > 1) {
      const allFilters = builderFiltersInstance.And(filters);
      if (allFilters) {
        const v = builderFiltersInstance.ConvertToValueType(allFilters);
        if (v) {
          finalExpression.push({
            key: ATTRIBUTE_FILTER,
            is_arg: true, // Need to check the value
            value: v,
          });
        }
      }
    } else {
      const v = builderFiltersInstance.ConvertToValueType(filters[0]);
      if (v) {
        finalExpression.push({
          key: ATTRIBUTE_FILTER,
          is_arg: true, // Need to check the value
          value: v,
        });
      }
    }
  }
  return finalExpression;
}

export const convertOperatorToEnum = (operator: string): FilterOpEnum => {
  if (operator === "=") {
    return FilterOpEnum.Equal;
  } else if (operator === "!=") {
    return FilterOpEnum.NotEqual;
  } else if (operator === "<") {
    return FilterOpEnum.LessThan;
  } else if (operator === "<=") {
    return FilterOpEnum.LessThanEqual;
  } else if (operator === ">") {
    return FilterOpEnum.GreaterThan;
  } else if (operator === ">=") {
    return FilterOpEnum.GreaterThanEqual;
  } else if (operator === "IN") {
    return FilterOpEnum.In;
  } else if (operator === "NOT IN") {
    return FilterOpEnum.NotIn;
  } else if (operator === "LIKE") {
    return FilterOpEnum.Like;
  } else if (operator === "NOT LIKE") {
    return FilterOpEnum.NotLike;
  } else if (operator === "ILIKE") {
    return FilterOpEnum.ILIKE;
  } else if (operator === "NOT ILIKE") {
    return FilterOpEnum.NotILike;
  } else if (operator === "BETWEEN") {
    return FilterOpEnum.Between;
  } else if (operator === "IS NULL") {
    return FilterOpEnum.Null;
  } else if (operator === "IS NOT NULL") {
    return FilterOpEnum.NotNull;
  } else if (operator === "REGEX") {
    return FilterOpEnum.Regex;
  }
  return FilterOpEnum.Equal;
};
