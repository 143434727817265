import * as React from "react";
import { RoutesPatterns } from "core/utils/route_schema";

const LLMRoutes: RoutesPatterns = {
  moduleName: "llm",
  slug: "/llm",
  breadcrumbs: React.lazy(
    () => import("views/modules/llm-observability/breadcrumbs")
  ),
  items: [
    {
      pattern: ["/traces"],
      component: React.lazy(
        () => import("views/modules/llm-observability/traces/index")
      ),
      breadcrumbTitle: "LLM Traces",
      sidebar: React.lazy(
        () =>
          import("views/modules/llm-observability/sidebar/llm-trace-sidebar")
      ),
    },
    {
      pattern: ["/overview"],
      component: React.lazy(
        () => import("views/modules/llm-observability/dashboard")
      ),
      breadcrumbTitle: "LLM Overview",
    },
    {
      pattern: ["/insights"],
      component: React.lazy(
        () => import("views/modules/llm-observability/dashboard")
      ),
      breadcrumbTitle: "LLM Insights",
    },
  ],
};
export default LLMRoutes;
