import {
  AxisLabelsFormatterCallbackFunction,
  AxisTypeValue,
  Options,
  PlotAreaOptions,
  PlotBarOptions,
  PlotColumnOptions,
  PlotColumnrangeOptions,
  PlotHeatmapOptions,
  PlotOptions,
  PlotPieOptions,
  PlotScatterOptions,
  PlotSplineOptions,
  PlotTilemapOptions,
  PlotTreemapOptions,
  SeriesOptionsType,
  TooltipFormatterContextObject,
  TooltipOptions,
} from "highcharts";
import { DateRange } from "views/layouts/app/routes/model";
import {
  ResourceViewOptions,
  TimeSeriesChartOptions,
} from "views/modules/builder/entities/builder.entities";
import { RenderTpl } from "views/modules/builder/entities/tpl.entities";
export interface TypeMap {
  column: PlotColumnOptions;
  spline: PlotSplineOptions;
  bar: PlotBarOptions;
  pie: PlotPieOptions;
  scatter: PlotScatterOptions;
  area: PlotAreaOptions;
  treemap: PlotTreemapOptions;
  heatmap: PlotHeatmapOptions;
  tilemap: PlotTilemapOptions;
  columnrange: PlotColumnrangeOptions;
}

export type ChartTypeOptions =
  | "column"
  | "spline"
  | "pie"
  | "bar"
  | "scatter"
  | "area"
  | "treemap"
  | "heatmap"
  | "tilemap"
  | "columnrange";

export const ChartType = {
  COLUMN: "column" as ChartTypeOptions,
  BAR: "bar" as ChartTypeOptions,
  LINE: "spline" as ChartTypeOptions,
  PIE: "pie" as ChartTypeOptions,
  SCATTER: "scatter" as ChartTypeOptions,
  AREA: "area" as ChartTypeOptions,
  TREEMAP: "treemap" as ChartTypeOptions,
  HEATMAP: "heatmap" as ChartTypeOptions,
  TILEMAP: "tilemap" as ChartTypeOptions,
  COLUMNRANGE: "columnrange" as ChartTypeOptions,
};

export type ChartTypes<T> = {
  [K in keyof T]-?: {
    isZooming?: boolean;
    chartType: K;
    extraChartConfig?: T[K];
    showMarkers?: boolean;
    extraPlotOptions?: PlotOptions;
    series: SeriesOptionsType[];
    tooltipOptions?: TooltipOptions;
    yAxsisVisible?: boolean;
    showCrossHair?: boolean;
    xAxsisVisible?: boolean;
    /**
     * @deprecated legends are not implemented
     */
    showLegend?: boolean;
    xAxsisCategory?: string[];
    yAxsisTitle?: string;
    secondYAxisTitle?: string;
    stacked?: boolean;
    extraConfig?: Options;
    resource?: ResourceViewOptions;
    tickAmount?: number;
    xAxisFormatter?: AxisLabelsFormatterCallbackFunction;
    /**
     * @deprecated do not pass this, it will be auto taken in the charts itself, Please review the exmaple component of charts
     */
    toolTipFormate?: (
      x: TooltipFormatterContextObject,
      isClicked?: boolean
    ) => React.JSX.Element;
    interval?: number;
    xType?: AxisTypeValue;
    yAxisConfig?: RenderTpl;
    secondyAxisConfig?: RenderTpl;
    /**
     * Chart Height set for different content
     * @default 125
     */
    chartHeight?: number;
    /**
     * Multiple Y axis
     */
    multipleYAxis?: boolean;
    timeseriesChartOptions?: TimeSeriesChartOptions;
    maxYAxisValue?: number;
    onDateChange?: (range: DateRange) => void;

  };
}[keyof T];

export const DEFAULT_CHART_HEIGHT = {
  HEIGHT_80: 80,
  HEIGHT_100: 100,
  HEIGHT_120: 120,
  HEIGHT_125: 135,
  HEIGHT_150: 150,
  HEIGHT_170: 170,
  HEIGHT_190: 190,
  HEIGHT_200: 200,
  HEIGHT_225: 225,
  HEIGHT_250: 250,
};

export interface LegendType {
  name: string;
  color: string;
  visible: boolean;
}

export const NULL_SERIES = "null-series";
