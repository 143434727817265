import {
  agoTime,
  converMicroSecondsToSeconds,
  convertValueToSeconds,
  diffYMDHMS,
  formatBytes,
  isNanoseconds,
} from "core/application/utils";
import { ProgressBarWithPercent } from "core/components/progress-bar/bars";
import { Align } from "core/components/v2/enums";
import StatusBadge, { StatusBadgeType } from "core/components/v2/status-badge";
import Tooltip from "core/components/v2/tooltip";
import { formatYAxisTick } from "core/utils/chart_helper";
import dayjs from "dayjs";
import moment from "moment";
import * as React from "react";
import {
  CustomUnitConfig,
  getStatusColorFromConfig,
  getTplByType,
  RenderTpl,
  StatusConfig,
  TIME_UTLIZATION_TYPES,
  TimeUtilizationConfig,
  TPL_TYPE_AGE,
  TPL_TYPE_BADGE,
  TPL_TYPE_BYTES_RATE,
  TPL_TYPE_BYTES_TO_SIZE,
  TPL_TYPE_COUNT,
  TPL_TYPE_CUSTOM_UNIT,
  TPL_TYPE_DATE_FORMAT,
  TPL_TYPE_DOLLARS,
  TPL_TYPE_ICON,
  TPL_TYPE_KB_TO_SIZE,
  TPL_TYPE_MCORE,
  TPL_TYPE_OS_ICON,
  TPL_TYPE_PERCENT,
  TPL_TYPE_PERCENT_WITHOUT_BAR,
  TPL_TYPE_STATUS,
  TPL_TYPE_TEXT_STYLE,
  TPL_TYPE_TIME_AGO,
  TPL_TYPE_TIME_UTILIZATION,
} from "views/modules/builder/entities/tpl.entities";
import {
  ServiceProviderSvgIcons,
  ServiceProviderSvgType,
} from "views/modules/helper";
interface TplProps {
  appKey: string;
  colTpl: RenderTpl;
  colValue: any;
  align?: Align;
}

export const ColumnTemplates = function (props: TplProps) {
  const colTpl = props.colTpl;
  const colValue = props.colValue;

  const _tpl = getTplByType(colTpl.type);
  if (_tpl && colTpl?.config) {
    const tplCnf: any = colTpl.config;
    if (_tpl.type == TPL_TYPE_PERCENT) {
      let _val = colValue;
      if (
        tplCnf?.precision > 0 &&
        _val > 0 &&
        typeof _val.toFixed === "function"
      ) {
        _val = _val.toFixed(tplCnf.precision);
      }
      if (tplCnf["progress-bar"]) {
        const _content = <>Usage: {_val}%</>;
        return (
          <ProgressBarWithPercent
            tooltipId={"clm"}
            tooltipContent={_content}
            progressValue={_val}
            align={props.align}
          />
        );
      } else {
        return <span>{_val}%</span>;
      }
    } else if (_tpl.type == TPL_TYPE_PERCENT_WITHOUT_BAR) {
      let _val = colValue;
      if (
        tplCnf?.precision > 0 &&
        _val > 0 &&
        typeof _val.toFixed === "function"
      ) {
        _val = _val.toFixed(tplCnf.precision);
      }
      return <span>{_val}%</span>;
    } else if (_tpl.type == TPL_TYPE_BADGE) {
      return (
        <StatusBadge {...{ [tplCnf.valueType]: true, labelText: colValue }} />
      );
    } else if (_tpl.type == TPL_TYPE_ICON) {
      return <span>Ic {colValue}</span>;
    } else if (_tpl.type == TPL_TYPE_OS_ICON) {
      const osname: string = colValue as string;
      const _os: ServiceProviderSvgType = colValue as ServiceProviderSvgType;
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              // backgroundColor: "#ECECEC",
              // borderRadius: "50%",
              display: "flex",
              width: "20px",
              height: "20px",
            }}
          >
            {ServiceProviderSvgIcons[_os]}
          </div>
          <div style={{ display: "flex", paddingLeft: "5px" }}>
            {osname[0].toUpperCase() + osname.slice(1)}
          </div>
        </div>
      );
    } else if (_tpl.type == TPL_TYPE_BYTES_TO_SIZE) {
      return formatBytes(colValue);
    } else if (_tpl.type == TPL_TYPE_KB_TO_SIZE) {
      const bytes = colValue * 1024;
      return formatBytes(bytes);
    } else if (_tpl.type == TPL_TYPE_DATE_FORMAT) {
      let val = colValue;
      if (typeof val == "string") {
        val = Number(colValue);
      }
      if (isNanoseconds(val)) {
        val = converMicroSecondsToSeconds(val);
      }
      const date = moment(new Date(val));
      if (date.isValid()) {
        return date.format(tplCnf.valueType);
      } else {
        return "-";
      }
    } else if (_tpl.type == TPL_TYPE_DOLLARS) {
      if (typeof colValue !== "number" || isNaN(colValue)) {
        return colValue;
      }
      const _value = "$" + colValue.toFixed(5);
      return _value;
    } else if (_tpl.type == TPL_TYPE_TIME_AGO) {
      const difference = agoTime(dayjs(), dayjs(new Date(colValue)));
      return (
        <Tooltip content={difference.formatted + " ago"} position="topLeft">
          {difference.formatted}
        </Tooltip>
      );
    } else if (_tpl.type == TPL_TYPE_TEXT_STYLE) {
      const _style: React.CSSProperties = {
        fontWeight: "normal",
      };
      if (tplCnf.valueType == "bold") _style.fontWeight = "bold";
      if (tplCnf.valueType == "italic") _style.fontStyle = "italic";
      if (tplCnf.valueType == "underline") _style.textDecoration = "underline";
      return <span style={_style}>{colValue}</span>;
    } else if (_tpl.type == TPL_TYPE_BYTES_RATE) {
      return formatBytes(colValue, 2) + "/s";
    } else if (_tpl.type == TPL_TYPE_TIME_UTILIZATION) {
      const c5 = colTpl.config as TimeUtilizationConfig;
      let units: TIME_UTLIZATION_TYPES = "second";
      let value = colValue;
      if (Array.isArray(c5.valueType)) {
        c5.valueType = c5.valueType[0];
      }
      switch (c5.valueType) {
        case "nanosecond":
          units = "nanosecond";
          // value = value / 1000000;
          break;
        case "microsecond":
          units = "microsecond";
          break;
        case "millisecond":
          units = "millisecond";
          break;
        case "second":
          units = "second";
          break;
        case "minute":
          units = "minute";
          break;
        case "hour":
          units = "hour";
          break;
        case "day":
          units = "day";
          break;
        case "week":
          units = "week";
          break;
        case "month":
          units = "month";
          break;
        case "year":
          units = "year";
          break;
        default:
          units = "second";
          break;
      }
      const timeDuration = convertValueToSeconds(value, units);
      return (
        diffYMDHMS(
          moment().add(timeDuration, "second"),
          moment(),
          true,
          true,
          units === "microsecond" || units === "nanosecond" ? true : false,
          units === "nanosecond" ? true : false,
          timeDuration
        ).formatted || "0"
      );
    } else if (_tpl.type == TPL_TYPE_COUNT) {
      try {
        const intergerValue = Math.round(colValue);
        if (!isNaN(intergerValue)) {
          return formatYAxisTick(colValue + "");
        }
        return colValue;
      } catch (error) {
        return colValue;
      }
    } else if (_tpl.type == TPL_TYPE_STATUS) {
      const statusConfig = colTpl.config as StatusConfig;
      statusConfig.valueType = statusConfig.valueType || [];
      const statusValue = getStatusColorFromConfig(colValue, statusConfig);
      const label: string = (statusValue.replaceValue || colValue || "") + "";
      return (
        <StatusBadge
          type={
            label.toUpperCase() === "RUNNING" ||
            label.toUpperCase() === "SUCCEEDED" ||
            label.toUpperCase() === "READY" ||
            label.toUpperCase() === "BOUND" ||
            label.toUpperCase() === "UP" ||
            label.toUpperCase() === "ACTIVE"
              ? StatusBadgeType.Success
              : label.toUpperCase() === "PENDING" ||
                  label.toUpperCase() === "UNKNOWN"
                ? StatusBadgeType.Warning
                : label.toUpperCase() === "FAILED" ||
                    label.toUpperCase() === "NOT READY" ||
                    label.toUpperCase() === "DOWN" ||
                    label.toUpperCase() === "INACTIVE"
                  ? StatusBadgeType.Error
                  : undefined
          }
          // bgStyle={{ background: statusValue.color }}
          labelText={label.toUpperCase()}
        />
      );
    } else if (_tpl.type == TPL_TYPE_AGE) {
      let newValue = colValue;
      if (isNanoseconds(newValue)) {
        newValue = converMicroSecondsToSeconds(newValue);
      }
      const age = moment(newValue).fromNow();
      return age;
    } else if (_tpl.type == TPL_TYPE_MCORE) {
      let intValue = parseFloat(colValue);
      let unit = " mCores";
      if (isNaN(intValue)) {
        return colValue;
      } else {
        if (intValue < 1) {
          intValue = intValue * 1000;
        } else if (intValue >= 1) {
          unit = " Cores";
        }
      }
      // precison 2
      if (colValue && typeof intValue.toFixed === "function") {
        return intValue.toFixed(0) + unit;
      } else {
        return colValue + unit;
      }
    } else if (_tpl.type == TPL_TYPE_CUSTOM_UNIT) {
      const customConfig = colTpl.config as CustomUnitConfig;
      return `${colValue} ${customConfig.customUnit}`;
    } else {
      if (isNaN(colValue)) {
        return colValue;
      } else {
        if (colValue && typeof colValue.toFixed === "function") {
          return colValue.toFixed(2);
        } else {
          return colValue;
        }
      }
    }
  } else {
    return colValue;
  }
};
