import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import MwRouteContext from "views/layouts/app/routes/MWRouteContext";
import useDidMountEffect from "views/layouts/app/routes/useDidMountEffect";
import WidgetAppView from "../core/widget-app-view";
import {
  BuilderProps,
  BuilderViewOptions,
  ORDER_BY_METRICS,
  SELECT_LIMIT,
} from "../entities/builder.entities";
import { WithExpr } from "../entities/extra.entities";
import useBuilderView, { BuilderDataType } from "./hooks/useBuilderView";
import WidgetView from "./internal-views/widget-view";

const BuilderView = (props: BuilderProps) => {
  const dispatch = useDispatch();

  const routeData = useContext(MwRouteContext);

  const {
    nestedProps,
    listOnClick,
    boxTitle,
    subInfo,
    columnsSelection,
    onColumnsChange,
    builderViewOptions,
    additionalBuilderViewOptions,
    onDefaultLabelClick,
    expandableTitleOptions,
  } = props;
  let pagination = props.pagination;
  if (!pagination) {
    const limitWith = builderViewOptions?.resource?.with?.findIndex(
      (item) => item.key === SELECT_LIMIT
    );
    if (limitWith && limitWith !== -1) {
      const n = builderViewOptions?.resource?.with?.[limitWith].value as {
        n: number;
        offset: number;
      };
      if (n.n > 0) {
        pagination = {
          noOfRecordsPerPage: n.n,
          limit: n.n,
        };
      }
    }
  }
  const resource = builderViewOptions?.resource;
  const displayScope = builderViewOptions?.displayScope;
  const additionalResource: BuilderViewOptions[] = [];
  if (additionalBuilderViewOptions) {
    additionalBuilderViewOptions.forEach((a) => {
      if (a.resource) {
        additionalResource.push(a);
      }
    });
  }
  const builderView = useBuilderView(props, BuilderDataType.Resource);
  const { titleChild } = nestedProps || {};
  const { requestData, subScribeBuilderData, unSubscribeBuilderData } =
    builderView;

  useEffect(() => {
    if (resource?.name) {
      requestData({}, "name change");
      if (routeData.params.isLive) {
        subScribeBuilderData();
      }
    }
    return () => {
      if (routeData.params.isLive) {
        unSubscribeBuilderData();
      }
    };
  }, []);
  const selectedColumns = resource?.columns;
  const additionalResourceSelectedColumns: string[] = [];
  additionalResource.forEach((a) => {
    if (a.resource) {
      additionalResourceSelectedColumns.push(a.resource.columns?.join(""));
    }
  });
  useDidMountEffect(() => {
    if (resource?.name) {
      if (routeData.params.isLive) {
        subScribeBuilderData();
      }
      requestData({}, "name,type and columns change");
    }
  }, [
    resource?.name,
    resource?.resourceType,
    selectedColumns?.join(""),
    additionalResourceSelectedColumns.join(""),
    displayScope,
  ]);
  useDidMountEffect(() => {
    requestData({}, "props.filters");
  }, [JSON.stringify(props.filters)]);
  useDidMountEffect(() => {
    requestData({}, "props.sortBy");
  }, [JSON.stringify(props.sortBy)]);
  useDidMountEffect(() => {
    requestData({}, "props.groupBy");
  }, [JSON.stringify(props.groupBy)]);
  useDidMountEffect(() => {
    requestData({}, "props.limit");
  }, [JSON.stringify(props.limit)]);
  useDidMountEffect(() => {
    requestData({}, "props.builderViewOptions.resource.granularity");
  }, [JSON.stringify(props.builderViewOptions?.resource?.granularity)]);

  if (resource && resource.resourceType !== "") {
    return (
      <WidgetAppView
        nestedProps={nestedProps}
        builderViewOptions={{
          resource,
        }}
        onSortingChange={(sortingColumnName, sortingType) => {
          const extraParams: WithExpr[] = [];
          if (sortingColumnName) {
            extraParams.push({
              key: ORDER_BY_METRICS,
              value: {
                [sortingColumnName]: sortingType,
              },
              is_arg: true,
            });
            extraParams.push({
              key: SELECT_LIMIT,
              value: {
                n: pagination?.limit ?? 100,
                offset: 0,
              },
              is_arg: false,
            });
          }
          requestData(
            {
              withExpressions: extraParams,
            },
            "sorting"
          );
        }}
        pagination={pagination}
        listOnClick={listOnClick}
        refreshData={() => {
          requestData({}, "refresh");
        }}
        boxTitle={boxTitle}
        subInfo={subInfo}
        columnsSelection={columnsSelection}
        onColumnsChange={onColumnsChange}
        onDefaultLabelClick={onDefaultLabelClick}
        titleChild={titleChild}
        expandableTitleOptions={expandableTitleOptions}
        onPagination={(offset: number) => {
          const extraParams: WithExpr[] = [];
          extraParams.push({
            key: SELECT_LIMIT,
            value: {
              n: pagination?.limit ?? 100,
              offset: offset,
            },
            is_arg: false,
          });

          requestData(
            {
              withExpressions: extraParams,
            },
            "pagination"
          );
        }}
        resourceView={builderView.getChartData(resource.name)}
      />
    );
  } else {
    return <WidgetView {...props} />;
  }
};
export default BuilderView;
