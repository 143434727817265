import React, { useContext, useState } from "react";
import "../../style.scss";
import { RenderGraph } from "../render-graph";
import { useSelector } from "react-redux";
import {
  getSetText2QueryRequest,
  getShouldShowText2Query,
} from "store/logs/selectors";
import { DateRange } from "../../types";
import MwRouteContext from "views/layouts/app/routes/MWRouteContext";

interface WrappedComponentProps {
  sectionExpand: boolean;
}

const withSectionExpand = <P extends WrappedComponentProps>(
  WrappedComponent: React.ComponentType<P>
) => {
  const HOC = (props: P) => {
    const routerContext = useContext(MwRouteContext);
    const dateRange: DateRange = {
      fromTs: routerContext?.params.dateRange.fromTs || 0,
      toTs: routerContext?.params.dateRange.toTs || 0,
    };
    const shouldShowText2Query = useSelector(getShouldShowText2Query);
    const text2QueryReqData = useSelector(getSetText2QueryRequest);
    let newDateRange = dateRange;
    if (text2QueryReqData?.dateRange) {
      if (
        text2QueryReqData?.dateRange?.fromTs &&
        text2QueryReqData?.dateRange.toTs &&
        text2QueryReqData?.dateRange.toTs -
          text2QueryReqData?.dateRange.fromTs !=
          3600000
      ) {
        newDateRange = text2QueryReqData.dateRange;
      }
    }

    const conditionalProps =
      shouldShowText2Query && text2QueryReqData
        ? {
            filters: text2QueryReqData.filters,
            groupBy: text2QueryReqData.groupBy,
            dateRangeOverride: newDateRange,
          }
        : {};

    const [sectionExpand, setSectionExpand] = useState(true);

    const renderGraph = (
      <div className={"bar-scale"}>
        <RenderGraph
          expandableTitleOptions={{
            showExpandable: true,
            titleOnClick: (expanded) => {
              setSectionExpand(expanded);
            },
          }}
          chartType="bar_chart"
          column={[
            "total_info",
            "total_errors",
            "total_warnings",
            "total_debug",
          ]}
          titleInfo="Graph built based on selected severity values overtime"
          resourceType="log"
          widgetAppId={2}
          title="LOGS OVER TIME"
          columnConfig={{
            total_info: { color: "var(--color-secondary)" },
            total_errors: { color: "var(--color-error)" },
            total_warnings: { color: "var(--color-warning)" },
            total_debug: { color: "var(--color-success)" },
          }}
          {...conditionalProps}
        />
      </div>
    );

    return (
      <>
        <div className="line-chart-container">
          {/* <SectionExpand
            expandItem={sectionExpand}
            handleSectionExpand={() => setSectionExpand(!sectionExpand)}
            showBorder={false}
            shouldtitleStyleBold={false}
            title="Logs over time"
          >
           
          </SectionExpand> */}
          {renderGraph}
        </div>
        <WrappedComponent {...props} sectionExpand={sectionExpand} />
      </>
    );
  };

  return HOC;
};

export default withSectionExpand;
