import { GLOBAL_ATTRIBUTE_FILTER } from "views/modules/builder/entities/builder.entities";
import { WithExpr } from "views/modules/builder/entities/extra.entities";

export const REFRESH_BTN_CLICK = "REFRESH_BTN_CLICK";
export const SET_FILTER_BAR = "SET_FILTER_BAR";
export const SEARCH_FILTER_BAR = "SEARCH_FILTER_BAR";
export const LOGS_RECEIVED = "LOGS_RECEIVED";
export const LOGS_LIST_RECEIVED = "LOGS_LIST_RECEIVED";
export const FILTERED_LOGS_RECEIVED = "FILTERED_LOGS_RECEIVED";
export const LOG_DETAIL_RECEIVED = "LOG_DETAIL_RECEIVED";
export const WS_LOGS_RECEIVED = "WS_LOGS_RECEIVED";
export const WS_LOG_DETAIL_RECEIVED = "WS_LOG_DETAIL_RECEIVED";
export const SET_FILTER_ACTION = "SET_FILTER_ACTION";
export const LOGS_ATTR_RECEIVED = "LOGS_ATTR_RECEIVED";
export const LOG_TIMELINE_RECEIVED = "LOG_TIMELINE_RECEIVED";
export const LOGS_TIMELINE_RECEIVED = "LOGS_TIMELINE_RECEIVED";
export const SET_LOG_INDICATOR_REQUEST = "SET_LOG_INDICATOR_REQUEST";
export const FILTERS_RECIEVED = "FILTERS_RECIEVED";
export const SET_LOG_TIMINGS = "SET_LOG_TIMINGS";
export const SOURCE_FILTERS_RECIEVED = "SOURCE_FILTERS_RECIEVED";
export const SOURCE_FILTERS_CHANGED = "SOURCE_FILTERS_CHANGED";
export const SET_PICKER_DATE = "SET_PICKER_DATE";
export const SET_FILTER_FOR_LOGS = "SET_FILTER_FOR_LOGS";
export const REMOVE_FILTER_FOR_LOGS = "REMOVE_FILTER_FOR_LOGS";
export const SET_SELECTED_FILTER = "SET_SELECTED_FILTER";
export const SET_FILTERS_OPTIONS = "SET_FILTERS_OPTIONS";
export const ADD_FILTERS_OPTIONS = "ADD_FILTERS_OPTIONS";
export const ADD_VALUE_FILTERS_OPTIONS = "ADD_VALUE_FILTERS_OPTIONS";
export const SET_LOG_TIMELINE_TIMINGS = "SET_LOG_TIMELINE_TIMINGS";
export const APPEND_TO_LOGS = "APPEND_TO_LOGS";
export const LOGS_SETTINGS_RECEIVED = "LOGS_SETTINGS_RECEIVED";
export const SET_SETTING_ID = "SET_SETTING_ID";
export const FINGER_PRINTS_RECEIVED = "FINGER_PRINTS_RECEIVED";
export const APM_PROJECTS_RECIEVED = "APM_PROJECTS_RECIEVED";
export const SET_LOG_INIT = "SET_LOG_INIT";
export const NAME_SPACES_RECIEVED = "NAME_SPACES_RECIEVED";
export const LOG_CUSTOM_METRICS_RECIEVED = "LOG_CUSTOM_METRICS_RECIEVED";
export const SET_CUSTOM_METRICS_TIMINGS = "SET_CUSTOM_METRICS_TIMINGS";

export const WS_LOGS_TL_RECEIVED = "WS_LOGS_TL_RECEIVED";
export const LOGS_TL_RECEIVED = "LOGS_TL_RECEIVED";

export const SET_COLUMN_FOR_LOGS = "SET_COLUMN_FOR_LOGS";
export const SET_TEXT2QUERY_REQUEST = "SET_TEXT2QUERY_REQUEST";
export const SHOUD_SHOW_TEXT2QUERY = "SHOUD_SHOW_TEXT2QUERY";
export const CUSTOM_METRIC_ID = "CUSTOM_METRIC_ID";
export const FILTER_ACTION = {} as any;

export const SET_BUILDER_CONDITIONS = "SET_BUILDER_CONDITIONS";

export const FILTERS = {} as any;
export const LOG_FILTERS = {} as any;
export const TIMINGS = {
  start_time: new Date().getTime() - 12 * 3600000,
  end_time: new Date().getTime(),
};

export const TIMELINE = {
  start_time: new Date().getTime() - 24 * 3600000,
  end_time: new Date().getTime(),
};

export const CUSTOM_METRIC_TIME = {
  start_time: new Date().getTime() - 900000,
  end_time: new Date().getTime(),
};

export const PICKERDATE = {
  time: [new Date().getTime()],
};

export const BUILDER_EXPRESSIN: WithExpr = {
  key: GLOBAL_ATTRIBUTE_FILTER,
  value: {},
  is_arg: true,
};

export const FILTER_BAR = {
  // index: {
  //     label: "Index",
  //     checkboxes: ["cert-manager", "default", "dynatrace", "elastic-system", "ngress-ngnix", "ingress-controller-test", "buoyant-cloud"],
  //     selected: [],
  // },
  // source: {
  //     label: "Source",
  //     checkboxes: ["option 1", "option 2"],
  //     selected: [],
  // },
  // host: {
  //     label: "Host",
  //     checkboxes: ["option 1", "option 2"],
  //     selected: [],
  // },
  // service: {
  //     label: "Service",
  //     checkboxes: ["option 1", "option 2"],
  //     selected: [],
  // },
  // status: {
  //     label: "Status",
  //     checkboxes: ["option 1", "option 2"],
  //     selected: [],
  // },
  // component_name: {
  //     label: "Component Name",
  //     checkboxes: ["option 1", "option 2"],
  //     selected: [],
  // },
  // version: {
  //     label: "Version",
  //     checkboxes: ["option 1", "option 2"],
  //     selected: [],
  // },
  // image_tag: {
  //     label: "Image Tag",
  //     checkboxes: ["option 1", "option 2"],
  //     selected: [],
  // },
  // container_name: {
  //     label: "Container Name",
  //     checkboxes: ["option 1", "option 2"],
  //     selected: [],
  // },
  // short_image: {
  //     label: "Short Image",
  //     checkboxes: ["option 1", "option 2"],
  //     selected: [],
  // }
} as any;
