import { ReactNode } from "react";

export interface DropdownSelectionProps {
  mode?: DropdownMode;
  placeholder?: string;
  defaultValue?: string[] | string;
  onChange: (value: string | string[]) => void;
  options: OptionProps[] | string[];
  showSearch?: boolean;
  onSearch?: (value: string) => void;
  disabled?: boolean;
  notFoundContent?: string | ReactNode;
  isError?: boolean;
  className?: string;
  isMethodSelection?: boolean;
  onClear?: () => void;
  selectedValues?: string | string[] | number | number[];
  allowClear?: boolean;
  onFocus?: (e: FocusEvent) => void;
  onBlur?: (e: FocusEvent) => void;
  onPopupScroll?: (e: UIEvent) => void;
  extraNoteTitle?: string;
  isLoading?: boolean;
  maxSelection?: number;
}
export interface OptionProps {
  label: string;
  value: string;
  subLabel?: string;
  className?: string;
  disabled?: boolean;
  prefixIcon?: ReactNode
}
export enum DropdownMode {
  Multiple = "multiple",
  AddOptions = "tags",
  Default = "default",
}

export interface DescriptiveDropdownOptions<T> {
  value: string;
  label: string;
  descriptionDetails?: T;
  disabled?: boolean;
  className?: string;
}

export interface DescriptiveDropdownProps<T>
  extends Omit<DropdownSelectionProps, "options"> {
  options: DescriptiveDropdownOptions<T>[];
  descriptionJSX: (details?: T) => ReactNode;
  footerJSX?: () => ReactNode;
  popupMatchSelectWidth?: number | boolean;
  listHeight?: number;
}
