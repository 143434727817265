/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import Button from "core/components/v2/button";
import FormInputValidation from "core/components/v2/form/form-input-validation";
import FormTextInput from "core/components/v2/form/form-text-input";
import { debounceHandler } from "core/utils";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  generateClientAgentConfig,
  requestAgentTokenByName,
  updateClientAgentConfig,
} from "store/settings/installation/api";
import { showToast } from "store/toast-alerts/actions";
import { App, Languages } from ".";
import MobileInstallationPage from "./component/mobile-installation-page";

export interface ApplicationDetails {
  app_name: string;
  token: string;
  language: string;
  tracePropagationTargets: string[];
  type: string;
}

const MobileInstallation = ({
  language,
  isUpdate,
  application,
  isApplicationConfigUpdated,
  setIsApplicationConfigUpdated,
}: {
  language: Languages;
  isUpdate?: boolean;
  application?: App;
  isApplicationConfigUpdated?: boolean;
  setIsApplicationConfigUpdated?: (value: boolean) => void;
}) => {
  const dispatch = useDispatch();

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [updateFlag, setUpdateFlag] = useState(isUpdate || false);
  const [applicationDetails, setApplicationDetails] =
    useState<ApplicationDetails>(
      isUpdate && application
        ? {
            app_name: application.name,
            token: application.token,
            language: application.language,
            tracePropagationTargets: application.tracePropagationTargets,
            type: "client",
          }
        : {
            app_name: "",
            token: "",
            language: language,
            tracePropagationTargets: [],
            type: "client",
          }
    );

  const validateApplicationName = (value: string) => {
    if (!value) {
      setErrors({
        ...errors,
        app_name: "Application name is required",
      });
    } else {
      requestAgentTokenByName({ name: value }, (res: any) => {
        if (value === res?.data?.data?.meta_data?.RawMessage?.name) {
          setErrors({
            ...errors,
            app_name: "Application name already exists.",
          });
        } else {
          setErrors({
            ...errors,
            app_name: "",
          });
        }
      });
    }
  };
  const setAppName = (value: string) => {
    validateApplicationName(value);

    setApplicationDetails({
      ...applicationDetails,
      app_name: value,
    });
  };

  const createNewApplication = () => {
    generateClientAgentConfig(
      { ...applicationDetails, app_name: applicationDetails?.app_name?.trim() },
      (success, config) => {
        if (success) {
          setApplicationDetails({
            ...applicationDetails,
            token: config?.token ?? "",
          });
          setUpdateFlag(true);
          dispatch(
            showToast(
              "success",
              `Application ${applicationDetails.app_name} has been created successfully."`,
              {
                title: "Successfully Created",
              }
            )
          );
        } else {
          dispatch(
            showToast(
              "error",
              "An error occurred while updating the application.",
              {
                title: "Not Created",
              }
            )
          );
        }
      }
    );
  };

  const updateApplicationConfig = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateClientAgentConfig(
      { ...applicationDetails, app_name: applicationDetails?.app_name?.trim() },
      (success, config) => {
        if (success) {
          setIsApplicationConfigUpdated &&
            setIsApplicationConfigUpdated(!isApplicationConfigUpdated);
          dispatch(
            showToast(
              "success",
              `Application ${applicationDetails.app_name} has been updated successfully."`,
              {
                title: "Successfully Updated",
              }
            )
          );
        } else {
          dispatch(
            showToast(
              "error",
              "An error occurred while updating the application.",
              {
                title: "Not Updated",
              }
            )
          );
        }
      }
    );
  };

  const getTitle = () => {
    if (language === Languages.IOS) {
      return "iOS";
    } else if (language == Languages.Android) {
      return "Android";
    } else {
      return "ReactNative";
    }
  };

  return (
    <div className="application">
      {!isUpdate && (
        <span>
          {`${getTitle()} RUM is for generic web application user monitoring.`}
        </span>
      )}
      <div className="application-form">
        <div className="application-input">
          <FormTextInput
            id={"app_name"}
            label={"Application Name"}
            defaultValue={applicationDetails.app_name}
            placeholder="Application Name"
            isError={Boolean(errors?.app_name)}
            onChange={debounceHandler(
              0,
              (value: string) => {
                setAppName(value);
              },
              500
            )}
          />
          <FormInputValidation
            isError={Boolean(errors?.app_name)}
            text={errors?.app_name}
          />
        </div>
        <div className="application-action">
          {updateFlag ? (
            <Button
              primary
              disabled={Boolean(errors?.app_name)}
              onClick={() => updateApplicationConfig()}
            >
              Save Configuration
            </Button>
          ) : (
            <Button
              primary
              disabled={Boolean(errors?.app_name)}
              onClick={() => createNewApplication()}
            >
              Create Application
            </Button>
          )}
        </div>
      </div>
      {updateFlag && (
        <MobileInstallationPage
          language={language}
          applicationDetails={applicationDetails}
        />
      )}
    </div>
  );
};

export default MobileInstallation;
