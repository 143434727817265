import { createSelector } from "reselect";
import { get } from "lodash-es";

export const getBackoffAuthRandom = createSelector(
  (state: any) => get(state, ["backoff", "auth", "random"], ""),
  (data: any) => data
);

export const getBackoffAuthToken = createSelector(
  (state: any) => get(state, ["backoff", "auth", "token"], ""),
  (data: any) => data
);

export const getBackoffUsersList = createSelector(
  (state: any) => {
    return get(state, ["backoff", "user", "list"], []);
  },
  (data: any) => data
);
export const getBackoffUsersMachine = createSelector(
  (state: any) => {
    return get(state, ["backoff", "user", "machineCount"], []);
  },
  (data: any) => data
);

export const getBackoffUsersInflight = createSelector(
  (state: any) => {
    return get(state, ["backoff", "user", "inflight"], false);
  },
  (data: any) => data
);

export const getBackoffAgentsList = createSelector(
  (state: any) => {
    return get(state, ["backoff", "agent", "agentsuser"], []);
  },
  (data: any) => data
);

export const getBackoffLogs = createSelector(
  (state: any) => {
    return get(state, ["backoff", "logs", "logsdetail"], []);
  },
  (data: any) => data
);

export const getBackoffUsages = createSelector(
  (state: any) => get(state, ["backoff", "usages"], {}),
  (data) => data
);
export const getBackoffUserLogin = createSelector(
  (state: any) => {
    return get(state, ["backoff", "userlogin", "userlogindetails"], []);
  },
  (data: any) => data
);

export const getEmailDetails = createSelector(
  (state: any) => {
    return get(state, ["backoff", "emaildetail", "emaildetails"], []);
  },
  (data: any) => data
);

export const getEmailCampaigns = createSelector(
  (state: any) => {
    return get(state, ["backoff", "campaign", "emailtemplates"], []);
  },
  (data: any) => data
);

export const getContactList = createSelector(
  (state: any) => {
    return get(state, ["backoff", "contact", "usercontact"], []);
  },
  (data: any) => data
);

export const getThemesMode = createSelector(
  (state: any) => {
    const token = get(state, ["auth_token"], null);
    if (!token) return "light";
    return get(state, ["theme", "mode"], "light");
  },
  (mode: string) => mode
);

export const getVercelData = createSelector(
  (state: any) => {
    return get(state, ["backoff", "verceldata", "verceldata"], []);
  },
  (data: any) => data
);

export const getFeatureFlagStatus = createSelector(
  (state: any) => {
    return get(state, ["backoff", "featureFlagStatus"]);
  },
  (data: any) => data
);
