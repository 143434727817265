import React, { useRef, useState } from "react";

import classNames from "classnames";
import { DownArrowSvg } from "core/components/svg/icons";
import { InfoPlacement } from "core/components/v2/card/entity";
import { Size } from "core/components/v2/enums";
import StatusBadge, { StatusBadgeType } from "core/components/v2/status-badge";
import {
  MwCardProps,
  ValueType,
} from "views/layouts/app/components/cards/model";
import { ActionMenu } from "../action-menu";
import Button from "../button";
import LogoLoader from "../loader/logo-loader";
import { PopoverItem } from "../popover/item";
import { InfoIcon, SectionMinusIcon, SectionPlusIcon } from "../svg/icons";
import Tooltip from "../tooltip";

const CLASS_PREFIX = "mw";

interface CardProps {
  /**
   * Custom Class name for the Card
   */
  className?: string;
  /**
   * Custom Class name for the Card body
   */
  bodyclassName?: string;
  /**
   * If card has borders
   * @default false
   */
  bordered?: boolean;
  /**
   * If card Header Title
   * @default null
   */
  title?: string | React.ReactNode;
  /**
   * View extra on hover of a card
   * @default false
   */
  displayActionsOnHoverOnly?: boolean;
  /**
   * View extra elements on right upper corder
   * @default null
   */
  cardActions?: React.ReactNode;
  /**
   * View extra elements on right upper corder
   * @default null
   */
  titleInfo?: string;
  /**
   * Card Body
   * @default null
   */
  children?: React.ReactNode;
  options?: string[];
  /**
   * Default Selected Option
   */
  selectedOption?: string;
  /**
   * Call back when option is selected
   */
  selectOption?: (x: string) => void;
  queryCardProps?: MwCardProps;
  infoPlacement?: InfoPlacement;
  isLoading?: boolean;
  isError?: boolean;
  errorMsg?: string;
  refreshData?: () => void;
  fontColor?: string;
  expandableTitleOptions?: {
    showExpandable?: boolean;
    titleOnClick?: (expanded: boolean) => void;
  };
  bgColor?: string;
}
const Card = (props: CardProps) => {
  const {
    children,
    bordered,
    className: MainCardCalssName = "",
    bodyclassName = "",
    displayActionsOnHoverOnly,
    title = "",
    cardActions = null,
    titleInfo = null,
    options = [],
    selectedOption = null,
    selectOption = null,
    queryCardProps,
    infoPlacement = "start",
    isLoading = false,
    isError = false,
    // errorMsg,
    refreshData,
    fontColor,
    expandableTitleOptions,
    bgColor,
  } = props;
  const [isHovered, setIsHovered] = useState(
    displayActionsOnHoverOnly === false
  );
  const [isExpandedTitle, setIsExpandedTitle] = useState(true);
  const className = classNames(CLASS_PREFIX + "-card-v2", MainCardCalssName, {
    bordered: bordered === true,
    "display-on-hover": displayActionsOnHoverOnly === true,
    "info-placement-end": infoPlacement === InfoPlacement.End,
  });
  const extraClassName = classNames(CLASS_PREFIX + "-card-extra", {
    "extra-visible": isHovered === true,
    "extra-hidden": isHovered === false,
  });
  const headerClassName = classNames(CLASS_PREFIX + "-card-header", {
    "has-actions": cardActions,
  });
  const bodyClassName = classNames(CLASS_PREFIX + "-card-box", bodyclassName, {
    "is-warning": queryCardProps?.warning,
    "is-error": queryCardProps?.error,
    "is-success": queryCardProps?.success,
    "is-default": queryCardProps?.default,
    "is-hidden": !isExpandedTitle,
  });
  let showHeader = false;
  if (title) {
    showHeader = true;
  }
  if (cardActions) {
    showHeader = true;
  }

  const queryCardContentRef = useRef<HTMLDivElement>(null);
  const queryValueFontSize = Math.min((queryCardContentRef.current?.offsetWidth || 0) / 8, (queryCardContentRef.current?.offsetHeight || 0) / 2);

  const badgeType = queryCardProps?.success
    ? StatusBadgeType.Success
    : queryCardProps?.error
      ? StatusBadgeType.Error
      : queryCardProps?.warning
        ? StatusBadgeType.Warning
        : StatusBadgeType.Info;
  return (
    <div
      className={className}
      onMouseEnter={() => {
        if (displayActionsOnHoverOnly) setIsHovered(true);
      }}
      onMouseLeave={() => {
        if (displayActionsOnHoverOnly) setIsHovered(false);
      }}
    >
      {showHeader && (
        <div className={headerClassName}>
          <div className={CLASS_PREFIX + "-card-header-wrapper"}>
            {expandableTitleOptions?.showExpandable && (
              <Button
                size={Size.Small}
                className="card-expand-button"
                prefixicon={
                  isExpandedTitle ? (
                    <div className="circle">
                      <SectionMinusIcon />
                    </div>
                  ) : (
                    <div className="circle">
                      <SectionPlusIcon />
                    </div>
                  )
                }
                onClick={() => {
                  if (expandableTitleOptions.titleOnClick) {
                    expandableTitleOptions.titleOnClick(!isExpandedTitle);
                  }

                  setIsExpandedTitle(!isExpandedTitle);
                }}
              />
            )}
            {(title || options.length > 0) && (
              <div className={CLASS_PREFIX + "-card-header-title"}>
                {options.length > 0 ? (
                  <>
                    <ActionMenu
                      suffixicon={<DownArrowSvg />}
                      text={selectedOption ? selectedOption : ""}
                    >
                      {options.map((item) => (
                        <div key={item}>
                          <PopoverItem
                            selected={item === selectedOption}
                            onClick={() => {
                              if (selectOption) selectOption(item);
                            }}
                          >
                            {item}
                          </PopoverItem>
                        </div>
                      ))}
                    </ActionMenu>
                  </>
                ) : (
                  title
                )}
                {titleInfo && (
                  <Tooltip content={titleInfo}>
                    <div>
                      <InfoIcon />
                    </div>
                  </Tooltip>
                )}
              </div>
            )}
            {cardActions && <div className={extraClassName}>{cardActions}</div>}
          </div>
        </div>
      )}
      <div
        className={bodyClassName}
        style={{
          position: "relative",
          backgroundColor: bgColor ?? "inherit",
        }}
      >
        {isError && !isLoading ? (
          <div className="error-portion">
            <InfoIcon size={32} color="var(--color-error)" />
            <div className="error-text">
              <span>Could not load data.</span>
            </div>
            {refreshData && (
              <div className="retry-btn" onClick={refreshData}>
                <span>Retry</span>
              </div>
            )}
          </div>
        ) : (
          <>
            {children}
            {queryCardProps && (
              <div className={"content-area"} ref={queryCardContentRef}>
                {queryCardProps.prefixicon}
                {typeof queryCardProps.value !== "undefined" ? (
                  <div className={"value"}>
                    {queryCardProps.valueType === ValueType.Badge &&
                    queryCardProps.value ? (
                      <StatusBadge
                        labelText={queryCardProps.value}
                        size={Size.Medium}
                        type={badgeType}
                      />
                    ) : (
                      <span style={{ color: fontColor ?? "inherit", fontSize: queryValueFontSize ?? "inherit" }}>
                        {queryCardProps.value}
                      </span>
                    )}
                  </div>
                ) : (
                  ""
                )}
                {queryCardProps.subInfo && (
                  <div className={"sub-info"}>
                    <StatusBadge
                      labelText={queryCardProps.subInfo}
                      size={Size.Small}
                      type={badgeType}
                    />
                  </div>
                )}
              </div>
            )}
          </>
        )}
        {isLoading ? (
          <div
            className="error-portion"
            style={{
              position: "absolute",
              width: "100%",
              backgroundColor: "#ffffff40",
              zIndex: 1,
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <LogoLoader size={40} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Card;

Card.defaultProps = {
  className: "",
  bodyclassName: "",
  bordered: false,
  displayActionsOnHoverOnly: false,
};
