import { createReducer } from "core/utils/create_reducer";
import { combineReducers } from "redux";
import {
  DB_TRACE_LIST_RECEIVED,
  ERROR_FILTER_RECEIVED,
  REMOVE_SEARCH_TAG,
  SEARCH_DB_VALUE_SELECT,
  SEARCH_VALUE_SELECT,
  SELECT_FILTER_VALUES,
  SET_APM_DATE_RANGE,
  SET_DB_SPAN_FILTER_LABELS,
  SET_SPAN_FILTER_LABELS,
  SPAN_DB_LABEL_DATA_UPDATE,
  SPAN_LABEL_DATA_UPDATE,
  SPAN_LABELS_DATA_RECEIVED,
  SPAN_LIST_RECEIVED,
  TOGGLE_FILTER_VALUES,
  UPDATE_DB_SEARCH_LABEL,
  UPDATE_SEARCH_LABEL,
  WS_SPAN_LIST_RECEIVED,
} from "store/apm/constant";
import { granularityInterval } from "core/utils/chart_helper";
import { isArray } from "lodash-es";
import { organizeTags } from "views/modules/apm/utils";

const APMReducer = combineReducers({
  applied_date_range: createReducer(
    {
      from_ts: null,
      to_ts: null,
      interval: 0,
    },
    {
      [SET_APM_DATE_RANGE]: (state, opts) => {
        state.from_ts = opts.from_ts.valueOf();
        state.to_ts = opts.to_ts.valueOf();
        state.interval = granularityInterval(
          opts.from_ts.valueOf(),
          opts.to_ts.valueOf()
        );

        return { ...state };
      },
    }
  ),
  apm_services: createReducer(
    {
      services: [],
    },
    {
      [SPAN_LABELS_DATA_RECEIVED]: (state, opts) => {
        if (
          opts &&
          opts.root &&
          opts.label == "service.name" &&
          isArray(opts.data) &&
          opts.data.length > 0
        ) {
          state.services = opts.data;
        }

        return { ...state };
      },
    }
  ),
  trace_list: createReducer(
    {
      trace_timeline: null,
      error_trace_timeline: null,
      trace_list: [],
      error_trace_list: [],
      span_filters: {},
      span_search_filters: {},
      error_filters: {},
      error_search_filters: {},
    },
    {
      [UPDATE_SEARCH_LABEL]: (state, opts) => {
        if (
          state.span_search_filters &&
          Object.keys(state.span_search_filters).length > 0
        ) {
          if (state.span_search_filters[opts.label]) {
            state.span_search_filters[opts.label].is_loading = opts.is_loading;
          }
        }

        return { ...state };
      },
      [SET_SPAN_FILTER_LABELS]: (state, opts) => {
        if (Object.keys(opts).length > 0 && opts?.table?.filters) {
          const tags = opts.table.filters;
          const organizedTags = organizeTags(tags);

          const searchFilter: Record<string, { data: any[] }> = {};

          for (const item in tags) {
            searchFilter[item] = { data: [] };
          }

          state.span_filters = organizedTags;
          state.span_search_filters = searchFilter;
        }

        return { ...state };
      },
      [SPAN_LABELS_DATA_RECEIVED]: (state, opts) => {
        if (opts && opts.label && opts.data && opts.data.length > 0) {
          if (
            Object.keys(state.span_search_filters).length > 0 &&
            state.span_search_filters[opts.label]
          ) {
            if (opts.filter && Object.keys(opts.filter).length > 0) {
              state.span_search_filters[opts.label].data = opts.data.map(
                (item: any) => {
                  if (opts.filter.operator === "=") {
                    if (opts.filter.value.includes(item.value)) {
                      item.is_filter = true;
                    }
                  } else if (opts.filter.operator === "!=") {
                    if (!opts.filter.value.includes(item.value)) {
                      item.is_filter = true;
                    }
                  }

                  return item;
                }
              );
            } else {
              state.span_search_filters[opts.label].data = opts.data;
            }
          }

          const label = opts.label;
          const labelData = opts.data;
          const traceFilters = { ...state.span_filters };

          if (Object.keys(traceFilters).length > 0) {
            const splitLabel = label.split(".");
            const category = splitLabel[0];

            if (traceFilters[category]) {
              const categoryFilterLabel = traceFilters[category][label];
              categoryFilterLabel.data = labelData;
              categoryFilterLabel.toggle = true;
              categoryFilterLabel.is_loading = false;
            }
          }

          state.span_filters = traceFilters;
        }
        return { ...state };
      },
      [TOGGLE_FILTER_VALUES]: (state, opts) => {
        const label = opts.label;
        const traceFilters = { ...state.span_filters };

        if (Object.keys(traceFilters).length > 0) {
          const splitLabel = label.split(".");
          const category = splitLabel[0];

          if (traceFilters[category]) {
            traceFilters[category][label] = {
              ...traceFilters[category][label],
              toggle: opts.toggle,
            };
          }
        }

        state.span_filters = traceFilters;

        return { ...state };
      },
      [SELECT_FILTER_VALUES]: (state, opts) => {
        const label = opts.label;
        const traceFilters = { ...state.span_filters };

        if (Object.keys(traceFilters).length > 0) {
          const splitLabel = label.split(".");
          const category = splitLabel[0];

          if (traceFilters[category]) {
            traceFilters[category][label].data.find(
              (item: any) => item.value === opts.value
            ).is_filter = opts.checked;
          }
        }

        const searchFilters = { ...state.span_search_filters };

        if (Object.keys(searchFilters).length > 0) {
          searchFilters[label].data.find(
            (item: any) => item.value === opts.value
          ).is_filter = opts.checked;
        }

        state.span_search_filters = searchFilters;
        state.span_filters = traceFilters;

        return { ...state };
      },
      [SPAN_LABEL_DATA_UPDATE]: (state, opts) => {
        if (opts.data && opts.data.length > 0) {
          const label = opts.label;
          const splitLabel = label.split(".");
          const category = splitLabel[0];

          const traceFilters = { ...state.span_filters };
          const searchFilters = { ...state.span_search_filters };

          if (Object.keys(traceFilters).length > 0) {
            if (traceFilters[category]) {
              const categoryFilterLabel = traceFilters[category][label];
              categoryFilterLabel.data = opts.data;
              categoryFilterLabel.toggle = true;
              categoryFilterLabel.is_loading = false;
            }
          }

          if (
            searchFilters &&
            Object.keys(searchFilters).length > 0 &&
            searchFilters[label]
          ) {
            searchFilters[label].data = opts.data;
            searchFilters[label].is_loading = false;
          }

          state.span_filters = traceFilters;
          state.span_search_filters = searchFilters;
        }

        return { ...state };
      },
      [SEARCH_VALUE_SELECT]: (state, opts) => {
        const traceFilters = { ...state.span_filters };
        const searchFilters = { ...state.span_search_filters };
        const label = opts.label;
        const splitLabel = label.split(".");
        const category = splitLabel[0];

        if (traceFilters[category] && traceFilters[category][label]) {
          traceFilters[category][label].toggle = true;
        }

        if (opts.operator === "=") {
          if (traceFilters[category] && traceFilters[category][label]) {
            traceFilters[category][label].data.find(
              (item: any) => item.value.toString() == opts.value.toString()
            ).is_filter = true;
          }

          searchFilters[label].data.find(
            (item: any) => item.value.toString() == opts.value.toString()
          ).is_filter = true;
        }

        state.span_filters = traceFilters;
        state.span_search_filters = searchFilters;

        return { ...state };
      },
      [REMOVE_SEARCH_TAG]: (state, opts) => {
        const traceFilters = { ...state.span_filters };
        const searchFilters = { ...state.span_search_filters };
        const label = opts.label;
        const splitLabel = label.split(".");
        const category = splitLabel[0];

        if (Object.keys(traceFilters).length > 0 && traceFilters[category]) {
          traceFilters[category][label].toggle = false;
          traceFilters[category][label].data.forEach((item: any) => {
            item.is_filter = false;
          });
        }

        if (Object.keys(searchFilters).length > 0 && searchFilters[label]) {
          searchFilters[label].data.forEach((item: any) => {
            item.is_filter = false;
          });
        }

        state.span_filters = traceFilters;
        state.span_search_filters = searchFilters;

        return { ...state };
      },
      [ERROR_FILTER_RECEIVED]: (state, opts) => {
        if (opts.status && Object.values(opts.data).length > 0) {
          state.error_filters = opts.data;

          const newObject: any = {};
          Object.entries(opts.data).forEach(([key, value]: any) => {
            newObject[key] = value.map((item: any) => item.filter_value);
          });

          state.error_search_filters = newObject;
        }
        return { ...state };
      },
    }
  ),
  database_traces: createReducer(
    {
      db_span_search_filters: {},
    },
    {
      [SET_DB_SPAN_FILTER_LABELS]: (state, opts) => {
        const tags = opts.data;
        const searchFilter: Record<string, { data: any[] }> = {};

        for (const item in tags) {
          searchFilter[item] = { data: [] };
        }

        state.db_span_search_filters = searchFilter;
        return { ...state };
      },
      [UPDATE_DB_SEARCH_LABEL]: (state, opts) => {
        if (
          state.db_span_search_filters &&
          Object.keys(state.db_span_search_filters).length > 0
        ) {
          if (state.db_span_search_filters[opts.label]) {
            state.db_span_search_filters[opts.label].data = opts.data;
          }
        }

        return { ...state };
      },
      [SPAN_DB_LABEL_DATA_UPDATE]: (state, opts) => {
        if (opts.data && opts.data.length > 0) {
          const label = opts.label;
          const searchFilters = { ...state.db_span_search_filters };

          if (
            searchFilters &&
            Object.keys(searchFilters).length > 0 &&
            searchFilters[label]
          ) {
            searchFilters[label].data = opts.data;
            searchFilters[label].is_loading = false;
          }

          state.db_span_search_filters = searchFilters;
        }

        return { ...state };
      },
      [SEARCH_DB_VALUE_SELECT]: (state, opts) => {
        const searchFilters = { ...state.db_span_search_filters };
        const label = opts.label;

        if (opts.operator === "=") {
          searchFilters[label].data.find(
            (item: any) => item.value.toString() == opts.value.toString()
          ).is_filter = true;
        }

        state.db_span_search_filters = searchFilters;

        return { ...state };
      },
    }
  ),
  span_list: createReducer(
    {
      list: [],
    },
    {
      [SPAN_LIST_RECEIVED]: (state, opts) => {
        state.list = opts.spans;
        return { ...state };
      },
      [WS_SPAN_LIST_RECEIVED]: (state, opts) => {
        if (opts.data && opts.data.service) {
          const newSpans = Object.values(opts.data.service).flat();
          const filteredSpans = newSpans.map((span: any) => ({
            durationNano: span.durationNano,
            events: span.events,
            name: span.name,
            parentSpanID: span.parentSpanId,
            resource_attributes: span.resourceAttributes,
            "service.name": span.resourceAttributes["service.name"],
            spanID: span.spanId,
            span_count: 0,
            tagMap: span.tagMap,
            timestampNs: span.timestampNs,
            traceID: span.traceId,
          }));

          const combinedSpans = [...filteredSpans, ...state.list];
          combinedSpans.sort((a, b) => b.timestampNs - a.timestampNs);

          state.list = combinedSpans.slice(0, 50);
        }

        return { ...state };
      },
    }
  ),
});

export default APMReducer;
