import { createReducer } from "core/utils/create_reducer";
import {
  LOGS_RECEIVED,
  LOG_DETAIL_RECEIVED,
  WS_LOGS_RECEIVED,
  WS_LOG_DETAIL_RECEIVED,
  FILTER_BAR,
  SET_FILTER_BAR,
  SEARCH_FILTER_BAR,
  FILTER_ACTION,
  SET_FILTER_ACTION,
  LOGS_ATTR_RECEIVED,
  FILTERED_LOGS_RECEIVED,
  LOGS_TIMELINE_RECEIVED,
  SET_LOG_INDICATOR_REQUEST,
  FILTERS_RECIEVED,
  FILTERS,
  SET_LOG_TIMINGS,
  TIMINGS,
  SOURCE_FILTERS_RECIEVED,
  SOURCE_FILTERS_CHANGED,
  SET_PICKER_DATE,
  PICKERDATE,
  SET_FILTER_FOR_LOGS,
  REMOVE_FILTER_FOR_LOGS,
  SET_SELECTED_FILTER,
  LOG_FILTERS,
  SET_FILTERS_OPTIONS,
  ADD_FILTERS_OPTIONS,
  ADD_VALUE_FILTERS_OPTIONS,
  SET_LOG_TIMELINE_TIMINGS,
  TIMELINE,
  APPEND_TO_LOGS,
  LOGS_SETTINGS_RECEIVED,
  SET_SETTING_ID,
  FINGER_PRINTS_RECEIVED,
  APM_PROJECTS_RECIEVED,
  SET_LOG_INIT,
  NAME_SPACES_RECIEVED,
  LOG_CUSTOM_METRICS_RECIEVED,
  CUSTOM_METRIC_ID,
  CUSTOM_METRIC_TIME,
  SET_CUSTOM_METRICS_TIMINGS,
  SET_COLUMN_FOR_LOGS,
  WS_LOGS_TL_RECEIVED,
  LOGS_TL_RECEIVED,
  REFRESH_BTN_CLICK,
  SET_BUILDER_CONDITIONS,
  BUILDER_EXPRESSIN,
  LOGS_LIST_RECEIVED,
  // SET_TEXT2QUERY_REQUEST,
  SHOUD_SHOW_TEXT2QUERY,
  SET_TEXT2QUERY_REQUEST,
} from "./constant";
import { combineReducers } from "redux";
import { LogsListBase, LogsList } from "src/entities/logs/lists";
import { Log } from "views/modules/logs-v2/types";

function pushMetric(sourceArray: object[], newArray: object) {
  if (sourceArray.length >= 10) sourceArray.shift();
  sourceArray.push(newArray);
  // sourceArray = sourceArray.concat(newArray);
  return sourceArray;
}

export default combineReducers({
  setText2QueryRequest: createReducer({}, {
    [SET_TEXT2QUERY_REQUEST]: (state, opts) => {
      return opts.payLoad;
    },
  }),
  shouldShowText2Query: createReducer(false, {
    [SHOUD_SHOW_TEXT2QUERY]: (state, opts) => {
      return opts.payLoad;
    },
  }),
  refreshBtnClick: createReducer(false, {
    [REFRESH_BTN_CLICK]: (state, opts) => {
      return opts.value;
    },
  }),
  filterBar: createReducer(FILTER_BAR, {
    [SET_FILTER_BAR]: (state, opts) => {
      return opts.filterBar;
    },
    [SEARCH_FILTER_BAR]: (state, opts) => {
      const filterBar = JSON.parse(JSON.stringify(FILTER_BAR));
      if (opts?.key && opts.key !== "") {
        let result = [];
        result = filterBar[opts.key].checkboxes.filter((item: any) => {
          return item.includes(opts.search);
        });
        filterBar[opts.key].checkboxes = result;
      } else {
        for (const filterKey in filterBar) {
          let result = [];
          result = filterBar[filterKey].checkboxes.filter((item: any) => {
            return item.includes(opts.search);
          });
          filterBar[filterKey].checkboxes = result;
        }
      }
      return filterBar;
    },
  }),

  builder: createReducer(BUILDER_EXPRESSIN, {
    [SET_BUILDER_CONDITIONS]: (state, opts) => {
      return opts.expression;
    },
  }),

  filterAction: createReducer(FILTER_ACTION, {
    [SET_FILTER_ACTION]: (state, opts) => {
      return opts.filterAction;
    },
  }),

  liveIndicator: createReducer(false, {
    [SET_LOG_INDICATOR_REQUEST]: (state, opts) => {
      return opts.value;
    },
  }),

  filters: createReducer(FILTERS, {
    [FILTERS_RECIEVED]: (state, opts) => {
      return opts.res || {};
    },
    [SOURCE_FILTERS_RECIEVED]: (state, opts) => {
      if (opts.res) {
        const { value } = opts;
        if (value && state[value] && typeof state[value] == "object") {
          state[value].attributes = { ...opts.res };
        }
      }
      return { ...state };
    },
    [SOURCE_FILTERS_CHANGED]: (state, opts) => {
      const { fKey, sKey, index, check } = opts;
      if (
        state[fKey] &&
        state[fKey].attributes &&
        Array.isArray(state[fKey].attributes[sKey]) &&
        index >= 0
      ) {
        state[fKey].attributes[sKey][index].Checked = check;
      }
      return { ...state };
    },
  }),
  nameSpaces: createReducer([], {
    [NAME_SPACES_RECIEVED]: (state, opts) => {
      return opts?.payLoad || [];
    },
  }),

  logFilters: createReducer(LOG_FILTERS, {
    [SET_FILTER_FOR_LOGS]: (state, opts) => {
      const { key, value, proc } = opts;

      if (proc == "delete_all") {
        state = {};
        return { ...state };
      }
      if (proc == "add_batch") {
        const { data } = opts || {};

        return { ...data };
      }
      if (key && value) {
        if (state[key]) {
          if (proc == "bulk-addition") {
            state[key] = value;
          } else if (proc == "append") {
            state[key] = [...state[key], ...value];
          } else if (state[key].indexOf(value) == -1) {
            state[key].push(value);
          }
        } else {
          state[key] = [];
          if (proc == "append") {
            state[key] = [...value];
          } else {
            state[key].push(value);
          }
        }
      }
      return { ...state };
    },
    [REMOVE_FILTER_FOR_LOGS]: (state, opts) => {
      const { key, value, proc } = opts;

      if (key && state[key]) {
        if (!proc) {
          delete state[key];
        } else {
          if (proc === "multi_delete") {
            if (value && Array.isArray(value)) {
              state[key] = state[key].filter(
                (item: any) => value.indexOf(item.toLowerCase()) < 0
              );
            }
          } else {
            if (value) {
              // if(value == 'WARN' || value == 'warn') {
              //     state[key] = state[key].filter((item: any) => item !== value && item !== value.toLowerCase() && item !== 'warning');
              // }else {

              // }
              state[key] = state[key].filter(
                (item: any) => item.toLowerCase() !== value.toLowerCase()
              );
            }
          }
        }
        if (state[key] && Array.isArray(state[key]) && state[key].length == 0) {
          delete state[key];
        }
      }

      return { ...state };
    },
  }),

  filterOptions: createReducer(
    {},
    {
      [SET_FILTERS_OPTIONS]: (state, opts) => {
        delete opts["type"];
        state = { ...state, ...opts };
        return { ...state };
      },
      [ADD_FILTERS_OPTIONS]: (state, opts) => {
        delete opts["type"];
        state = { ...state, ...opts };
        return { ...state };
      },
      [ADD_VALUE_FILTERS_OPTIONS]: (state, opts) => {
        delete opts["type"];
        const { key, value } = opts;
        if (
          key &&
          value &&
          Array.isArray(value) &&
          value.length &&
          state[key]
        ) {
          state[key] = value;
        }

        return { ...state };
      },
    }
  ),

  logColumns: createReducer(
    {},
    {
      [SET_COLUMN_FOR_LOGS]: (state, opts) => {
        const { key, proc } = opts;

        switch (proc) {
          case "add_batch":
            const { data } = opts || {};
            return { ...data };
          case "remove_key":
            delete state[key];
            return { ...state };
          default:
            const tempState = { ...state };
            tempState[key] = key;
            return { ...tempState };
        }

        return { ...state };
      },
    }
  ),

  selectedFilter: createReducer(
    { key: null },
    {
      [SET_SELECTED_FILTER]: (state, opts) => {
        return opts;
      },
    }
  ),
  timings: createReducer(TIMINGS, {
    [SET_LOG_TIMINGS]: (state, opts) => {
      return opts;
    },
  }),
  timelineTimings: createReducer(TIMELINE, {
    [SET_LOG_TIMELINE_TIMINGS]: (state, opts) => {
      return opts;
    },
  }),
  customMetricsTimings: createReducer(CUSTOM_METRIC_TIME, {
    [SET_CUSTOM_METRICS_TIMINGS]: (state, opts) => {
      return opts;
    },
  }),
  pickerDate: createReducer(PICKERDATE, {
    [SET_PICKER_DATE]: (state, opts) => {
      return opts.time || [];
    },
  }),

  logsSetting: createReducer([], {
    [LOGS_SETTINGS_RECEIVED]: (state, opts) => {
      return opts.list || [];
    },
  }),

  logsSettingId: createReducer(0, {
    [SET_SETTING_ID]: (state, opts) => {
      return opts.payLoad || 0;
    },
  }),

  apmProjects: createReducer(
    { items: [] },
    {
      [APM_PROJECTS_RECIEVED]: (state, opts) => {
        if (opts.data && Array.isArray(opts.data)) {
          state.items = opts.data;
        }
        return { ...state };
      },
    }
  ),

  initLogs: createReducer(
    { init: true },
    {
      [SET_LOG_INIT]: (state, opts) => {
        return opts.payLoad || { init: true };
      },
    }
  ),

  list: createReducer(
    {
      isLoading: false,
      isError: false,
      items: [],
      update: null,
    },
    {
      [LOGS_RECEIVED]: (state, opts: LogsListBase<LogsList[]>) => {
        if (opts.status && Array.isArray(opts.items)) {
          state.items = opts.items as LogsList[];
        }
        return { ...state };
      },
      [LOGS_LIST_RECEIVED]: (state, opts) => {
        if (opts && opts.items && Array.isArray(opts.items)) {
          state.items = opts.items as Log[];
        }
        return { ...state };
      },
      [APPEND_TO_LOGS]: (state, opts: LogsListBase<LogsList[]>) => {
        if (opts.status && Array.isArray(opts.items)) {
          state.items = [...state.items, ...opts.items] as LogsList[];
        }
        return { ...state };
      },
      [FILTERED_LOGS_RECEIVED]: (state, opts) => {
        if (opts.status && Array.isArray(opts.items)) {
          state.items = opts.items;
        }
        return { ...state };
      },
      [LOGS_TIMELINE_RECEIVED]: (state, opts) => {
        state.timeline = [];
        if (opts && opts.items && Array.isArray(opts.items)) {
          state.timeline = opts.items;
        }
        return { ...state };
      },
      [LOGS_ATTR_RECEIVED]: (state, opts) => {
        const { timeline, resource } = opts;

        if (state.items && Array.isArray(state.items)) {
          const items = state.items;
          for (let record of items) {
            const { id, resourceId } = record;
            if (timeline && timeline[id]) {
              record.timeline = timeline[id];
            }
            if (resourceId && resource[resourceId]) {
              record.attributes =
                resource[resourceId]["resource-attributes"] || {};
            }
          }
          state.items = [...items];
        }

        return { ...state };
      },
      [WS_LOGS_RECEIVED]: (state, opts) => {
        if (opts?.data?.service) {
          if (Object.keys(opts.data.service).length) {
            const wsRecords = opts.data.service;
            if (state.items && Array.isArray(state.items)) {
              const wsItems: Log[] = [];

              for (let w in wsRecords) {
                if (
                  wsRecords[w] &&
                  Array.isArray(wsRecords[w]) &&
                  wsRecords[w].length
                ) {
                  wsRecords[w].forEach((ws: any) => {
                    const obj: Log = {
                      bodyFingerprint: ws.crc32 || "",
                      timestampMs: ws.timestampMs || 0,
                      severityText: ws.severityText || "",
                      logAttributes: ws.attrs || {},
                      count: 0,
                      is_apm: ws.is_apm ? 1 : 0,
                      is_infra: ws.is_apm ? 0 : 1,
                      body: ws.body || "",
                      resource: ws.resource || "",
                      resource_attributes: ws.resourceAttributes || {},
                      source: ws.source || "",
                      attributes: { ...ws.attrs, ...ws.resourceAttributes },
                      serviceName: ws.serviceName || "",
                      traceID: ws.traceID || "",
                      spanId: ws.spanId || "",
                    };

                    wsItems.push(obj);
                  });
                }
              }

              state.items = [...wsItems, ...state.items];

              if (state.items.length > 100) {
                state.items.splice(100);
              }
            }
          }
        }
        return { ...state };
      },
    }
  ),

  timelineLogs: createReducer(
    { items: [] },
    {
      [LOGS_TL_RECEIVED]: (state, opts) => {
        if (opts.list && Array.isArray(opts.list)) {
          state.items = opts.list;
        }
        return { ...state };
      },
      [WS_LOGS_TL_RECEIVED]: (state, opts) => {
        if (opts?.data?.service) {
          if (Object.keys(opts.data.service).length) {
            const wsRecords = opts.data.service;
            if (state.items && Array.isArray(state.items)) {
              const wsItems: any = [];
              // console.log({wsRecords})
              for (let w in wsRecords) {
                if (
                  wsRecords[w] &&
                  Array.isArray(wsRecords[w]) &&
                  wsRecords[w].length
                ) {
                  wsRecords[w].forEach((ws: any) => {
                    const obj: any = {};
                    obj.service = ws.serviceName || "";
                    obj.log_attributes = ws.attrs || {};
                    obj.attributes = ws.resourceAttributes || {};
                    obj.source = ws.attrs?.source || "";
                    obj.id = ws.crc32;
                    obj.body = ws.body;
                    obj.severity = ws.severityText;
                    obj.is_infra = ws.is_apm ? 0 : 1;
                    obj.timestamp = ws.timestampMs;
                    obj.timeline = [];
                    wsItems.push(obj);
                  });
                }
              }

              state.items = [...wsItems, ...state.items];

              if (state.items.length > 200) {
                state.items.splice(200);
              }
            }
          }
        }
        return { ...state };
      },
    }
  ),

  customMetrics: createReducer([], {
    [LOG_CUSTOM_METRICS_RECIEVED]: (state, opts) => {
      return opts.payLoad || [];
    },
  }),

  metricId: createReducer("", {
    [CUSTOM_METRIC_ID]: (state, opts) => {
      return opts.id || "";
    },
  }),

  fingerPrints: createReducer([], {
    [FINGER_PRINTS_RECEIVED]: (state, opts) => {
      if (Array.isArray(opts.items)) {
        state.items = opts.items;
      }
      return { ...state };
    },
  }),

  sidePanel: createReducer(
    {
      info: {},
      update: null,
    },
    {
      [LOG_DETAIL_RECEIVED]: (state, opts) => {
        if (
          opts.status &&
          opts.hasOwnProperty("info") &&
          Object.keys(opts.info).length > 0
        ) {
          state.info = opts.info;
        }
        return { ...state };
      },
      [WS_LOG_DETAIL_RECEIVED]: (state, opts) => {
        if (opts.wsResponse) {
          const wsResponse = opts.wsResponse;
          const wsHostInfo = state.info["host_info"];
          const wsStack = state.info["ws_stats"];
          if (
            wsResponse.hasOwnProperty("cpu") &&
            wsStack.hasOwnProperty("cpu")
          ) {
            const linesLength = wsResponse.cpu.length;
            if (linesLength > 0) {
              wsStack.cpu[0].values = pushMetric(
                wsStack.cpu[0].values,
                wsResponse.cpu[0]
              );
            }
            if (wsHostInfo?.cpu?.hasOwnProperty("usage")) {
              wsHostInfo.cpu.usage = wsResponse.cpu[0]["yAxis"];
            }
          }

          if (
            wsResponse.hasOwnProperty("ram") &&
            wsStack.hasOwnProperty("ram")
          ) {
            const linesLength = wsResponse.ram.length;
            if (linesLength > 0) {
              wsStack.ram[0].values = pushMetric(
                wsStack.ram[0].values,
                wsResponse.ram[0]
              );
            }
            if (wsHostInfo?.ram?.hasOwnProperty("usage")) {
              wsHostInfo.ram.usage = wsResponse.ram[0]["yAxis"];
            }
          }

          if (
            wsResponse.hasOwnProperty("avg_load") &&
            wsStack.hasOwnProperty("avg_load")
          ) {
            const linesLength = wsResponse.avg_load.length;
            if (linesLength > 0) {
              wsStack.avg_load[0].values = pushMetric(
                wsStack.avg_load[0].values,
                wsResponse.avg_load[0]
              );
            }
            if (wsHostInfo?.avg_load?.hasOwnProperty("value")) {
              wsHostInfo.avg_load.value = wsResponse.avg_load[0]["yAxis"];
            }
          }

          if (
            wsResponse.hasOwnProperty("core") &&
            wsStack.hasOwnProperty("core")
          ) {
            for (const coreStats in wsResponse.core) {
              if (wsStack.core.hasOwnProperty(coreStats)) {
                const linesLength = wsResponse.core[coreStats].length;
                for (const [lineIndex, _] of wsResponse.core[
                  coreStats
                ].entries()) {
                  if (linesLength === wsStack.core[coreStats].length) {
                    wsStack.core[coreStats][lineIndex].values = pushMetric(
                      wsStack.core[coreStats][lineIndex].values,
                      wsResponse.core[coreStats][lineIndex]
                    );
                  }
                }
              }
            }
          }

          if (
            wsResponse.hasOwnProperty("app_wise") &&
            wsStack.hasOwnProperty("app_wise")
          ) {
            for (const appStats in wsResponse.app_wise) {
              if (wsStack.app_wise.hasOwnProperty(appStats)) {
                for (const chart in wsResponse.app_wise[appStats]) {
                  const linesLength =
                    wsResponse.app_wise[appStats][chart].length;
                  for (const [lineIndex, _] of wsResponse.app_wise[appStats][
                    chart
                  ].entries()) {
                    if (
                      linesLength === wsStack.app_wise[appStats][chart].length
                    ) {
                      wsStack.app_wise[appStats][chart][lineIndex].values =
                        pushMetric(
                          wsStack.app_wise[appStats][chart][lineIndex].values,
                          wsResponse.app_wise[appStats][chart][lineIndex]
                        );
                    }
                  }
                }
              }
            }
          }

          state.info["host_info"] = wsHostInfo;
          state.info["ws_stats"] = wsStack;
          state.update = Date.now();
          // console.log("state==>", hostInfo.metrics.core.boxes[0].chart.stats[0].values);
        }
        return { ...state };
      },
    }
  ),
});
