/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { getTenantUrl } from "core/application/utils";
import Button from "core/components/v2/button";
import FormInputValidation from "core/components/v2/form/form-input-validation";
import FormTextInput from "core/components/v2/form/form-text-input";
import { DeleteIcon, InfoIcon, PlusIcon } from "core/components/v2/svg/icons";
import Tooltip from "core/components/v2/tooltip";
import AppContext from "core/components/wrapper/context";
import { debounceHandler } from "core/utils";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getThemesMode } from "store/selectors";
import {
  generateClientAgentConfig,
  requestAgentTokenByName,
  updateClientAgentConfig,
} from "store/settings/installation/api";
import { showToast } from "store/toast-alerts/actions";
import { App } from "views/modules/installation-v2/pages/rum";
import Command from "../../components/code-block/command";
import "./_styles.scss";

const JavaScriptInstallation = ({
  isUpdate,
  application,
  isApplicationConfigUpdated,
  setIsApplicationConfigUpdated,
}: {
  isUpdate?: boolean;
  application?: App;
  isApplicationConfigUpdated?: boolean;
  setIsApplicationConfigUpdated?: (value: boolean) => void;
}) => {
  const dispatch = useDispatch();
  const themeMode = useSelector(getThemesMode);
  const [appNameInput, setAppNameInput] = useState("");

  const getTracePropagationTargetValues = (
    tracePropagationTargetValues: string[]
  ) => {
    const tracePropagationTargetValuesObj: Record<string, string> = {};
    tracePropagationTargetValues.forEach(
      (tracePropagationTargetValue: string, index: number) => {
        tracePropagationTargetValuesObj["tracePropagationTargets_" + index] =
          tracePropagationTargetValue;
      }
    );
    return tracePropagationTargetValuesObj;
  };

  const getTracePropagationTargets = (
    tracePropagationTargetValues: string[]
  ) => {
    const tracePropagationTargets = tracePropagationTargetValues.map(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (tracePropagationTargetValue) => ({ type: "text" })
    );

    return tracePropagationTargets.length > 0
      ? tracePropagationTargets
      : [{ type: "text" }];
  };

  const [tracePropagationTargetValues, setTracePropagationTargetValues] =
    useState<Record<string, string>>(
      isUpdate && application
        ? getTracePropagationTargetValues(application.tracePropagationTargets)
        : {}
    );
  const [applicationDetails, setApplicationDetails] = useState<{
    app_name: string;
    token: string;
    language: string;
    tracePropagationTargets: string[];
    type: string;
  }>(
    isUpdate && application
      ? {
          app_name: application.name,
          token: application.token,
          language: application.language,
          tracePropagationTargets: application.tracePropagationTargets,
          type: "client",
        }
      : {
          app_name: "",
          token: "",
          language: "javascript",
          tracePropagationTargets: [],
          type: "client",
        }
  );
  const [tracePropagationTargets, setTracePropagationTargets] = useState(
    isUpdate && application
      ? getTracePropagationTargets(application.tracePropagationTargets)
      : [{ type: "text" }]
  );
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [updateFlag, setUpdateFlag] = useState(isUpdate || false);

  useEffect(() => {
    if (isUpdate && application) {
      setAppNameInput(application.name);
      setTracePropagationTargets(
        getTracePropagationTargets(application.tracePropagationTargets)
      );
      setTracePropagationTargetValues(
        getTracePropagationTargetValues(application.tracePropagationTargets)
      );
      setApplicationDetails({
        app_name: application.name,
        token: application.token,
        language: "javascript",
        tracePropagationTargets: application.tracePropagationTargets,
        type: "client",
      });
    }
  }, [application]);

  const validateApplicationName = (value: string) => {
    if (!value) {
      setErrors({
        ...errors,
        app_name: "Application name is required",
      });
    } else {
      requestAgentTokenByName({ name: value }, (res: any) => {
        if (value === res?.data?.data?.meta_data?.RawMessage?.name) {
          setErrors({
            ...errors,
            app_name: "Application name already exists.",
          });
        } else {
          setErrors({
            ...errors,
            app_name: "",
          });
        }
      });
    }
  };
  const setAppName = (value: string) => {
    validateApplicationName(value.trim());

    setApplicationDetails({
      ...applicationDetails,
      app_name: value,
    });
  };

  const handleTracePropagationTargetValuesChange = (e: any) => {
    const { name, value } = e.target;

    setTracePropagationTargetValues({
      ...tracePropagationTargetValues,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      [name]: value?.trim(),
    });
  };

  const addTracePropagationTargets = () => {
    setTracePropagationTargets([...tracePropagationTargets, { type: "text" }]);
  };

  const removeTracePropagationTargets = (index: number) => {
    tracePropagationTargets.pop();
    setTracePropagationTargets(tracePropagationTargets);

    delete tracePropagationTargetValues[`tracePropagationTargets_${index}`];
    setTracePropagationTargetValues(tracePropagationTargetValues);
  };

  const createNewApplication = () => {
    generateClientAgentConfig(
      { ...applicationDetails, app_name: applicationDetails?.app_name?.trim() },
      (success, config) => {
        if (success) {
          setApplicationDetails({
            ...applicationDetails,
            token: config?.token ?? "",
          });
          setUpdateFlag(true);
          dispatch(
            showToast(
              "success",
              `Application ${applicationDetails.app_name} has been created successfully."`,
              {
                title: "Successfully Created",
              }
            )
          );
        } else {
          dispatch(
            showToast(
              "error",
              "An error occurred while updating the application.",
              {
                title: "Not Created",
              }
            )
          );
        }
      }
    );
    setIsApplicationConfigUpdated;
  };

  const updateApplicationConfig = () => {
    const applicationConfig = {
      ...applicationDetails,
      app_name: applicationDetails?.app_name?.trim(),
      tracePropagationTargets: Object.values(tracePropagationTargetValues),
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateClientAgentConfig(applicationConfig, (success, config) => {
      if (success) {
        setIsApplicationConfigUpdated &&
          setIsApplicationConfigUpdated(!isApplicationConfigUpdated);
        dispatch(
          showToast(
            "success",
            `Application ${applicationDetails.app_name} has been updated successfully."`,
            {
              title: "Successfully Updated",
            }
          )
        );
      } else {
        dispatch(
          showToast(
            "error",
            "An error occurred while updating the application.",
            {
              title: "Not Updated",
            }
          )
        );
      }
    });
  };

  const step1 = `<script src="https://cdnjs.middleware.io/browser/libs/0.0.1/middleware-rum.min.js" type="text/javascript"></script>

<script>
  if (window.Middleware){
    Middleware.track({
      serviceName:"${applicationDetails.app_name}",
      projectName:"${applicationDetails.app_name}",
      accountKey:"${applicationDetails.token}",
      target: "https://${getTenantUrl(useContext(AppContext).user)}",
      defaultAttributes: {
        "app.version": "1.0.0",
      },
      tracePropagationTargets: [${Object.values(tracePropagationTargetValues).length > 0 ? Object.values(tracePropagationTargetValues).map((x: string) => new RegExp(x, "i")) : [/localhost:3000/i]}]
    })
  }
</script>`;

  const debounceRequest = useCallback(
    debounceHandler(0, (text: string) => setAppName(text), 500),
    []
  );

  return (
    <div className="application">
      {!isUpdate && (
        <span>
          JavaScript RUM is for generic web application user monitoring.
        </span>
      )}
      <div className="application-form">
        <div className="application-input">
          <FormTextInput
            id={"app_name"}
            label={"Application Name"}
            value={appNameInput}
            placeholder="Enter name here"
            isError={Boolean(errors?.app_name)}
            onChange={(text) => {
              setAppNameInput(text);
              debounceRequest(text);
            }}
          />
          <FormInputValidation
            isError={Boolean(errors?.app_name)}
            text={errors?.app_name}
          />
        </div>

        <div className="application-action">
          {updateFlag ? (
            <Button
              primary
              disabled={Boolean(errors?.app_name || errors?.origin)}
              onClick={() => updateApplicationConfig()}
            >
              Save Configuration
            </Button>
          ) : (
            <Button
              primary
              disabled={Boolean(errors?.app_name)}
              onClick={() => createNewApplication()}
            >
              Create Application
            </Button>
          )}
        </div>
      </div>
      {updateFlag && (
        <>
          <div className="application">
            <div className="application-body">
              <label>Trace Propagation</label>
              <Tooltip content="You can add multiple trace domain names by comma separating them. Trace will be generated only for specified domains. For example: https://api.example.com, http://localhost:3000.">
                <div>
                  <InfoIcon />
                </div>
              </Tooltip>
            </div>
            <div className="trace-propagation">
              {tracePropagationTargets.map(
                (data: { type: string }, index: number) => {
                  return (
                    <div
                      className="item"
                      style={{
                        background:
                          themeMode === "dark"
                            ? "var(--color-neutrals-100)"
                            : "var(--color-grey-50)",
                      }}
                      key={index + "_div"}
                    >
                      <div className="step-number">
                        <span>{index + 1}</span>
                      </div>
                      <input
                        type={data.type}
                        name={"tracePropagationTargets_" + index}
                        value={
                          tracePropagationTargetValues[
                            "tracePropagationTargets_" + index
                          ]
                        }
                        key={index + "_input"}
                        placeholder="https://api.example.com"
                        onChange={handleTracePropagationTargetValuesChange}
                      />
                      <Button
                        secondary
                        error
                        onClick={() => {
                          removeTracePropagationTargets(index);
                        }}
                        disabled={tracePropagationTargets.length !== index + 1}
                        key={index + "_button"}
                      >
                        <DeleteIcon color="var(--color-error)" />
                      </Button>
                    </div>
                  );
                }
              )}
            </div>
            <div>
              <Button
                prefixicon={<PlusIcon color="var(--color-text)" />}
                onClick={() => addTracePropagationTargets()}
              >
                Add Trace Propagation
              </Button>
            </div>
          </div>
          <span className="content">
            Add Middleware script in the head of the index.html
          </span>
          <div>
            <Command command={step1} className="code-block" />
          </div>
        </>
      )}
    </div>
  );
};

export default JavaScriptInstallation;
