import { createReducer } from "core/utils/create_reducer";
import {
  RECEIVED_BACKOFF_AGENTS,
  RECEIVED_BACKOFF_AGENTS_BINDING,
  RECEIVED_BACKOFF_AUTH_TOKEN,
  RECEIVED_BACKOFF_LOGIN_DETAILS,
  RECEIVED_BACKOFF_USER,
  RECEIVED_BACKOFF_EMAIL_DETAILS,
  RECEIVED_BACKOFF_SENDER,
  RECEIVED_BACKOFF_EMAIL_CAMPAIGN,
  RECEIVED_BACKOFF_EDIT_EMAIL_CAMPAIGN,
  RECEIVED_BACKOFF_DELETE_EMAIL_CAMPAIGN,
  RECEIVED_BACKOFF_LOGS,
  RECEIVED_BACKOFF_DELETE_ACCOUNT,
  RECEIVED_BACKOFF_VERCEL_DATA,
  RECEIVED_BACKOFF_UPDATE_ACCOUNT,
  REQUEST_BACKOFF_INFLIGHT,
  RECEIVED_BACKOFF_CONTACT_DETAILS,
  RECEIVED_BACKOFF_USAGES,
  RECEIVED_FEATURE_FLAG_STATUS,
} from "./constant";
import { combineReducers } from "redux";
import { getCookie } from "core/application/utils";
import { getThemeStateBkoff, MW_BACKOFF_COOKIE_NAME } from "core/utils";
import { THEME_MODE } from "store/constant";
import { MW_DAILY_USAGE_RECEIVED } from "store/settings/billing/constant";

const auth = createReducer(
  {
    inflight: true,
    token: getCookie(MW_BACKOFF_COOKIE_NAME),
  },
  {
    [RECEIVED_BACKOFF_AUTH_TOKEN]: (state, opts) => {
      if (typeof opts == "object") {
        const _update = Object.assign({}, state);
        _update.inflight =
          typeof opts.inflight !== "undefined" ? opts.inflight : false;
        if (typeof opts.token !== "undefined") {
          _update.token = opts.token;
        }
        _update._random = Date.now();
        return { ..._update };
      }
      return { ...state };
    },
  }
);

const user = createReducer(
  {
    inflight: true,
    list: [],
    agents: [],
    machineCount: 0,
  },
  {
    [RECEIVED_BACKOFF_USER]: (state, opts) => {
      if (typeof opts == "object") {
        if (opts.hasOwnProperty("isScroll") && opts?.isScroll) {
          state.list = state.list.concat(opts?.users || []);
        } else {
          state.list = opts?.users || [];
        }
        if (opts.hasOwnProperty("is_plan_filter") && opts.is_plan_filter) {
          state.list = opts?.users || [];
        }
        // state.list = opts?.users || [];
        if (opts?.updateTrialDays) {
          const Index = state.list.findIndex(
            (l: any) => l.account_id == opts.accountId
          );
          if (Index != -1) {
            state.list[Index].trial_days = 9;
          }
        }
        state.inflight = false;
        state.machineCount = opts?.machineCount || [];
      }
      return { ...state };
    },
    [RECEIVED_BACKOFF_AGENTS]: (state, opts) => {
      if (typeof opts == "object") {
        state.agents = opts.list;
      }
      return { ...state };
    },

    [REQUEST_BACKOFF_INFLIGHT]: (state, opts) => {
      state.inflight = true;
      return { ...state };
    },
  }
);

const agent = createReducer(
  {
    inflight: true,
    agentsuser: [],
  },
  {
    [RECEIVED_BACKOFF_AGENTS]: (state, opts) => {
      if (typeof opts == "object") {
        if (opts?.agents)
          state.agentsuser = Array.isArray(opts.agents) ? opts.agents : [];
      }
      return { ...state };
    },
  }
);

const logs = createReducer(
  {
    inflight: true,
    logsdetail: [],
  },
  {
    [RECEIVED_BACKOFF_LOGS]: (state, opts) => {
      if (typeof opts == "object") {
        if (opts?.logsdetail)
          state.logsdetail = Array.isArray(opts.logsdetail)
            ? opts.logsdetail
            : [];
      }
      return { ...state };
    },
  }
);

const usages = createReducer([], {
  [RECEIVED_BACKOFF_USAGES]: (state, opts) => {
    if (Object.keys(opts.data).length) {
      return opts.data;
    }
    return { ...state };
  },
});

const userlogin = createReducer(
  {
    inflight: true,
    userlogindetails: [],
  },
  {
    [RECEIVED_BACKOFF_LOGIN_DETAILS]: (state, opts) => {
      if (typeof opts == "object") {
        if (opts?.userlogins)
          state.userlogindetails = Array.isArray(opts.userlogins)
            ? opts.userlogins
            : [];
        // console.log(opts, 'state.emailTemplates')
      }
      return { ...state };
    },
  }
);

const emaildetail = createReducer(
  {
    inflight: true,
    emaildetails: [],
  },
  {
    [RECEIVED_BACKOFF_EMAIL_DETAILS]: (state, opts) => {
      if (typeof opts == "object") {
        if (opts?.emailcontent)
          state.emaildetails = Array.isArray(opts.emailcontent)
            ? opts.emailcontent
            : [];
      }
      return { ...state };
    },
  }
);

const sender = createReducer(
  {
    inflight: true,
    senderdetails: [],
  },
  {
    [RECEIVED_BACKOFF_SENDER]: (state, opts) => {
      if (typeof opts == "object") {
        if (opts?.sender)
          state.senderdetails = Array.isArray(opts.sender) ? opts.sender : [];
      }
      return { ...state };
    },
  }
);

const campaign = createReducer(
  {
    inflight: true,
    emailtemplates: [],
  },
  {
    [RECEIVED_BACKOFF_EMAIL_CAMPAIGN]: (state, opts) => {
      if (typeof opts == "object") {
        if (opts?.email)
          state.emailtemplates = Array.isArray(opts.email) ? opts.email : [];
        // console.log(opts.email, 'state.emailTemplates')
      }
      return { ...state };
    },
  }
);

const contact = createReducer(
  {
    inflight: true,
    usercontact: [],
  },
  {
    [RECEIVED_BACKOFF_CONTACT_DETAILS]: (state, opts) => {
      if (Array.isArray(opts.usercontact)) {
        state.usercontact = Array.isArray(opts.usercontact)
          ? opts.usercontact
          : [];
      }
      return { ...state };
    },
  }
);

const editCampagin = createReducer(
  {
    inflight: true,
    emailEditemplates: [],
  },
  {
    [RECEIVED_BACKOFF_EDIT_EMAIL_CAMPAIGN]: (state, opts) => {
      if (typeof opts == "object") {
        if (opts?.email)
          state.emailEditemplates = Array.isArray(opts.email) ? opts.email : [];
      }
      return { ...state };
    },
  }
);

const deleteCampagin = createReducer(
  {
    inflight: true,
    emailEditemplates: [],
  },
  {
    [RECEIVED_BACKOFF_DELETE_EMAIL_CAMPAIGN]: (state, opts) => {
      if (typeof opts == "object") {
        if (opts?.email)
          state.emailEditemplates = Array.isArray(opts.email) ? opts.email : [];
      }
      return { ...state };
    },
  }
);

const deleteAccount = createReducer(
  {
    inflight: true,
    deleteAccount: [],
  },
  {
    [RECEIVED_BACKOFF_DELETE_ACCOUNT]: (state, opts) => {
      if (typeof opts == "object") {
        if (opts?.email)
          state.deleteAccount = Array.isArray(opts.email) ? opts.email : [];
      }
      return { ...state };
    },
  }
);

const reActivateAccount = createReducer(
  {
    inflight: true,
    reActivateAccount: [],
  },
  {
    [RECEIVED_BACKOFF_UPDATE_ACCOUNT]: (state, opts) => {
      if (typeof opts == "object") {
        if (opts?.email)
          state.reActivateAccount = Array.isArray(opts.email) ? opts.email : [];
      }
      return { ...state };
    },
  }
);

const verceldata = createReducer(
  {
    inflight: true,
    verceldata: null,
  },
  {
    [RECEIVED_BACKOFF_VERCEL_DATA]: (state, opts) => {
      if (typeof opts == "object") {
        if (opts?.details) state.verceldata = opts.details;
      }
      return { ...state };
    },
  }
);

const featureFlagStatus = createReducer(
  {
    featureFlag: {},
  },
  {
    [RECEIVED_FEATURE_FLAG_STATUS]: (state, opts) => {
      if (opts.arg.name) {
        state.featureFlag = { [opts.arg.name]: opts.arg.state };
      }
      return { ...state };
    },
  }
);

export default combineReducers({
  auth,
  user,
  agent,
  logs,
  usages,
  userlogin,
  emaildetail,
  sender,
  campaign,
  editCampagin,
  deleteCampagin,
  deleteAccount,
  reActivateAccount,
  contact,
  verceldata,
  featureFlagStatus,
  theme: createReducer(
    {
      mode: getThemeStateBkoff(),
    },
    {
      [THEME_MODE]: (state, action) => {
        if (action?.mode) return { mode: action?.mode };
        return { ...state };
      },
    }
  ),
});
