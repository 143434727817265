import { useEffect, useRef, useCallback } from "react";
import { POLL_INTERVAL } from "./helper";

export interface Poller<T extends (...args: any[]) => void> {
  start: (...args: Parameters<T>) => void;
  stop: () => void;
  updateArgs: (...args: Parameters<T>) => void;
}

function usePoller<T extends (...args: any[]) => void>(
  callback: T,
  interval: number = POLL_INTERVAL
): Poller<T> {
  const savedCallback = useRef<T>(callback);
  const intervalId = useRef<NodeJS.Timeout | null>(null);
  const argsRef = useRef<Parameters<T> | undefined>();

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  const start = useCallback(
    (...args: Parameters<T>) => {
      argsRef.current = args;
      if (intervalId.current === null) {
        // Immediately invoke the callback with the initial args
        savedCallback.current(...args);

        intervalId.current = setInterval(() => {
          if (argsRef.current) {
            savedCallback.current(...argsRef.current);
          }
        }, interval);
      }
    },
    [interval]
  );

  // Function to stop polling
  const stop = useCallback(() => {
    if (intervalId.current !== null) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
  }, []);

  // Function to update args without restarting the interval
  const updateArgs = useCallback((...args: Parameters<T>) => {
    argsRef.current = args;
  }, []);

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      stop();
    };
  }, [stop]);

  return { start, stop, updateArgs };
}

export default usePoller;
