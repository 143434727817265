import classNames from "classnames";
import React, { useState } from "react";
import { CloseEyeSvg, InfoIcon, OpenEyeSvg } from "../../svg/icons";
import Tooltip from "../../tooltip";
import "./_style.scss";

interface Props {
  onChange: (value: string) => void;
  value?: string;
  label?: string;
  placeholder?: string;
  id: string;
  isError?: boolean;
  disabled?: boolean;
  className?: string;
  onBlur?: (value: string) => void;
  type?: "number" | "text" | "password";
  children?: React.ReactNode;
  defaultValue?: string;
  onFocus?: (value: string) => void;
  onKeyUp?: (value: React.KeyboardEvent) => void;
  infoText?: string;
}
const FormTextInput = (props: Props) => {
  const {
    onChange,
    value,
    id,
    label,
    placeholder,
    className,
    disabled,
    isError,
    onBlur,
    type = "text",
    children,
    onFocus,
    onKeyUp,
    defaultValue,
    infoText,
  } = props;
  const [passwordHide, setPasswordHide] = useState<boolean>(true);
  const togglePasswordVisibility = () => {
    setPasswordHide(!passwordHide);
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };
  const handleBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onBlur) {
      onBlur(event.target.value);
    }
  };
  const handleFocus = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onFocus) {
      onFocus(event.target.value);
    }
  };
  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (onKeyUp) {
      onKeyUp(event);
    }
  };
  const inputWrapperClassname = classNames("mw-input", className, {
    "is-error": isError,
    "is-disabled": disabled,
    "has-value": value?.length ?? 0 > 0,
  });
  const inputClassname = classNames("form-input-element", className, {
    "is-error": isError,
    "is-disabled": disabled,
    "has-value": value?.length ?? 0 > 0,
  });

  const inputType =
    type === "password" ? (passwordHide ? "password" : "text") : type;
  return (
    <>
      <div className={inputWrapperClassname}>
        {label && (
          <div className="label-container">
            <label htmlFor={id}>{label} </label>
            {infoText && (
              <Tooltip content={infoText}>
                <div>
                  <InfoIcon />
                </div>
              </Tooltip>
            )}
          </div>
        )}
        <input
          type={inputType}
          id={id}
          defaultValue={defaultValue}
          onChange={handleChange}
          placeholder={placeholder}
          value={value}
          className={inputClassname}
          onBlur={handleBlur}
          onFocus={handleFocus}
          disabled={disabled}
          onKeyUp={handleKeyUp}
        />
        {children}
        {type === "password" && (
          <span
            className="password-eye-icon"
            onClick={() => {
              togglePasswordVisibility();
            }}
          >
            <span className={"icon-" + (passwordHide ? "hide" : "show")}>
              {" "}
              {passwordHide ? <CloseEyeSvg /> : <OpenEyeSvg />}
            </span>
          </span>
        )}
      </div>
    </>
  );
};
export default FormTextInput;
