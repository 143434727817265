import React, { useEffect, useState } from "react";

export interface CardSelectionTab {
  label: string;
  value: number;
  icon: React.ReactNode;
  enabled: boolean;
}

interface CardSelectionProps {
  tabs: CardSelectionTab[];
  selectedTab?: number;
  onTabChange: (selectedTab: CardSelectionTab) => void;
  showIconBorder?: boolean;
}
const CardSelection = (props: CardSelectionProps) => {
  const { tabs, showIconBorder = false } = props;
  const [selectedTab, setSelectedTab] = useState(
    props.selectedTab ? props.selectedTab : tabs[0].value
  );
  useEffect(() => {
    if (props.selectedTab) {
      setSelectedTab(props.selectedTab);
    }
  }, [props.selectedTab]);
  const setActiveTab = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    tabObj: CardSelectionTab
  ) => {
    e.preventDefault();
    setSelectedTab(tabObj.value);
    if (props.onTabChange && typeof props.onTabChange === "function") {
      props.onTabChange(tabObj);
    }
  };
  return (
    <div className="mw-card-tab-selection">
      {tabs.map((it) => (
        <div
          key={it.value}
          className={
            "mw-tab " +
            (it.value === selectedTab ? "active" : "") +
            (it.enabled ? "" : "disabled")
          }
          onClick={(e) => setActiveTab(e, it)}
        >
          {/* <Card> */}
          {/* <div className="mw-icon-card"> */}
          <span className={showIconBorder ? "show-border" : ""}>{it.icon}</span>
          <span>{it.label}</span>
          {/* </div> */}
          {/* </Card> */}
        </div>
      ))}
    </div>
  );
};

export default CardSelection;
