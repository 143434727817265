import ChartsV2 from "core/components/v2/charts";
import { SeriesOptionsType, SeriesPieOptions } from "highcharts";
import React from "react";
import { DateRange } from "views/layouts/app/routes/model";
import { generateHashAndMetricColor } from "views/modules/helper";
import {
  BuilderNestedProps,
  GridviewDataType,
} from "../../../../../views/modules/builder/entities/builder.entities";
import {
  RenderTpl,
  StatusConfig,
  TPL_TYPE_STATUS,
  getStatusColorFromConfig,
  getValueFromConfig,
} from "../../../../../views/modules/builder/entities/tpl.entities";
import NoDataSpace from "../../no-data";
import { ChartType } from "../models";

export interface PieChartProps {
  piechartData: GridviewDataType | undefined;
  nestedProps?: BuilderNestedProps;
  onDateChange?: (range: DateRange) => void;
}
const getDiameter = (metricIndex: number) => {
  return 100 - metricIndex * 20;
};
const BuilderPieChartV2 = (props: PieChartProps) => {
  let tpl: RenderTpl | undefined;
  const { piechartData, nestedProps, onDateChange } = props;
  const columnConfig = nestedProps?.columnConfig;

  if (!piechartData) return null;

  if (!piechartData.data.length) {
    return <NoDataSpace />;
  }
  const generateData = (): SeriesOptionsType[] => {
    const chartData: SeriesPieOptions[] = [];

    const metrics = piechartData.columns.filter((col) => col.isMetric);
    const groups = piechartData.columns.filter((col) => !col.isMetric);
    metrics.map((metricColumn, mIndex) => {
      tpl = columnConfig?.[metricColumn.accessor]?.tpl || metricColumn.tpl;
      // Create pie for each metric
      // So each metric will be one series and there will be multiple groups
      // So each group will be one data point in the series
      const obj: SeriesPieOptions = {
        name: metricColumn.accessor,
        data: [],
        size: `${getDiameter(mIndex + 1)}%`,
        showInLegend: false,
        dataLabels: [
          {
            enabled: true,
            color: "#ffffff",
            useHTML: true,
            formatter: function () {
              return `<div>${tpl ? getValueFromConfig(this.y!, tpl) : this.y}</div>`;
            },
          },
        ],
        type: "pie" as SeriesPieOptions["type"],
      };
      obj.data = piechartData.data.map((item) => {
        const groupName = groups
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          .map((group) => (item[group.accessor] ? item[group.accessor] : ""))
          .join("-");
        let name = groupName + "-" + metricColumn.accessor;
        if (metrics.length == 1) {
          name = groupName;
          if (columnConfig?.[metricColumn.accessor]?.title) {
            name =
              groupName + "-" + columnConfig?.[metricColumn.accessor].title;
          }
        }
        if (!name) {
          name = metricColumn.accessor;
        }
        if (/^\d+$/.test(name) && groups.length) {
          const number = parseInt(name);
          const group = groups[0];
          if (group) {
            name = getValueFromConfig(number, group.tpl);
          }
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const metricValue = item[metricColumn.accessor];
        let color: string;

        if (groups?.[0]?.tpl?.type == TPL_TYPE_STATUS) {
          const statusConfig = (groups[0].tpl.config || {
            valueType: [],
          }) as StatusConfig;
          color = getStatusColorFromConfig(groupName, statusConfig).color;
          if (color == "var(--color-blue-500)") {
            color = generateHashAndMetricColor(groupName).color;
          }
        } else {
          color = generateHashAndMetricColor(groupName).color;
        }
        if (typeof metricValue === "number") {
          return {
            name,
            y: metricValue,
            //TODO: TPL not working in pie chart
            [metricColumn.accessor]: metricValue,
            color: color,
            custom: {
              tooltipText: {
                [name]: getValueFromConfig(
                  metricValue,
                  tpl || metricColumn.tpl
                ),
              },
            },
          };
        }
        return null;
      });
      obj.data = obj.data.sort((a, b) =>
        Array.isArray(a) && Array.isArray(b) ? (b[1] ?? 0) - (a[1] ?? 0) : 1
      );
      chartData.push(obj);
    });
    return chartData.filter(
      (it: SeriesPieOptions) => it.data && it.data.length > 0
    );
  };
  const chartData = generateData();
  return (
    <ChartsV2
      chartType={ChartType.PIE}
      series={chartData}
      yAxsisVisible={false}
      yAxisConfig={tpl}
      showLegend={props?.nestedProps?.showLegend}
      xAxsisVisible={false}
      chartHeight={props.nestedProps?.chartHeight}
      onDateChange={onDateChange}
    />
  );
};

export default BuilderPieChartV2;
