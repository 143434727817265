import { Align } from "core/components/v2/enums";
import { StatsData } from "core/components/v2/table-view/outer-wrapper";
import { TPL_TYPE_STATUS } from "views/modules/builder/entities/tpl.entities";
import { DefaultGroupName } from "../../../../core/components/v2/charts/components/entities";
import { getChartTypeFromAppId } from "../core/widgets.app";
import {
  ATTRIBUTE_FILTER,
  AggMethod,
  AggregationOptionType,
  AggregationOptions,
  AvailableResource,
  ChartType,
  ColumnConfig,
  CustomWidget,
  LayoutItem,
  MetricItem,
  MetricTypeEnum,
  Query,
  QueryCountDataType,
  SELECT_DATA_BY,
  SELECT_METRICS_FORMULA,
  SelectFilter,
  SelectGroupBy,
  SelectOneMetric,
  VisualFormattingRule,
  sqlOperators,
} from "../entities/builder.entities";
import { CustomObject, ValueType } from "../entities/extra.entities";

export const BUILDER_DISPLAY_SCOPE_INFRASTRUCTURE_HOST_LIST =
  "infrastructure_host_list";
export const BUILDER_DISPLAY_SCOPE_INFRASTRUCTURE_CONTAINER_LIST =
  "infrastructure_container_list";
export const BUILDER_DISPLAY_SCOPE_INFRASTRUCTURE_PROCESS_LIST =
  "infrastructure_process_list";

export const BUILDER_DISPLAY_SCOPE_INFRASTRUCTURE = "infrastructure";
export const BUILDER_DISPLAY_SCOPE_INFRA_PROCESS_DASH =
  "infrastructure_process_dashboard";
export const BUILDER_DISPLAY_SCOPE_INFRA_CONTAINER_DASH =
  "infrastructure_container_dashboard";

export const BUILDER_DISPLAY_SCOPE_INFRA_K8S_DASH =
  "infrastructure_k8s_dashboard";
export const BUILDER_DISPLAY_SCOPE_INFRA_K8S_PODS_DASH =
  "infrastructure_k8s_pods_dashboard";
export const BUILDER_DISPLAY_SCOPE_INFRA_K8S_CONTAINERS_DASH =
  "infrastructure_k8s_containers_dashboard";
export const BUILDER_DISPLAY_SCOPE_INFRA_K8S_CLUSTER_DASH =
  "infrastructure_k8s_cluster_dashboard";
export const BUILDER_DISPLAY_SCOPE_INFRA_K8S_NODES_DASH =
  "infrastructure_k8s_nodes_dashboard";
export const BUILDER_DISPLAY_SCOPE_INFRA_K8S_SERVICES_DASH =
  "infrastructure_k8s_services_dashboard";
export const BUILDER_DISPLAY_SCOPE_INFRA_K8S_WORKLOADS_DASH =
  "infrastructure_k8s_workloads_dashboard";
export const BUILDER_DISPLAY_SCOPE_INFRA_K8S_NETWORK_DASH =
  "infrastructure_k8s_network_dashboard";
export const BUILDER_DISPLAY_SCOPE_INFRA_K8S_STORAGE_DASH =
  "infrastructure_k8s_storage_dashboard";
export const BUILDER_DISPLAY_SCOPE_INFRA_K8S_ACCESS_CONTROL_DASH =
  "infrastructure_k8s_access_control_dashboard";
export const BUILDER_DISPLAY_SCOPE_INFRA_K8S_DEPLOYMENTS_DASH =
  "infrastructure_k8s_deployment_dashboard";
export const BUILDER_DISPLAY_SCOPE_INFRA_K8S_REPLICA_SETS_DASH =
  "infrastructure_k8s_replica_sets_dashboard";
export const BUILDER_DISPLAY_SCOPE_INFRA_K8S_JOBS_DASH =
  "infrastructure_k8s_jobs_dashboard";
export const BUILDER_DISPLAY_SCOPE_INFRA_K8S_CRON_JOBS_DASH =
  "infrastructure_k8s_cron_jobs_dashboard";
export const BUILDER_DISPLAY_SCOPE_INFRA_K8S_DEAMONSETS_DASH =
  "infrastructure_k8s_deamonsets_dashboard";
export const BUILDER_DISPLAY_SCOPE_INFRA_K8S_STATEFULSTATES_DASH =
  "infrastructure_k8s_stateful_sets_dashboard";
export const BUILDER_DISPLAY_SCOPE_INFRA_K8S_NAMESPACE_DASH =
  "infrastructure_k8s_namespace_dashboard";
// export const BUILDER_DISPLAY_SCOPE_INFRA_K8S_VOLUMES_DASH = "infrastructure_k8s_volumes_dashboard"
export const BUILDER_DISPLAY_SCOPE_INFRA_K8S_HPA_DASH =
  "infrastructure_k8s_hpa_dashboard";
export const BUILDER_DISPLAY_SCOPE_INFRA_K8S_INGRESSES_DASH =
  "infrastructure_k8s_ingresses_dashboard";
export const BUILDER_DISPLAY_SCOPE_INFRA_K8S_PERSISTENT_VOLUMES_DASH =
  "infrastructure_k8s_persistent_volumes_dashboard";
export const BUILDER_DISPLAY_SCOPE_INFRA_K8S_PERSISTENT_VOLUME_CLAIMS_DASH =
  "infrastructure_k8s_persistent_volume_claims_dashboard";
export const BUILDER_DISPLAY_SCOPE_INFRA_K8S_SERVICE_ACCOUNTS_DASH =
  "infrastructure_k8s_service_accounts_dashboard";
export const BUILDER_DISPLAY_SCOPE_INFRA_K8S_CLUSTER_ROLES_DASH =
  "infrastructure_k8s_cluster_roles_dashboard";
export const BUILDER_DISPLAY_SCOPE_INFRA_K8S_ROLES_DASH =
  "infrastructure_k8s_roles_dashboard";
export const BUILDER_DISPLAY_SCOPE_INFRA_K8S_CLUSTER_ROLE_BINDINGS_DASH =
  "infrastructure_k8s_cluster_role_bindings_dashboard";
export const BUILDER_DISPLAY_SCOPE_INFRA_K8S_ROLE_BINDINGS_DASH =
  "infrastructure_k8s_role_bindings_dashboard";

export const legendsLimit = 15;

export const getReportKeyFromPathname = (
  slug: string,
  pathname: string
): string => {
  let key = "";
  const sPath = pathname.split("/" + slug + "/");
  if (sPath.length > 1 && sPath[1] != "") {
    key = sPath[1];
  }
  return key;
};

export const getReportToPathSlug = (
  slug: string,
  pathname: string,
  reports: any
): any => {
  let _active = null;
  if (
    pathname !== "" &&
    reports &&
    Array.isArray(reports) &&
    reports.length > 0
  ) {
    _active = reports[0];
    const _key = getReportKeyFromPathname(slug, pathname);
    if (_key != "") {
      const listIndex = reports.findIndex((c: any) => c.key == _key);
      _active = reports[listIndex];
    }
  }
  return _active;
};
const _scopes_actions: any = {
  [BUILDER_DISPLAY_SCOPE_INFRA_PROCESS_DASH]: "Add to process",
  [BUILDER_DISPLAY_SCOPE_INFRA_CONTAINER_DASH]: "Add to container",
  [BUILDER_DISPLAY_SCOPE_INFRA_K8S_DASH]: "Add to k8s dashboard",
  [BUILDER_DISPLAY_SCOPE_INFRA_K8S_PODS_DASH]: "Add to k8s pods",
  [BUILDER_DISPLAY_SCOPE_INFRA_K8S_CONTAINERS_DASH]: "Add to k8s containers",
  [BUILDER_DISPLAY_SCOPE_INFRA_K8S_CLUSTER_DASH]: "Add to k8s cluster",
  [BUILDER_DISPLAY_SCOPE_INFRA_K8S_NODES_DASH]: "Add to k8s nodes",
  [BUILDER_DISPLAY_SCOPE_INFRA_K8S_DEPLOYMENTS_DASH]: "Add to k8s deployments",
  [BUILDER_DISPLAY_SCOPE_INFRA_K8S_REPLICA_SETS_DASH]:
    "Add to k8s replica sets",
  [BUILDER_DISPLAY_SCOPE_INFRA_K8S_JOBS_DASH]: "Add to k8s jobs",
  [BUILDER_DISPLAY_SCOPE_INFRA_K8S_CRON_JOBS_DASH]: "Add to k8s cron jobs",
  [BUILDER_DISPLAY_SCOPE_INFRA_K8S_DEAMONSETS_DASH]: "Add to k8s deamonsets",
  [BUILDER_DISPLAY_SCOPE_INFRA_K8S_STATEFULSTATES_DASH]:
    "Add to k8s stateful sets",
  // [BUILDER_DISPLAY_SCOPE_INFRA_K8S_VOLUMES_DASH]: "Add to k8s volumes",
  [BUILDER_DISPLAY_SCOPE_INFRA_K8S_HPA_DASH]: "Add to k8s hpa",
};
export const randomColorFromList = (index: number): string => {
  const colors = [
    "#C2D3DA",
    "#E2F4DE",
    "#F1F4BB",
    "#D3CEDF",
    "#D4EAA9",
    "#EBD7CC",
    "#D6CDE0",
    "#E2E2E2",
    "#F2C6A1",
    "#FDD9D9",
  ];
  const colorsCount = colors.length;
  return colors[index % colorsCount];
};
const darkColors: string[] = [
  "#3498DB", // Dodger Blue
  "#E74C3C", // Alizarin Crimson
  "#2ECC71", // Emerald Green
  "#9B59B6", // Amethyst Purple
  "#1ABC9C", // Turquoise
  "#E67E22", // Carrot Orange
  "#34495E", // Wet Asphalt
  "#FF5733", // Deep Saffron
  "#66D9EF", // Light Sky Blue
  "#C71585", // Medium Violet Red
  "#27AE60", // Dark Green
  "#FFC300", // Vivid Yellow
  "#6A5ACD", // Slate Blue
  "#FF6347", // Tomato
  "#1E90FF", // Dodger Blue
  "#87CEEB", // Sky Blue
  "#8A2BE2", // Blue Violet
  "#FF4500", // Orange Red
  "#7FFF00", // Chartreuse
  "#483D8B", // Dark Slate Blue
  "#FFD700", // Gold
  "#2F4F4F", // Dark Slate Gray
  "#8B4513", // Saddle Brown
  "#DA70D6", // Orchid
  "#E84393", // Dark Pink
  "#16A085", // Green Sea
  "#8E44AD", // Wisteria Purple
  "#F39C12", // Sunflower Yellow
];
export const getDarkColor = (index: number): string => {
  const colorsCount = darkColors.length;
  return darkColors[index % colorsCount];
};
export const colorSchemas = (index: number): string => {
  const colors = [
    "#5781CF",
    "#47B39C",
    "#61c1e8",
    "#662b17",
    "#a3a3d3",
    "#33150b",
    "#FF6F61",
    "#98FB98",
    "#D3D3D3",
    "#C19A6B",
    "#FF0000",
    "#77EB68",
    "#B57EDC",
    "#967117",
    "#321414",
    "#87CEEB",
    "#8F00FF",
    "#FDCF5E",
    "#FAD6A5",
    "#2F4F4F",
    "#A4C639",
    "#CC43D9",
    "#9AA4B2",
    "#2ADFDD",
    "#6A2442",
    "#3e4800",
    "#12158C",
    "#365744",
    "#A3359D",
    "#44B3DA",
    "#8029F1",
    "#91A538",
    "#846844",
    "#464e4d",
    "#F13A45",
    "#EBCB36",
    "#EE5AB8",
    "#A8B62F",
    "#D5BA1A",
    "#964834",
    "#924679",
    "#89BC0B",
    "#679600",
    "#0E98CD",
    "#790D4A",
    "#439CA0",
    "#8FB4B2",
    "#5C3971",
    "#069E99",
    "#687EC2",
    "#C623BA",
    "#181215",
    "#225898",
    "#AA8ABA",
    "#0016C5",
    "#543868",
    "#35CB1F",
    "#62C11B",
    "#4DF30E",
    "#1A5771",
    "#A6EE57",
    "#60A65D",
    "#4B1B98",
    "#1BDFDC",
    "#4E1D9B",
    "#91091F",
    "#AFD5AE",
    "#53FC5C",
    "#6B6DE0",
    "#C606ED",
    "#1A58D0",
    "#24D50D",
    "#62ACC4",
    "#53C599",
    "#62E135",
    "#555FA3",
    "#E5C2EA",
    "#EC3EC7",
    "#DB46EE",
    "#74F715",
    "#EB8AD7",
    "#978E8B",
    "#714E66",
    "#61E042",
    "#D4FE35",
    "#83CB44",
    "#5B0D30",
    "#EDE1B1",
    "#675194",
    "#CEE3BB",
    "#757F54",
    "#1CBA3A",
    "#6E28B4",
    "#7A1849",
    "#122E94",
    "#F85ADB",
    "#867EB7",
    "#4B6BAA",
    "#A46C24",
    "#83FAB6",
    "#828D4C",
    "#2A9608",
    "#D79ACC",
    "#115799",
    "#5C14A6",
    "#031836",
    "#4EA7FA",
    "#503CBC",
    "#497364",
    "#020E45",
    "#C671C8",
    "#2D2824",
    "#FE2357",
    "#26AC01",
    "#5853E6",
    "#21463B",
    "#A59459",
    "#6256CD",
    "#637312",
    "#0AC3FB",
    "#749018",
    "#447E3B",
    "#7E1C01",
    "#E18C86",
    "#35ECE2",
    "#4A493E",
    "#5E9AC1",
    "#BB2C88",
    "#319977",
    "#D9697D",
    "#9A5ACC",
    "#CD5013",
    "#6C4663",
    "#5864C4",
    "#2951A3",
    "#8A741A",
    "#64E0BD",
    "#229B9D",
    "#16C323",
    "#72ECC4",
    "#949318",
    "#E7DCDD",
    "#941F86",
    "#7134A6",
    "#C232BC",
    "#8308FC",
    "#78EB75",
    "#688AB5",
    "#C3A4BD",
    "#9A0EBA",
    "#D1F83D",
    "#A0B616",
    "#113724",
    "#1EE920",
    "#B1D8CB",
    "#845DC4",
    "#72C568",
    "#149C92",
    "#3B9E7B",
    "#84E5B6",
    "#20078B",
    "#7D284E",
    "#CB918A",
    "#41A6E1",
    "#B3DE99",
    "#03A814",
    "#CB94D8",
    "#EDA54B",
    "#B54EDC",
    "#9E293E",
    "#CB9412",
    "#739B77",
    "#AE5912",
    "#BEADC6",
    "#DCAAE0",
    "#3DF8B8",
    "#0221CF",
    "#4073E4",
    "#060874",
    "#C9E6F4",
    "#F27828",
    "#806EEC",
    "#745B12",
    "#72E553",
    "#985633",
    "#EA893C",
    "#89BB32",
    "#13A925",
    "#F8571D",
    "#41D23C",
    "#59C76B",
    "#462E85",
    "#F8233B",
    "#424EB6",
    "#828A24",
    "#8CC019",
    "#B260A9",
    "#29C80E",
    "#E608A9",
    "#4C42D0",
    "#C5E8D1",
    "#556FF1",
    "#183BA9",
    "#689C66",
    "#B8CDC8",
    "#E1234B",
    "#D8F866",
    "#E8D738",
    "#BEA7C6",
    "#33A264",
    "#2AC02D",
    "#DDA3C6",
    "#CF516C",
    "#94AC89",
    "#6134B2",
    "#B862E8",
    "#A44B9C",
    "#71C53A",
    "#374147",
    "#B395FC",
    "#DEAF1E",
    "#43C924",
    "#914AEA",
    "#D63221",
    "#965467",
    "#C38EE6",
    "#A63CA9",
    "#E4B2E2",
    "#CDD9E7",
    "#D8342C",
    "#D1C54E",
    "#7C7D47",
    "#8EF53B",
    "#B814DB",
    "#A66B5A",
    "#556AA6",
    "#9E6CA3",
    "#9E9E8C",
    "#05EEDD",
    "#CCC56A",
    "#DA77A9",
    "#4AD657",
    "#891F6A",
    "#793F68",
    "#E2A70E",
    "#89206A",
    "#5645D6",
    "#C7914A",
    "#022E25",
    "#2296C8",
    "#A53EFD",
    "#D2CD12",
    "#616AEE",
    "#D49E6D",
    "#BE53CC",
    "#325139",
    "#BA23C7",
    "#74884B",
    "#A71315",
    "#FB5FEA",
    "#047AD3",
    "#818ABC",
    "#72A991",
    "#4E6C8C",
    "#B87698",
    "#6AEBD4",
    "#913EBD",
    "#DEE352",
    "#3D89A7",
    "#DD6D53",
    "#107ECC",
    "#DB34A4",
    "#F2C8F2",
    "#8A017D",
    "#B96861",
    "#A098BB",
    "#57D653",
    "#659A1C",
    "#56B4BF",
    "#8D2AED",
    "#E245B5",
    "#EA8177",
    "#5430D8",
    "#B02510",
    "#05FB22",
    "#8F2319",
    "#A93B41",
    "#2D1454",
    "#26C235",
    "#56149B",
    "#55F7B5",
    "#CC42DA",
    "#12CE62",
    "#543245",
    "#A77625",
    "#C6A305",
    "#48BA2D",
    "#7D4D21",
    "#418ADA",
    "#D44B70",
    "#C19743",
    "#1FEA30",
    "#41CD47",
    "#965D3C",
    "#C7C98A",
    "#6A3D13",
    "#3C3A4C",
    "#6957A1",
    "#089269",
    "#54A705",
    "#CCB1D5",
    "#00E615",
    "#C8C82C",
    "#8118EE",
    "#64B8BE",
    "#7B4453",
    "#1754D2",
    "#C584D7",
    "#5745F3",
    "#BA5FAA",
    "#F24459",
    "#59AF6E",
    "#6963EC",
    "#D9D92A",
    "#E96BF4",
    "#B8346B",
    "#C88897",
    "#82D81D",
    "#9CC721",
    "#7CDDED",
    "#30BB44",
    "#EEA7E7",
    "#BBDAA6",
    "#BC31B0",
    "#CED6AE",
    "#043B05",
    "#11A37E",
    "#87A7E7",
    "#211E3B",
    "#F5A956",
    "#5E19B1",
    "#DD0AF5",
    "#458539",
    "#5AA51E",
    "#55414A",
    "#56269A",
    "#6CC405",
    "#59686A",
    "#41E369",
    "#83DE6F",
    "#EBDF10",
    "#6B96B3",
    "#92D376",
    "#913638",
    "#14DF31",
    "#4C42F8",
    "#E07611",
    "#FDFEB8",
    "#0911A6",
    "#48777C",
    "#DAACA3",
    "#E57823",
    "#7634F3",
    "#05801D",
    "#64226D",
    "#B6378D",
    "#CB4557",
    "#40F882",
    "#2CBDC2",
    "#B47731",
    "#2D3268",
    "#78B475",
    "#473B68",
    "#C1F75C",
    "#9AB7E5",
    "#BA8892",
    "#CE5876",
    "#34E823",
    "#D1B574",
    "#D17839",
    "#63C2E2",
    "#9F7665",
    "#868192",
    "#E4B51F",
    "#7DC1AB",
    "#96B4A3",
    "#C41F11",
    "#5AE72B",
    "#5676DF",
    "#935266",
    "#7905AB",
    "#3EE648",
    "#8C29A4",
    "#47C1F5",
    "#5CDB63",
    "#42772E",
    "#4D61F9",
    "#CFB4CF",
    "#0E4DE4",
    "#7799EB",
    "#91588A",
    "#2C987B",
    "#821D73",
    "#E19C85",
    "#84B96A",
    "#445A43",
    "#71E238",
    "#B5FA27",
    "#15C6C3",
    "#216E69",
    "#BB37D4",
    "#C5A7D2",
    "#A58361",
    "#D5AD5D",
    "#A64CC6",
    "#695D6D",
    "#0D4BC1",
    "#79D9B2",
    "#D3D54C",
    "#EA3D0B",
    "#AAF2A3",
    "#38503A",
    "#1CED45",
    "#78C45A",
    "#26DBA8",
    "#323BDC",
    "#7AAC3E",
    "#23D1E2",
    "#924CCC",
    "#3AC35E",
    "#32874A",
    "#3D47F5",
    "#400E3C",
    "#EC7303",
    "#C9CB41",
    "#3365FA",
    "#4AF6AF",
    "#B5A309",
    "#83BDDB",
    "#8267B0",
    "#857FBC",
    "#D7D672",
    "#2C7CAB",
    "#A15847",
    "#84E2CA",
    "#740D29",
    "#3D1533",
    "#3D8661",
    "#3DC590",
    "#7B6873",
    "#3FD23B",
    "#A4E51F",
    "#2955CB",
    "#DA9E84",
    "#C65479",
    "#77C9B6",
    "#72018A",
    "#FB22B8",
    "#46ED47",
    "#767B7D",
    "#32FD1B",
    "#B39AAE",
    "#EB6E2F",
    "#C3807D",
    "#8527A4",
    "#A60DC6",
    "#06961D",
    "#DE6524",
    "#585715",
    "#667C3A",
    "#D6DA96",
    "#C1A9B1",
    "#6A6D75",
    "#639CCA",
    "#59D322",
    "#0E9E97",
    "#C06A6C",
    "#AB3987",
    "#551D69",
    "#6D48E5",
    "#F229B8",
    "#E5D2DE",
    "#74E3B1",
    "#890155",
    "#0C0477",
    "#63E463",
    "#B538B8",
    "#9453C8",
    "#DF6887",
    "#49DFAC",
    "#25DC66",
    "#59333B",
    "#16223C",
    "#EEE577",
    "#AD73C6",
    "#DDF0B4",
    "#57D1B1",
    "#45D8A4",
    "#E9B9F6",
    "#E83144",
    "#9E111B",
    "#557CA9",
    "#BA7949",
    "#E4A646",
    "#13712F",
    "#102EB6",
    "#8500DD",
    "#D2FCD9",
    "#8D5BDA",
    "#7836AE",
    "#AC5156",
    "#6923D7",
    "#AC4864",
    "#7C9DBB",
    "#8E925C",
    "#AD123A",
    "#10E2E0",
    "#7CE964",
    "#EFE4C5",
    "#EAE3E2",
    "#00C4C9",
    "#A52C21",
    "#D96EC6",
    "#4A5435",
    "#2D2339",
    "#21ADDC",
    "#3EA22B",
    "#E49223",
    "#2D47AE",
    "#4D649D",
    "#1A2364",
    "#3954C7",
    "#4D5DB5",
    "#829DE8",
    "#674BCD",
    "#376E22",
    "#D8AAE3",
    "#D861BC",
    "#2E282E",
    "#EEA6B5",
    "#E5744B",
    "#149D79",
    "#D882B5",
    "#AD2666",
    "#597417",
    "#8C881D",
    "#C8E539",
    "#7D6CFA",
    "#C777A1",
    "#774C81",
    "#19B689",
    "#2D6683",
    "#B82C71",
    "#C13C32",
    "#7C617B",
    "#A75F1C",
    "#ACD43D",
    "#959ADB",
    "#46F9E5",
    "#6117CD",
    "#8B3288",
    "#06C1D0",
    "#DA4179",
    "#85F5E1",
    "#4997A6",
    "#077277",
    "#870A2F",
    "#327706",
    "#CA5A07",
    "#88D744",
    "#7909DD",
    "#8B3194",
    "#7EC8E5",
    "#38D8BB",
    "#C12DA4",
    "#FE7DD4",
    "#463C8E",
    "#CA7A9B",
    "#B62CB2",
    "#64E749",
    "#63B7F5",
    "#424136",
    "#4D8B94",
    "#F4213D",
    "#9A8E1F",
    "#BB2F9E",
    "#BADF80",
    "#03E123",
    "#A5811E",
    "#E46B3E",
    "#B526C3",
    "#533A55",
    "#B83A1A",
    "#811D9B",
    "#4906CA",
    "#43E8C8",
    "#C672E2",
    "#ADA9AD",
    "#DC6A9E",
    "#21B365",
    "#764D89",
    "#3ACBED",
    "#E73034",
    "#496AF4",
    "#4D9165",
    "#DC00C5",
    "#298257",
    "#765DA2",
    "#E5C82D",
    "#F6E957",
    "#23E3D2",
    "#123D85",
    "#198556",
    "#EA413C",
    "#E74259",
    "#3C31B5",
    "#69B9D6",
    "#AA460B",
    "#962224",
    "#CABB5E",
    "#39ED26",
    "#0D41AF",
    "#634E7A",
    "#B6515D",
    "#3FCA82",
    "#C7786E",
    "#126EE3",
    "#D65EEA",
    "#788864",
    "#44BD23",
    "#D4D20B",
    "#CA9DD4",
    "#3B957B",
    "#862044",
    "#690825",
    "#486F1E",
    "#4EAB35",
    "#C4E632",
    "#DE2E1C",
    "#BBA622",
    "#5BAE9A",
    "#DCE4AB",
    "#DE63A2",
    "#3866E2",
    "#E4B371",
    "#9BFEA5",
    "#625B3D",
    "#8A6EC7",
    "#AA2A56",
    "#5BBB54",
    "#2DC2A9",
    "#2DED11",
    "#C76DCD",
    "#16B134",
    "#9EE341",
    "#BD4847",
    "#E89624",
    "#B33ECB",
    "#3EB762",
    "#C73E13",
    "#7B943E",
    "#E419B5",
    "#2EB7C2",
    "#6D5D78",
    "#BA9CFA",
    "#C98C19",
    "#42D70B",
    "#8376D6",
    "#D9C3D6",
    "#8288AC",
    "#22AAEB",
    "#CD81B5",
    "#C8E16E",
    "#368417",
    "#D9B2D6",
    "#AE4F28",
    "#6FA487",
    "#12A682",
    "#44600A",
    "#BEA76B",
    "#A8930B",
    "#972F6A",
    "#177142",
    "#C706AC",
    "#211CCD",
    "#4BCDAB",
    "#E46FC8",
    "#357BEE",
    "#3A5298",
    "#99774C",
    "#265062",
    "#D6A772",
    "#E81B5C",
    "#49FD53",
    "#5D76A5",
    "#79C249",
    "#CEB16C",
    "#7D7494",
    "#07AB15",
    "#E10F87",
    "#25261A",
    "#DBD373",
    "#E6E7E1",
    "#CC36EE",
    "#B9A323",
    "#3501D1",
    "#91CE4D",
    "#EDEA11",
    "#E48BC0",
    "#F6E84A",
    "#AF1A3D",
    "#43B35C",
    "#91EB10",
    "#BD06E2",
    "#9C1C3B",
    "#594640",
    "#428EE4",
    "#62D92C",
    "#62A6C9",
    "#FACFBD",
    "#6916AA",
    "#E525DB",
    "#A95535",
    "#6E473D",
    "#AB211E",
    "#58B733",
    "#5C2108",
    "#E7C866",
    "#42826E",
    "#135A97",
    "#11209E",
    "#08FC76",
    "#B93C35",
    "#03AC0D",
    "#E2EB9B",
    "#BA62E7",
    "#5AB46B",
    "#7B1944",
    "#120303",
    "#0327C8",
    "#3512FE",
    "#4857D5",
    "#B49521",
    "#0746A6",
    "#E4D57E",
    "#27BD99",
    "#8A5DA8",
    "#8C5512",
    "#57C3B1",
    "#D89D41",
    "#97EF15",
    "#50E636",
    "#86CDCE",
    "#BD8526",
    "#9D7A71",
    "#CC9835",
    "#BEB8B0",
    "#A45371",
    "#0C4ECC",
    "#220546",
    "#DF3886",
    "#8BBD5B",
    "#ED77B9",
    "#D4B296",
    "#947584",
    "#879F61",
    "#164623",
    "#D2D915",
    "#2B8D52",
    "#E177E0",
    "#781E16",
    "#455E71",
    "#CC6788",
    "#C3E32E",
    "#186A76",
    "#271C4E",
    "#74C582",
    "#5D65DB",
    "#447B93",
    "#E4152E",
    "#65A62C",
    "#D90FDB",
    "#FF49B7",
    "#35B835",
    "#4CA09D",
    "#4E943E",
    "#E4A160",
    "#3C9245",
    "#D57733",
    "#5D1467",
    "#D189F1",
    "#AA0DAB",
    "#08E35C",
    "#6FA732",
    "#0FAD6E",
    "#19E1D9",
    "#57E232",
    "#0F5473",
    "#A3C322",
    "#92C498",
    "#152CC2",
    "#15BE5B",
    "#29B88B",
    "#676F43",
    "#09968E",
    "#411935",
    "#BBB9C9",
    "#EEDADC",
    "#6A7CEC",
    "#38A2F4",
    "#58A70C",
    "#A10227",
    "#456A53",
    "#5A6078",
    "#D10E77",
    "#88C854",
    "#A6F8B9",
    "#EB35B6",
    "#AEBAC0",
    "#191BF3",
    "#361E7B",
    "#BA98A7",
    "#E67724",
    "#1858AA",
    "#1F13B4",
    "#3CB86B",
    "#685903",
    "#229204",
    "#2AE0D2",
    "#E77CF1",
    "#FA19A3",
    "#A40145",
    "#068291",
    "#9EE12A",
    "#C35FA1",
    "#159CC2",
    "#5BB2DE",
    "#606BC5",
    "#FB879D",
    "#B4412A",
    "#17242B",
    "#91F69D",
    "#27B673",
    "#93739E",
    "#429D98",
    "#A8CEA9",
    "#BEAC34",
    "#8D6876",
    "#219BB8",
    "#29ACC5",
    "#668CE5",
    "#ACDABC",
    "#E2AC50",
    "#253294",
    "#BAABDC",
    "#892C32",
    "#8CA493",
    "#7D5FD2",
    "#A08ADD",
    "#17520C",
    "#B989D1",
    "#E34DE8",
    "#E45B98",
    "#1A4367",
    "#5A4CB9",
    "#287AB9",
    "#4144AF",
    "#8AE3D9",
    "#A16F16",
    "#DB68D6",
    "#5A5A3A",
    "#9D4767",
    "#F48721",
    "#3D0917",
    "#9A6CA9",
    "#B1A557",
    "#FCBB14",
    "#0B8CB1",
    "#4FDF5C",
    "#C6E4EE",
    "#2953AB",
    "#B4702D",
    "#EB86F4",
    "#7C4354",
    "#EC47C7",
    "#577B7B",
    "#26A352",
    "#583C92",
    "#69F35B",
    "#974B9B",
    "#1E6BC3",
    "#68B48A",
    "#DDF8B1",
    "#47D14E",
    "#828C1D",
    "#EC413E",
    "#B0F9DB",
    "#514C5C",
    "#F507AA",
    "#6478DC",
    "#D7E5DB",
    "#447A97",
    "#7A83CB",
    "#B1B680",
    "#645879",
    "#5165F1",
    "#812FC6",
    "#3A49D2",
    "#577E52",
    "#EB2C73",
    "#9E3CA6",
    "#E12E84",
    "#2509C0",
    "#A2E1AB",
    "#3604BD",
    "#2B859C",
    "#2C366A",
    "#B1DFC4",
    "#11C63C",
    "#FB5463",
    "#DB3BC9",
    "#88D1B3",
    "#7CE933",
    "#4A7B71",
    "#4652E9",
    "#F0E4C2",
    "#7C87CD",
    "#B86458",
    "#9271D5",
    "#78EDC2",
    "#9BEC47",
    "#C762F5",
    "#6F6697",
    "#298EC9",
    "#4EB776",
    "#66F6D1",
    "#CD8F58",
    "#6D69FC",
    "#721B3A",
    "#64A1A1",
    "#AC22D7",
    "#B46D41",
    "#57B47D",
    "#B64AB7",
    "#94DD71",
    "#72F758",
    "#D84132",
    "#55617B",
    "#759283",
    "#CA10F1",
    "#093ED3",
    "#A671E1",
    "#574150",
    "#6B5E5C",
    "#E772EB",
    "#13D5B1",
    "#9300D5",
    "#011C9A",
    "#C5D02C",
    "#35BB1D",
    "#2D57D8",
    "#256A61",
    "#75DFEC",
    "#8B85B5",
    "#E464EB",
    "#BAD18E",
    "#6E3F49",
    "#AF4B19",
    "#9CD3D5",
    "#421966",
    "#7D569C",
    "#A2CC7B",
    "#26B1FC",
    "#A5C298",
    "#22272F",
    "#56CF89",
    "#6E87CB",
    "#9E12B7",
    "#AA3A64",
    "#F193E0",
    "#44C5BE",
    "#51A312",
    "#B2CCE8",
    "#7D8E71",
    "#0A7A71",
    "#59DE4E",
    "#93D3FD",
    "#867EA1",
    "#6C7D3D",
    "#C21973",
    "#917AE9",
    "#367341",
    "#48B09E",
    "#B697C5",
    "#4DAAB5",
    "#E83854",
    "#CC1E8A",
    "#0A1412",
    "#BA1A9A",
    "#C43FD2",
    "#C4F8BB",
    "#29898D",
    "#B297B9",
    "#41558A",
    "#BDDEB4",
    "#B8275C",
    "#1388A6",
    "#BE294A",
    "#637C56",
    "#81D63B",
    "#6D7E75",
    "#42D9BD",
    "#63ACDC",
    "#03D96B",
    "#38733C",
    "#59C821",
    "#4B8375",
    "#9985C2",
    "#ABD849",
    "#6A9D75",
    "#41BAA2",
    "#3559B5",
    "#15580C",
    "#533846",
    "#0084A5",
    "#4174C9",
  ];
  const colorsCount = colors.length;
  return colors[index % colorsCount];
};

export const METRICS_KEY_HOST = "host";
export const METRICS_KEY_CONTAINER = "container";
export const METRICS_KEY_PROCESS = "process";
export const METRICS_KEY_K8S_CLUSTER = "k8s.cluster";
export const METRICS_KEY_K8S_NODE = "k8s.node";
export const METRICS_KEY_K8S_SERVICE = "k8s.service";
export const METRICS_KEY_K8S_POD = "k8s.pod";
export const METRICS_KEY_K8S_CONTAINER = "k8s.container";
export const METRICS_KEY_K8S_DEPLOYMENT = "k8s.deployment";
export const METRICS_KEY_K8S_REPLICASET = "k8s.replicaset";
export const METRICS_KEY_K8S_JOB = "k8s.job";
export const METRICS_KEY_K8S_CRONJOB = "k8s.cronjob";
export const METRICS_KEY_K8S_DAEMONSET = "k8s.daemonset";
export const METRICS_KEY_K8S_STATEFULSET = "k8s.statefulset";
export const METRICS_KEY_K8S_NAMESPACE = "k8s.namespace";
// export const METRICS_KEY_K8S_VOLUME = "k8s.volume"
export const METRICS_KEY_K8S_HPA = "k8s.hpa";
export const METRICS_KEY_K8S_INGRESSES = "k8s.ingress";
export const METRICS_KEY_K8S_PERSISTENT_VOLUME_CLAIMS =
  "k8s.persistentvolumeclaim";
export const METRICS_KEY_K8S_PERSISTENT_VOLUMES = "k8s.persistentvolume";
export const METRICS_KEY_K8S_SERVICE_ACCOUNTS = "k8s.serviceaccount";
export const METRICS_KEY_K8S_CLUSTER_ROLES = "k8s.clusterrole";
export const METRICS_KEY_K8S_ROLES = "k8s.role";
export const METRICS_KEY_K8S_CLUSTER_ROLE_BINDINGS = "k8s.clusterrolebinding";
export const METRICS_KEY_K8S_ROLE_BINDINGS = "k8s.rolebinding";
export enum ResourceKeyEnum {
  HOST = "host",
  CONTAINER = "container",
  PROCESS = "process",
  K8S_CLUSTER = "k8s.cluster",
  K8S_NODE = "k8s.node",
  K8S_SERVICE = "k8s.service",
  K8S_POD = "k8s.pod",
  K8S_CONTAINER = "k8s.container",
  K8S_DEPLOYMENT = "k8s.deployment",
  K8S_REPLICASET = "k8s.replicaset",
  K8S_JOB = "k8s.job",
  K8S_CRONJOB = "k8s.cronjob",
  K8S_DAEMONSET = "k8s.daemonset",
  K8S_STATEFULSET = "k8s.statefulset",
  K8S_NAMESPACE = "k8s.namespace",
}
export enum TabKey {
  LIST = "list",
  DASHBOARD = "dashboard",
  OVERVIEW = "overview",
}
export const INFRASTRUCTURE_SCOPE = "infrastructure";
export const INFRASTRUCTURE_HOST_SCOPE =
  INFRASTRUCTURE_SCOPE + "/" + METRICS_KEY_HOST;
export const INFRASTRUCTURE_CONTAINER_SCOPE =
  INFRASTRUCTURE_SCOPE + "/" + METRICS_KEY_CONTAINER;
export const INFRASTRUCTURE_PROCESS_SCOPE =
  INFRASTRUCTURE_SCOPE + "/" + METRICS_KEY_PROCESS;
export const INFRASTRUCTURE_K8S_CLUSTER_SCOPE =
  INFRASTRUCTURE_SCOPE + "/" + METRICS_KEY_K8S_CLUSTER;
export const INFRASTRUCTURE_K8S_NODE_SCOPE =
  INFRASTRUCTURE_SCOPE + "/" + METRICS_KEY_K8S_NODE;
export const INFRASTRUCTURE_K8S_SERVICE_SCOPE =
  INFRASTRUCTURE_SCOPE + "/" + METRICS_KEY_K8S_SERVICE;
export const INFRASTRUCTURE_K8S_POD_SCOPE =
  INFRASTRUCTURE_SCOPE + "/" + METRICS_KEY_K8S_POD;
export const INFRASTRUCTURE_K8S_CONTAINER_SCOPE =
  INFRASTRUCTURE_SCOPE + "/" + METRICS_KEY_K8S_CONTAINER;
export const INFRASTRUCTURE_K8S_DEPLOYMENT_SCOPE =
  INFRASTRUCTURE_SCOPE + "/" + METRICS_KEY_K8S_DEPLOYMENT;
export const INFRASTRUCTURE_K8S_REPLICASET_SCOPE =
  INFRASTRUCTURE_SCOPE + "/" + METRICS_KEY_K8S_REPLICASET;
export const INFRASTRUCTURE_K8S_JOB_SCOPE =
  INFRASTRUCTURE_SCOPE + "/" + METRICS_KEY_K8S_JOB;
export const INFRASTRUCTURE_K8S_CRONJOB_SCOPE =
  INFRASTRUCTURE_SCOPE + "/" + METRICS_KEY_K8S_CRONJOB;
export const INFRASTRUCTURE_K8S_DAEMONSET_SCOPE =
  INFRASTRUCTURE_SCOPE + "/" + METRICS_KEY_K8S_DAEMONSET;
export const INFRASTRUCTURE_K8S_STATEFULSET_SCOPE =
  INFRASTRUCTURE_SCOPE + "/" + METRICS_KEY_K8S_STATEFULSET;
export const INFRASTRUCTURE_K8S_NAMESPACE_SCOPE =
  INFRASTRUCTURE_SCOPE + "/" + METRICS_KEY_K8S_NAMESPACE;
// export const INFRASTRUCTURE_K8S_VOLUME_SCOPE = INFRASTRUCTURE_SCOPE + "/" + METRICS_KEY_K8S_VOLUME;
export const INFRASTRUCTURE_K8S_HPA_SCOPE =
  INFRASTRUCTURE_SCOPE + "/" + METRICS_KEY_K8S_HPA;
export const INFRASTRUCTURE_K8S_INGRESSES_SCOPE =
  INFRASTRUCTURE_SCOPE + "/" + METRICS_KEY_K8S_INGRESSES;
export const INFRASTRUCTURE_K8S_PERSISTENT_VOLUME_CLAIMS_SCOPE =
  INFRASTRUCTURE_SCOPE + "/" + METRICS_KEY_K8S_PERSISTENT_VOLUME_CLAIMS;
export const INFRASTRUCTURE_K8S_PERSISTENT_VOLUMES_SCOPE =
  INFRASTRUCTURE_SCOPE + "/" + METRICS_KEY_K8S_PERSISTENT_VOLUMES;
export const INFRASTRUCTURE_K8S_SERVICE_ACCOUNTS_SCOPE =
  INFRASTRUCTURE_SCOPE + "/" + METRICS_KEY_K8S_SERVICE_ACCOUNTS;
export const INFRASTRUCTURE_K8S_CLUSTER_ROLES_SCOPE =
  INFRASTRUCTURE_SCOPE + "/" + METRICS_KEY_K8S_CLUSTER_ROLES;
export const INFRASTRUCTURE_K8S_ROLES_SCOPE =
  INFRASTRUCTURE_SCOPE + "/" + METRICS_KEY_K8S_ROLES;
export const INFRASTRUCTURE_CLUSTER_ROLE_BINDINGS_SCOPE =
  INFRASTRUCTURE_SCOPE + "/" + METRICS_KEY_K8S_CLUSTER_ROLE_BINDINGS;
export const INFRASTRUCTURE_K8S_ROLE_BINDINGS_SCOPE =
  INFRASTRUCTURE_SCOPE + "/" + METRICS_KEY_K8S_ROLE_BINDINGS;

export const SCOPE_PATH_HOST_LIST = INFRASTRUCTURE_HOST_SCOPE;
export const SCOPE_PATH_CONTAINERS_LIST = INFRASTRUCTURE_CONTAINER_SCOPE;
export const SCOPE_PATH_PROCESS_LIST = INFRASTRUCTURE_PROCESS_SCOPE;
export const SCOPE_PATH_K8S_CLUSTERS = INFRASTRUCTURE_K8S_CLUSTER_SCOPE;
export const SCOPE_PATH_K8S_NODES = INFRASTRUCTURE_K8S_NODE_SCOPE;
export const SCOPE_PATH_K8S_SERVICES = INFRASTRUCTURE_K8S_SERVICE_SCOPE;
export const SCOPE_PATH_K8S_WORKLOADS_PODS = INFRASTRUCTURE_K8S_POD_SCOPE;
export const SCOPE_PATH_K8S_WORKLOADS_CONTAINERS =
  INFRASTRUCTURE_K8S_CONTAINER_SCOPE;
export const SCOPE_PATH_K8S_WORKLOADS_DEPLOYMENTS =
  INFRASTRUCTURE_K8S_DEPLOYMENT_SCOPE;
export const SCOPE_PATH_K8S_WORKLOADS_REPLICASETS =
  INFRASTRUCTURE_K8S_REPLICASET_SCOPE;
export const SCOPE_PATH_K8S_WORKLOADS_JOBS = INFRASTRUCTURE_K8S_JOB_SCOPE;
export const SCOPE_PATH_K8S_WORKLOADS_CRONJOBS =
  INFRASTRUCTURE_K8S_CRONJOB_SCOPE;
export const SCOPE_PATH_K8S_WORKLOADS_DEAMONSETS =
  INFRASTRUCTURE_K8S_DAEMONSET_SCOPE;
export const SCOPE_PATH_K8S_WORKLOADS_STATEFULSETS =
  INFRASTRUCTURE_K8S_STATEFULSET_SCOPE;
export const SCOPE_PATH_K8S_WORKLOADS_NAMESPACES =
  INFRASTRUCTURE_K8S_NAMESPACE_SCOPE;
// export const SCOPE_PATH_K8S_WORKLOADS_VOLUMES = INFRASTRUCTURE_K8S_VOLUME_SCOPE
export const SCOPE_PATH_K8S_WORKLOADS_HPA = INFRASTRUCTURE_K8S_HPA_SCOPE;
export const SCOPE_PATH_K8S_CONFIG_INGRESSES =
  INFRASTRUCTURE_K8S_INGRESSES_SCOPE;
export const SCOPE_PATH_K8S_CONFIG_PERSISTENT_VOLUME_CLAIMS =
  INFRASTRUCTURE_K8S_PERSISTENT_VOLUME_CLAIMS_SCOPE;
export const SCOPE_PATH_K8S_CONFIG_PERSISTENT_VOLUMES =
  INFRASTRUCTURE_K8S_PERSISTENT_VOLUMES_SCOPE;
export const SCOPE_PATH_K8S_CONFIG_SERVICE_ACCOUNTS =
  INFRASTRUCTURE_K8S_SERVICE_ACCOUNTS_SCOPE;
export const SCOPE_PATH_K8S_CONFIG_CLUSTER_ROLES =
  INFRASTRUCTURE_K8S_CLUSTER_ROLES_SCOPE;
export const SCOPE_PATH_K8S_CONFIG_ROLES = INFRASTRUCTURE_K8S_ROLES_SCOPE;
export const SCOPE_PATH_K8S_CONFIG_CLUSTER_ROLE_BINDINGS =
  INFRASTRUCTURE_CLUSTER_ROLE_BINDINGS_SCOPE;
export const SCOPE_PATH_K8S_CONFIG_ROLE_BINDINGS =
  INFRASTRUCTURE_K8S_ROLE_BINDINGS_SCOPE;

export const getScopeSchema = (resource: string): ScopeType | undefined => {
  const schUrl = GenerateScopePath(resource);
  return _scopes[schUrl];
};
export const GenerateScopePath = (resourceType: string) => {
  return `${INFRASTRUCTURE_SCOPE}/${resourceType}`;
};
export const GetScopeSchema = (resource: string): ScopeType | undefined => {
  const schUrl = GenerateScopePath(resource);
  return _scopes[schUrl];
};
export interface ScopeType {
  // this will be used to display in the header
  title: string;
  // this will be used to display custom dashboard widgets
  displayScope: string;
  // resource type, ex: host, container, process, k8s.cluster, k8s.node, k8s.pod, k8s.container
  metrics: string;
  // sub resource type, ex: k8s.node will be sub resource of k8s.cluster
  // as we are not having data of k8s.cluster directly
  subResourceType?: string;
  // default columns to be displayed in the list
  defaultColumns: string[];
  additionalColumns?: string[];
  excludeColumns?: string[];
  // searchable columns in the list
  searchableColumns: string[];
  // timeline metrics to be displayed in the timeline
  timelineMetrics: {
    metrics: {
      label: string;
      key: string;
    }[];
    groupBy: string[];
  };
  // this will be used when we want to list data in group by
  listGroupBy: string[];
  // this will be used to disable column selection in the list
  disableColumnSelection?: boolean;
  // this will be used to display custom column names in the list
  //TODO: add bkoff settings to add these columns dynamically
  // will be altered dynamically from bkoff so the static values won't be applicable
  columnsNames?: Record<string, string>;
  //TODO: this will be used to enable inner dialog in the list. confirm do we need this or not
  enableInnerDialog: boolean;
  // this will be used to identify primary column in the list
  primaryColumnKey: string;
  defaultAggregations?: Record<string, AggMethod>;
  subAggregations?: Record<string, AggMethod>;
  // overview tab
  overview?: boolean;
  disableInnerDialog?: boolean;
  columnConfig?: ColumnConfig;
  customResourceColumns?: Record<string, ScopeType>;
}
export interface OverviewProps {
  caller: string;
}
const _scopes: Record<string, ScopeType> = {
  [SCOPE_PATH_HOST_LIST]: {
    title: "Hosts",
    displayScope: BUILDER_DISPLAY_SCOPE_INFRASTRUCTURE_HOST_LIST,
    metrics: METRICS_KEY_HOST,
    timelineMetrics: {
      metrics: [
        {
          label: "Memory Usage",
          key: "system.memory.utilization",
        },
      ],
      groupBy: ["host.name"],
    },
    searchableColumns: ["host.name", "host.id"],
    defaultColumns: [
      "host.name",
      "os.type",
      "system.memory.utilization",
      //"system.memory.usage",
      //"system.filesystem.usage",
      "system.filesystem.utilization",
      "resource_status",
      "system.cpu.utilization",
    ],
    enableInnerDialog: false,
    columnsNames: {
      "system.cpu.utilization": "CPU Utilization",
      "system.memory.utilization": "Memory Utilization",
      "system.memory.usage": "Memory (Bytes)",
      "system.cpu.load_average.15m": "CPU Avg. Load (15m)",
      "system.filesystem.usage": "Filesystem (Bytes)",
      "system.filesystem.utilization": "Filesystem Utilization",
      "system.disk.io.speed": "Disk IO Speed (Bytes/sec)",
      "system.network.connections": "Network Connections",
      resource_status: "Status",
      "host.name": "Host Name",
      "os.type": "Operating System",
    },
    additionalColumns: [
      "avg(system.memory.usage,state(used))",
      "avg(system.memory.usage,state(free))",
      "avg(system.memory.usage,state(cached))",
      "avg(system.memory.usage,state(buffered))",
      "avg(system.filesystem.usage,state(reserved))",
      "avg(system.filesystem.usage,state(used))",
      "avg(system.filesystem.usage,state(free))",
      "avg(system.filesystem.usage,state(cached))",
    ],
    columnConfig: {
      "host.name": {
        width: 286,
      },
      "os.type": {
        width: 127,
      },
      "system.memory.utilization": {
        width: 128,
        align: Align.Right,
        tooltipContent: " ",
      },
      "system.memory.usage": {
        width: 110,
        align: Align.Right,
        tooltipContent: " ",
      },
      "system.filesystem.utilization": {
        width: 150,
        align: Align.Right,
        tooltipContent: " ",
      },
      "system.filesystem.usage": {
        width: 122,
        align: Align.Right,
        tooltipContent: " ",
      },
      resource_status: {
        width: 110,
        align: Align.Center,
      },
      "system.cpu.utilization": {
        width: 109,
        align: Align.Right,
      },
      "system.disk.io.speed": {
        width: 122,
        align: Align.Right,
      },
      "system.network.connections": {
        width: 122,
        align: Align.Right,
      },
      "system.cpu.load_average.15m": {
        align: Align.Right,
      },
      "system.cpu.load_average.1m": {
        align: Align.Right,
      },
      "avg(system.memory.usage,state(used))": {
        isHidden: true,
      },
      "avg(system.memory.usage,state(free))": {
        isHidden: true,
      },
      "avg(system.memory.usage,state(cached))": {
        isHidden: true,
      },
      "avg(system.memory.usage,state(buffered))": {
        isHidden: true,
      },
      "avg(system.filesystem.usage,state(used))": {
        isHidden: true,
      },
      "avg(system.filesystem.usage,state(free))": {
        isHidden: true,
      },
      "avg(system.filesystem.usage,state(cached))": {
        isHidden: true,
      },
      "avg(system.filesystem.usage,state(reserved))": {
        isHidden: true,
      },
    },
    primaryColumnKey: "host.name",
    listGroupBy: ["host.name"],
  },
  [SCOPE_PATH_CONTAINERS_LIST]: {
    title: "Containers",
    displayScope: BUILDER_DISPLAY_SCOPE_INFRASTRUCTURE_CONTAINER_LIST,
    metrics: METRICS_KEY_CONTAINER,
    timelineMetrics: {
      metrics: [
        {
          label: "CPU Usage",
          key: "container.cpu.percent",
        },
        {
          label: "Memory Usage",
          key: "container.memory.percent",
        },
      ],
      groupBy: ["container.name"],
    },
    searchableColumns: [
      "container.name",
      "container.id",
      "container.image.name",
      "container.runtime",
    ],
    defaultColumns: [
      "container.name",
      "container.resource_status",
      "container.image.name",
      "container.cpu.utilization",
      "container.memory.percent",
      "container.network.io.usage.tx_bytes",
      "container.network.io.usage.rx_bytes",
    ],
    excludeColumns: ["container.hostname"],
    columnsNames: {
      "container.id": "ID",
      "container.name": "Container Name",
      "host.id": "Host",
      "os.type": "OS",
      "container.cpu.utilization": "Container CPU Utilization Percent",
      "container.memory.percent": "Container Memory Percent",
      "container.hostname": "Hostname",
      "container.image.name": "Image",
      "container.runtime": "Runtime",
      "container.started_on": "Started On",
      "container.network.io.usage.tx_bytes": "Bytes Received",
      "container.network.io.usage.rx_bytes": "Bytes Sent",
    },
    enableInnerDialog: false,
    primaryColumnKey: "container.name",
    listGroupBy: ["container.id"],
    columnConfig: {
      "container.name": {
        width: 240,
      },
      "container.cpu.utilization": {
        width: 140,
        align: Align.Center,
      },
      "container.resource_status": {
        width: 128,
        align: Align.Center,
      },
      "container.image.name": {
        width: 110,
      },
      "container.network.io.usage.tx_bytes": {
        width: 112,
        align: Align.Right,
      },
      "container.network.io.usage.rx_bytes": {
        width: 112,
        align: Align.Right,
      },
      "container.started_on": {
        width: 131,
      },
      "container.memory.percent": {
        align: Align.Right,
      },
    },
  },
  [SCOPE_PATH_PROCESS_LIST]: {
    title: "Processes",
    displayScope: BUILDER_DISPLAY_SCOPE_INFRASTRUCTURE_PROCESS_LIST,
    metrics: METRICS_KEY_PROCESS,
    timelineMetrics: {
      metrics: [
        {
          label: "CPU Usage",
          key: "process.cpu.percent",
        },
        {
          label: "Memory Usage",
          key: "process.memory.percent",
        },
      ],
      groupBy: ["process.executable.name"],
    },
    searchableColumns: [
      "process.executable.name",
      "process.pid",
      "process.command_line",
    ],
    defaultColumns: [
      "process.executable.name",
      "process.owner",
      "host.name",
      "process.cpu.percent",
      "process.memory.percent",
      "process.started_on",
      "process.command_line",
    ],
    listGroupBy: ["process.pid"],
    enableInnerDialog: false,
    primaryColumnKey: "process.executable.name",
    columnConfig: {
      "process.executable.name": {
        width: 240,
      },
      "host.id": {
        width: 110,
      },
      "process.cpu.percent": {
        align: Align.Right,
      },
      "process.memory.percent": {
        align: Align.Right,
      },
    },
  },
  [SCOPE_PATH_K8S_CLUSTERS]: {
    title: "Clusters",
    displayScope: BUILDER_DISPLAY_SCOPE_INFRA_K8S_CLUSTER_DASH,
    metrics: METRICS_KEY_K8S_CLUSTER,
    subResourceType: METRICS_KEY_K8S_NODE,
    timelineMetrics: {
      metrics: [
        {
          label: "CPU Usage",
          key: "k8s.node.cpu.utilization",
        },
      ],
      groupBy: ["k8s.cluster.name"],
    },
    searchableColumns: ["k8s.cluster.name"],
    defaultColumns: [
      "k8s.cluster.name",
      "k8s.node.name",
      "k8s.node.cpu.utilization_percent",
      "k8s.node.memory.utilization",
    ],
    excludeColumns: [
      "k8s.node.k8s.node.cpu.utilization_percent",
      "k8s.node.k8s.node.memory.utilization",
    ],
    columnsNames: {
      "k8s.cluster.name": "Name",
      resource_status: "Status",
      "k8s.node.cpu.utilization": "CPU Usage(mcore)",
      "k8s.node.cpu.utilization_percent": "CPU",
      "sum(k8s.node.memory.utilization,value(avg))": "Memory Usage",
      "k8s.node.memory.utilization": "Memory",
      "k8s.node.network.io": "Network I/O",
      "sum(k8s.pod.cpu_limit_utilization,value(avg))": "Pods Usage",
      "uniq(k8s.node.name)": "Nodes",
      "k8s.node.name": "Nodes",
      "uniq(k8s.pod.name)": "Pod Statuses",
    },
    defaultAggregations: {
      "k8s.node.name": "uniq",
      "k8s.node.memory.utilization": "sum",
      "k8s.node.cpu.utilization": "sum",
    },
    subAggregations: {
      "k8s.node.memory.utilization": "avg",
      "k8s.node.cpu.utilization": "avg",
    },
    listGroupBy: ["k8s.cluster.name"],
    disableColumnSelection: true,
    enableInnerDialog: false,
    primaryColumnKey: "k8s.cluster.name",
    columnConfig: {
      "k8s.cluster.name": {
        title: "Name",
        width: 600,
        //tooltipContent: "Name of the K8s Cluster",
      },
      "uniq(k8s.pod.name)": {
        title: "Pod Statuses",
        align: Align.Center,
        width: 200,
      },
      "uniq(k8s.pod.name)-2": {
        isHidden: true,
      },
      "uniq(k8s.pod.name)-3": {
        isHidden: true,
      },
      "uniq(k8s.node.name)": {
        title: "Nodes",
        align: Align.Center,
        width: 100,
      },
      "k8s.node.condition_ready": {
        isHidden: true,
      },
      "k8s.node.cpu.utilization_percent": {
        title: "CPU",
        align: Align.Center,
        width: 200,
      },
      "sum(k8s.node.memory.utilization,value(avg))": {
        title: "Memory",
        align: Align.Center,
        width: 200,
      },
      "sum(k8s.pod.cpu_limit_utilization,value(avg))": {
        title: "Pods Usage",
        align: Align.Center,
        width: 200,
      },
      "sum(k8s.node.cpu.utilization,value(avg))": {
        isHidden: true,
      },
      "sum(k8s.node.allocatable_cpu,value(avg))": {
        isHidden: true,
      },
      "sum(k8s.node.memory.working_set,value(avg))": {
        isHidden: true,
      },
      "sum(k8s.node.allocatable_memory,value(avg))": {
        isHidden: true,
      },
    },
    additionalColumns: [
      "sum(k8s.node.memory.working_set,value(avg))",
      "sum(k8s.node.allocatable_memory,value(avg))",
    ],
    customResourceColumns: {
      [METRICS_KEY_K8S_POD]: {
        title: "",
        displayScope: "",
        metrics: "",
        timelineMetrics: {
          metrics: [
            {
              label: "CPU Usage",
              key: "k8s.node.cpu.utilization",
            },
          ],
          groupBy: ["k8s.cluster.name"],
        },
        defaultColumns: [
          "uniq(k8s.pod.name)",
          "sum(k8s.pod.cpu_limit_utilization,value(avg))",
        ],
        searchableColumns: [],
        columnsNames: {
          "sum(k8s.pod.cpu_limit_utilization,value(avg))": "Pods Usage",
        },
        defaultAggregations: {
          "k8s.pod.cpu_limit_utilization": "avg",
        },
        subAggregations: {
          "k8s.pod.cpu_limit_utilization": "sum",
        },
        listGroupBy: ["k8s.cluster.name"],
        primaryColumnKey: "k8s.cluster.name",
        enableInnerDialog: false,
      },
    },
  },
  [SCOPE_PATH_K8S_NODES]: {
    title: "Nodes",
    displayScope: BUILDER_DISPLAY_SCOPE_INFRA_K8S_NODES_DASH,
    metrics: METRICS_KEY_K8S_NODE,
    timelineMetrics: {
      metrics: [
        {
          label: "CPU Usage",
          key: "k8s.node.cpu.utilization",
        },
      ],
      groupBy: ["k8s.node.name"],
    },
    searchableColumns: ["k8s.node.name", "k8s.node.uid"],
    defaultColumns: [
      "k8s.node.name",
      "k8s.cluster.name",
      "k8s.node.condition_ready",
      "k8s.node.cpu.utilization_percent",
      "k8s.node.memory.utilization",
      "k8s.node.start_time",
    ],
    excludeColumns: [
      "k8s.node.k8s.node.cpu.utilization_percent",
      "k8s.node.k8s.node.memory.utilization",
    ],
    columnsNames: {
      "k8s.node.uid": "UID",
      "k8s.node.condition_ready": "Status",
      "k8s.node.name": "Node",
      "k8s.node.cpu.utilization_percent": "CPU",
      "k8s.node.memory.utilization": "Memory",
      "k8s.node.filesystem.usage": "Filesystem Usage",
      "any(k8s.node.condition_ready)": "Status",
      "k8s.node.start_time": "Age",
      "uniq(k8s.pod.name)": "Pods",
    },
    defaultAggregations: {
      "k8s.node.condition_ready": "any",
    },
    columnConfig: {
      "k8s.node.name": {
        width: 500,
      },
      "k8s.node.start_time": {
        width: 100,
        align: Align.Center,
      },
      "k8s.cluster.name": {
        width: 100,
        align: Align.Center,
      },
      "k8s.node.cpu.utilization": {
        isHidden: true,
      },
      "k8s.node.memory.utilization": {
        width: 150,
        align: Align.Center,
      },
      "any(k8s.node.condition_ready)": {
        width: 120,
        align: Align.Center,
        title: "Status",
      },
      "k8s.node.cpu.utilization_percent": {
        title: "CPU",
        width: 150,
        align: Align.Center,
      },
      "uniq(k8s.pod.name)": {
        title: "Pods",
        width: 200,
        align: Align.Center,
      },
      "uniq(k8s.pod.name)-2": {
        isHidden: true,
      },
      "uniq(k8s.pod.name)-3": {
        isHidden: true,
      },
      "k8s.node.allocatable_cpu": {
        isHidden: true,
      },
      "k8s.node.memory.working_set": {
        isHidden: true,
      },
      "k8s.node.allocatable_memory": {
        isHidden: true,
      },
    },
    enableInnerDialog: true,
    primaryColumnKey: "k8s.node.name",
    listGroupBy: ["k8s.node.name"],
    customResourceColumns: {
      [METRICS_KEY_K8S_POD]: {
        title: "",
        displayScope: "",
        metrics: "",
        timelineMetrics: {
          metrics: [
            {
              label: "CPU Usage",
              key: "k8s.pod.cpu.utilization",
            },
          ],
          groupBy: ["k8s.node.name"],
        },
        defaultColumns: ["uniq(k8s.pod.name)"],
        searchableColumns: [],

        primaryColumnKey: "k8s.node.name",
        listGroupBy: ["k8s.node.name"],
        enableInnerDialog: false,
      },
    },
  },
  [SCOPE_PATH_K8S_SERVICES]: {
    title: "Services",
    displayScope: BUILDER_DISPLAY_SCOPE_INFRA_K8S_SERVICES_DASH,
    metrics: METRICS_KEY_K8S_SERVICE,
    timelineMetrics: {
      metrics: [
        {
          label: "CPU Usage",
          key: "k8s.pod.cpu.utilization",
        },
      ],
      groupBy: ["k8s.pod.name"],
    },
    searchableColumns: ["service.name", "k8s.service.uid", "k8s.service.type"],
    defaultColumns: [
      "k8s.service.name",
      "k8s.service.namespace",
      "k8s.service.type",
      "k8s.service.port_count",
      "k8s.service.cluster_ip",
    ],
    columnsNames: {
      "k8s.service.uid": "UID",
      "k8s.service.name": "Name",
      "k8s.service.namespace": "Namespace",
      "k8s.cluster.name": "Cluster",
      "k8s.service.port_count": "Port Count",
      "k8s.service.cluster_ip": "Cluster IP",
      "k8s.service.type": "Type",
    },
    enableInnerDialog: true,
    primaryColumnKey: "k8s.service.name",
    listGroupBy: ["k8s.service.uid"],
    columnConfig: {
      "k8s.service.name": {
        width: 250,
      },
      "k8s.service.namespace": {
        width: 250,
      },
      "k8s.service.type": {
        width: 100,
        align: Align.Center,
      },
      "k8s.service.cluster_ip": {
        width: 100,
        align: Align.Center,
      },
      "k8s.service.port_count": {
        width: 100,
        align: Align.Center,
      },
      resource_status: {
        align: Align.Center,
      },
    },
  },
  [SCOPE_PATH_K8S_WORKLOADS_PODS]: {
    title: "Pods",
    displayScope: BUILDER_DISPLAY_SCOPE_INFRA_K8S_PODS_DASH,
    metrics: METRICS_KEY_K8S_POD,
    timelineMetrics: {
      metrics: [
        {
          label: "CPU Usage",
          key: "k8s.pod.cpu.utilization",
        },
      ],
      groupBy: ["k8s.pod.name"],
    },
    searchableColumns: ["k8s.pod.name", "k8s.pod.uid"],
    defaultColumns: [
      "k8s.pod.name",
      "k8s.namespace.name",
      "k8s.pod.phase",
      // "k8s.cluster.name",
      "k8s.pod.start_time",
      "k8s.pod.cpu.utilization",
      "k8s.pod.memory.usage",
      "k8s.daemonset.uid",
      "k8s.node.name",
    ],
    columnsNames: {
      "k8s.pod.name": "Pod Name",
      resource_status: "Resource Status",
      "k8s.pod.phase": "Phase",
      "any(k8s.pod.phase)": "Phase",
      "k8s.pod.start_time": "Age",
      "k8s.pod.cpu_limit_utilization": "CPU",
      "k8s.pod.memory_limit_utilization": "Memory",
      "k8s.cluster.name": "Cluster",
      "k8s.namespace.name": "Namespace",
      "k8s.daemonset.uid": "Controlled By",
      "k8s.node.name": "Node",
      "uniq(k8s.container.name)": "Container",
      "sum(k8s.container.restarts)": "Restart Count",
    },
    defaultAggregations: {
      "k8s.pod.phase": "any",
    },
    enableInnerDialog: true,
    listGroupBy: ["k8s.pod.uid"],
    primaryColumnKey: "k8s.pod.name",
    overview: true,
    columnConfig: {
      "k8s.pod.name": {
        width: 100,
      },
      "k8s.pod.start_time": {
        width: 100,
        align: Align.Center,
      },
      "k8s.node.name": {
        title: "Node",
        width: 100,
        align: Align.Center,
      },
      resource_status: {
        width: 120,
        align: Align.Center,
      },
      "k8s.cluster.name": {
        width: 120,
        align: Align.Center,
      },
      "k8s.namespace.name": {
        width: 120,
        align: Align.Center,
      },
      "k8s.pod.cpu.utilization": {
        width: 120,
        align: Align.Center,
      },
      "k8s.pod.cpu_limit_utilization": {
        width: 131,
      },
      "k8s.pod.memory_limit_utilization": {
        width: 131,
      },
      "k8s.pod.memory.usage": {
        width: 120,
        align: Align.Center,
      },
      "uniq(k8s.container.name)": {
        title: "Container",
        align: Align.Center,
        width: 100,
      },
      "k8s.daemonset.uid": {
        title: "Controlled By",
        width: 100,
        align: Align.Center,
      },
      "k8s.job.uid": {
        isHidden: true,
      },
      "k8s.statefulset.uid": {
        isHidden: true,
      },
      "k8s.replicaset.uid": {
        isHidden: true,
      },
      "sum(k8s.container.restarts)": {
        title: "Restart Count",
        width: 100,
        align: Align.Center,
      },
      "uniq(k8s.container.name)-2": {
        isHidden: true,
      },
      "any(k8s.pod.phase)": {
        align: Align.Center,
        width: 100,
        tpl: {
          type: TPL_TYPE_STATUS,
          config: {
            valueType: [
              "1:#ffb800:Pending",
              "2:#2da44e:Running",
              "3:#2da44e:Succeeded",
              "4:#d62747:Failed",
              "5:#d62747:Unknown",
              "0:#ffb800:Unknown",
            ],
          },
          label: "Pod Phase",
        },
      },
    },
    customResourceColumns: {
      [METRICS_KEY_K8S_CONTAINER]: {
        title: "",
        displayScope: "",
        metrics: "",
        timelineMetrics: {
          metrics: [
            {
              label: "CPU Usage",
              key: "k8s.pod.cpu.utilization",
            },
          ],
          groupBy: ["k8s.pod.uid"],
        },
        defaultColumns: [
          "uniq(k8s.container.name)",
          "sum(k8s.container.restarts)",
        ],
        searchableColumns: [],

        primaryColumnKey: "k8s.pod.name",
        listGroupBy: ["k8s.pod.uid"],
        enableInnerDialog: false,
      },
    },
  },
  // [SCOPE_PATH_K8S_WORKLOADS_VOLUMES]: {
  //     title: "Volumes",
  //     displayScope: BUILDER_DISPLAY_SCOPE_INFRA_K8S_VOLUMES_DASH,
  //     metrics: METRICS_KEY_K8S_VOLUME,
  //     timelineMetrics: {
  //         key: ["k8s.volume.available"],
  //         groupBy: ["k8s.volume.name"]
  //     },
  //     searchableColumns: [
  //         "k8s.volume.name",
  //         "k8s.volume.type",
  //     ],
  //     defaultColumns: [
  //         "k8s.volume.name",
  //         "k8s.pod.name",
  //         "k8s.cluster.name",
  //         "k8s.namespace.name",
  //         "k8s.node.name",
  //         "k8s.volume.available",
  //         "k8s.volume.capacity",
  //         "k8s.volume.type",
  //     ],
  //     columnsNames: {
  //         "k8s.volume.name" : "Volume",
  //         "k8s.pod.name": "Pod",
  //         "k8s.cluster.name": "Cluster",
  //         "k8s.namespace.name": "Namespace",
  //         "k8s.node.name": "Node",
  //         "k8s.volume.available": "Available Volume",
  //         "k8s.volume.capacity" : "Capacity",
  //         "k8s.volume.type": "Volume Type",
  //     },
  //     enableInnerDialog: true,
  //     primaryColumnKey: "k8s.volume.name",
  //     listGroupBy: ["k8s.volume.name"],
  //     disableInnerDialog: true
  // },
  [SCOPE_PATH_K8S_WORKLOADS_HPA]: {
    title: "Horizontal Pod Autoscalers",
    displayScope: BUILDER_DISPLAY_SCOPE_INFRA_K8S_HPA_DASH,
    metrics: METRICS_KEY_K8S_HPA,
    timelineMetrics: {
      metrics: [
        {
          label: "Current Replicas",
          key: "k8s.hpa.current_replicas",
        },
      ],
      groupBy: ["k8s.hpa.name"],
    },
    searchableColumns: ["k8s.hpa.name", "k8s.hpa.uid"],
    defaultColumns: [
      "k8s.hpa.name",
      "k8s.namespace.name",
      "k8s.hpa.current_replicas",
      "k8s.hpa.desired_replicas",
      "k8s.hpa.max_replicas",
      "k8s.hpa.min_replicas",
    ],
    columnsNames: {
      "k8s.hpa.uid": "UID",
      "k8s.hpa.name": "Name",
      "k8s.namespace.name": "Namespace",
      "k8s.hpa.current_replicas": "Current Replicas",
      "k8s.hpa.desired_replicas": "Desired Replicas",
      "k8s.hpa.max_replicas": "Max Replicas",
      "k8s.hpa.min_replicas": "Min Replicas",
    },
    columnConfig: {
      "k8s.namespace.name": {
        title: "Namespace",
      },
    },
    enableInnerDialog: true,
    primaryColumnKey: "k8s.hpa.name",
    listGroupBy: ["k8s.hpa.uid"],
  },
  [SCOPE_PATH_K8S_WORKLOADS_CONTAINERS]: {
    title: "Containers",
    displayScope: BUILDER_DISPLAY_SCOPE_INFRA_K8S_CONTAINERS_DASH,
    metrics: METRICS_KEY_K8S_CONTAINER,
    timelineMetrics: {
      metrics: [
        {
          label: "CPU Usage",
          key: "container.cpu.utilization",
        },
      ],
      groupBy: ["k8s.container.name"],
    },
    searchableColumns: ["k8s.container.name", "k8s.pod.name"],
    defaultColumns: [
      "k8s.container.name",
      "k8s.pod.name",
      "k8s.container.ready",
      "k8s.pod.start_time",
      "container.cpu.utilization",
      "container.memory.usage",
    ],
    additionalColumns: ["avg(container.filesystem.capacity)"],
    columnsNames: {
      "k8s.container.name": "Name",
      "k8s.container.ready": "Ready",
      "k8s.namespace.name": "Namespace Name",
      "k8s.node.name": "Node Name",
      "any(k8s.container.ready)": "Status",
      "k8s.pod.name": "Pod",
      "k8s.pod.start_time": "Age",
      "container.cpu.utilization": "CPU Usage",
      "container.cpu.time": "CPU Time",
      "container.memory.rss": "Memory RSS",
      "container.filesystem.available": "Filesystem Available",
      "container.filesystem.usage": "Filesystem Usage",
      "k8s.container.restarts": "Restarts",
    },
    defaultAggregations: {
      "k8s.container.restarts": "any",
      "k8s.container.ready": "any",
    },
    enableInnerDialog: true,
    listGroupBy: ["container.id"],
    primaryColumnKey: "k8s.container.name",
    columnConfig: {
      "k8s.container.name": {
        width: 300,
      },
      "k8s.pod.start_time": {
        width: 100,
        align: Align.Center,
      },
      "k8s.container.restarts": {
        width: 110,
        align: Align.Center,
      },
      "k8s.pod.name": {
        width: 300,
      },
      "k8s.pod.uid": {
        width: 120,
      },
      "any(k8s.container.ready)": {
        title: "Status",
        width: 100,
        align: Align.Center,
      },
      "container.cpu.utilization": {
        width: 100,
        align: Align.Center,
      },
      "container.memory.usage": {
        width: 100,
        align: Align.Center,
      },
      "container.filesystem.usage": {
        align: Align.Center,
        width: 150,
        tooltipContent: " ",
      },
      "container.filesystem.available": {
        align: Align.Right,
        width: 124,
      },
      "avg(container.filesystem.capacity)": {
        isHidden: true,
      },
    },
  },
  [SCOPE_PATH_K8S_WORKLOADS_DEPLOYMENTS]: {
    title: "Deployments",
    displayScope: BUILDER_DISPLAY_SCOPE_INFRA_K8S_DEPLOYMENTS_DASH,
    metrics: METRICS_KEY_K8S_DEPLOYMENT,
    timelineMetrics: {
      metrics: [
        {
          label: "CPU Usage",
          key: "k8s.pod.cpu.utilization",
        },
      ],
      groupBy: ["k8s.pod.name"],
    },
    searchableColumns: ["k8s.deployment.name", "k8s.deployment.uid"],
    defaultColumns: [
      "k8s.deployment.name",
      "k8s.namespace.name",
      "k8s.cluster.name",
      "k8s.deployment.start_time",
      "k8s.deployment.available",
    ],
    columnsNames: {
      "k8s.deployment.name": "Deployment Name",
      "k8s.cluster.name": "Cluster",
      "k8s.namespace.name": "Namespace",
      resource_status: "Resource Status",
      "k8s.deployment.start_time": "Age",
      "k8s.deployment.available": "Available",
      "k8s.deployment.desired": "Desired",
    },
    enableInnerDialog: true,
    primaryColumnKey: "k8s.deployment.name",
    listGroupBy: ["k8s.deployment.uid"],
    columnConfig: {
      "k8s.deployment.name": {
        width: 250,
      },
      resource_status: {
        width: 100,
        align: Align.Center,
      },
      "k8s.namespace.name": {
        tooltipContent: "",
        tooltipInfo: "",
        width: 250,
      },
      "k8s.cluster.name": {
        tooltipContent: "",
        width: 250,
      },
      "k8s.deployment.start_time": {
        width: 100,
        align: Align.Center,
      },
      "k8s.deployment.available": {
        width: 100,
        align: Align.Center,
      },
      "k8s.deployment.desired": {
        width: 100,
        align: Align.Center,
      },
    },
  },
  [SCOPE_PATH_K8S_WORKLOADS_REPLICASETS]: {
    title: "Replica Sets",
    displayScope: BUILDER_DISPLAY_SCOPE_INFRA_K8S_REPLICA_SETS_DASH,
    metrics: METRICS_KEY_K8S_REPLICASET,
    timelineMetrics: {
      metrics: [
        {
          label: "CPU Usage",
          key: "k8s.pod.cpu.utilization",
        },
      ],
      groupBy: ["k8s.pod.name"],
    },
    searchableColumns: ["k8s.replicaset.name", "k8s.replicaset.uid"],
    defaultColumns: [
      "k8s.replicaset.name",
      "k8s.cluster.name",
      "k8s.namespace.name",
      "k8s.replicaset.available",
      "k8s.replicaset.desired",
      "k8s.replicaset.failed",
      "k8s.replicaset.start_time",
    ],
    columnsNames: {
      "k8s.replicaset.name": "Replicaset Name",
      "k8s.cluster.name": "Cluster",
      "k8s.replicaset.start_time": "Age",
      "k8s.replicaset.available": "Available",
      "k8s.replicaset.desired": "Desired",
      "k8s.replicaset.failed": "Failed",
    },
    columnConfig: {
      "k8s.replicaset.name": {
        width: 300,
      },
      "k8s.namespace.name": {
        width: 300,
      },
      "k8s.cluster.name": {
        width: 300,
      },
      "k8s.replicaset.available": {
        width: 120,
        align: Align.Center,
      },
      "k8s.replicaset.desired": {
        width: 120,
        align: Align.Center,
      },
      "k8s.replicaset.failed": {
        width: 120,
        align: Align.Center,
      },
      "k8s.replicaset.start_time": {
        width: 120,
        align: Align.Center,
      },
    },
    enableInnerDialog: true,
    primaryColumnKey: "k8s.replicaset.name",
    listGroupBy: ["k8s.replicaset.uid"],
  },
  [SCOPE_PATH_K8S_WORKLOADS_JOBS]: {
    title: "Jobs",
    displayScope: BUILDER_DISPLAY_SCOPE_INFRA_K8S_JOBS_DASH,
    metrics: METRICS_KEY_K8S_JOB,
    timelineMetrics: {
      metrics: [
        {
          label: "CPU Usage",
          key: "k8s.pod.cpu.utilization",
        },
      ],
      groupBy: ["k8s.job.name"],
    },
    searchableColumns: ["k8s.job.name", "k8s.job.uid"],
    defaultColumns: [
      "k8s.job.name",
      "k8s.namespace.name",
      "resource_status",
      "k8s.job.successful_pods",
      "k8s.job.start_time",
    ],
    columnsNames: {
      "k8s.job.uid": "Job UID",
      "k8s.job.name": "Name",
      resource_status: "Resource Status",
      "k8s.job.start_time": "Age",
      "k8s.job.successful_pods": "Successful Pods",
      "k8s.job.active_pods": "Active Pods",
      "k8s.job.failed_pods": "Failed Pods",
      "k8s.job.max_parallel_pods": "Max Parallel Pods",
    },
    enableInnerDialog: true,
    primaryColumnKey: "k8s.job.name",
    listGroupBy: ["k8s.job.uid"],
    columnConfig: {
      "k8s.job.name": {
        width: 250,
      },
      "k8s.namespace.name": {
        width: 250,
      },
      "k8s.cluster.name": {
        width: 250,
      },
      "k8s.job.successful_pods": {
        width: 100,
        align: Align.Center,
      },
      "k8s.job.desired_successful_pods": {
        width: 100,
        align: Align.Center,
      },
      "k8s.job.failed_pods": {
        width: 100,
        align: Align.Center,
      },
      "k8s.job.active_pods": {
        width: 100,
        align: Align.Center,
      },
      "k8s.job.max_parallel_pods": {
        width: 110,
        align: Align.Right,
      },
      "k8s.job.start_time": {
        width: 100,
        align: Align.Center,
      },
      resource_status: {
        width: 100,
        align: Align.Center,
      },
    },
  },
  [SCOPE_PATH_K8S_WORKLOADS_CRONJOBS]: {
    title: "Cron Jobs",
    displayScope: BUILDER_DISPLAY_SCOPE_INFRA_K8S_CRON_JOBS_DASH,
    metrics: METRICS_KEY_K8S_CRONJOB,
    timelineMetrics: {
      metrics: [
        {
          label: "CPU Usage",
          key: "k8s.pod.cpu.utilization",
        },
      ],
      groupBy: ["k8s.pod.name"],
    },
    searchableColumns: ["k8s.cronjob.name", "k8s.cronjob.uid"],
    defaultColumns: [
      "k8s.cronjob.name",
      "k8s.namespace.name",
      "k8s.cronjob.suspend",
      "k8s.cronjob.active_jobs",
      "k8s.cronjob.start_time",
    ],
    columnsNames: {
      "k8s.cronjob.uid": "UID",
      resource_status: "Resource Status",
      "k8s.cronjob.name": "Name",
      "k8s.namespace.name": "Namespace Name",
      "k8s.cronjob.schedule": "Schedule",
      "k8s.cronjob.suspend": "Suspend",
      "k8s.cronjob.active_jobs": "Active Jobs",
      "k8s.cronjob.start_time": "Age",
    },
    enableInnerDialog: true,
    primaryColumnKey: "k8s.cronjob.name",
    listGroupBy: ["k8s.cronjob.uid"],
    columnConfig: {
      "k8s.cronjob.name": {
        width: 250,
      },
      "k8s.namespace.name": {
        width: 250,
      },
      "k8s.cronjob.schedule": {
        width: 100,
        align: Align.Center,
      },
      "k8s.cronjob.suspend": {
        width: 100,
        align: Align.Center,
      },
      "k8s.cronjob.active_jobs": {
        width: 100,
        align: Align.Center,
      },
      "k8s.cronjob.start_time": {
        width: 100,
        align: Align.Center,
      },
    },
  },
  [SCOPE_PATH_K8S_WORKLOADS_DEAMONSETS]: {
    title: "Daemon Sets",
    displayScope: BUILDER_DISPLAY_SCOPE_INFRA_K8S_DEAMONSETS_DASH,
    metrics: METRICS_KEY_K8S_DAEMONSET,
    timelineMetrics: {
      metrics: [
        {
          label: "CPU Usage",
          key: "k8s.pod.cpu.utilization",
        },
      ],
      groupBy: ["k8s.pod.name"],
    },
    searchableColumns: ["k8s.daemonset.name", "k8s.daemonset.uid"],
    defaultColumns: [
      "k8s.daemonset.name",
      "k8s.namespace.name",
      "k8s.cluster.name",
      "k8s.daemonset.ready_nodes",
      "k8s.daemonset.start_time",
    ],
    columnsNames: {
      "k8s.daemonset.uid": "UID",
      "k8s.daemonset.name": "Daemon Set Name",
      "k8s.namespace.name": "Namespace",
      "k8s.cluster.name": "Cluster",
      "k8s.daemonset.start_time": "Age",
      "k8s.daemonset.desired_scheduled_nodes": "Desired Scheduled Nodes",
      "k8s.daemonset.ready_nodes": "Ready Nodes",
      "k8s.daemonset.current_scheduled_nodes": "Current Scheduled Nodes",
    },
    columnConfig: {
      "k8s.namespace.name": {
        width: 250,
      },
      "k8s.cluster.name": {
        width: 250,
      },
      "k8s.daemonset.ready_nodes": {
        width: 100,
        align: Align.Center,
      },
      "k8s.daemonset.desired_scheduled_nodes": {
        width: 100,
        align: Align.Center,
      },
      "k8s.daemonset.start_time": {
        width: 100,
        align: Align.Center,
      },
    },
    listGroupBy: ["k8s.daemonset.uid"],
    enableInnerDialog: true,
    primaryColumnKey: "k8s.daemonset.name",
  },
  [SCOPE_PATH_K8S_WORKLOADS_STATEFULSETS]: {
    title: "Stateful Sets",
    displayScope: BUILDER_DISPLAY_SCOPE_INFRA_K8S_STATEFULSTATES_DASH,
    metrics: METRICS_KEY_K8S_STATEFULSET,
    timelineMetrics: {
      metrics: [
        {
          label: "CPU Usage",
          key: "k8s.pod.cpu.utilization",
        },
      ],
      groupBy: ["k8s.pod.name"],
    },
    searchableColumns: ["k8s.statefulset.name", "k8s.statefulset.uid"],
    defaultColumns: [
      "k8s.statefulset.name",
      "k8s.namespace.name",
      "k8s.cluster.name",
      "k8s.statefulset.current_pods",
      "k8s.statefulset.desired_pods",
      "k8s.statefulset.start_time",
    ],
    columnsNames: {
      "k8s.statefulset.uid": "UID",
      "k8s.statefulset.name": "Name",
      resource_status: "Status",
      "k8s.cluster.name": "Cluster",
      "k8s.namespace.name": "Namespace",
      "k8s.statefulset.ready_pods": "Ready",
      "k8s.statefulset.failed_pods": "Failed",
      "k8s.statefulset.current_pods": "Current Pods",
      "k8s.statefulset.desired_pods": "Desired Pods",
      "k8s.statefulset.start_time": "Age",
    },
    enableInnerDialog: true,
    primaryColumnKey: "k8s.statefulset.name",
    listGroupBy: ["k8s.statefulset.uid"],
    columnConfig: {
      "k8s.statefulset.name": {
        width: 250,
      },
      resource_status: {
        width: 100,
        align: Align.Center,
      },
      "k8s.cluster.name": {
        width: 250,
      },
      "k8s.namespace.name": {
        width: 250,
      },
      "k8s.statefulset.current_pods": {
        width: 100,
        align: Align.Center,
      },
      "k8s.statefulset.desired_pods": {
        width: 100,
        align: Align.Center,
      },
      "k8s.statefulset.ready_pods": {
        width: 100,
        align: Align.Center,
      },
      "k8s.statefulset.start_time": {
        width: 100,
        align: Align.Center,
      },
    },
  },
  [SCOPE_PATH_K8S_WORKLOADS_NAMESPACES]: {
    title: "Namespaces",
    displayScope: BUILDER_DISPLAY_SCOPE_INFRA_K8S_NAMESPACE_DASH,
    metrics: METRICS_KEY_K8S_NAMESPACE,
    timelineMetrics: {
      metrics: [
        {
          label: "CPU Usage",
          key: "k8s.pod.cpu.utilization",
        },
      ],
      groupBy: ["k8s.pod.name"],
    },
    searchableColumns: ["k8s.namespace.name", "k8s.namespace.uid"],
    defaultColumns: [
      "k8s.namespace.name",
      "k8s.cluster.name",
      "k8s.namespace.phase",
      "k8s.namespace.start_time",
    ],
    columnsNames: {
      "k8s.namespace.uid": "UID",
      "k8s.namespace.name": "Name",
      "k8s.namespace.start_time": "Age",
      "k8s.namespace.phase": "Phase",
      "sum(k8s.deployment.available,value(any))": "Available",
      "sum(k8s.deployment.desired,value(any))": "Desired",
      "sum(k8s.pod.cpu_limit_utilization,value(avg))": "CPU",
      "sum(k8s.pod.memory_limit_utilization,value(avg))": "Memory",
    },
    enableInnerDialog: true,
    // TODO: confirm if group should be by uid or name
    // because if same namespace name is there in different clusters
    listGroupBy: ["k8s.namespace.name"],
    primaryColumnKey: "k8s.namespace.name",
    columnConfig: {
      "k8s.namespace.name": {
        width: 400,
      },
      "k8s.cluster.name": {
        width: 400,
      },
      resource_status: {
        width: 150,
        align: Align.Center,
      },
      "k8s.namespace.phase": {
        width: 100,
        align: Align.Center,
      },
      "k8s.namespace.start_time": {
        width: 100,
        align: Align.Center,
      },
      "sum(k8s.pod.cpu_limit_utilization,value(avg))": {
        width: 100,
        align: Align.Center,
      },
      "sum(k8s.pod.memory_limit_utilization,value(avg))": {
        width: 100,
        align: Align.Center,
      },
    },
    customResourceColumns: {
      [METRICS_KEY_K8S_POD]: {
        title: "",
        displayScope: "",
        metrics: "",
        timelineMetrics: {
          metrics: [
            {
              label: "CPU Usage",
              key: "k8s.node.cpu.utilization",
            },
          ],
          groupBy: ["k8s.namespace.name"],
        },
        defaultColumns: [
          "sum(k8s.pod.cpu_limit_utilization,value(avg))",
          "sum(k8s.pod.memory_limit_utilization,value(avg))",
        ],
        searchableColumns: [],
        columnsNames: {
          "sum(k8s.pod.cpu_limit_utilization,value(avg))": "CPU Usage",
          "sum(k8s.pod.memory_limit_utilization,value(avg))": "Memory Usage",
        },
        defaultAggregations: {
          "k8s.pod.cpu.utilization": "sum",
        },
        subAggregations: {
          "k8s.pod.cpu.utilization": "avg",
        },
        listGroupBy: ["k8s.namespace.name"],
        primaryColumnKey: "k8s.namespace.name",
        enableInnerDialog: false,
      },
    },
  },
  [SCOPE_PATH_K8S_CONFIG_INGRESSES]: {
    title: "Ingresses",
    displayScope: BUILDER_DISPLAY_SCOPE_INFRA_K8S_INGRESSES_DASH,
    metrics: METRICS_KEY_K8S_INGRESSES,
    defaultColumns: [
      "k8s.ingress.name",
      "k8s.cluster.name",
      "k8s.ingress.namespace",
      "k8s.ingress.start_time",
      "k8s.ingress.rule_count",
      "k8s.ingress.rules",
    ],
    columnsNames: {
      "k8s.ingress.name": "Ingress Name",
      "k8s.ingress.namespace": "Namespace",
      "k8s.cluster.name": "Cluster",
      "k8s.ingress.start_time": "Age",
      "k8s.ingress.rule_count": "Rule Count",
      "k8s.ingress.rules": "Rules",
    },
    searchableColumns: [
      "k8s.ingress.name",
      "k8s.ingress.namespace",
      "k8s.cluster.name",
      "k8s.ingress.type",
      "k8s.ingress.rules",
    ],
    listGroupBy: ["k8s.ingress.uid"],
    enableInnerDialog: true,
    primaryColumnKey: "k8s.ingress.name",
    timelineMetrics: {
      metrics: [
        {
          label: "Rules",
          key: "k8s.ingress.rule_count",
        },
      ],
      groupBy: ["k8s.ingress.name", "k8s.cluster.name"],
    },
    columnConfig: {
      "k8s.ingress.name": {
        width: 180,
      },
      "k8s.cluster.name": {
        width: 180,
      },
      "k8s.ingress.namespace": {
        width: 180,
      },
      "k8s.ingress.start_time": {
        width: 150,
      },
      "k8s.ingress.rules": {
        width: 269,
      },
    },
  },
  [SCOPE_PATH_K8S_CONFIG_PERSISTENT_VOLUME_CLAIMS]: {
    title: "Persistent Volume Claims",
    displayScope: BUILDER_DISPLAY_SCOPE_INFRA_K8S_PERSISTENT_VOLUME_CLAIMS_DASH,
    metrics: METRICS_KEY_K8S_PERSISTENT_VOLUME_CLAIMS,
    defaultColumns: [
      "k8s.persistentvolumeclaim.name",
      "k8s.cluster.name",
      "k8s.persistentvolumeclaim.namespace",
      "k8s.persistentvolumeclaim.storage_class",
      "k8s.persistentvolumeclaim.phase",
      "k8s.persistentvolumeclaim.volume_name",
      "k8s.persistentvolumeclaim.access_modes",
      "k8s.persistentvolumeclaim.start_time",
      "k8s.persistentvolumeclaim.capacity",
    ],
    columnsNames: {
      "k8s.persistentvolumeclaim.name": "Persistent Volume Claim Name",
      "k8s.cluster.name": "Cluster Name",
      "k8s.persistentvolumeclaim.namespace": "Namespace Name",
      "host.name": "Host Name",
      "k8s.persistentvolumeclaim.storage_class": "Storage Class",
      "k8s.persistentvolumeclaim.phase": "Phase",
      "k8s.persistentvolumeclaim.volume_name": "Persistent Volume",
      "k8s.persistentvolumeclaim.access_modes": "Access Mode",
      "k8s.persistentvolumeclaim.start_time": "Age",
      "k8s.persistentvolumeclaim.capacity": "Capacity",
    },
    searchableColumns: [
      "k8s.persistentvolumeclaim.name",
      "k8s.cluster.name",
      "host.name",
      "k8s.persistentvolumeclaim.namespace",
      "k8s.persistentvolumeclaim.phase",
      "k8s.persistentvolumeclaim.volume_name",
      "k8s.persistentvolumeclaim.volume_mode",
      "k8s.persistentvolumeclaim.access_modes",
      "k8s.persistentvolumeclaim.type",
      "k8s.persistentvolumeclaim.finalizers",
      "k8s.persistentvolumeclaim.labels",
    ],
    listGroupBy: ["k8s.persistentvolumeclaim.uid"],
    enableInnerDialog: true,
    primaryColumnKey: "k8s.persistentvolumeclaim.name",
    timelineMetrics: {
      metrics: [
        {
          label: "Capacity",
          key: "k8s.persistentvolumeclaim.capacity",
        },
      ],
      groupBy: ["k8s.persistentvolumeclaim.name", "k8s.cluster.name"],
    },
    columnConfig: {
      "k8s.persistentvolumeclaim.name": {
        width: 163,
      },
      "k8s.cluster.name": {
        width: 112,
      },
      "k8s.persistentvolumeclaim.namespace": {
        width: 111,
      },
      "k8s.persistentvolumeclaim.storage_class": {
        width: 85,
      },
      "k8s.persistentvolumeclaim.phase": {
        width: 100,
        align: Align.Center,
      },
      "k8s.persistentvolumeclaim.volume_name": {
        width: 135,
      },
      "k8s.persistentvolumeclaim.access_modes": {
        width: 152,
      },
      "k8s.persistentvolumeclaim.start_time": {
        width: 141,
      },
      "k8s.persistentvolumeclaim.capacity": {
        width: 141,
        align: Align.Right,
      },
    },
  },
  [SCOPE_PATH_K8S_CONFIG_PERSISTENT_VOLUMES]: {
    title: "Persistent Volumes",
    displayScope: BUILDER_DISPLAY_SCOPE_INFRA_K8S_PERSISTENT_VOLUMES_DASH,
    metrics: METRICS_KEY_K8S_PERSISTENT_VOLUMES,
    defaultColumns: [
      "k8s.persistentvolume.name",
      "k8s.cluster.name",
      "k8s.persistentvolume.namespace",
      "host.name",
      "k8s.persistentvolume.storage_class",
      "k8s.persistentvolume.phase",
      "k8s.persistentvolume.access_modes",
      "k8s.persistentvolume.start_time",
      "k8s.persistentvolume.capacity",
    ],
    columnsNames: {
      "k8s.persistentvolume.name": "Persistent Volume Name",
      "k8s.cluster.name": "Cluster Name",
      "k8s.persistentvolume.namespace": "Namespace Name",
      "host.name": "Host Name",
      "k8s.persistentvolume.storage_class": "Storage Class",
      "k8s.persistentvolume.phase": "Phase",
      "k8s.persistentvolume.access_modes": "Access Mode",
      "k8s.persistentvolume.start_time": "Age",
      "k8s.persistentvolume.capacity": "Capacity",
    },
    searchableColumns: [
      "k8s.persistentvolume.name",
      "host.name",
      "k8s.persistentvolume.phase",
      "k8s.cluster.name",
      "k8s.persistentvolumeclaim.name",
      "k8s.persistentvolume.access_modes",
      "k8s.persistentvolume.annotations",
      "k8s.persistentvolume.volume_mode",
    ],
    listGroupBy: ["k8s.persistentvolume.uid"],
    enableInnerDialog: true,
    primaryColumnKey: "k8s.persistentvolume.name",
    timelineMetrics: {
      metrics: [
        {
          label: "Capacity",
          key: "k8s.persistentvolume.capacity",
        },
      ],
      groupBy: ["k8s.persistentvolume.name", "k8s.cluster.name"],
    },
    columnConfig: {
      "k8s.persistentvolume.name": {
        width: 280,
      },
      "k8s.persistentvolume.phase": {
        align: Align.Center,
      },
      "k8s.persistentvolume.capacity": {
        align: Align.Right,
      },
    },
  },
  [SCOPE_PATH_K8S_CONFIG_SERVICE_ACCOUNTS]: {
    title: "Service Accounts",
    displayScope: BUILDER_DISPLAY_SCOPE_INFRA_K8S_SERVICE_ACCOUNTS_DASH,
    metrics: METRICS_KEY_K8S_SERVICE_ACCOUNTS,
    defaultColumns: [
      "k8s.serviceaccount.name",
      "k8s.cluster.name",
      "k8s.serviceaccount.namespace",
      "k8s.serviceaccount.secrets",
      "k8s.serviceaccount.start_time",
      "k8s.serviceaccount.secret_count",
    ],
    searchableColumns: [
      "k8s.serviceaccount.name",
      "k8s.cluster.name",
      "k8s.serviceaccount.labels",
      "k8s.serviceaccount.type",
      "k8s.serviceaccount.annotations",
      "k8s.serviceaccount.image_pull_secrets",
      "k8s.serviceaccount.secrets",
    ],
    listGroupBy: ["k8s.serviceaccount.uid"],
    enableInnerDialog: true,
    primaryColumnKey: "k8s.serviceaccount.name",
    timelineMetrics: {
      metrics: [
        {
          label: "Secrets",
          key: "k8s.serviceaccount.secret_count",
        },
      ],
      groupBy: ["k8s.serviceaccount.name", "k8s.cluster.name"],
    },
    columnConfig: {
      "k8s.serviceaccount.name": {
        width: 173,
      },
      "k8s.cluster.name": {
        width: 132,
      },
      "k8s.serviceaccount.namespace": {
        width: 128,
      },
      "k8s.serviceaccount.secrets": {
        width: 269,
      },
      "k8s.serviceaccount.start_time": {
        width: 146,
      },
      "k8s.serviceaccount.secret_count": {
        width: 173,
        align: Align.Right,
      },
    },
  },
  [SCOPE_PATH_K8S_CONFIG_CLUSTER_ROLES]: {
    title: "Cluster Roles",
    displayScope: BUILDER_DISPLAY_SCOPE_INFRA_K8S_CLUSTER_ROLES_DASH,
    metrics: METRICS_KEY_K8S_CLUSTER_ROLES,
    defaultColumns: [
      "k8s.clusterrole.name",
      "k8s.cluster.name",
      "k8s.clusterrole.rules",
      "k8s.clusterrole.rule_count",
      "k8s.clusterrole.start_time",
    ],
    searchableColumns: [
      "k8s.clusterrole.name",
      "k8s.cluster.name",
      "k8s.clusterrole.type",
      "k8s.clusterrole.rules",
    ],
    listGroupBy: ["k8s.clusterrole.uid"],
    enableInnerDialog: true,
    primaryColumnKey: "k8s.clusterrole.name",
    timelineMetrics: {
      metrics: [
        {
          label: "Rules",
          key: "k8s.clusterrole.rule_count",
        },
      ],
      groupBy: ["k8s.clusterrole.name", "k8s.cluster.name"],
    },
    columnConfig: {
      "k8s.clusterrole.name": {
        width: 283,
      },
      "k8s.cluster.name": {
        width: 212,
      },
      "k8s.clusterrole.rules": {
        width: 497,
      },
      "k8s.clusterrole.start_time": {
        width: 146,
      },
      "k8s.clusterrole.rule_count": {
        align: Align.Right,
      },
    },
  },
  [SCOPE_PATH_K8S_CONFIG_ROLES]: {
    title: "Roles",
    displayScope: BUILDER_DISPLAY_SCOPE_INFRA_K8S_ROLES_DASH,
    metrics: METRICS_KEY_K8S_ROLES,
    defaultColumns: [
      "k8s.role.name",
      "k8s.cluster.name",
      "k8s.role.namespace",
      "k8s.role.rules",
      "k8s.role.rule_count",
      "k8s.role.start_time",
    ],
    columnsNames: {
      "k8s.role.name": "Role Name",
      "k8s.cluster.name": "Cluster",
      "k8s.role.namespace": "Namespace",
      "k8s.role.rules": "Rules",
      "k8s.role.start_time": "Age",
    },
    searchableColumns: [
      "k8s.role.name",
      "k8s.cluster.name",
      "k8s.role.namespace",
      "k8s.role.labels",
      "k8s.role.type",
      "k8s.role.rules",
    ],
    listGroupBy: ["k8s.role.uid"],
    enableInnerDialog: true,
    primaryColumnKey: "k8s.role.name",
    timelineMetrics: {
      metrics: [
        {
          label: "Rules",
          key: "k8s.role.rule_count",
        },
      ],
      groupBy: ["k8s.role.name", "k8s.cluster.name"],
    },
    columnConfig: {
      "k8s.role.name": {
        width: 173,
      },
      "k8s.cluster.name": {
        width: 132,
      },
      "k8s.role.namespace": {
        width: 188,
      },
      "k8s.role.rules": {
        width: 499,
      },
      "k8s.role.start_time": {
        width: 146,
      },
      "k8s.role.rule_count": {
        align: Align.Right,
      },
    },
  },
  [SCOPE_PATH_K8S_CONFIG_CLUSTER_ROLE_BINDINGS]: {
    title: "Cluster Role Bindings",
    displayScope: BUILDER_DISPLAY_SCOPE_INFRA_K8S_CLUSTER_ROLE_BINDINGS_DASH,
    metrics: METRICS_KEY_K8S_CLUSTER_ROLE_BINDINGS,
    defaultColumns: [
      "k8s.clusterrolebinding.name",
      "k8s.cluster.name",
      "k8s.clusterrolebinding.role_ref",
      "k8s.clusterrolebinding.subjects",
      "k8s.clusterrolebinding.subject_count",
      "k8s.clusterrolebinding.start_time",
    ],
    columnsNames: {
      "k8s.clusterrolebinding.name": "Cluster Role Binding Name",
      "k8s.cluster.name": "Cluster Name",
      "k8s.clusterrolebinding.role_ref": "Role Ref",
      "k8s.clusterrolebinding.subjects": "Subjects",
      "k8s.clusterrolebinding.start_time": "Age",
    },
    searchableColumns: [
      "k8s.clusterrolebinding.name",
      "k8s.cluster.name",
      "k8s.clusterrolebinding.labels",
      "k8s.clusterrolebinding.annotations",
      "k8s.clusterrolebinding.subjects",
      "k8s.clusterrolebinding.role_ref",
      "k8s.clusterrolebinding.type",
    ],
    listGroupBy: ["k8s.clusterrolebinding.uid"],
    enableInnerDialog: true,
    primaryColumnKey: "k8s.clusterrolebinding.name",
    timelineMetrics: {
      metrics: [
        {
          label: "Subjects",
          key: "k8s.clusterrolebinding.subject_count",
        },
      ],
      groupBy: ["k8s.clusterrolebinding.name", "k8s.cluster.name"],
    },
    columnConfig: {
      "k8s.clusterrolebinding.name": {
        width: 253,
      },
      "k8s.cluster.name": {
        width: 192,
      },
      "k8s.clusterrolebinding.role_ref": {
        width: 278,
      },
      "k8s.clusterrolebinding.subjects": {
        width: 269,
      },
      "k8s.clusterrolebinding.subject_count": {
        align: Align.Right,
      },
      "k8s.clusterrolebinding.start_time": {
        width: 146,
      },
    },
  },
  [SCOPE_PATH_K8S_CONFIG_ROLE_BINDINGS]: {
    title: "Role Bindings",
    displayScope: BUILDER_DISPLAY_SCOPE_INFRA_K8S_ROLE_BINDINGS_DASH,
    metrics: METRICS_KEY_K8S_ROLE_BINDINGS,
    defaultColumns: [
      "k8s.rolebinding.name",
      "k8s.cluster.name",
      "k8s.rolebinding.namespace",
      "k8s.rolebinding.role_ref",
      "k8s.rolebinding.subjects",
      "k8s.rolebinding.subject_count",
      "k8s.rolebinding.start_time",
    ],
    searchableColumns: [
      "k8s.rolebinding.name",
      "k8s.cluster.name",
      "k8s.rolebinding.namespace",
      "k8s.rolebinding.labels",
      "k8s.rolebinding.role_ref",
      "k8s.rolebinding.subjects",
      "k8s.rolebinding.type",
    ],
    listGroupBy: ["k8s.rolebinding.uid"],
    enableInnerDialog: true,
    primaryColumnKey: "k8s.rolebinding.name",
    timelineMetrics: {
      metrics: [
        {
          label: "Subjects",
          key: "k8s.rolebinding.subject_count",
        },
      ],
      groupBy: ["k8s.rolebinding.name", "k8s.cluster.name"],
    },
    columnConfig: {
      "k8s.rolebinding.name": {
        width: 253,
      },
      "k8s.cluster.name": {
        width: 192,
      },
      "k8s.rolebinding.namespace": {
        width: 192,
      },
      "k8s.rolebinding.role_ref": {
        width: 238,
      },
      "k8s.rolebinding.subjects": {
        width: 159,
      },
      "k8s.rolebinding.start_time": {
        width: 146,
      },
      "k8s.rolebinding.subject_count": {
        align: Align.Right,
      },
    },
  },
};
export interface MetricDetails {
  metricName: string;
  attributes: Record<string, string>;
  aggregation: AggMethod;
  subAggregation: AggMethod;
  label: string;
}
export const getColumnName = (details: MetricDetails) => {
  const { metricName, attributes, aggregation, subAggregation } = details;
  const nameDetails: string[] = [];
  let columnName = `${aggregation ? aggregation : "avg"}(`;
  if (metricName) {
    nameDetails.push(metricName);
  } else {
    nameDetails.push(SelectOneMetric);
  }
  if (Object.keys(attributes).length) {
    Object.keys(attributes).map((key) => {
      nameDetails.push(`${key}(${attributes[key]})`);
    });
  }
  columnName += nameDetails.join(",");
  const findValue = getSubAggregationString(columnName);
  if (
    !getSubAggregationString(columnName) &&
    isMetricAggregation(aggregation)
  ) {
    columnName += ",value(" + (subAggregation || aggregation) + ")";
  } else if (isMetricAggregation(aggregation)) {
    columnName = columnName.replace(findValue, "");
    columnName += ",value(" + (subAggregation || aggregation) + ")";
  } else if (!isMetricAggregation(aggregation)) {
    columnName = columnName.replace(findValue, "");
  }
  columnName += ")";
  return columnName;
};
const getSubAggregationString = (columnName: string) => {
  if (columnName.indexOf("value(avg") > -1) {
    return ",value(avg)";
  } else if (columnName.indexOf("value(sum") > -1) {
    return ",value(sum)";
  } else if (columnName.indexOf("value(min") > -1) {
    return ",value(min)";
  } else if (columnName.indexOf("value(max") > -1) {
    return ",value(max)";
  } else {
    return "";
  }
};
const nameIncludesAggregation = (name: string) => {
  const aggregations = AggregationOptions;
  return aggregations.some((agg) => name.startsWith(agg.value + "("));
};
export const isMetricAggregation = (selectedAggregation: string) => {
  const aggregations = ["avg", "sum", "min", "max"];
  return aggregations.find((a) => a === selectedAggregation);
};
export const getMetricDetails = (metric: string): MetricDetails => {
  let metricName = "";
  let attributes: Record<string, string> = {};
  let aggregation: AggMethod = "avg";
  let subAggregation: AggMethod = "avg";
  if (!metric.length) {
    return {
      metricName,
      attributes,
      aggregation,
      subAggregation: "avg",
      label: "",
    };
  }
  if (metric.indexOf("#") !== -1) {
    // format can be
    // system.cpu.utilization#state=user,cpu=cpu0
    const metricParts = metric.split("#");
    metricName = metricParts[0];
    if (metricParts.length > 1) {
      metricParts[1].split(",");
      attributes = metricParts[1].split(",").reduce(
        (acc, curr) => {
          const [key, value] = curr.split("=");
          acc[key] = value;
          return acc;
        },
        {} as Record<string, string>
      );
    }
    aggregation = "avg";
  } else if (nameIncludesAggregation(metric) && metric !== "count(*)") {
    // format of metics can be
    // avg(system.cpu.utilization, state(user), cpu(cpu0))
    const agg = metric.split("(")[0];
    aggregation = agg as AggMethod;
    const firstBracketIndex = metric.indexOf("(");
    const lastBracketIndex = metric.lastIndexOf(")");
    const metricNameWithAttributes = metric.substring(
      firstBracketIndex + 1,
      lastBracketIndex
    );
    const metricNameWithAttributesParts = metricNameWithAttributes.split(",");
    metricName = metricNameWithAttributesParts[0];
    if (metricNameWithAttributesParts.length > 1) {
      attributes = metricNameWithAttributesParts.slice(1).reduce(
        (acc, curr) => {
          const firstBracketIndex = curr.indexOf("(");
          const lastBracketIndex = curr.lastIndexOf(")");
          const attributeKey = curr.substring(0, firstBracketIndex);
          const attributeValue = curr.substring(
            firstBracketIndex + 1,
            lastBracketIndex
          );
          acc[attributeKey] = attributeValue;
          return acc;
        },
        {} as Record<string, string>
      );
    }
  } else {
    metricName = metric;
  }
  Object.keys(attributes).forEach((a) => {
    if (a === "value") {
      subAggregation = attributes[a] as AggMethod;
      delete attributes[a];
    }
  });
  return {
    metricName,
    attributes,
    aggregation,
    subAggregation,
    label: "",
  };
};
export const getDefaultAggregation = (
  metricToCheck: string,
  resource: AvailableResource
): AggMethod => {
  let defaultAggregation: AggMethod = "avg";
  const mDetails = getMetricDetails(metricToCheck);
  resource.table.metrics.forEach((m: MetricItem) => {
    if (m.name === mDetails.metricName) {
      if (
        m.type === MetricTypeEnum.ResourceAttributeKey ||
        m.type === MetricTypeEnum.MetricAttributeKey
      ) {
        defaultAggregation = "any";
      }
    }
  });
  return defaultAggregation;
};
export const getAvailableAggregations = (
  metricType: MetricTypeEnum
): AggregationOptionType[] => {
  return AggregationOptions.filter((op) => {
    return op.excludedMetricType.indexOf(metricType) === -1;
  });
};
export const getToolTipTitle = (
  metricName: string,
  groupName: string,
  metricsCount: number,
  allMetrics: string[],
  columnConfig?: ColumnConfig
): string => {
  const metaData = columnConfig?.[metricName];
  const mDetails = getMetricDetails(metricName);
  let detailsName = mDetails.metricName;
  const uniqqMetrics = allMetrics.filter(
    (m, index) => allMetrics.indexOf(m) === index
  );
  // remove the current metric from the list
  const index = uniqqMetrics.indexOf(metricName);
  if (index > -1) {
    uniqqMetrics.splice(index, 1);
  }
  const isSameMetricThere = uniqqMetrics.find((m) => {
    const md = getMetricDetails(m);
    if (md.metricName === mDetails.metricName) {
      return true;
    }
  });
  if (isSameMetricThere) {
    detailsName = metricName;
  }
  const title = metaData?.title || detailsName || metricName;
  let tooltipTitleKey = groupName + "-" + title;
  if (groupName === DefaultGroupName) {
    tooltipTitleKey = title;
  }
  if (metricsCount == 1 && groupName !== DefaultGroupName) {
    tooltipTitleKey = groupName;
  }
  return tooltipTitleKey;
};
export const getFreshLayout = (
  widgets: CustomWidget[]
): LayoutItem | undefined => {
  const layout: LayoutItem = {
    x: 0,
    y: 0,
    w: 4,
    h: 5,
    _scope_id: -1,
    resizeHandles: ["se"],
  };

  let lastWidgetWidth = 0,
    lastWidgetHeight = 0;
  widgets.forEach((w) => {
    if (!w.layout) return;

    if (
      w.layout.y > layout.y ||
      (w.layout.y === layout.y && w.layout.x >= layout.x)
    ) {
      layout.y = w.layout.y;
      layout.x = w.layout.x;
      lastWidgetWidth = w.layout.w;
      lastWidgetHeight = w.layout.h;
    }
  });

  layout.x = (layout.x + lastWidgetWidth) % 12;
  layout.y =
    layout.x === 0 && lastWidgetHeight > 0
      ? layout.y + lastWidgetHeight
      : layout.y;

  return layout;
};
export const isAggregationSupported = (
  type: MetricTypeEnum,
  selectedAggregation: AggMethod
): boolean => {
  const supportedAggregations = getAvailableAggregations(type);
  return supportedAggregations.some((agg) => agg.value === selectedAggregation);
};
export const getQueryValueData = (
  widgetData: QueryCountDataType | undefined
): number => {
  const queryCountChartData = widgetData;
  if (queryCountChartData && Array.isArray(queryCountChartData)) {
    const values = queryCountChartData.map((item) => {
      return item.value;
    });
    // Consider query value is having only query value
    if (values.length) {
      return values[0];
    }
  }
  return 0;
};
const validateRule = (rule: VisualFormattingRule, value: number): boolean => {
  const arithmeticOperator = sqlOperators.find(
    (op) => op.value === rule.operator
  );
  if (arithmeticOperator?.validateValue) {
    return arithmeticOperator.validateValue({
      originalValue: rule.value,
      valuesToCompare: value,
    });
  }
  return false;
};
export const validateVisualFormattingRules = (
  rules: VisualFormattingRule[],
  value: number
) => rules.filter((rule) => validateRule(rule, value));

export enum ContrastColorEnum {
  Black = "black",
  White = "white",
}
export const getContrastColor = (hexcolor: string): ContrastColorEnum => {
  const r = parseInt(hexcolor.substring(1, 3), 16);
  const g = parseInt(hexcolor.substring(3, 5), 16);
  const b = parseInt(hexcolor.substring(5, 7), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? ContrastColorEnum.Black : ContrastColorEnum.White;
};

export const parseTableStatsData = (
  statsData: string | undefined
): StatsData | undefined => {
  // Ex: 1 {{resource}} up of 1 {{resource}}
  let total = 0,
    filtered = 0;
  const label = "",
    totalLabel = "";
  if (!statsData) {
    return;
  }
  const statsDataParts = statsData.split(" ");
  if (statsDataParts.length > 0) {
    filtered = parseInt(statsDataParts[0]);
  }
  if (statsDataParts.length > 3) {
    total = parseInt(statsDataParts[5]);
    if (isNaN(total)) {
      total = parseInt(statsDataParts[4]);
    }
  }
  return { total, filtered, label, totalLabel };
};
export { _scopes, _scopes_actions };

// generateDashboardKey generates a key for the dashboard based on the label
export const generateDashboardKey = (label: string) =>
  label
    .trim()
    .replace(/[^A-Z\d]/gi, "_")
    .toLocaleLowerCase();

// isValidArithmeticExpression validates the given airthmetic expression
// isValidArithmeticExpression validates the given arithmetic expression
export const isValidArithmeticExpression = (expression: string): boolean => {
  const validCharacters = /^[0-9a-z+\-*/(). ]+$/;
  if (!validCharacters.test(expression)) {
    return false;
  }

  const stack: string[] = [];
  let lastChar = "";
  let dotInCurrentOperand = false;
  const operatorPattern = /[+\-*/]/;
  const operandPattern = /[0-9a-z]/;
  const decimalPointPattern = /\./;

  expression = expression.replace(/\s+/g, "");
  for (let i = 0; i < expression.length; i++) {
    const char = expression[i];
    if (char === "(") {
      if (operandPattern.test(lastChar) || lastChar === ".") {
        return false; // Invalid: Operand or '.' followed directly by '('
      }
      stack.push(char);
      dotInCurrentOperand = false; // Reset dot flag for new sub-expression
    } else if (char === ")") {
      if (
        operatorPattern.test(lastChar) ||
        lastChar === "(" ||
        lastChar === "."
      ) {
        return false; // Invalid: Operator, '(' or '.' before ')'
      }
      if (stack.length === 0) {
        return false; // Invalid: Unmatched closing parenthesis
      }
      stack.pop();
      dotInCurrentOperand = false; // Reset dot flag after sub-expression
    } else if (operatorPattern.test(char)) {
      if (
        operatorPattern.test(lastChar) ||
        lastChar === "(" ||
        lastChar === "" ||
        lastChar === "."
      ) {
        return false; // Invalid: Consecutive operators or operator at start, after '(', or after '.'
      }
      dotInCurrentOperand = false; // Reset dot flag for new operand
    } else if (operandPattern.test(char)) {
      if (
        lastChar === ")" ||
        (operandPattern.test(lastChar) &&
          /\d/.test(lastChar) !== /\d/.test(char))
      ) {
        return false; // Invalid: Operand directly after ')' or another operand
      }
    } else if (decimalPointPattern.test(char)) {
      if (
        lastChar === "" ||
        lastChar === "(" ||
        lastChar === ")" ||
        operatorPattern.test(lastChar) ||
        dotInCurrentOperand
      ) {
        return false; // Invalid: '.' at start, after '(', after ')', after operator, or if dot already in operand
      }
      dotInCurrentOperand = true; // Mark dot as used in current operand
    } else if (char === " ") {
      continue;
    } else {
      return false;
    }
    lastChar = char;
  }

  // Ensure there are no unmatched opening parentheses and expression does not end with an operator or '.'
  return (
    stack.length === 0 && !operatorPattern.test(lastChar) && lastChar !== "."
  );
};

export interface ValidateResponse {
  isValid: boolean;
  msg: string;
}

// validateQuery checks if the query columns i.e. metrics are valid or not.
export const validateQuery = (query: Query): ValidateResponse => {
  if (query.source.name === "billing") {
    if (!query.columns.length)
      return {
        isValid: false,
        msg: "Please select the product",
      };

    if (!query.meta_data?.billingUnit)
      return {
        isValid: false,
        msg: "Please select the unit",
      };

    return {
      isValid: true,
      msg: "",
    };
  }

  if (query.columns.length === 0) {
    return {
      isValid: false,
      msg: "Please select the metric",
    };
  }

  const anyColummnInvalid = query.columns.some((col) => {
    const mDetails = getMetricDetails(col as string);
    return mDetails.metricName === SelectOneMetric;
  });

  return {
    isValid: !anyColummnInvalid,
    msg: !anyColummnInvalid ? "" : "Please select valid metric",
  };
};

// validateQueries checks if the queries are valid or not.
export const validateQueries = (
  builderConfig?: Query | Query[]
): ValidateResponse => {
  if (!Array.isArray(builderConfig)) {
    return {
      isValid: false,
      msg: "Please add the query",
    };
  }
  // Check for duplicate queries
  const hasNoDuplicates = builderConfig.every((query, idx) => {
    let hasDuplicate = false;
    builderConfig.forEach((query1, idx1) => {
      if (idx === idx1) return;
      const same = JSON.stringify(query) === JSON.stringify(query1);
      if (same) hasDuplicate = true;
    });
    return !hasDuplicate;
  });
  if (!hasNoDuplicates)
    return {
      isValid: false,
      msg: "Duplicate queries, please change",
    };

  let validation: ValidateResponse = {
    isValid: true,
    msg: "",
  };
  builderConfig.forEach((q: Query) => {
    const result = validateQuery(q);
    if (!result.isValid) {
      validation = result;
      return;
    }
  });
  return validation;
};

export const isFilterValid = (builderConfig?: Query | Query[]) => {
  if (!Array.isArray(builderConfig)) {
    return {
      isValid: false,
      msg: "Please Add Filters",
    };
  }
  let validation: ValidateResponse = {
    isValid: true,
    msg: "",
  };
  builderConfig.forEach((q: Query) => {
    const result = isQueryFilterValid(q);
    if (!result.isValid) {
      validation = result;
      return;
    }
  });
  return validation;
};

export const isQueryFilterValid = (query: Query): ValidateResponse => {
  const attributeFilter = query.with?.find((w) => w.key === ATTRIBUTE_FILTER);
  if (!attributeFilter || !Object.keys(attributeFilter.value).length)
    return {
      isValid: true,
      msg: "",
    };

  return isValueTypeValid(attributeFilter.value);
};

export const isValueTypeValid = (vt: ValueType): ValidateResponse => {
  const valueObj = vt as Record<string, unknown>;

  for (const key in valueObj) {
    // If the key is not "and/or", then it is the name of the
    // attribute key and must not be Select filter or have empty value.
    if (!["and", "or"].includes(key)) {
      if (!key || key === SelectFilter)
        return {
          isValid: false,
          msg: "Please select the filter",
        };

      // There will be only one entry in the object. "operator-value"
      const filterDetails = valueObj[key] as CustomObject;
      if (!Object.keys(filterDetails).length || !Object.keys(filterDetails)[0])
        return {
          isValid: false,
          msg: "Please select the filter operator",
        };

      const filterValue = Object.values(filterDetails)[0];

      if (!filterValue || (Array.isArray(filterValue) && !filterValue.length))
        return {
          isValid: false,
          msg: "Please select the filter value",
        };

      continue;
    }

    // "and/or" operator will contain the array of filters.
    // Recursively check for "and/or"
    const filters = valueObj[key] as ValueType[];
    for (const filter of filters) {
      const result = isValueTypeValid(filter);
      if (!result.isValid) return result;
    }
  }

  return {
    isValid: true,
    msg: "",
  };
};

export const isGroupByValid = (builderConfig?: Query | Query[]) => {
  if (!Array.isArray(builderConfig)) {
    return {
      isValid: false,
      msg: "Please Add Group By",
    };
  }
  let validation: ValidateResponse = {
    isValid: true,
    msg: "",
  };
  builderConfig.forEach((q: Query) => {
    const result = isQueryGroupByValid(q);
    if (!result.isValid) {
      validation = result;
      return;
    }
  });
  return validation;
};

const isQueryGroupByValid = (query: Query): ValidateResponse => {
  const avg = query.with?.find((w) => w.key === SELECT_DATA_BY);
  if (avg && Array.isArray(avg.value)) {
    const result = avg.value.some((val) => {
      if (val === SelectGroupBy) {
        return true;
      }
    });
    return {
      isValid: !result,
      msg: "Please select group by or remove empty group by",
    };
  }
  return {
    isValid: true,
    msg: "",
  };
};

export const validateFormulas = (
  formulas?: string[],
  builderConfig?: Query | Query[]
): ValidateResponse => {
  if (formulas) {
    for (const formula of formulas) {
      if (!isValidFormula(formula, builderConfig))
        return {
          isValid: false,
          msg: "Invalid formula",
        };
    }
  }

  return {
    isValid: true,
    msg: "",
  };
};

export const validateGraphRequest = (
  widgetBuilder: CustomWidget
): ValidateResponse => {
  if (
    !Array.isArray(widgetBuilder.builderConfig) ||
    !widgetBuilder.builderConfig.length
  )
    return {
      isValid: false,
      msg: "At least one query is required.",
    };

  const chartType = getChartTypeFromAppId(widgetBuilder.widgetAppId);
  switch (chartType) {
    case ChartType.QueryValueChart: {
      if (
        widgetBuilder.builderConfig.length > 1 &&
        !widgetBuilder.formulas?.length
      )
        return {
          isValid: false,
          msg: "Query Value chart can have only one query.",
        };

      if (widgetBuilder.formulas && widgetBuilder.formulas.length > 1)
        return {
          isValid: false,
          msg: "Query Value chart can have only one formula.",
        };

      break;
    }

    case ChartType.ScatterPlotChart: {
      if (
        widgetBuilder.builderConfig.length === 1 &&
        !widgetBuilder.formulas?.length
      )
        return {
          isValid: false,
          msg: "Scatter Plot chart needs to have two queries.",
        };

      if (
        widgetBuilder.builderConfig.length > 1 &&
        widgetBuilder.formulas &&
        widgetBuilder.formulas.length === 1
      )
        return {
          isValid: false,
          msg: "Scatter Plot chart can have only one query and one formula.",
        };

      if (widgetBuilder.formulas && widgetBuilder.formulas.length > 2)
        return {
          isValid: false,
          msg: "Scatter Plot chart can have only two formulas.",
        };

      break;
    }

    case ChartType.TopListChart: {
      if (widgetBuilder.builderConfig.length > 1)
        return {
          isValid: false,
          msg: "Top List chart can have only one query.",
        };

      if (widgetBuilder.formulas && widgetBuilder.formulas.length > 1)
        return {
          isValid: false,
          msg: "Top List chart can have only one formula.",
        };

      break;
    }
  }

  return {
    isValid: true,
    msg: "",
  };
};

export const validateWidgetConfig = (
  widgetBuilder: CustomWidget
): {
  isValid: boolean;
  msg: string;
} => {
  const queriesValidation = validateQueries(widgetBuilder.builderConfig);
  if (!queriesValidation.isValid) return queriesValidation;

  const filterValidation = isFilterValid(widgetBuilder.builderConfig);
  if (!filterValidation.isValid) return filterValidation;

  const groupByValidation = isGroupByValid(widgetBuilder.builderConfig);
  if (!groupByValidation.isValid) return groupByValidation;

  const formulasValidation = validateFormulas(
    widgetBuilder.formulas,
    widgetBuilder.builderConfig
  );
  if (!formulasValidation.isValid) return formulasValidation;

  const graphValidation = validateGraphRequest(widgetBuilder);
  if (!graphValidation.isValid) return graphValidation;

  return {
    isValid: true,
    msg: "",
  };
};

const operandExtractor = new RegExp(/[a-zA-Z]+/g);

// isValidFormula validates the given formula
export const isValidFormula = (
  formula: string,
  builderConfig?: Query | Query[]
) => {
  // Check the formula syntax
  if (!formula.trim() || !isValidArithmeticExpression(formula)) return false;

  // Get the list of available operand (query keys)
  const queryKeys = new Set<string>();
  if (Array.isArray(builderConfig)) {
    builderConfig.forEach((_, idx) => {
      queryKeys.add(String.fromCharCode(idx + "a".charCodeAt(0)));
    });
  }

  // Check the operands of the formula
  const operands = formula.replace(/\s+/g, "").match(operandExtractor);
  if (!operands) return true;

  for (const operand of operands) {
    if (!queryKeys.has(operand)) return false;
  }

  return true;
};

export const isNameValid = (widgetBuilder: CustomWidget): ValidateResponse => {
  const isValid = widgetBuilder.label !== "";
  return {
    isValid: isValid,
    msg: !isValid ? "Please enter a widget name" : "",
  };
};

export const validateAddQueryAction = (
  widgetBuilder: CustomWidget
): ValidateResponse => {
  const chartType = getChartTypeFromAppId(widgetBuilder.widgetAppId);
  const builderConfig = widgetBuilder.builderConfig;
  if (!Array.isArray(builderConfig)) {
    return {
      isValid: false,
      msg: "Please add the query.",
    };
  }

  if (builderConfig.length >= 5) {
    return {
      isValid: false,
      msg: "At most five queries are allowed to add.",
    };
  }

  switch (chartType) {
    case ChartType.QueryValueChart: {
      if (
        builderConfig.length >= 1 &&
        (!widgetBuilder.formulas || widgetBuilder.formulas.length <= 0)
      )
        return {
          isValid: false,
          msg: "Query Value chart can have only one query.",
        };

      break;
    }

    case ChartType.ScatterPlotChart: {
      if (
        builderConfig.length >= 2 &&
        (!widgetBuilder.formulas || widgetBuilder.formulas.length <= 0)
      )
        return {
          isValid: false,
          msg: "Scatter Plot chart can have only two queries.",
        };

      if (
        builderConfig.length >= 1 &&
        widgetBuilder.formulas &&
        widgetBuilder.formulas.length === 1
      )
        return {
          isValid: false,
          msg: "Scatter Plot chart can have only one query and one formula.",
        };

      break;
    }

    case ChartType.TopListChart: {
      if (builderConfig.length >= 1)
        return {
          isValid: false,
          msg: "Top List chart can have only one query",
        };

      break;
    }
  }

  return {
    isValid: true,
    msg: "",
  };
};

export const validateAddFormulaAction = (
  widgetBuilder: CustomWidget
): ValidateResponse => {
  if (widgetBuilder.formulas && widgetBuilder.formulas.length >= 5)
    return {
      isValid: false,
      msg: "At most five formulas are allowed to add.",
    };

  const chartType = getChartTypeFromAppId(widgetBuilder.widgetAppId);
  switch (chartType) {
    case ChartType.QueryValueChart: {
      if (widgetBuilder.formulas && widgetBuilder.formulas.length >= 1)
        return {
          isValid: false,
          msg: "Query Value chart can have only one formula.",
        };

      break;
    }

    case ChartType.ScatterPlotChart: {
      if (widgetBuilder.formulas && widgetBuilder.formulas.length >= 2)
        return {
          isValid: false,
          msg: "Scatter Plot chart can have only two formulas.",
        };

      break;
    }

    case ChartType.TopListChart: {
      if (widgetBuilder.formulas && widgetBuilder.formulas.length >= 1)
        return {
          isValid: false,
          msg: "Top List chart can have only one formula",
        };

      break;
    }
  }

  return {
    isValid: true,
    msg: "",
  };
};

export const validateDeleteQueryAction = (
  widgetBuilder: CustomWidget
): ValidateResponse => {
  if (
    !widgetBuilder.builderConfig ||
    !Array.isArray(widgetBuilder.builderConfig)
  )
    return {
      isValid: true,
      msg: "",
    };

  if (
    Array.isArray(widgetBuilder.builderConfig) &&
    widgetBuilder.builderConfig.length <= 1
  )
    return {
      isValid: false,
      msg: "At least one query is required",
    };

  const chartType = getChartTypeFromAppId(widgetBuilder.widgetAppId);
  switch (chartType) {
    case ChartType.ScatterPlotChart: {
      if (!widgetBuilder.formulas?.length)
        return {
          isValid: false,
          msg: "Scatter Plot chart needs to have two queries.",
        };

      if (
        widgetBuilder.builderConfig.length == 1 &&
        widgetBuilder.formulas &&
        widgetBuilder.formulas.length === 1
      )
        return {
          isValid: false,
          msg: "Scatter Plot chart needs to have a query, if there is only formula",
        };

      break;
    }

    case ChartType.QueryValueChart: {
      if (widgetBuilder.formulas && !widgetBuilder.formulas.length)
        return {
          isValid: false,
          msg: "Query Value chart needs to have one query.",
        };

      break;
    }
  }

  return {
    isValid: true,
    msg: "",
  };
};

export const validateDeleteFormulaAction = (
  widgetBuilder: CustomWidget
): ValidateResponse => {
  if (!widgetBuilder.formulas?.length)
    return {
      isValid: true,
      msg: "",
    };

  if (
    !widgetBuilder.formulas?.length &&
    Array.isArray(widgetBuilder.builderConfig) &&
    widgetBuilder.builderConfig.length <= 0
  )
    return {
      isValid: false,
      msg: "At least one formula or query is required",
    };

  const chartType = getChartTypeFromAppId(widgetBuilder.widgetAppId);
  switch (chartType) {
    case ChartType.QueryValueChart: {
      if (
        Array.isArray(widgetBuilder.builderConfig) &&
        widgetBuilder.builderConfig.length > 1
      )
        return {
          isValid: false,
          msg: "Query Value chart needs to have a formula, if there are more than one query.",
        };

      break;
    }
    case ChartType.ScatterPlotChart: {
      if (
        widgetBuilder.formulas.length === 1 &&
        Array.isArray(widgetBuilder.builderConfig) &&
        widgetBuilder.builderConfig.length === 1
      )
        return {
          isValid: false,
          msg: "Scatter Plot chart needs to have a formula, if there is only one query.",
        };

      if (
        widgetBuilder.formulas.length === 2 &&
        Array.isArray(widgetBuilder.builderConfig) &&
        !widgetBuilder.builderConfig.length
      )
        return {
          isValid: false,
          msg: "Scatter Plot chart needs to have two formulas.",
        };

      if (
        widgetBuilder.formulas.length === 2 &&
        Array.isArray(widgetBuilder.builderConfig) &&
        widgetBuilder.builderConfig.length > 1
      )
        return {
          isValid: false,
          msg: "Scatter Plot chart needs to have two formulas, if there is more than one query.",
        };

      break;
    }
  }

  return {
    isValid: true,
    msg: "",
  };
};

// extractFormulas extracts the formulas from the builderConfig array and store it in the "formulas" key.
export const extractFormulas = (widgetData?: CustomWidget) => {
  if (!widgetData) return;

  const newWidgetBuilder = JSON.parse(
    JSON.stringify(widgetData)
  ) as CustomWidget;

  // Check if builderConfig exists or not
  if (
    !newWidgetBuilder.builderConfig ||
    !Array.isArray(newWidgetBuilder.builderConfig)
  ) {
    return newWidgetBuilder;
  }

  // Get the config which contains the list of formulas
  const formulasConfigIdx = newWidgetBuilder.builderConfig.findIndex(
    (query) =>
      query.with &&
      query.with?.findIndex(
        (condition) => condition.key === SELECT_METRICS_FORMULA
      ) >= 0
  );
  if (formulasConfigIdx < 0) {
    return newWidgetBuilder;
  }

  const formulasConfig = newWidgetBuilder.builderConfig[formulasConfigIdx];
  newWidgetBuilder.builderConfig.splice(formulasConfigIdx, 1);

  // Get the list of formulas from the config
  const formulas = formulasConfig.with?.find(
    (condition) => condition.key === SELECT_METRICS_FORMULA
  )?.value as string[];
  if (!formulas) {
    return newWidgetBuilder;
  }

  newWidgetBuilder.formulas = formulas;
  return newWidgetBuilder;
};

// insertFormulas insert the formulas from the "formulas" key into the builderConfig array.
export const insertFormulas = (widgetData: CustomWidget) => {
  const newWidgetBuilder = JSON.parse(
    JSON.stringify(widgetData)
  ) as CustomWidget;

  if (!widgetData.formulas?.length) return newWidgetBuilder;

  const newFormulaConfig: Query = {
    columns: [],
    source: {
      alias: "",
      dataset_id: 0,
      name: "",
    },
    with: [
      {
        key: SELECT_METRICS_FORMULA,
        value: widgetData.formulas,
        is_arg: false,
      },
    ],
  };

  // Check if builderConfig exists or not
  if (
    !newWidgetBuilder.builderConfig ||
    !Array.isArray(newWidgetBuilder.builderConfig)
  ) {
    newWidgetBuilder.builderConfig = [newFormulaConfig];
    return newWidgetBuilder;
  }

  // Get the config which contains the list of formulas
  const formulasConfigIdx = newWidgetBuilder.builderConfig.findIndex(
    (query) =>
      query.with &&
      query.with?.findIndex(
        (condition) => condition.key === SELECT_METRICS_FORMULA
      ) >= 0
  );
  if (formulasConfigIdx < 0) {
    newWidgetBuilder.builderConfig.push(newFormulaConfig);
  } else {
    newWidgetBuilder.builderConfig[formulasConfigIdx] = newFormulaConfig;
  }

  return newWidgetBuilder;
};

// addKeysToQueries add the unique identifier to each query
export const addKeysToQueries = (widgetData: CustomWidget) => {
  const widgetDataCopy = JSON.parse(JSON.stringify(widgetData)) as CustomWidget;
  if (Array.isArray(widgetDataCopy.builderConfig)) {
    widgetDataCopy.builderConfig.forEach((query, idx) => {
      query.key = String.fromCharCode(idx + "a".charCodeAt(0));
    });
  }
  return widgetDataCopy;
};
