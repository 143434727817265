import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import classNames from "classnames";
import { ArrowLeftSvg } from "core/components/svg/icons";
import EventEmitter from "core/utils/events";
import { getThemesMode } from "store/selectors";
import SidebarPlaceholder from "views/layouts/app/sidebar/loader.placeholder";
interface MainSidebarProps {
  className?: string;
  children: React.ReactNode;
}
const MainSidebar: React.FC<MainSidebarProps> = (props: MainSidebarProps) => {
  const { className, children } = props;
  const themeMode = useSelector(getThemesMode);
  const [isOpen, setIsOpen] = useState(true);
  const [dragging, setDragging] = useState(false);
  const [initialWidth, setInitialWidth] = useState(200);
  const [toggleBtnLeft, setToggleBtnLeft] = useState(250);

  const sidebarDivRef = useRef<HTMLDivElement>(null);

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (sidebarDivRef.current) {
      setDragging(true);
      setInitialWidth(sidebarDivRef.current.offsetWidth);
    }
  };

  const handleMouseMove = (event: MouseEvent) => {
    event.preventDefault();
    if (dragging && sidebarDivRef.current) {
      const newWidth = event.clientX;

      if (newWidth >= 200 && newWidth <= 750) {
        sidebarDivRef.current.style.width = `${newWidth}px`;
        setToggleBtnLeft(sidebarDivRef.current.offsetWidth + 50);
      }
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);

    if (sidebarDivRef.current) {
      resizeObserver.observe(sidebarDivRef.current);
    }
  };

  useEffect(() => {
    if (dragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [dragging]);

  const resizeObserver = new ResizeObserver((entries) => {
    for (const entry of entries) {
      if (entry.target === sidebarDivRef.current) {
        setToggleBtnLeft(entry.contentRect.width + 50);
      }
    }
  });

  return (
    <React.Fragment>
      <button
        className={classNames("sidebar__toggle-action", "resizable", {
          is__collapse: !isOpen,
        })}
        onClick={() => {
          EventEmitter.dispatch("sidebar_toggle__action", !isOpen);
          setIsOpen(!isOpen);
        }}
        style={{
          left: isOpen ? `${toggleBtnLeft}px` : "50px",
        }}
      >
        <ArrowLeftSvg themeMode={themeMode} />
      </button>

      <div
        ref={sidebarDivRef}
        style={{ width: `${initialWidth}px` }}
        className={classNames("main-sidebar__context", className, {
          is__open: isOpen,
          is__dark: themeMode !== "light",
        })}
      >
        <React.Suspense fallback={<SidebarPlaceholder />}>
          <div className={"sidebar-inner__context"}>{children}</div>
        </React.Suspense>
        <div
          className={`resizer resizer-verticle ${dragging ? "resizer-dragging" : ""}`}
          onMouseDown={handleMouseDown}
        ></div>
      </div>
    </React.Fragment>
  );
};
export default MainSidebar;
