/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
import ChartsV2 from "core/components/v2/charts";
import { ChartType, NULL_SERIES } from "core/components/v2/charts/models";
import { granularityInterval } from "core/utils/chart_helper";
import dayjs from "dayjs";
import {
  PointOptionsObject,
  SeriesAreaOptions,
  SeriesColumnOptions,
  SeriesOptionsType,
} from "highcharts";
import React, { useContext, useMemo } from "react";
import MwRouteContext from "views/layouts/app/routes/MWRouteContext";
import { DateRange } from "views/layouts/app/routes/model";
import { getMetricDetails } from "../../../../../views/modules/builder/common/utils";
import {
  BuilderNestedProps,
  IsAreaChart,
  IsBarChart,
  IsStackedBarChart,
  ResourceViewOptions,
  TimeSeriesData,
  getIntValue,
} from "../../../../../views/modules/builder/entities/builder.entities";
import { RenderTpl } from "../../../../../views/modules/builder/entities/tpl.entities";
import { BuilderChartPoint, DefaultGroupName } from "./entities";

interface BaseChartProps {
  timeseriesData: TimeSeriesData;
  chartType:
    | "column"
    | "area"
    | "spline"
    | "pie"
    | "scatter"
    | "treemap"
    | "heatmap"
    | "tilemap"
    | string;
  resource?: ResourceViewOptions;
  nestedProps?: BuilderNestedProps;
  xAxisCustomFormat?: (val: string | number) => string;
  onDateChange?: (range: DateRange) => void;
}
interface IdxElementType {
  dataToRender: Record<string, number | null>;
  timestamp: number | string;
}
const noData = {
  key: "-1",
  label: "No data found during this current time period.",
  color: "red",
};

const TimeseriesChartV2 = ({
  chartType,
  resource,
  timeseriesData,
  nestedProps,
  onDateChange,
}: BaseChartProps) => {
  const routeData = useContext(MwRouteContext);

  // Get the charttype
  if (nestedProps?.chartType) chartType = nestedProps.chartType;

  // Get the time range
  const from =
    timeseriesData?.timeRange?.fromTs || routeData.params.dateRange.fromTs || 0;
  const to =
    timeseriesData?.timeRange?.toTs || routeData.params.dateRange.toTs || 0;

  const colorGroups = useMemo(() => {
    const colorGroups: Record<string, string> = {};
    if (!nestedProps?.columnConfig) return colorGroups;

    Object.entries(nestedProps.columnConfig).forEach(([mName, mConfig]) => {
      if (mConfig.color) {
        colorGroups[mName] = mConfig.color;
      }
    });
    return colorGroups;
  }, [JSON.stringify(nestedProps?.columnConfig)]);

  const chartTypeProp = useMemo(() => {
    if (IsBarChart(chartType) || IsStackedBarChart(chartType))
      return ChartType.COLUMN;
    if (IsAreaChart(chartType)) return ChartType.AREA;
    return ChartType.LINE;
  }, [chartType]);

  const chartData = useMemo(() => {
    const points: BuilderChartPoint[] = [];
    const processedData = timeseriesData || {};
    let max = 0;
    let config: RenderTpl | undefined = undefined;
    const chartIdxData: Record<string, IdxElementType> = {};
    const metricNamesGroups: Record<string, number> = {};
    const metricNamesConfigs: Record<string, RenderTpl> = {};
    if (processedData && Object.keys(processedData.chartGroups).length > 0) {
      Object.keys(processedData.chartGroups).forEach((groupName) => {
        if (groupName === "-1") {
          return;
        }
        const metricGroup = processedData.chartGroups[groupName] || {};
        const metricsCount = Object.keys(metricGroup).length;
        Object.keys(metricGroup).forEach((metricName) => {
          let color: string | undefined = undefined;
          let colorGroupName = groupName;
          if (colorGroupName === DefaultGroupName) {
            colorGroupName = metricName;
          }
          if (colorGroups?.[colorGroupName] || colorGroups?.[metricName]) {
            color = colorGroups[colorGroupName] || colorGroups?.[metricName];
          }
          let tooltipTitleKey = "";
          if (nestedProps?.columnConfig?.[metricName]?.title) {
            tooltipTitleKey +=
              groupName + "-" + nestedProps?.columnConfig?.[metricName]?.title;
          } else {
            tooltipTitleKey = groupName + "-" + metricName;
          }

          if (metricsCount == 1) {
            tooltipTitleKey = groupName;
          }
          if (groupName === DefaultGroupName) {
            const mDetails = getMetricDetails(metricName);
            const configName = nestedProps?.columnConfig?.[metricName]?.title;
            tooltipTitleKey = configName
              ? configName
              : mDetails.metricName || metricName;
          }
          const tplConfig =
            nestedProps?.columnConfig?.[groupName]?.tpl ||
            nestedProps?.columnConfig?.[metricName]?.tpl;
          points.push({
            dataKey:
              tooltipTitleKey === noData.key ? noData.label : tooltipTitleKey,
            title: groupName === noData.key ? noData.label : groupName,
            color: tooltipTitleKey === noData.key ? noData.color : color,
          });

          metricNamesGroups[tooltipTitleKey] = 0;
          if (metricGroup[metricName]?.length) {
            metricGroup[metricName].forEach((record, recordIndex) => {
              if (recordIndex === 0 && !config && record.config.type) {
                config = record.config;
              }
              if (tplConfig?.type) {
                config = tplConfig;
              }
              if (config?.type) {
                metricNamesConfigs[tooltipTitleKey] = config;
              }
              metricNamesGroups[tooltipTitleKey] = 0;
              const timestamp = record.timestamp;
              let idxElement: IdxElementType = chartIdxData[timestamp];
              if (!idxElement) {
                idxElement = {
                  timestamp: timestamp,
                  dataToRender: {},
                };
              }
              if (groupName === noData.key) {
                idxElement.dataToRender[noData.label] = 0;
                chartIdxData[timestamp] = idxElement;
              } else {
                const chartValue =
                  Number(record.value) >= 0
                    ? getIntValue(record.value)
                    : undefined;
                if (typeof chartValue !== "undefined" && chartValue >= 0) {
                  idxElement.dataToRender[tooltipTitleKey] =
                    Number(record.value) >= 0 ? getIntValue(record.value) : 0;
                  if (chartValue && max < chartValue) {
                    max = chartValue;
                  }
                }

                chartIdxData[timestamp] = idxElement;
              }
            });
          } else {
            const timestamp = from;
            chartIdxData[timestamp] = {
              timestamp,
              dataToRender: {},
            };
          }
        });
      });
    }

    const int = timeseriesData?.timeRange?.interval
      ? timeseriesData?.timeRange?.interval
      : granularityInterval(from, to);
    const interval = int * 1000;
    if (chartIdxData && Object.keys(chartIdxData).length) {
      // This loop will fill the empty points with null values for timestamps available in the range
      const fromTs = Math.round(from / interval) * interval;
      const toTs = Math.round(to / interval) * interval;
      const steps = Math.ceil((toTs - fromTs) / interval);
      for (let i = 0; i <= steps - 2; i++) {
        const timestamp = fromTs + (i + 1) * interval;
        if (typeof chartIdxData[timestamp] !== "undefined") {
          if (
            Object.keys(chartIdxData[timestamp]).length - 2 !=
            Object.keys(metricNamesGroups).length
          ) {
            Object.keys(metricNamesGroups).forEach((metricName) => {
              if (
                chartIdxData[timestamp] &&
                chartIdxData[timestamp].dataToRender[metricName] === undefined
              ) {
                chartIdxData[timestamp].dataToRender[metricName] = null;
              }
            });
          }
        } else {
          const idxElement: IdxElementType = {
            timestamp: timestamp,
            dataToRender: {},
          };
          Object.keys(metricNamesGroups).forEach((metricName) => {
            idxElement.dataToRender[metricName] = null;
          });
          chartIdxData[timestamp] = idxElement;
        }
      }
    }
    /**
     * Used any because of un even type of every different keys, and we have random keys
     */
    const objData: Record<
      string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [string | number, number | null][]
    > = {};
    Object.keys(chartIdxData)
      .sort()
      .reduce((obj: Record<string, IdxElementType>, timestmap) => {
        obj[timestmap] = chartIdxData[timestmap];
        if (
          chartIdxData?.[timestmap] &&
          Object.keys(chartIdxData[timestmap].dataToRender as object).length
        ) {
          Object.keys(chartIdxData[timestmap].dataToRender as object).map(
            (it) => {
              if (objData[it]?.length) {
                objData[it].push([
                  !Number.isNaN(Number(timestmap))
                    ? Number(timestmap)
                    : timestmap,
                  chartIdxData[timestmap].dataToRender[it],
                ]);
              } else {
                objData[it] = [
                  [
                    !Number.isNaN(Number(timestmap))
                      ? Number(timestmap)
                      : timestmap,
                    chartIdxData[timestmap].dataToRender[it],
                  ],
                ];
              }
            }
          );
        }

        return obj;
      }, {});
    const series: SeriesOptionsType[] = [];
    Object.keys(objData).map((key) => {
      const foundKey = points.findIndex((i) => i.dataKey === key);
      if (foundKey > -1) {
        const obj: SeriesOptionsType = {
          showInLegend: false,
          // `type: column` is required for type-checking this options as a column series
          type:
            IsBarChart(chartType) || IsStackedBarChart(chartType)
              ? "column"
              : IsAreaChart(chartType)
                ? "areaspline"
                : "spline",
          name: key,
          data: objData[key],
          color: points[foundKey].color,
          marker: {
            enabled: true,
            radius: 1,
            symbol: "circle",
            states: {
              hover: {
                enabled: true,
                radius: 5, // Increase the radius on hover for better visibility
              },
            },
          },
          showInNavigator: true,
          dataGrouping: {
            units: [
              ["day", [1]],
              ["hours", [1]],
              ["minutes", [1]],
            ],
            forced: true,
            enabled: true,
            groupAll: true,
          },
        };

        if (IsStackedBarChart(chartType) || nestedProps?.stacked) {
          series.push({
            ...obj,
            stacking: "normal",
            stack: 1,
          } as SeriesColumnOptions);
        } else if (IsAreaChart(chartType)) {
          series.push({
            ...obj,
          } as SeriesAreaOptions);
        } else {
          series.push({
            ...obj,
            stacking: undefined,
          } as SeriesColumnOptions);
        }
      }
    });

    const nullSeries: Record<string, number> = {};
    for (const element of series) {
      const newEle: SeriesOptionsType & {
        data?: PointOptionsObject | (number | object | unknown[])[] | undefined;
      } = { ...element };

      if (!Array.isArray(newEle.data)) continue;

      for (const ele of newEle.data) {
        if (!Array.isArray(ele)) continue;

        const timestamp = Number(ele[0]);
        const value =
          typeof ele[1] === "number" && !isNaN(ele[1])
            ? Number(ele[1])
            : undefined;

        // If value is undefined, add it to null series.
        if (typeof value === "undefined" && !nullSeries[timestamp])
          nullSeries[timestamp] = timestamp;
        // If value is there and, entry for the timestamp is present in the nullSeries (due to other group data), remove it.
        if (typeof value !== "undefined" && nullSeries[timestamp])
          delete nullSeries[timestamp];
      }
    }

    // Will be used to show empty points in the chart with No Data line
    if (nullSeries && Object.keys(nullSeries).length) {
      const scatterData = Object.values(nullSeries);
      const find = series.find((it) => it.name === NULL_SERIES);

      if (!find) {
        series.push({
          type: "scatter",
          name: NULL_SERIES,
          showInLegend: false,
          enableMouseTracking: true,
          data: scatterData.map((it) => [it, 0, {}]),
          lineWidth: 0,
          marker: {
            enabled: false,
            states: {
              hover: {
                lineWidthPlus: 0,
                enabled: false,
              },
            },
          },
        });
      }
    }
    let format = "HH:mm";
    const timestamps = Object.keys(chartIdxData).map((i) => {
      const ii = parseInt(i);
      return chartIdxData[ii].timestamp;
    });
    const first = timestamps[0];
    const last = timestamps[timestamps.length - 1];
    const diffInMinutes = (Number(last) - Number(first)) / 60000;
    if (diffInMinutes < 60) {
      format = "HH:mm:ss";
    } else if (diffInMinutes < 1440) {
      format = "HH:mm";
    } else {
      format = "MMM DD HH:mm";
    }
    return {
      max,
      points,
      yAxisConfig: config,
      series,
      format,
    };
  }, [
    chartType,
    JSON.stringify(timeseriesData),
    JSON.stringify(nestedProps?.columnConfig),
    nestedProps?.stacked,
  ]);

  return chartData ? (
    <ChartsV2
      chartType={chartTypeProp}
      series={chartData.series}
      yAxsisVisible
      resource={resource}
      stacked={nestedProps?.stacked}
      showLegend={nestedProps?.showLegend}
      yAxisConfig={chartData.yAxisConfig}
      yAxsisTitle={nestedProps?.yAxisTitle}
      xAxisFormatter={function (ctx) {
        return dayjs(ctx.value).format(chartData.format);
      }}
      chartHeight={nestedProps?.chartHeight}
      maxYAxisValue={chartData.max}
      timeseriesChartOptions={nestedProps?.timeseriesChartOptions}
      onDateChange={onDateChange}
    />
  ) : null;
};

export default TimeseriesChartV2;
