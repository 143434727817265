import { createReducer } from "core/utils/create_reducer";
import { combineReducers } from "redux";
import {
  FETCH_METRICS_LIST_ERROR,
  FETCH_METRICS_LIST_LOADING,
  FETCH_METRICS_LIST_SUCCESS,
  FETCH_METRICS_PIPELINE_RULES_ERROR,
  FETCH_METRICS_PIPELINE_RULES_LOADING,
  FETCH_METRICS_PIPELINE_RULES_SUCCESS,
  FETCH_METRIC_FILTER_LIST_ERROR,
  FETCH_METRIC_FILTER_LIST_LOADING,
  FETCH_METRIC_FILTER_LIST_SUCCESS,
  FETCH_METRIC_INFO_ERROR,
  FETCH_METRIC_INFO_LOADING,
  FETCH_METRIC_INFO_SUCCESS,
  UPDATED_METRICS_PIPELINE_RULES,
} from "./constants";
import { FilterListResp, Metric, MetricListResp } from "./entities";

export interface MetricsListState {
  inflight: boolean;
  total?: number;
  page?: number;
  limit?: number;
  metrics: Metric[];
  error: string | null;
}

export interface MetricsFiltersState {
  inflight: boolean;
  resources: string[];
  error: string | null;
}

const initialMetricsListState: MetricsListState = {
  inflight: false,
  metrics: [],
  error: null,
};

const metricsListReducer = createReducer(initialMetricsListState, {
  [FETCH_METRICS_LIST_LOADING]: (state: MetricsListState) => {
    state.inflight = true;
    return { ...state };
  },
  [FETCH_METRICS_LIST_SUCCESS]: (
    state: MetricsListState,
    action: {
      resp: MetricListResp;
    }
  ) => {
    state = {
      inflight: false,
      total: action.resp.total,
      page: action.resp.offset,
      limit: action.resp.limit,
      metrics: action.resp.metrics,
      error: null,
    };
    return { ...state };
  },
  [FETCH_METRICS_LIST_ERROR]: (
    state: MetricsListState,
    action: {
      error: string;
    }
  ) => {
    state.inflight = false;
    state.error = action.error;
    return { ...state };
  },
});

const initialMetricsFiltersListState: MetricsFiltersState = {
  inflight: false,
  resources: [],
  error: null,
};

const metricsFiltersListReducer = createReducer(
  initialMetricsFiltersListState,
  {
    [FETCH_METRIC_FILTER_LIST_LOADING]: (state: MetricsFiltersState) => {
      state.inflight = true;
      return { ...state };
    },
    [FETCH_METRIC_FILTER_LIST_SUCCESS]: (
      state: MetricsFiltersState,
      action: {
        resp: FilterListResp;
      }
    ) => {
      state = {
        inflight: false,
        resources: action.resp.resources,
        error: null,
      };
      return { ...state };
    },
    [FETCH_METRIC_FILTER_LIST_ERROR]: (
      state: MetricsFiltersState,
      action: {
        error: string;
      }
    ) => {
      state.inflight = false;
      state.error = action.error;
      return { ...state };
    },
  }
);

export type MetricsInfoState = Record<
  string,
  {
    inflight: boolean;
    metric?: Metric;
    error?: string;
  }
>;

const initialMetricsInfoState: MetricsInfoState = {};

const metricsInfoReducer = createReducer(initialMetricsInfoState, {
  [FETCH_METRIC_INFO_LOADING]: (
    state: MetricsInfoState,
    action: {
      metricKey: string;
    }
  ) => {
    state[action.metricKey] = {
      inflight: true,
    };
    return JSON.parse(JSON.stringify(state)) as MetricsInfoState;
  },
  [FETCH_METRIC_INFO_SUCCESS]: (
    state: MetricsInfoState,
    action: {
      metricKey: string;
      data: Metric;
    }
  ) => {
    state[action.metricKey] = {
      inflight: false,
      metric: action.data,
    };
    return JSON.parse(JSON.stringify(state)) as MetricsInfoState;
  },
  [FETCH_METRIC_INFO_ERROR]: (
    state: MetricsInfoState,
    action: {
      metricKey: string;
      error: string;
    }
  ) => {
    state[action.metricKey] = {
      inflight: false,
      error: action.error,
    };
    return JSON.parse(JSON.stringify(state)) as MetricsInfoState;
  },
});

const initialMetricsPipelineRulesState: MetricsListState = {
  inflight: false,
  metrics: [],
  error: null,
};

const metricsPipelineRulesReducer = createReducer(
  initialMetricsPipelineRulesState,
  {
    [FETCH_METRICS_PIPELINE_RULES_LOADING]: (state: MetricsListState) => {
      state.inflight = true;
      return { ...state };
    },
    [FETCH_METRICS_PIPELINE_RULES_SUCCESS]: (
      state: MetricsListState,
      action: {
        resp: MetricListResp;
      }
    ) => {
      state = {
        inflight: false,
        total: action.resp.total,
        page: action.resp.offset,
        limit: action.resp.limit,
        metrics: action.resp.metrics,
        error: null,
      };
      return { ...state };
    },
    [FETCH_METRICS_PIPELINE_RULES_ERROR]: (
      state: MetricsListState,
      action: {
        error: string;
      }
    ) => {
      state.inflight = false;
      state.error = action.error;
      return { ...state };
    },
    [UPDATED_METRICS_PIPELINE_RULES]: (
      state: MetricsListState,
      action: {
        rules: Record<string, boolean>;
      }
    ) => {
      state.metrics.forEach((metric) => {
        if (
          Object.hasOwn(action.rules, metric.setting_key) &&
          metric.metadata
        ) {
          metric.metadata.enabled = action.rules[metric.setting_key];
        }
      });
      return { ...state };
    },
  }
);

export default combineReducers({
  metricsList: metricsListReducer,
  metricsInfo: metricsInfoReducer,
  metricsFilters: metricsFiltersListReducer,
  metricsPipelineRules: metricsPipelineRulesReducer,
});
