import { RoutesPatterns } from "core/utils/route_schema";
import * as React from "react";

const SettingsRoutes: RoutesPatterns = {
  moduleName: "settings",
  slug: "/settings",
  sidebar: React.lazy(() => import("views/modules/settings-v2/sidebar")),
  // breadcrumbs: React.lazy(() => import("views/modules/settings/breadcrumbs")),
  items: [
    {
      pattern: ["/"],
      component: React.lazy(
        () => import("views/modules/settings-v2/profile/index")
      ),
      breadcrumbTitle: "Profile",
    },
    {
      pattern: ["/create", "/edit/:id"],
      component: React.lazy(() => import("views/modules/settings/create")),
    },
    {
      pattern: ["/list"],
      component: React.lazy(() => import("views/modules/settings/list")),
    },
    {
      pattern: ["/role"],
      component: React.lazy(
        () => import("views/modules/settings-v2/role/index")
      ),
      breadcrumbTitle: "Roles & Permissions",
    },
    {
      pattern: ["/role/create", "/role/:id"],
      component: React.lazy(() => import("views/modules/settings/role/create")),
    },
    {
      pattern: ["/user"],
      component: React.lazy(
        () => import("views/modules/settings-v2/users/index")
      ),
      breadcrumbTitle: "Users",
    },
    {
      pattern: ["/api-keys"],
      component: React.lazy(() => import("views/modules/settings-v2/api-keys")),
      breadcrumbTitle: "API Keys",
    },
    {
      pattern: ["/audit-logs"],
      component: React.lazy(
        () => import("views/modules/settings/audit-logs/index")
      ),
      breadcrumbTitle: "Audit Logs",
    },
    {
      pattern: ["/billing"],
      component: React.lazy(
        () => import("views/modules/settings-v2/billing/index")
      ),
      breadcrumbTitle: "Billing",
    },
    {
      pattern: ["/billing/usages"],
      component: React.lazy(
        () => import("views/modules/settings/billing-usages/billing-usage")
      ),
      breadcrumbTitle: "Billing",
    },
    {
      pattern: ["/usage"],
      component: React.lazy(
        () => import("views/modules/settings-v2/usages/index")
      ),
      breadcrumbTitle: "Usage",
    },
    {
      pattern: ["/data-management"],
      component: React.lazy(
        () => import("views/modules/settings/data-management/index")
      ),
      breadcrumbTitle: "Data Management",
    },
    {
      pattern: ["/compliances"],
      component: React.lazy(
        () => import("views/modules/settings/legal-compliances/index")
      ),
      breadcrumbTitle: "Legal & Compliances",
    },
    {
      pattern: ["/organization"],
      component: React.lazy(
        () => import("views/modules/settings/organization/index")
      ),
      breadcrumbTitle: "Organization",
    },
    {
      pattern: ["/preferences"],
      component: React.lazy(
        () => import("views/modules/settings/preferences/index")
      ),
      breadcrumbTitle: "Preferences",
    },
    {
      pattern: ["/sso"],
      component: React.lazy(() => import("views/modules/settings/sso/index")),
      breadcrumbTitle: "SSO",
    },
    {
      pattern: ["/teams"],
      component: React.lazy(
        () => import("views/modules/settings-v2/teams/index")
      ),
      breadcrumbTitle: "Teams",
    },
    {
      pattern: ["/ingestion-controls"],
      component: React.lazy(
        () => import("views/modules/settings-v2/ingestion-control")
      ),
      breadcrumbTitle: "Ingestion Controls",
    },
    {
      pattern: ["/projects"],
      component: React.lazy(
        () => import("views/modules/settings-v2/projects/index")
      ),
      breadcrumbTitle: "Projects",
    },
    {
      pattern: ["/pipeline"],
      component: React.lazy(
        () => import("views/modules/settings-v2/pipeline/index")
      ),
      breadcrumbTitle: "Pipeline",
    },
  ],
};
export default SettingsRoutes;
