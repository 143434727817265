export const SLACK_AUTH_URL = "SLACK_AUTH_URL";
export const VERCEL_DETAILS_RECEIVED = "VERCEL_DETAILS_RECEIVED";
export const VERCEL_DETAILS_INFLIGHT = "VERCEL_DETAILS_INFLIGHT";
export const VERCEL_PROJECTS_RECEIVED = "VERCEL_PROJECTS_RECEIVED";
export const VERCEL_STATUS_RECEIVED = "VERCEL_STATUS_RECEIVED";
export const AWS_CREDENTIALS_STATUS_RECEIVED =
  "AWS_CREDENTIALS_STATUS_RECEIVED";
export const AWS_CREDENTIALS_STATUS_RESET = "AWS_CREDENTIALS_STATUS_RESET";
export const AWS_VALIDATION_DETAILS_INFLIGHT =
  "AWS_VALIDATION_DETAILS_INFLIGHT";
export const OKTA_DETAIL_RECEIVED = "OKTA_DETAIL_RECEIVED";
