import * as React from "react";

import { RoutesPatterns } from "core/utils/route_schema";

const GuidelinesRoutes: RoutesPatterns = {
  moduleName: "guidelines-V2",
  slug: "/guidelines/v2",
  sidebar: React.lazy(() => import("views/modules/guidelines-v2/sidebar")),
  items: [
    {
      pattern: ["/", "/*"],
      breadcrumbTitle: "Guidelines v2",
      component: React.lazy(() => import("views/modules/guidelines-v2")),
    },
    {
      pattern: ["/buttons"],
      breadcrumbTitle: "Buttons",
      component: React.lazy(
        () => import("core/components/v2/button/button-example")
      ),
    },
    {
      pattern: ["/cards"],
      breadcrumbTitle: "Cards",
      component: React.lazy(
        () => import("core/components/v2/card/card.example")
      ),
    },
    {
      pattern: ["/status-badge"],
      breadcrumbTitle: "Status Badge",
      component: React.lazy(
        () => import("core/components/v2/status-badge/status-badge-example")
      ),
    },
    {
      pattern: ["/progress-bar"],
      breadcrumbTitle: "Progress Bars",
      component: React.lazy(
        () => import("core/components/v2/progress-bar/progress-bar-example")
      ),
    },
    {
      pattern: ["/form"],
      breadcrumbTitle: "Form",
      component: React.lazy(
        () => import("core/components/v2/form/form-example")
      ),
    },
    {
      pattern: ["/nav-tab"],
      breadcrumbTitle: "Nav Tab",
      component: React.lazy(
        () => import("core/components/v2/nav-tab/nav-tab-example")
      ),
    },
    {
      pattern: ["/charts"],
      breadcrumbTitle: "Charts",
      component: React.lazy(
        () => import("core/components/v2/charts/charts.example")
      ),
    },
    {
      pattern: ["/loader"],
      breadcrumbTitle: "Loader",
      component: React.lazy(
        () => import("core/components/v2/loader/loader-example")
      ),
    },
    {
      pattern: ["/accordion"],
      breadcrumbTitle: "Accordion",
      component: React.lazy(
        () => import("core/components/v2/accordion/accordion-example")
      ),
    },
    {
      pattern: ["/popover"],
      breadcrumbTitle: "Popover",
      component: React.lazy(() => import("core/components/v2/popover/example")),
    },
    {
      pattern: ["/date-picker"],
      breadcrumbTitle: "Date Picker",
      component: React.lazy(
        () => import("core/components/v2/date-picker/date-picker-example")
      ),
    },
    {
      pattern: ["/tooltip"],
      breadcrumbTitle: "tooltip",
      component: React.lazy(
        () => import("core/components/v2/tooltip/tooltip-example")
      ),
    },
    {
      pattern: ["/action-menu"],
      breadcrumbTitle: "Action Menu",
      component: React.lazy(
        () => import("core/components/v2/action-menu/example")
      ),
    },
    {
      pattern: ["/dropdown"],
      breadcrumbTitle: "Dropdown",
      component: React.lazy(
        () => import("core/components/v2/dropdown/dropdown-example")
      ),
    },
    {
      pattern: ["/table-view"],
      breadcrumbTitle: "Table View",
      component: React.lazy(
        () => import("core/components/v2/table-view/example")
      ),
    },
    {
      pattern: ["/dialog"],
      breadcrumbTitle: "Dialog",
      component: React.lazy(
        () => import("core/components/v2/dialog/dialog-example")
      ),
    },
    {
      pattern: ["/search"],
      breadcrumbTitle: "Search",
      component: React.lazy(
        () => import("core/components/v2/search/search-example")
      ),
    },
    {
      pattern: ["/tab-selection"],
      breadcrumbTitle: "Tab Selection",
      component: React.lazy(
        () => import("core/components/v2/tab-selection/tab-selection-example")
      ),
    },
    {
      pattern: ["/customise-filter"],
      breadcrumbTitle: "Customise Filter",
      component: React.lazy(
        () =>
          import(
            "src/views/layouts/app/components/customise-filter-button/example"
          )
      ),
    },
    {
      pattern: ["/no-data-space"],
      breadcrumbTitle: "No Data",
      component: React.lazy(
        () => import("core/components/v2/no-data/no-data-example")
      ),
    },
    {
      pattern: ["/section-expand"],
      breadcrumbTitle: "Section Expand",
      component: React.lazy(
        () => import("core/components/v2/section-expand/section-expand-example")
      ),
    },
    {
      pattern: ["/svg-icons"],
      breadcrumbTitle: "Svg",
      component: React.lazy(() => import("core/components/v2/svg/example")),
    },
    {
      pattern: ["/collapsible-block"],
      breadcrumbTitle: "Collapsible Block",
      component: React.lazy(
        () => import("core/components/v2/collapsible-block/example")
      ),
    },
    {
      pattern: ["/dropdown-selection"],
      breadcrumbTitle: "Dropdown selection",
      component: React.lazy(
        () => import("core/components/v2/dropdown-selection/example")
      ),
    },
    {
      pattern: ["/time-picker"],
      breadcrumbTitle: "Time Picker",
      component: React.lazy(
        () => import("core/components/v2/time-picker/example")
      ),
    },
    {
      pattern: ["/file-upload"],
      breadcrumbTitle: "File Upload",
      component: React.lazy(
        () => import("core/components/v2/file-upload/file-upload-example")
      ),
    },
    {
      pattern: ["/range-slider"],
      breadcrumbTitle: "Range Slider",
      component: React.lazy(
        () => import("core/components/v2/range-slider/example")
      ),
    },
    {
      pattern: ["/app-selection"],
      breadcrumbTitle: "App Selection",
      component: React.lazy(
        () => import("core/components/v2/app-selection/example")
      ),
    },
    {
      pattern: ["/color-picker"],
      breadcrumbTitle: "Color Picker",
      component: React.lazy(
        () => import("core/components/v2/color-picker/example")
      ),
    },
    {
      pattern: ["/code-viewer"],
      breadcrumbTitle: "Code Viewer",
      component: React.lazy(
        () => import("core/components/v2/code-viewer/example")
      ),
    },
    {
      pattern: ["/yaml-viewer"],
      breadcrumbTitle: "Yaml Viewer",
      component: React.lazy(
        () => import("core/components/v2/yaml-viewer/example")
      ),
    },
  ],
};

export default GuidelinesRoutes;
