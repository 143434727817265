import StatusBadge, { StatusBadgeType } from "core/components/v2/status-badge";
import React from "react";
import { MetricMetadata } from "views/modules/settings-v2/pipeline/metrics/entities";
import "./style.scss";

export default function MetricMetadataView({
  metadata,
}: {
  metadata?: MetricMetadata;
}) {
  const attributes: {
    label: string;
    value: string;
  }[] = [];
  metadata?.attributes?.forEach((attribute) => {
    attributes.push({
      label: attribute,
      value: metadata.resourceType + attribute,
    });
  });
  metadata?.resourceAttributes?.forEach((attribute) => {
    attributes.push({
      label: attribute,
      value: metadata.resourceType + attribute,
    });
  });

  return (
    <div className="metric-metadata-container">
      <div className="top-part">
        {metadata?.metricKey && (
          <span className="metric-name">{metadata.metricKey}</span>
        )}
        <div className="properties-container">
          {metadata?.type && (
            <span className="property">
              <span className="property name">Type :</span> {metadata.type}
            </span>
          )}
          {metadata?.unit && (
            <span className="property">
              <span className="property name">Unit : </span>
              {metadata.unit}
            </span>
          )}
        </div>
      </div>
      <div className="center-part">
        {metadata?.description && <span>{metadata.description}</span>}
        {attributes.length > 0 && (
          <div className="attributes-container">
            {attributes.map((attribute) => (
              <StatusBadge
                key={attribute.value}
                type={StatusBadgeType.Primary}
                labelText={attribute.label}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
