import { LogsListBase, LogsList } from "src/entities/logs/lists";
import {
  SET_FILTER_BAR,
  SEARCH_FILTER_BAR,
  LOGS_RECEIVED,
  LOG_DETAIL_RECEIVED,
  FILTER_BAR,
  FILTER_ACTION,
  SET_FILTER_ACTION,
  WS_LOGS_RECEIVED,
  LOGS_ATTR_RECEIVED,
  LOG_TIMELINE_RECEIVED,
  FILTERED_LOGS_RECEIVED,
  LOGS_TIMELINE_RECEIVED,
  SET_LOG_INDICATOR_REQUEST,
  FILTERS_RECIEVED,
  SET_LOG_TIMINGS,
  SOURCE_FILTERS_RECIEVED,
  SOURCE_FILTERS_CHANGED,
  SET_PICKER_DATE,
  SET_FILTER_FOR_LOGS,
  REMOVE_FILTER_FOR_LOGS,
  SET_SELECTED_FILTER,
  SET_FILTERS_OPTIONS,
  ADD_FILTERS_OPTIONS,
  ADD_VALUE_FILTERS_OPTIONS,
  SET_LOG_TIMELINE_TIMINGS,
  APPEND_TO_LOGS,
  LOGS_SETTINGS_RECEIVED,
  SET_SETTING_ID,
  FINGER_PRINTS_RECEIVED,
  APM_PROJECTS_RECIEVED,
  TIMINGS,
  SET_LOG_INIT,
  NAME_SPACES_RECIEVED,
  LOG_CUSTOM_METRICS_RECIEVED,
  CUSTOM_METRIC_ID,
  CUSTOM_METRIC_TIME,
  SET_CUSTOM_METRICS_TIMINGS,
  SET_COLUMN_FOR_LOGS,
  WS_LOGS_TL_RECEIVED,
  LOGS_TL_RECEIVED,
  REFRESH_BTN_CLICK,
  LOGS_LIST_RECEIVED,
  SET_TEXT2QUERY_REQUEST,
  SHOUD_SHOW_TEXT2QUERY,
} from "./constant";
import { Log, Text2QueryRequest } from "views/modules/logs-v2/types";

export const setShouldShowText2Query = (arg: boolean) => {
  return {
    type: SHOUD_SHOW_TEXT2QUERY,
    payLoad: arg,
  };
};

export const setText2QueryRequest = (arg: Text2QueryRequest) => {
  return {
    type: SET_TEXT2QUERY_REQUEST,
    payLoad: arg as Text2QueryRequest,
  };
};

export const setRefreshBtnClick = (isClicked: boolean) => {
  return {
    type: REFRESH_BTN_CLICK,
    value: isClicked,
  };
};

export const setFilterBar = (filterBar: typeof FILTER_BAR) => {
  return {
    type: SET_FILTER_BAR,
    filterBar,
  };
};

export const setIndicatorRequest = (isLive: boolean) => {
  return {
    type: SET_LOG_INDICATOR_REQUEST,
    value: isLive,
  };
};

export const searchFilterBar = (arg: any = {}) => {
  return {
    type: SEARCH_FILTER_BAR,
    ...arg,
  };
};

export const logsReceived = (arg: LogsListBase<LogsList[]>) => {
  return {
    type: LOGS_RECEIVED,
    ...arg,
  };
};

export const listLogsReceived = (arg: Log[]) => {
  return {
    type: LOGS_LIST_RECEIVED,
    items: arg,
  };
};

export const recievedApmProjects = (arg: any = {}) => {
  return {
    type: APM_PROJECTS_RECIEVED,
    ...arg,
  };
};

export const fingerPrintsRecieved = (arg: any = {}) => {
  return {
    type: FINGER_PRINTS_RECEIVED,
    ...arg,
  };
};

export const appendToLogs = (arg: LogsListBase<LogsList[]>) => {
  return {
    type: APPEND_TO_LOGS,
    ...arg,
  };
};

export const filtersRecieved = (arg: any = {}) => {
  return {
    type: FILTERS_RECIEVED,
    ...arg,
  };
};

export const nameSpacesRecieved = (arg: any = []) => {
  return {
    type: NAME_SPACES_RECIEVED,
    payLoad: arg,
  };
};

export const sourceFiltersRecieved = (arg: any = {}) => {
  return {
    type: SOURCE_FILTERS_RECIEVED,
    ...arg,
  };
};

export const setFiltersOptions = (arg: any = {}) => {
  return {
    type: SET_FILTERS_OPTIONS,
    ...arg,
  };
};

export const addToFilterOptions = (arg: any = {}) => {
  return {
    type: ADD_FILTERS_OPTIONS,
    ...arg,
  };
};

export const addValueToFilterOptions = (arg: any = { key: "", value: [] }) => {
  return {
    type: ADD_VALUE_FILTERS_OPTIONS,
    ...arg,
  };
};

export const sourceFiltersChanged = (arg: any = {}) => {
  return {
    type: SOURCE_FILTERS_CHANGED,
    ...arg,
  };
};

export const logsTimelineRecieved = (arg: any = {}) => {
  return {
    type: LOGS_TIMELINE_RECEIVED,
    ...arg,
  };
};

export const filteredLogsReceived = (arg: any = {}) => {
  return {
    type: FILTERED_LOGS_RECEIVED,
    ...arg,
  };
};

export const logDetailReceived = (arg: any = {}) => {
  return {
    type: LOG_DETAIL_RECEIVED,
    ...arg,
  };
};

export const logTimelineRecieved = (arg: any = {}) => {
  return {
    type: LOG_TIMELINE_RECEIVED,
    ...arg,
  };
};

export const logsAttrReceived = (arg: any = {}) => {
  return {
    type: LOGS_ATTR_RECEIVED,
    ...arg,
  };
};

export const setFilterAction = (filterAction: typeof FILTER_ACTION) => {
  return {
    type: SET_FILTER_ACTION,
    filterAction,
  };
};

export const setLogTimings = (arg: any = TIMINGS) => {
  return {
    type: SET_LOG_TIMINGS,
    ...arg,
  };
};

export const setLogTimelineTime = (arg: any = {}) => {
  return {
    type: SET_LOG_TIMELINE_TIMINGS,
    ...arg,
  };
};

export const setCustomMetricsTimings = (arg: any = CUSTOM_METRIC_TIME) => {
  return {
    type: SET_CUSTOM_METRICS_TIMINGS,
    ...arg,
  };
};

export const setPickerDate = (arg: any = { time: [new Date().getTime()] }) => {
  return {
    type: SET_PICKER_DATE,
    ...arg,
  };
};

export const setFiltersForLogs = (arg: any = {}) => {
  return {
    type: SET_FILTER_FOR_LOGS,
    ...arg,
  };
};

export const removeFiltersForLogs = (arg: any = {}) => {
  return {
    type: REMOVE_FILTER_FOR_LOGS,
    ...arg,
  };
};

export const setSelectedFilter = (arg: any = { key: null }) => {
  return {
    type: SET_SELECTED_FILTER,
    ...arg,
  };
};

export const wsLogsReceived = (arg: any = {}) => {
  return {
    type: WS_LOGS_RECEIVED,
    ...arg,
  };
};

export const recieveLogsSettings = (arg: any = []) => {
  return {
    type: LOGS_SETTINGS_RECEIVED,
    ...arg,
  };
};

export const setSettingId = (arg: number) => {
  return {
    type: SET_SETTING_ID,
    payLoad: arg,
  };
};

export const setLogInit = (arg: any = { init: true }) => {
  return {
    type: SET_LOG_INIT,
    payLoad: arg,
  };
};

export const recievedLogsCustomMetrics = (arg: any) => {
  return {
    type: LOG_CUSTOM_METRICS_RECIEVED,
    payLoad: arg,
  };
};

export const setMetricId = (arg: string) => {
  return {
    type: CUSTOM_METRIC_ID,
    id: arg || "",
  };
};

export const setColumnsForLogs = (arg: any = {}) => {
  return {
    type: SET_COLUMN_FOR_LOGS,
    ...arg,
  };
};
export const setLogsTimeline = (arg: any) => {
  return {
    type: LOGS_TL_RECEIVED,
    ...arg,
  };
};

export const setWsLogsTimeline = (arg: any) => {
  return {
    type: WS_LOGS_TL_RECEIVED,
    ...arg,
  };
};
//
// export const wsLogDetailReceived = (arg: any = {}) => {
//     return {
//         type: WS_LOG_DETAIL_RECEIVED,
//         ...arg
//     }
// }
