import React, { useEffect, useState } from "react";
import "./_style.scss";
import Button from "core/components/v2/button";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDataSourceWidgets,
  fetchWidgetDataSources,
} from "store/dashboard-builder/api";
import DropdownSelection from "core/components/v2/dropdown-selection";
import {
  getDataSourceWidgetsResponse,
  getWidgetDataSourcesResponse,
} from "store/dashboard-builder/selectors";
import { dataSourceWidgetsAction } from "store/dashboard-builder/actions";
import { APIStatus } from "core/application/utils";
import LogoLoader from "core/components/v2/loader/logo-loader";
import FormCheckbox from "core/components/v2/form/form-checkbox";
import { CustomWidget, DashboardWidget } from "../../entities/builder.entities";
import { getBuilderFormattedData2 } from "store/widgets/selectors";
import { ReportReducer } from "store/widgets/reducers";
import { getFreshLayout } from "../../common/utils";
import { requestReportBuilderCreate } from "store/widgets/api";
import { receivedBuilderFormattedData } from "store/widgets/actions";
import { showToast } from "store/toast-alerts/actions";
import GlobalSidePanel from "views/layouts/app/components/global-side-panel";
import AddFromDSCard from "./add-from-ds-card";
import CreateNewWidgetCard from "./create-new-widget-card";

export interface AddWidgetProps {
  dashboardId?: number;
  displayScope?: string;
  showWidgetsFromDS?: boolean;
  onClose: (createNewWidget?: boolean) => void;
}

export default function AddWidget(props: AddWidgetProps) {
  const dispatch = useDispatch();

  // showWidgetFromDS: true => show "widget from data source" operation content
  const [showWidgetFromDS, setShowWidgetsFromDS] = useState(
    props.showWidgetsFromDS || false
  );
  const [selectedDataSource, setSelectedDataSource] = useState("");
  const [selectedWidgets, setSelectedWidgets] = useState<DashboardWidget[]>([]);

  useEffect(() => {
    if (!showWidgetFromDS) return;
    dispatch(fetchWidgetDataSources());
  }, [showWidgetFromDS]);

  const widgetDataSourcesResp = useSelector(getWidgetDataSourcesResponse);
  const dataSourceWidgetsResp = useSelector(getDataSourceWidgetsResponse);
  const builderFormattedData = useSelector(getBuilderFormattedData2) as Record<
    string,
    unknown
  >;

  const builderWidgets =
    (builderFormattedData.reports as ReportReducer)?.builders.widgets || {};

  const dataSourceOptions =
    widgetDataSourcesResp.sources?.map((s) => s.label) || [];

  // Get all the widgets of the selected data source
  const dataSourceWidgets =
    dataSourceWidgetsResp.apiStatus === APIStatus.SUCCESS
      ? widgetDataSourcesResp.sources?.find(
          (source) => source.label === selectedDataSource
        )?.widgets
      : undefined;

  // TODO: Revamp it after demo.
  const handleAddWidget = () => {
    const cpBuilderWidgets = Object.values(builderWidgets).map((w) => ({
      ...w,
    }));

    const newWidgets = selectedWidgets.map((widget) => {
      // Create a details for the widget
      const cw: CustomWidget = {
        builderConfig: widget.config,
        key: widget.key + Math.random().toString(36).substring(2),
        label: widget.label,
        builderViewOptions: {},
        builderId: 0,
        scopeId: 0,
        widgetAppId: widget.widgetAppId,
        layout: widget.layout,
      };

      // Add dashboard details if it is report-based dashboard
      if (props.dashboardId) {
        cw.builderViewOptions.report = {
          reportId: props.dashboardId,
          reportName: "",
          reportKey: "",
        };
      } else if (props.displayScope) {
        // Add display scope if it is scope-based dashboard
        cw.builderViewOptions.displayScope = props.displayScope;
      }

      // Calculate the layout of the new widget
      cw.layout = getFreshLayout(cpBuilderWidgets);
      cpBuilderWidgets.push(cw);

      return cw;
    });

    newWidgets.forEach((cw, idx) => {
      // Store the new widget in the dashboard via API
      // Revamp below to call multiple API calls.
      storeNewWidget(cw)
        .then((newCW) => {
          if (newCW) {
            cpBuilderWidgets[newCW.builderId] = newCW;
          }

          // MTODO: Revamp below and implement it in a more efficient manner.
          if (idx === selectedWidgets.length - 1) {
            dispatch(
              showToast(
                "success",
                `All selected widgets from the datasource ${selectedDataSource} added successfully.`,
                {
                  title: "Widgets Added",
                }
              )
            );

            props.onClose();
          }
        })
        .catch((err: string) => {
          dispatch(
            showToast("error", err, {
              title: "Unsuccessful Widget Creation",
            })
          );
        });
    });
  };

  // MTODO: Revamp below in more efficient manner.
  const storeNewWidget = (cw: CustomWidget) =>
    new Promise<CustomWidget>((resolve, reject) => {
      dispatch(
        requestReportBuilderCreate(cw, (status: boolean, res) => {
          if (res.error) {
            reject(res?.error || "Something went wrong!");
            return;
          }

          if (status && !res.inflight && !res.error) {
            cw.builderId = res.widget?.id || -1;
            cw.scopeId = res.widget?.scope?.id || -1;
            cw.isClone = false;
            if (res.widget?.scope?.meta_data?.RawMessage?.layouts) {
              cw.layout = res.widget?.scope.meta_data.RawMessage.layouts;
            }

            dispatch(
              receivedBuilderFormattedData({
                action: "add",
                body: cw,
                inflight: false,
              })
            );

            resolve(cw);
          }
        })
      );
    });

  const renderFooter = () => (
    <div className="add-widget-content-footer">
      <div className="form-button-group">
        <div className="form-button">
          <Button
            error
            onClick={() => {
              setSelectedDataSource("");
              setSelectedWidgets([]);
            }}
          >
            Cancel
          </Button>
        </div>
        <div className="form-button">
          <Button
            primary
            disabled={selectedWidgets.length === 0}
            onClick={handleAddWidget}
          >
            Add Widget
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <GlobalSidePanel
      isOpen
      size="xxxs"
      title={showWidgetFromDS ? "Widget from Data Source" : "Add Widget"}
      datePicker={false}
      onClose={props.onClose}
      footer={showWidgetFromDS && renderFooter()}
      onBackBtnClick={
        showWidgetFromDS
          ? () => {
              setShowWidgetsFromDS(false);
              setSelectedDataSource("");
              setSelectedWidgets([]);
            }
          : undefined
      }
    >
      <div className="add-widget-content">
        <div className="content-body">
          {!showWidgetFromDS && (
            <>
              <AddFromDSCard onCardClick={() => setShowWidgetsFromDS(true)} />
              <CreateNewWidgetCard onCardClick={() => props.onClose(true)} />
            </>
          )}

          {showWidgetFromDS && (
            <div className="form-input-group">
              <span className="label">DATA SOURCE</span>
              <DropdownSelection
                placeholder="Search to select data source"
                options={dataSourceOptions}
                showSearch
                selectedValues={selectedDataSource || []}
                onChange={(label: string | string[]) => {
                  const dataSource = widgetDataSourcesResp.sources?.find(
                    (source) => source.label === label
                  );

                  if (dataSource?.widgets.length === 0) {
                    dispatch(fetchDataSourceWidgets(label as string));
                  } else {
                    // Widgets are already available no need to fetch again
                    dispatch(
                      dataSourceWidgetsAction({
                        apiStatus: APIStatus.SUCCESS,
                      })
                    );
                  }
                  setSelectedDataSource(label as string);
                  setSelectedWidgets([]);
                }}
              />
            </div>
          )}

          {dataSourceWidgetsResp.apiStatus === APIStatus.LOADING && (
            <div className="widgets-selection-group">
              <LogoLoader />
            </div>
          )}

          {dataSourceWidgets && (
            <div className="widgets-selection-group">
              {dataSourceWidgets.length === 0 && (
                <span>No widget available for selected data source</span>
              )}

              {dataSourceWidgets.length > 0 && (
                <div className="form-input-group ">
                  <span className="label">
                    WIDGET(S) SELECTION FROM {selectedDataSource}
                  </span>
                  <FormCheckbox
                    id="select-all-widgets"
                    label="Select All"
                    checked={
                      selectedWidgets.length === dataSourceWidgets.length
                    }
                    showMinusIcon={
                      selectedWidgets.length > 0 &&
                      selectedWidgets.length !== dataSourceWidgets.length
                    }
                    onChange={(checked) => {
                      if (checked) setSelectedWidgets(dataSourceWidgets);
                      else setSelectedWidgets([]);
                    }}
                  />

                  {dataSourceWidgets.map((widget) => {
                    return (
                      <FormCheckbox
                        key={widget.key}
                        id={widget.key}
                        label={widget.label}
                        checked={
                          selectedWidgets.findIndex(
                            (w) => w.key === widget.key
                          ) !== -1
                        }
                        onChange={(checked) => {
                          if (checked)
                            setSelectedWidgets([...selectedWidgets, widget]);
                          else {
                            setSelectedWidgets(
                              selectedWidgets.filter(
                                (w) => w.key !== widget.key
                              )
                            );
                          }
                        }}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </GlobalSidePanel>
  );
}
