import {
  abbreviateNumber,
  agoTime,
  converMicroSecondsToSeconds,
  convertValueToSeconds,
  diffYMDHMS,
  formatBytes,
  isNanoseconds,
} from "core/application/utils";
import { convertMilliseconds, formatYAxisTick } from "core/utils/chart_helper";
import dayjs from "dayjs";
import moment from "moment";

export type RenderTpl = {
  type: TPL_TYPES;
  config: ConfigType;
  label: string;
  externalVisible?: boolean;
};
export type ConfigType =
  | PercentConfig
  | PercentWithoutBarConfig
  | BadgeConfig
  | TextStyleConfig
  | DateFormatConfig
  | TimeAgoConfig
  | IconConfig
  | BytesToSizeConfig
  | BytesRateConfig
  | TimeUtilizationConfig
  | CountConfig
  | StatusConfig
  | McoreConfig
  | DefaultConfigType
  | CustomUnitConfig;
export type DefaultConfigType = {};
export type CustomUnitConfig = {
  customUnit: string;
  toCapitalCase?: boolean;
};
export type PercentConfig = {
  precision: number;
  "progress-bar": boolean;
};
export type PercentWithoutBarConfig = {
  precision: number;
};
export type BadgeConfig = {
  valueType: BADGE_STYLE;
  items: BADGE_STYLE[];
};
export type TextStyleConfig = {
  valueType: TEXT_STYLE_TYPES;
  items: TEXT_STYLE_TYPES[];
};
export type DateFormatConfig = {
  valueType: DATE_FORMAT_TYPES;
  items: DATE_FORMAT_TYPES[];
};

export type TimeAgoConfig = {};
export type IconConfig = {};
export type BytesToSizeConfig = {};
export type BytesRateConfig = {};
export type TimeUtilizationConfig = {
  valueType: TIME_UTLIZATION_TYPES;
  items: TIME_UTLIZATION_TYPES[];
};
export type CountConfig = {};
export type StatusConfig = {
  // status:color as single strings
  // Ex. RUNNING:success, STOPPED:danger
  valueType: string[];
};
export type McoreConfig = {};
export const getStatusColorFromConfig = (
  status: string,
  config: StatusConfig
): {
  color: string;
  replaceValue: string;
} => {
  const item = config.valueType.find((i) => {
    const _status = i.split(":")[0];
    return status == _status;
  });
  const value = {
    color: "var(--color-blue-500)",
    replaceValue: "",
  };
  if (item) {
    const data = item.split(":");
    const color = data.length > 0 ? data[1] : "var(--color-blue-500)";
    const isVariable = color.startsWith("$") && color.endsWith("$");
    const colorValue = `var(${color.replaceAll("$", "")})`;
    const replaceValue = data.length > 1 ? data[2] : "";
    value.replaceValue = replaceValue;
    value.color = isVariable ? colorValue : color;
  }
  return value;
};
export type BADGE_STYLE =
  | "success"
  | "simple"
  | "default"
  | "primary"
  | "warning"
  | "normal"
  | "info"
  | "neutral";
export const defaultBadgeStyle: BADGE_STYLE[] = [
  "success",
  "simple",
  "default",
  "primary",
  "warning",
  "normal",
  "info",
  "neutral",
];
export type TEXT_STYLE_TYPES = "normal" | "bold" | "italic" | "underline";
export const defaultTextStyle: TEXT_STYLE_TYPES[] = [
  "normal",
  "bold",
  "italic",
  "underline",
];
export type DATE_FORMAT_TYPES =
  | "YYYY-MM-DD"
  | "YYYY-MM-DD HH:mm:ss"
  | "YYYY-MM-DD HH:mm"
  | "MM/DD/YYYY"
  | "MM/DD/YYYY HH:mm:ss"
  | "MM/DD/YYYY HH:mm"
  | "DD/MM/YYYY"
  | "DD/MM/YYYY HH:mm:ss"
  | "DD/MM/YYYY HH:mm"
  | "HH:mm:ss"
  | "dddd, MMMM Do YYYY, h:mm:ss a"
  | "dddd, MMMM Do YYYY"
  | "ddd, hA"
  | "MMMM Do YYYY, h:mm:ss a"
  | "dddd"
  | "MMM Do YY"
  | "LT"
  | "LTS"
  | "L"
  | "l"
  | "LL"
  | "ll"
  | "LLL"
  | "LLLL"
  | "llll";

export const dateFormats: DATE_FORMAT_TYPES[] = [
  "YYYY-MM-DD",
  "YYYY-MM-DD HH:mm:ss",
  "YYYY-MM-DD HH:mm",
  "MM/DD/YYYY",
  "MM/DD/YYYY HH:mm:ss",
  "MM/DD/YYYY HH:mm",
  "DD/MM/YYYY",
  "DD/MM/YYYY HH:mm:ss",
  "DD/MM/YYYY HH:mm",
  "HH:mm:ss",
  "dddd, MMMM Do YYYY, h:mm:ss a",
  "dddd, MMMM Do YYYY",
  "ddd, hA",
  "MMMM Do YYYY, h:mm:ss a",
  "dddd",
  "MMM Do YY",
  "LT",
  "LTS",
  "L",
  "l",
  "LL",
  "ll",
  "LLL",
  "LLLL",
  "llll",
];

export type TPL_TYPES =
  | "percent"
  | "percent_without_bar"
  | "badge"
  | "text_style"
  | "cost"
  | "date_format"
  | "time_ago"
  | "ago"
  | "icon"
  | "os_icon"
  | "bytes_to_size"
  | "bytes_rate"
  | "time_utilization"
  | "count"
  | "status"
  | "age"
  | "mcore"
  | "kilo_bytes_to_size"
  | "time_milliseconds"
  | "time_microseconds"
  | "time_nanoseconds"
  | "seconds"
  | "milliseconds"
  | "custom_unit"
  | "default"
  | "dollars";

export const TPL_TYPE_DEFAULT = "default";
export const TPL_TYPE_PERCENT = "percent";
export const TPL_TYPE_PERCENT_WITHOUT_BAR = "percent_without_bar";
export const TPL_TYPE_BADGE = "badge";
export const TPL_TYPE_TEXT_STYLE = "text_style";
export const TPL_TYPE_COST = "cost";
export const TPL_TYPE_DATE_FORMAT = "date_format";
export const TPL_TYPE_TIME_AGO = "time_ago";
export const TPL_TYPE_AGO = "ago";
export const TPL_TYPE_ICON = "icon";
export const TPL_TYPE_OS_ICON = "os_icon";
export const TPL_TYPE_BYTES_TO_SIZE = "bytes_to_size";
export const TPL_TYPE_BYTES_RATE = "bytes_rate";
export const TPL_TYPE_TIME_UTILIZATION = "time_utilization";
export const TPL_TYPE_COUNT = "count";
export const TPL_TYPE_MILLISECONDS = "time_milliseconds";
export const TPL_TYPE_NANOSECONDS = "time_nanoseconds";
export const TPL_TYPE_SECONDS = "seconds";
export const TPL_TYPE_TO_MILLISECONDS = "milliseconds";
export const TPL_TYPE_STATUS = "status";
export const TPL_TYPE_AGE = "age";
export const TPL_TYPE_MCORE = "mcore";
export const TPL_TYPE_KB_TO_SIZE = "kilo_bytes_to_size";
export const TPL_TYPE_CUSTOM_UNIT = "custom_unit";
export const TPL_TYPE_DOLLARS = "dollars";

export type TIME_UTLIZATION_TYPES =
  | "nanosecond"
  | "microsecond"
  | "millisecond"
  | "second"
  | "minute"
  | "hour"
  | "day"
  | "week"
  | "month"
  | "year";

export const defaultTimeUtilizations: TIME_UTLIZATION_TYPES[] = [
  "nanosecond",
  "microsecond",
  "millisecond",
  "second",
  "minute",
  "hour",
  "day",
  "week",
  "month",
  "year",
];
export const defaultTplOptions: RenderTpl[] = [
  {
    type: TPL_TYPE_DEFAULT,
    config: {},
    label: "Default",
    externalVisible: true,
  },
  {
    type: TPL_TYPE_PERCENT,
    config: {
      precision: 2,
      "progress-bar": true,
    },
    label: "Percentage",
    externalVisible: true,
  },
  {
    type: TPL_TYPE_PERCENT_WITHOUT_BAR,
    config: {
      precision: 2,
    },
    label: "Percentage without bar",
  },
  {
    type: TPL_TYPE_BADGE,
    config: {
      valueType: defaultBadgeStyle[0],
      items: defaultBadgeStyle,
    },
    label: "Badge",
  },
  {
    type: TPL_TYPE_TEXT_STYLE,
    config: {
      valueType: defaultTextStyle[0],
      items: defaultTextStyle,
    },
    label: "Text Style",
  },
  {
    type: TPL_TYPE_COST,
    config: {
      valueType: defaultTextStyle[0],
      items: defaultTextStyle,
    },
    label: "Cost",
  },
  {
    type: TPL_TYPE_DATE_FORMAT,
    config: {
      valueType: dateFormats[1],
      items: dateFormats,
    },
    label: "Date Format",
    externalVisible: true,
  },
  {
    type: TPL_TYPE_TIME_AGO,
    config: {},
    label: "Time Ago",
    externalVisible: true,
  },
  {
    type: TPL_TYPE_AGO,
    config: {},
    label: "Ago",
    externalVisible: true,
  },
  {
    type: TPL_TYPE_ICON,
    config: {},
    label: "Icon",
  },
  {
    type: TPL_TYPE_NANOSECONDS,
    config: {
      valueType: "second",
    },
    label: "Nanosecond",
  },
  {
    type: TPL_TYPE_OS_ICON,
    config: {},
    label: "OS Icon",
  },
  {
    type: TPL_TYPE_BYTES_TO_SIZE,
    config: {},
    label: "Bytes to Size",
    externalVisible: true,
  },
  {
    type: TPL_TYPE_DOLLARS,
    config: {},
    label: "Dollars",
    externalVisible: true,
  },
  {
    type: TPL_TYPE_BYTES_RATE,
    config: {},
    label: "Bytes Rate",
    externalVisible: true,
  },
  {
    type: TPL_TYPE_TIME_UTILIZATION,
    config: {
      valueType: defaultTimeUtilizations[1],
      items: defaultTimeUtilizations,
    },
    label: "Time Utilization",
    externalVisible: true,
  },
  {
    type: TPL_TYPE_COUNT,
    config: {},
    label: "Count",
    externalVisible: true,
  },
  {
    type: TPL_TYPE_MILLISECONDS,
    config: {},
    label: "Milliseconds",
  },
  {
    type: TPL_TYPE_STATUS,
    config: {
      valueType: [],
    },
    label: "Status",
  },
  {
    type: TPL_TYPE_AGE,
    config: {},
    label: "Age",
    externalVisible: true,
  },
  {
    type: TPL_TYPE_MCORE,
    config: {},
    label: "mCores",
  },
  {
    type: TPL_TYPE_KB_TO_SIZE,
    config: {},
    label: "Kilo Bytes to Size",
  },
  {
    type: TPL_TYPE_CUSTOM_UNIT,
    config: {
      customUnit: "",
    },
    label: "Rate",
  },
];

export const getTplByType = (a0: string): RenderTpl | null => {
  let _tpl: RenderTpl | null = null;
  const tplIndex = defaultTplOptions.findIndex((tpl: any) => tpl.type === a0);
  if (tplIndex !== -1) {
    _tpl = defaultTplOptions[tplIndex];
  }
  return _tpl;
};
export const getValueFromConfig = (
  value: number,
  config: RenderTpl
): string => {
  let _value: string = value + "";
  if (config) {
    switch (config.type) {
      case TPL_TYPE_PERCENT:
        const c = config.config as PercentConfig;
        if (
          c.precision &&
          value &&
          !isNaN(value) &&
          typeof value.toFixed === "function"
        ) {
          _value = value.toFixed(c.precision);
        }
        _value = _value + "%";
        break;
      case TPL_TYPE_PERCENT_WITHOUT_BAR:
        const c1 = config.config as PercentWithoutBarConfig;
        if (
          c1.precision &&
          !isNaN(value) &&
          typeof value.toFixed === "function"
        ) {
          _value = value.toFixed(c1.precision);
        }
        _value = _value + "%";
        break;
      case TPL_TYPE_BADGE:
        // const c2 = config.config as BadgeConfig;
        // if (c2.valueType) {
        //     _value = c2.valueType
        // }
        _value = _value + "";
        break;
      case TPL_TYPE_TEXT_STYLE:
        // const c3 = config.config as TextStyleConfig;
        // if (c3.valueType) {
        //     _value = c3.valueType
        // }
        _value = _value + "";
        break;
      case TPL_TYPE_COST:
        _value = "$" + Number(_value).toFixed(2);
        break;
      case TPL_TYPE_DATE_FORMAT:
        const c4 = config.config as DateFormatConfig;
        if (c4.valueType) {
          const date = moment(new Date(value));
          if (date.isValid() && value) {
            return date.format(c4.valueType);
          } else {
            return "0";
          }
        }
        break;
      case TPL_TYPE_TIME_AGO:
        if (isNanoseconds(value)) {
          value = converMicroSecondsToSeconds(value);
        }
        const difference = diffYMDHMS(moment(), moment(new Date(value)));
        _value = difference.formatted + " ago";
        break;
      case TPL_TYPE_AGO:
        if (isNanoseconds(value)) {
          value = converMicroSecondsToSeconds(value);
        }
        _value =
          value === 0
            ? "0 mins"
            : agoTime(dayjs(), dayjs(new Date(value))).formatted + " ago";
        break;
      case TPL_TYPE_ICON:
        break;
      case TPL_TYPE_BYTES_TO_SIZE:
        _value = formatBytes(value);
        break;
      case TPL_TYPE_KB_TO_SIZE:
        const bytes = value * 1024;
        _value = formatBytes(bytes);
        break;
      case TPL_TYPE_BYTES_RATE:
        _value = formatBytes(value) + "/s";
        break;
      case TPL_TYPE_CUSTOM_UNIT: {
        const cu = config.config as CustomUnitConfig;
        _value = String(abbreviateNumber(value, false, cu.toCapitalCase))
          ? String(abbreviateNumber(value, false, cu.toCapitalCase)) +
          ` ${cu.customUnit}`
          : "";
        break;
      }
      case TPL_TYPE_TIME_UTILIZATION:
        const c5 = config.config as TimeUtilizationConfig;
        let units: TIME_UTLIZATION_TYPES = "second";
        if (Array.isArray(c5.valueType)) {
          c5.valueType = c5.valueType[0];
        }
        switch (c5.valueType) {
          case "nanosecond":
            units = "nanosecond";
            // value = value / 1000000;
            break;
          case "microsecond":
            units = "microsecond";
            break;
          case "millisecond":
            units = "millisecond";
            break;
          case "second":
            units = "second";
            break;
          case "minute":
            units = "minute";
            break;
          case "hour":
            units = "hour";
            break;
          case "day":
            units = "day";
            break;
          case "week":
            units = "week";
            break;
          case "month":
            units = "month";
            break;
          case "year":
            units = "year";
            break;
          default:
            units = "second";
            break;
        }
        const timeDuration = convertValueToSeconds(value, units);
        _value =
          diffYMDHMS(
            moment().add(timeDuration, "second"),
            moment(),
            true,
            true,
            units === "microsecond" || units === "nanosecond" ? true : false,
            units === "nanosecond" ? true : false,
            timeDuration
          ).formatted || "0";
        break;
      case TPL_TYPE_COUNT:
        try {
          const intergerValue = Math.round(parseInt(_value));
          if (isNaN(intergerValue)) {
            return formatYAxisTick(_value + "");
          }
          return formatYAxisTick(intergerValue + "");
        } catch (error) {
          return formatYAxisTick(_value + "");
        }
      case TPL_TYPE_MILLISECONDS:
        return convertMilliseconds(_value);
      case TPL_TYPE_NANOSECONDS:
        return `${convertValueToSeconds(Number(_value), "nanosecond")
          .toFixed(1)
          .toString()}s`;
      case TPL_TYPE_SECONDS:
        return convertValueToSeconds(Number(_value), "second")
          .toFixed(2)
          .toString();
      case TPL_TYPE_TO_MILLISECONDS:
        return convertValueToSeconds(Number(_value), "millisecond")
          .toFixed(2)
          .toString();
      case TPL_TYPE_STATUS:
        //TODO: verify this usecase
        const statusConfig = config.config as StatusConfig;
        statusConfig.valueType = statusConfig.valueType || [];
        const statusValue = getStatusColorFromConfig(_value, statusConfig);
        const label: string = statusValue.replaceValue || _value;
        _value = label;
        break;
      case TPL_TYPE_AGE:
        const age = moment(_value).fromNow();
        _value = age;
        break;
      case TPL_TYPE_MCORE:
        let intValue = parseFloat(_value);
        let unit = " mCores";
        if (isNaN(intValue)) {
          return _value;
        } else {
          if (intValue < 1) {
            intValue = intValue * 1000;
          } else if (intValue >= 1) {
            unit = " Cores";
          }
        }
        // precison 2
        if (_value && typeof intValue.toFixed === "function") {
          _value = intValue.toFixed(0) + unit;
        } else {
          return _value + unit;
        }
        break;
      case TPL_TYPE_DOLLARS:
        if (typeof value !== "number" || isNaN(value)) {
          return _value;
        }
        _value = "$" + value.toFixed(5);
        break;
      default:
        if (isNaN(value)) {
          return _value;
        } else {
          if (
            value &&
            typeof value.toFixed === "function" &&
            _value.includes(".")
          ) {
            _value = value.toString();
          } else {
            _value = value + "";
          }
        }
        break;
    }
  }
  return _value;
};
