import React from "react";
import { useSelector } from "react-redux";

import Button from "core/components/v2/button";
import {
  EscapeKeyIcon,
  KeyboardArrowDownKeyIcon,
  KeyboardArrowUpKeyIcon,
  KeyboardReturnKeyIcon,
} from "core/components/v2/svg/icons";
import { FilterOpEnum } from "views/modules/builder/entities/builder.entities";
import { OptionsType, SelectionType } from "../model";
import { Size } from "core/components/v2/enums";
import { APIStatus } from "core/application/utils";
import LogoLoader from "core/components/v2/loader/logo-loader";
import { getBarFilters } from "store/widgets/selectors";
import { BarType } from "store/widgets/entities";
import { QueryParams } from "views/layouts/app/routes/model";

export interface QueryOptionsListProps {
  currentType: SelectionType;
  options: OptionsType[];
  selectOptionHandler: (
    option: string,
    append?: boolean,
    isMultiValue?: boolean
  ) => void;
  runningQuery?: {
    attribute?: string;
    operator?: FilterOpEnum;
    values?: string[];
  };
  highlitedIndex: number;
  handleApplyFilter: (fltr: QueryParams) => void;
  inFilter: Record<
    string,
    {
      operator: FilterOpEnum;
      values: string[];
    }
  >;
}

const QueryOptionsList = (props: QueryOptionsListProps) => {
  const {
    currentType,
    options,
    selectOptionHandler,
    runningQuery,
    highlitedIndex,
    handleApplyFilter,
    inFilter,
  } = props;

  const querybarFilters = useSelector(getBarFilters(BarType.Querybar));

  const getOptionType = (): string => {
    if (currentType === SelectionType.Attributes) {
      return "Attribute";
    }
    if (currentType === SelectionType.Operator) {
      return "Operator";
    }
    return "Value";
  };

  return (
    <>
      <div className={"query-options"}>
        <div className="autocomplete-options">
          {querybarFilters.filters.apiStatus === APIStatus.LOADING && (
            <LogoLoader size={50} />
          )}
          {querybarFilters.filters.apiStatus !== APIStatus.LOADING &&
            options.length <= 0 && (
              <div className="options-items no-filters-option">
                <span>
                  <span className="options-label">No filters available</span>
                </span>
              </div>
            )}
          {options.map((item: OptionsType, index: number) => {
            return (
              <div
                key={"options-item-" + item.value + "-" + index + 1}
                className={`options-items ${index === highlitedIndex || runningQuery?.values?.includes(item.value) ? "highlight" : ""}`}
                onClick={() =>
                  selectOptionHandler(
                    item.value,
                    true,
                    (runningQuery?.values ?? []).length > 0
                  )
                }
              >
                <span className="option">
                  <span className={"options-label"}>{item.label}</span>
                  {currentType === SelectionType.Operator && (
                    <span className={"options-title"}>
                      {`attribute ${item.value} "value"`}
                    </span>
                  )}
                </span>
                <span className="option-type">{getOptionType()}</span>
              </div>
            );
          })}
        </div>
      </div>
      <div className={"query-guide"}>
        <div className={"instructions"}>
          <div className="left-aligned-instructions">
            <div className="instruction-block">
              <span className="ins-title">Use arrow Keys</span>

              <KeyboardArrowUpKeyIcon />

              <KeyboardArrowDownKeyIcon />
              <span className="ins-title">to navigate & </span>

              <KeyboardReturnKeyIcon />
              <span className="ins-title">to select</span>
            </div>

            <div className="instruction-block">
              <span className={"addons"}>
                <b> ! </b>&quot;Text&quot; <b> (exclude) </b>
                <span>entries</span>
              </span>
            </div>

            <div className="instruction-block">
              <span className={"addons"}>
                <b> ~ </b>&quot;Regex&quot;
                <span className="ins-title">to body</span>
              </span>
            </div>

            <div className="instruction-block">
              <span>
                <span>
                  Use (<b> , </b>){" "}
                </span>
                <span> bewteen values after </span>
                <span className={"addons"}> IN </span>
                <span> {` / `} </span>
                <span className={"addons"}>NOT IN </span>
                <span className="ins-title">
                  {" "}
                  operator for multiple values{" "}
                </span>
              </span>
            </div>
          </div>

          <div className="right-aligned-instructions">
            <div className="instruction-block">
              <EscapeKeyIcon />
              <span className="ins-title">to Dismiss</span>
            </div>
            {(runningQuery?.operator === FilterOpEnum.In ||
              runningQuery?.operator === FilterOpEnum.NotIn) &&
              currentType === SelectionType.Value &&
              options.length > 0 && (
                <div style={{ maxWidth: "fit-content", cursor: "pointer" }}>
                  <Button
                    onClick={() => handleApplyFilter(inFilter)}
                    primary
                    size={Size.Small}
                  >
                    Apply Filters
                  </Button>
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default QueryOptionsList;
