import { initRequest, requestInitAdditional } from "store/account/api";
import { logoutRequest } from "store/api";
// eslint-disable-next-line import/named
import {
  AccountData,
  Application,
  StatusPageData,
  User,
} from "core/application/model";
import SocketConnector from "core/application/socket";
import { generateChartId } from "core/components/charts/common/utils";
import { SITE_THEME_MODE, getAuthStore } from "core/utils";
import EventEmitter from "core/utils/events";
import { Store } from "redux";
import store from "store";
import { wsUnifiedDashboardReceived } from "store/dashboard/actions";
import { setWsLogsTimeline, wsLogsReceived } from "store/logs/actions";
import { getPublicStatusPageView } from "store/status_page/api";
import { unregister } from "store/subscription/api";
import { wsSynthetics } from "store/synthetics/helper";
import {
  SocketDataAction,
  receivedWsFormattedData,
} from "store/widgets/actions";
import { getPlanName } from "views/modules/settings-v2/billing/utils";
import { initMixPanel, isDebugEnabled, isProdWithDisabledUid } from "./utils";
import { wsSpanListReceived } from "store/apm/actions";

class MWApplication implements Application {
  store: Store;
  dispatch: any;
  accountData: AccountData | undefined;
  user: User | undefined;
  socket: SocketConnector | undefined;
  // isRegister: boolean;
  constructor() {
    this.store = store;
    this.dispatch = store.dispatch;
    this.socket = new SocketConnector();
    // this.isRegister = false;
  }

  init(callback?: (user: User) => void) {
    const token = getAuthStore();
    if (token && !this.user) {
      const sessionId = generateChartId(7);
      (window as any).mw_user_session = sessionId;

      this.dispatch(
        initRequest((status, inflight, res) => {
          if (!inflight && status && res) {
            this.user = res;

            if (this.user) {
              this.user.sessionId = sessionId;
              // commenting for now as chat widget can be required again
              // const isChatHide = sessionStorage.getItem(
              //   "mw_hide_acquire_chat_support"
              // );
              const win: any = window;
              // win.acquire = win.acquire || [];
              // win.acquire.push({
              //   fields: {
              //     contact: {
              //       name: this.user?.full_name,
              //       email: this.user?.email,
              //       phone: this.user?.phone,
              //     },
              //   },
              // });
              // win.acquire.push(function (app: any) {
              //   app.applyAction("update-settings", {
              //     "crm.hide_widget_in_web":
              //       isChatHide == null ? "yes" : isChatHide,
              //   });
              // });
              if (
                win.Middleware &&
                (this.user.account?.app_host === "middleware.io" ||
                  this.user.account?.app_host === "stage.env.middleware.io" ||
                  this.user.account?.app_host === "front.env.middleware.io")
              ) {
                win.Middleware.setAttributes({
                  name: this.user?.full_name,
                  email: this.user?.email,
                });
              }
              const prod = isProdWithDisabledUid();

              if (prod) {
                initMixPanel(
                  this.user.env || "",
                  this.user.account?.uid || "",
                  {
                    name: this.user?.full_name || "",
                    email: this.user?.email || "",
                    plan: getPlanName(this.user?.billing?.status || ""),
                    registered_at: this.user.account?.created_at,
                    already_installed:
                      this.user.account?.meta_data?.is_agent_installed || false,
                  },
                  this.user.account?.is_internal || false
                );
              }
            }
            this.user.inFlight = true;
            if (this.user.misc_details != undefined) {
              this.user.misc_details.has_data = true;
            }

            this.dispatch(
              requestInitAdditional((_inflight: boolean, data: any) => {
                if (this.user) {
                  this.user.inFlight = _inflight;
                  EventEmitter.dispatch("CONTEXT_UPDATE", {
                    key: "user",
                    value: this.user,
                  });
                }

                if (this.user && data?.err && data.err.length > 0) {
                  if (
                    (this.user.misc_details != undefined &&
                      data.err.toLowerCase().includes("database") &&
                      (data.err.toLowerCase().includes("doesn't exist") ||
                        data.err.toLowerCase().includes("does not exist"))) ||
                    (this.user.misc_details != undefined &&
                      data.showMenu === false)
                  ) {
                    this.user.misc_details.has_data = false;
                    console.log("db:err", data.err);
                  }
                }
                if (data && this.user) {
                  if (this.user?.account) {
                    if (data?.is_agent_installed)
                      this.user.account.is_agent_installed =
                        data.is_agent_installed;
                    if (data?.host_count)
                      this.user.account.host_count = data.host_count;
                    if (data?.apm_host_count)
                      this.user.account.apm_host_count = data.apm_host_count;
                  }
                  if (data?.misc_details)
                    this.user.misc_details = data.misc_details;
                  this.user.inFlight = _inflight;

                  EventEmitter.dispatch("CONTEXT_UPDATE", {
                    key: "user",
                    value: this.user,
                  });
                  this.socket?.connect(
                    `wss://${window.location.host}/custom-ws/connect?authorization=` +
                      token +
                      "&sessionId=" +
                      sessionId,
                    () => {
                      this.listenWsEvent();
                    }
                  );
                  if (isDebugEnabled()) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
                    (window as any).mwSocket = this.socket;
                  }
                  callback && callback(this.user);
                }
              })
            );
          }
        })
      );
    }
  }

  statuspageInit(callback?: (statusPageData: StatusPageData) => void) {
    this.dispatch(getPublicStatusPageView(window.location.host))
      .then((statusPageData: StatusPageData) => {
        if (callback) {
          const currentUrl = new URL(window.location.href);
          if (currentUrl.pathname === "/auth/login" && statusPageData != null) {
            currentUrl.pathname = "/";
            localStorage.setItem(SITE_THEME_MODE, "light");
            window.history.replaceState(
              {},
              document.title,
              currentUrl.toString()
            );
          }
          callback(statusPageData);
        }
      })
      .catch((err: any) => {
        console.error("Error fetching status page data:", err);
      });
  }

  listenWsEvent(): void {
    this.socket?.on("synthetics_test", ({ event, ...data }: any) => {
      data && wsSynthetics(this.store, event, data);
    });
    this.socket?.on("synthetics_test_view", ({ event, ...data }: any) => {
      data && wsSynthetics(this.store, event, data);
    });
    this.socket?.on(
      "synthetics_test_request_result",
      ({ event, data }: any) => {
        EventEmitter.dispatch("SYNTHETICS_TEST_REQUEST_RESULT", data);
      }
    );
    this.socket?.on("synthetics__metrics__update", ({ event, data }: any) => {
      data && wsSynthetics(this.store, event, data);
    });

    this.socket?.on("unified_dashboard_host", ({ event, metricsData }: any) => {
      this.dispatch(wsUnifiedDashboardReceived({ event, metricsData }));
    });

    this.socket?.on("unified_dashboard_log", ({ event, logsData }: any) => {
      this.dispatch(wsUnifiedDashboardReceived({ event, logsData }));
    });

    this.socket?.on("unified_dashboard_trace", ({ event, tracesData }: any) => {
      this.dispatch(wsUnifiedDashboardReceived({ event, tracesData }));
    });

    this.socket?.on("logs_list", ({ logsData }: any) => {
      this.dispatch(wsLogsReceived({ event: "logs_list", data: logsData }));
    });

    this.socket?.on("span_list", ({ tracesData }: any) => {
      this.dispatch(
        wsSpanListReceived({ event: "span_list", data: tracesData })
      );
    });

    this.socket?.on("builder", (data) => {
      if (data.data) {
        data = {
          ...data.data,
          ...data,
        };
      }
      this.dispatch(SocketDataAction(data));
      this.dispatch(receivedWsFormattedData(data));
    });

    this.socket?.on("logs_list_tl", ({ logsData }: any) => {
      this.dispatch(setWsLogsTimeline({ data: logsData }));
    });
  }

  logout() {
    this.socket?.disconnect();
    this.user = undefined;
    this.dispatch(unregister(true));
    // this.isRegister = false;
    this.dispatch(logoutRequest());
    EventEmitter.removeAllEvents();
  }
}

export default MWApplication;
