import { useEffect, useState } from "react";

function useIsclicked() {
  const [isAltClicked, setAltClicked] = useState(false);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.code === "AltLeft" || e.code === "AltRight") {
        setAltClicked(true);
      }
    };

    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.code === "AltLeft" || e.code === "AltRight") {
        setAltClicked(false);
      }
    };

    const handleBlur = () => {
      setAltClicked(false);
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
    window.addEventListener("blur", handleBlur);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
      window.removeEventListener("blur", handleBlur);
    };
  }, []);

  return { isAltClicked };
}

export default useIsclicked;
