import { SeriesOptionsType, TooltipFormatterContextObject } from "highcharts";
import { ChartType, NULL_SERIES } from "./models";
import React from "react";
import { getOperatingSystem } from "core/utils";
import {
  RenderTpl,
  getValueFromConfig,
} from "views/modules/builder/entities/tpl.entities";
import { formatYAxisTick } from "core/utils/chart_helper";
import moment from "moment";

const TooltipFormatter = function (
  thisData: TooltipFormatterContextObject,
  yAxisConfig?: RenderTpl,
  isAltClicked?: boolean,
  interval: number | null = null,
  secondyAxisConfig?: RenderTpl
) {
  const os = getOperatingSystem(window);
  let keyName = "Alt";
  if (os === "MacOS") {
    keyName = "Option";
  }

  const yAxisTicker = (
    value: number | string,
    yAxisConfig: RenderTpl | undefined
  ): string => {
    if (!yAxisConfig) return value + "";
    if (typeof value === "string") return value;
    if (!yAxisConfig.type && typeof value === "number") {
      /**
       * Disabled due to default it is any
       */
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return formatYAxisTick(value);
    }
    const v = getValueFromConfig(value || 0, yAxisConfig);
    return v;
  };
  let ifHasNullSeries = false;
  if (Array.isArray(thisData.points)) {
    const ifHasNullSeriesData = [...(thisData.points || [])].filter(
      (it) => it.series.name === NULL_SERIES
    );
    ifHasNullSeries = ifHasNullSeriesData && ifHasNullSeriesData.length > 0;
  } else if (!Array.isArray(thisData.points) && thisData.point?.series) {
    ifHasNullSeries = thisData.point.series.name === NULL_SERIES;
  }
  if (Array.isArray(thisData.points) && !ifHasNullSeries) {
    const point = thisData.point,
      series = thisData.series,
      pointIndex = point.index;
    const seriesType = series.options.type;
    const arrForPoints: React.JSX.Element[] = [];
    const points = thisData.points
      .sort(
        (a: TooltipFormatterContextObject, b: TooltipFormatterContextObject) =>
          a && Number(a.y) >= 0 && b && Number(b.y) >= 0
            ? Number(b.y) - Number(a.y)
            : -1
      )
      .filter((it, index) => (!isAltClicked ? index < 3 : true))
      .filter((point) => point.series.name !== NULL_SERIES);
    points.map((point) => {
      const pointers = (
        series.userOptions as SeriesOptionsType & {
          data: Record<string, string>[];
        }
      ).data[pointIndex];
      let additionalValue: Record<string, string> = {};
      if (pointers && pointers.length && pointers[2]) {
        /**
         * disabled due to library type missmatch
         */
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        additionalValue = pointers[2];
      }
      arrForPoints.push(
        <div
          className="highcharts_item_row"
          key={
            point.series.name +
            (additionalValue?.[point.series.name]
              ? additionalValue[point.series.name]
              : yAxisTicker(Number(point.y), yAxisConfig))
          }
        >
          <span className="highcharts_item_row_colored_icon">
            <div
              style={{
                height: "8px",
                width: "8px",
                background:
                  typeof point.color === "string" ? `${point.color}` : "",
                borderRadius: "100px",
              }}
            ></div>
            <span
              className={
                isAltClicked
                  ? "highcharts_item_row_text__clicked"
                  : "highcharts_item_row_text"
              }
            >
              {seriesType === ChartType.BAR ? point.x : point.series.name}
            </span>
          </span>
          <b>
            {additionalValue?.[point.series.name]
              ? additionalValue[point.series.name]
              : yAxisTicker(
                  Number(point.y),
                  point.series.name === "log_size" && secondyAxisConfig
                    ? secondyAxisConfig
                    : yAxisConfig
                )}
          </b>
        </div>
      );
    });
    return (
      <div
        className="highcharts_tooltip_custom"
        key={"date_" + moment(points[0]?.x).format()}
      >
        <div className="arrow" />
        <div
          className={
            points[0]?.x && moment(points[0]?.x).isValid() ? "date_block" : ""
          }
        >
          {
            <b>
              {points[0]?.x && moment(points[0]?.x).isValid()
                ? moment(points[0]?.x).format("YYYY/MM/DD HH:mm:ss")
                : ""}{" "}
              {interval ? `(${interval})` : ""}
            </b>
          }
          {thisData?.points?.length && thisData?.points?.length > 3
            ? `Hold ${keyName} for more`
            : ""}
          {""}
        </div>
        {arrForPoints}
      </div>
    );
  } else if (Array.isArray(thisData.points)) {
    return (
      <div
        className="highcharts_tooltip_custom"
        key={"date_" + moment(thisData.point.x).format()}
      >
        <div className="arrow" />
        {thisData.point.x && (
          <div className="date_block">
            <b>
              {moment(thisData.point.x).format("YYYY/MM/DD HH:mm:ss")}{" "}
              {interval ? `(${interval})` : ""}
            </b>
            {thisData?.points?.length && thisData?.points?.length > 3
              ? `Hold ${keyName} for more`
              : ""}
          </div>
        )}
        <div className="highcharts_item_row">
          <span className="highcharts_item_row_colored_icon">
            <span
              className={
                isAltClicked
                  ? "highcharts_item_row_text__clicked"
                  : "highcharts_item_row_text"
              }
            >
              {"No data"}
            </span>
          </span>
        </div>
      </div>
    );
  } else {
    const pointers = (
      thisData.series.userOptions as SeriesOptionsType & {
        data: Record<string, string>[];
      }
    ).data[thisData.point.index];
    const seriesType = thisData.series.options.type;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let additionalValue: Record<string, string> = {};
    if (pointers && Array.isArray(pointers) && pointers.length && pointers[2]) {
      /**
       * disabled due to library type missmatch
       */
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      additionalValue = pointers[2];
    } else if (pointers.custom) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
      additionalValue = pointers.custom as any;
    }
    return (
      <div
        className="highcharts_tooltip_custom"
        key={
          "date_" +
          moment(
            seriesType === ChartType.TREEMAP
              ? additionalValue?.date
              : thisData.point.x
          ).format()
        }
      >
        <div className="arrow" />
        {seriesType === ChartType.TREEMAP ? (
          additionalValue?.date
        ) : thisData.point.x ? (
          <div className="date_block">
            <b>
              {moment(
                seriesType === ChartType.TREEMAP
                  ? additionalValue?.date
                  : thisData.point.x
              ).format("YYYY/MM/DD HH:mm:ss")}{" "}
              {interval ? `(${interval})` : ""}
            </b>
          </div>
        ) : null}
        {seriesType === ChartType.TREEMAP ? (
          Object.keys(additionalValue)
            .filter((it) => it !== "date")
            .map((key) => {
              return (
                <div className="highcharts_item_row" key={key}>
                  <span className="highcharts_item_row_colored_icon">
                    <div
                      style={{
                        height: "8px",
                        width: "8px",
                        background:
                          typeof thisData.point.color === "string"
                            ? `${thisData.point.color}`
                            : "",
                        borderRadius: "100px",
                      }}
                    ></div>
                    <span
                      className={
                        isAltClicked
                          ? "highcharts_item_row_text__clicked"
                          : "highcharts_item_row_text"
                      }
                    >
                      {key}
                    </span>
                  </span>
                  <b>{additionalValue[key]}</b>
                </div>
              );
            })
        ) : (
          <div className="highcharts_item_row">
            {thisData.point.series.name === NULL_SERIES ? (
              <span className="highcharts_item_row_colored_icon">
                <span
                  className={
                    isAltClicked
                      ? "highcharts_item_row_text__clicked"
                      : "highcharts_item_row_text"
                  }
                >
                  {"No data"}
                </span>
              </span>
            ) : (
              <>
                <span className="highcharts_item_row_colored_icon">
                  <div
                    style={{
                      height: "8px",
                      width: "8px",
                      background:
                        typeof thisData.point.color === "string"
                          ? `${thisData.point.color}`
                          : "",
                      borderRadius: "100px",
                    }}
                  ></div>
                  <span
                    className={
                      isAltClicked
                        ? "highcharts_item_row_text__clicked"
                        : "highcharts_item_row_text"
                    }
                  >
                    {seriesType === ChartType.TREEMAP ||
                    seriesType === ChartType.PIE
                      ? thisData.point.name
                      : thisData.point.series.name
                        ? thisData.point.series.name
                        : ""}
                  </span>
                </span>
                <b>
                  {additionalValue?.[
                    seriesType === ChartType.TREEMAP
                      ? thisData.point.name
                      : thisData.point.series.name
                  ]
                    ? additionalValue[
                        seriesType === ChartType.TREEMAP
                          ? thisData.point.name
                          : thisData.point.series.name
                      ]
                    : yAxisTicker(Number(thisData.point.y), yAxisConfig)}
                </b>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
};

export default TooltipFormatter;
