import { generateChartId } from "core/components/charts/common/utils";
import Dialog from "core/components/dialog";
import { Align } from "core/components/v2/enums";
import React, { useContext, useEffect, useState } from "react";
import { CustomizedFilterOption } from "views/layouts/app/components/customise-filter-button";
import GlobalQueryBar from "views/layouts/app/components/global-query-bar";
import { OptionsType } from "views/layouts/app/components/global-query-bar/model";
import MwRouteContext from "views/layouts/app/routes/MWRouteContext";
import useDidMountEffect from "views/layouts/app/routes/useDidMountEffect";
import {
  getMetricDetails,
  parseTableStatsData,
} from "../../../../../views/modules/builder/common/utils";
import { ColumnTemplates } from "../../../../../views/modules/builder/core/column.templates";
import WidgetAppDialogView from "../../../../../views/modules/builder/core/dialog/widget.app.dialog.view";
import { WidgetAppViewOptions } from "../../../../../views/modules/builder/core/widget-app-view";
import {
  BuilderNestedProps,
  ChartDataType,
  GridviewData,
  GridviewDataType,
  Pagination,
  SortDirection,
} from "../../../../../views/modules/builder/entities/builder.entities";
import NoDataSpace from "../../no-data";
import { TableView } from "../../table-view";
import { ColumnProps, UnionRecordType } from "../../table-view/entity";
import TableOuterWrapper from "../../table-view/outer-wrapper";

export interface DataTableProps {
  widgetData: ChartDataType | undefined;
  builderViewOptions: WidgetAppViewOptions;
  onSortingChange: (
    sortingColumnName: string,
    sortingType: SortDirection
  ) => void;
  searchText?: string;
  listOnClick: (a0: {
    row: unknown;
    resource: unknown;
    rowIndex: number;
  }) => void;
  openInnerDialog?: boolean;
  nestedProps?: BuilderNestedProps;
  titleChild?: React.ReactNode;
  columnsSelection?: CustomizedFilterOption[];
  onColumnsChange?: (selectedColumns: string[]) => void;
  onDefaultLabelClick?: () => void;
  rowClassName?: string;
  onPagination?: (offset: number) => void;
  pagination?: Pagination;
  isDashboardItem?: boolean;
  refreshData?: () => void;
}
type GridColumn = ColumnProps & {
  order: number;
};
const DataTable = (props: DataTableProps) => {
  const {
    widgetData,
    onSortingChange,
    searchText,
    nestedProps,
    builderViewOptions,
    titleChild,
    columnsSelection,
    onColumnsChange,
    onDefaultLabelClick,
    rowClassName,
    onPagination,
    pagination,
    refreshData,
  } = props;
  const routeData = useContext(MwRouteContext);
  const columnToLabel = new Map<string, string>(
    columnsSelection
      ?.filter(
        (option: CustomizedFilterOption) => option.value !== option.label
      )
      .map((option: CustomizedFilterOption) => [option.value, option.label])
  );

  const columnConfig = nestedProps?.columnConfig;
  const [widgetDialog, setWidgetDialog] = useState<{
    isOpen: boolean;
    data: unknown;
  }>({
    isOpen: false,
    data: null,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [currentOffset, setCurrentOffset] = useState(0);
  const resource = builderViewOptions?.resource;
  const tableData: GridviewDataType | undefined = widgetData?.gridviewData;

  const paginatedTableData = (data: Record<string, UnionRecordType>[]) => {
    const limit = pagination?.limit ?? 100;
    const noOfRecordsPerPage = pagination?.noOfRecordsPerPage ?? 50;
    const factor = limit / noOfRecordsPerPage;

    const page = currentPage % factor === 0 ? factor : currentPage % factor;

    const startIdx = (page - 1) * noOfRecordsPerPage;
    const endIdx = startIdx + noOfRecordsPerPage;
    return data.slice(startIdx, endIdx);
  };

  const onPageChange = (page: number) => {
    const limit = pagination?.limit ?? 100;
    const noOfRecordsPerPage = pagination?.noOfRecordsPerPage ?? 50;
    const factor = limit / noOfRecordsPerPage;

    if (onPagination) {
      if (page % factor) {
        if (Math.floor(page / factor) * limit !== currentOffset) {
          onPagination(Math.floor(page / factor) * limit);
          setCurrentOffset(Math.floor(page / factor) * limit);
        }
      } else {
        if ((Math.floor(page / factor) - 1) * limit !== currentOffset) {
          onPagination((Math.floor(page / factor) - 1) * limit);
          setCurrentOffset((Math.floor(page / factor) - 1) * limit);
        }
      }
    }
    setCurrentPage(page);
  };
  const customOptions: OptionsType[] = [];

  const columns: GridColumn[] = [];
  if (
    tableData?.columns &&
    Array.isArray(tableData?.columns) &&
    tableData?.columns.length
  ) {
    for (const _c of tableData.columns) {
      if (_c.accessor === "fingerprint") {
        continue;
      }
      if (_c.accessor === "resource") {
        continue;
      }
      if (_c.accessor === "r.resource") {
        continue;
      }
      if (columnConfig?.[_c.accessor]?.isHidden) {
        continue;
      }
      let title = _c.accessor.replaceAll(".", " ").replaceAll("_", " ");
      if (columnConfig?.[_c.accessor]?.title) {
        title =
          columnConfig[_c.accessor].title ??
          _c.accessor.replaceAll(".", " ").replaceAll("_", " ");
      } else {
        const metricDetails = getMetricDetails(_c.accessor);
        if (metricDetails?.metricName) {
          title = metricDetails.metricName;
        }
        title = _c.accessor.replaceAll(".", " ").replaceAll("_", " ");

        if (resource?.columnNames) {
          if (resource?.columnNames[_c.accessor]) {
            title = resource?.columnNames[_c.accessor];
          }
        }

        if (columnToLabel.has(_c.accessor)) {
          title =
            columnToLabel.get(_c.accessor) ??
            _c.accessor.replaceAll(".", " ").replaceAll("_", " ");
        }
      }

      const _val: GridColumn = {
        title: title,
        columnKey: _c.accessor,
        order: _c.order,
        width: 200,
        align: Align.Left,
      };

      // to extract search option from label
      const custom_label = _c.accessor.match(/\(([^,)]*)[,\)]/g)?.[0];
      if (custom_label) {
        customOptions.push({
          value:
            custom_label?.substring(1, custom_label.length - 1) ?? _c.accessor,
          label:
            custom_label?.substring(1, custom_label.length - 1) ?? _c.accessor,
        });
      } else {
        customOptions.push({ value: _c.accessor, label: _c.accessor });
      }

      if (columnConfig?.[_c.accessor]?.width) {
        _val.width = columnConfig?.[_c.accessor].width;
      }
      if (columnConfig?.[_c.accessor]?.align) {
        _val.align = columnConfig?.[_c.accessor].align;
      }
      const tpl = columnConfig?.[_c.accessor]?.tpl ?? _c.tpl;
      if (columnConfig?.[_c.accessor]?.tooltipContent) {
        _val.renderTooltipContent = columnConfig?.[_c.accessor]?.tooltipContent;
      }
      if (columnConfig?.[_c.accessor]?.tooltipInfo) {
        _val.tooltipInfo = columnConfig?.[_c.accessor]?.tooltipInfo;
      }
      if (columnConfig?.[_c.accessor]?.customRender) {
        const customRender = columnConfig?.[_c.accessor].customRender;
        if (customRender) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          _val.render = (data: any, record) => {
            return (
              <div
                className={`custom-render-container ${_val.align?.toString()}`}
              >
                {customRender(data, record)}
              </div>
            );
          };
        }
      } else if (_c && tpl.type) {
        if (tpl.type === "percent" || tpl.type === "time_ago") {
          _val.renderTooltipContent = " ";
        }
        _val.render = (data: UnionRecordType) => {
          //TODO: Confirm from mittal that it will always be not be string
          let columnDatToRender = data ?? "";
          if (typeof columnDatToRender === "object") {
            columnDatToRender = JSON.stringify(columnDatToRender);
          }
          return (typeof columnDatToRender === "string" &&
            columnDatToRender.length > 0) ||
            typeof columnDatToRender === "number" ||
            typeof columnDatToRender === "boolean" ? (
            <div
              className={`custom-render-container ${_val.align?.toString()}`}
            >
              <ColumnTemplates
                appKey={"data_table"}
                colTpl={tpl}
                colValue={columnDatToRender}
                align={_val.align}
              />
            </div>
          ) : (
            "-"
          );
        };
      } else {
        _val.render = (data: UnionRecordType) => {
          //TODO: Confirm from mittal that it will always be not be string
          let columnDatToRender: unknown = data || "";
          if (typeof columnDatToRender === "object") {
            columnDatToRender = JSON.stringify(columnDatToRender);
          }
          if (typeof columnDatToRender === "string") {
            return columnDatToRender + "";
          }
          if (typeof columnDatToRender === "number") {
            return columnDatToRender.toString() + "";
          }
        };
      }
      _val.sorterTooltip =
        _c.sort === "desc"
          ? "Click to sort ascending"
          : "Click to sort descending";
      _val.defaultSortOrder = (_c.sort || SortDirection.Asc) as
        | SortDirection.Asc
        | SortDirection.Desc;
      columns.push(_val);
    }
    columns.sort((a: GridColumn, b: GridColumn) => {
      return a.order > b.order ? 1 : -1;
    });
  }
  try {
    let viewTableData: GridviewData[] = [];
    if (searchText?.length) {
      tableData?.data.forEach((d) => {
        const isValueContainsSearch = Object.values(d).some((t) => {
          if (typeof t === "string" && t.indexOf(searchText) > -1) {
            return true;
          }
          return false;
        });
        if (isValueContainsSearch) {
          viewTableData.push(d);
        }
      });
    } else if (tableData) {
      viewTableData = tableData.data;
    }
    const outerWrapper = () => {
      const statsData = parseTableStatsData(tableData?.up_count_data);
      if (statsData) {
        if (resource) {
          statsData.label = `${nestedProps?.tableOptions?.statsLabel ? nestedProps?.tableOptions?.statsLabel : resource.resourceType}${statsData.filtered > 1 ? "s" : ""} up of `;
        }
        statsData.totalLabel = "total";
      }
      return (
        <TableOuterWrapper
          titleChild={titleChild ?? undefined}
          statsData={
            nestedProps?.tableOptions?.showTableStats ? statsData : undefined
          }
          columnsSelection={columnsSelection}
          onColumnsChange={onColumnsChange}
          onDefaultLabelClick={onDefaultLabelClick}
        >
          {props.isDashboardItem && (
            <GlobalQueryBar
              customCaller={builderViewOptions.builderView?.builderId.toString()}
              uniqueId="log-query-bar"
              caller="metrics"
              extraAttributes={customOptions}
              placeholder="Search by name, tag, label or annotation"
              hideGroupByLabel={false}
              extraBtnLabel={""}
            />
          )}

          {tableRender()}
        </TableOuterWrapper>
      );
    };
    const tableRender = () => {
      return (
        <TableView
          columns={columns}
          dataProvider={paginatedTableData(viewTableData)}
          pagination={{
            totalRecords:
              tableData?.total_count ??
              parseTableStatsData(tableData?.up_count_data)?.total ??
              viewTableData.length ??
              0,
            recordsPerPage: pagination?.noOfRecordsPerPage ?? 50,
            page: currentPage,
            onChange: (page) => {
              onPageChange(page);
            },
          }}
          uniqId={resource?.name ?? generateChartId(5)}
          maxHeight={"calc(100vh - 200px)"}
          loading={widgetData?.inflight ?? false}
          rowClassName={rowClassName}
          onSortingChange={(
            sortingColumnName: string,
            sortingType: SortDirection
          ) => {
            if (typeof onSortingChange === "function") {
              setCurrentPage(1);
              // when user click on the column for the first time, it should be sorted in descending order
              const sortDirection =
                tableData?.columns.filter(
                  (column) => column.accessor === sortingColumnName
                )[0].sort === "desc"
                  ? SortDirection.Asc
                  : SortDirection.Desc;
              onSortingChange(sortingColumnName, sortDirection);
            }
          }}
          rowOnClick={(record, rowIndex) => {
            if (props.listOnClick) {
              props.listOnClick({ row: record, resource, rowIndex });
            }
          }}
        />
      );
    };

    useEffect(() => {
      setCurrentPage(1);
    }, [routeData.params.dateRange]);
    useDidMountEffect(() => {
      refreshData?.();
    }, [
      JSON.stringify(
        routeData.actions.getAppliedFilters(
          props.builderViewOptions.builderView?.builderId
            ? props.builderViewOptions.builderView?.builderId + ""
            : undefined
        )
      ),
    ]);

    return (
      <React.Fragment>
        {titleChild ?? outerWrapper()}
        {widgetDialog.isOpen && widgetDialog.data && (
          <Dialog
            isOpen
            onClose={() =>
              setWidgetDialog({
                isOpen: false,
                data: null,
              })
            }
            position={"right"}
          >
            <WidgetAppDialogView {...widgetDialog.data} />
          </Dialog>
        )}
      </React.Fragment>
    );
  } catch (e) {
    console.warn(e);
    return (
      <div className="app-error">
        <NoDataSpace />
      </div>
    );
  }
};
export default DataTable;
