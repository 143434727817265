import classNames from "classnames";
import SectionExpand from "core/components/v2/section-expand";
import React, { useContext, useEffect, useRef } from "react";
import RGL, {
  Layout,
  WidthProvider,
  WidthProviderProps,
} from "react-grid-layout";
import { useDispatch, useSelector } from "react-redux";
import { getGlobalRefresh } from "store/selectors";
import { subscribeData } from "store/subscription/api";
import { requestBuilderScopeUpdate } from "store/widgets/api";
import MwRouteContext from "views/layouts/app/routes/MWRouteContext";
import useDidMountEffect from "views/layouts/app/routes/useDidMountEffect";
import WidgetAppView from "../../core/widget-app-view";
import { getWidgetAppName } from "../../core/widgets.app";
import {
  ATTRIBUTE_FILTER,
  BuilderProps,
  CustomWidget,
  FilterOp,
  LayoutItem,
  ORDER_BY_METRICS,
  Query,
  SELECT_DATA_BY,
  SELECT_LIMIT,
  SubscribeFilter,
  SubscribeRequest,
  SubscrieDataRequest,
} from "../../entities/builder.entities";
import { CustomObject, WithExpr } from "../../entities/extra.entities";
import builderViewDataManager, {
  BuilderDataType,
  BuilderViewHook,
} from "../hooks/useBuilderView";

const ReactGridLayout = WidthProvider(RGL) as React.ComponentClass<
  RGL.ReactGridLayoutProps,
  WidthProviderProps
>;

interface WidgetGridViewProps {
  widgets: CustomWidget[];
  builderProps: BuilderProps;
  builderView: BuilderViewHook;
  onEditWidget: (widget: CustomWidget) => void;
  onDeleteWidget: (widgetId: number) => void;
}

function WidgetGridView({
  widgets,
  builderProps,
  builderView,
  onEditWidget,
  onDeleteWidget,
}: WidgetGridViewProps) {
  const dispatch = useDispatch();

  const handleLayoutChange = (layouts: Layout[]) => {
    // Update layout of widgets in the db
    const wbsLayouts: LayoutItem[] = [];
    layouts.forEach((layout) => {
      const widget = widgets.find((w) => w.builderId === parseInt(layout.i));
      if (!widget) return;

      wbsLayouts.push({
        _scope_id: widget.scopeId,
        x: layout.x,
        y: layout.y,
        w: layout.w,
        h: layout.h,
        resizeHandles: ["se"],
      });
    });

    dispatch(requestBuilderScopeUpdate({ layouts: wbsLayouts }));

    // Make sure that the API request to fetch the data of the widget being visible is made
    widgets.forEach((widget) => {
      // Don't fetch data if API call is already inprogress (inflight = true) or
      // any one type of data is available (was successfully loaded in the previous API call)
      // This is to prevent multiple API calls when this function is call too frequently.
      const d = builderView.getChartData(widget.builderId + "");
      if (
        (d && d.widgetAppId === -1) ||
        d?.widgetData?.inflight ||
        d?.widgetData?.gridviewData ||
        d?.widgetData?.queryCountChartData ||
        d?.widgetData?.timeseriesChartData
      )
        return;

      builderView.requestData(
        {
          widget,
        },
        "onLayoutChange"
      );
    });
  };

  const renderWidgetPanel = (widget: CustomWidget) => {
    const widgetAppName = getWidgetAppName(widget.widgetAppId);

    return (
      <div
        key={widget.builderId}
        data-grid={{
          ...widget.layout,
          minW: 1,
          minH: 3,
          maxW: 12,
          i: widget.builderId,
        }}
        id={`layout__item__${widget.builderId}`}
        className={`app-view-container ${widgetAppName}`}
      >
        <div className={`app-view ${widgetAppName}`}>
          <div
            className={classNames(
              "app-view-body chart__view apps__data_view_wrapper",
              {
                ["app__" + widgetAppName]: true,
                [widgetAppName === "data_table" ? "no-border" : "border"]: true,
              }
            )}
          >
            <WidgetAppView
              nestedProps={{
                ...builderProps.nestedProps,
                chartType: widget.builderMetaData?.chartType,
              }}
              builderViewOptions={{
                builderView: {
                  builderId: widget.builderId,
                  scopeId: widget.scopeId,
                },
              }}
              boxTitle={widget.widgetAppId === -1 ? "Logs" : widget?.label}
              onEdit={() => {
                onEditWidget(widget);
              }}
              onClone={() => {
                const newLabel = widget.label + " - Clone";
                const cloneData: CustomWidget = {
                  ...widget,
                  builderId: -1,
                  scopeId: -1,
                  label: newLabel,
                  isClone: true,
                  key:
                    newLabel.replace(/[^A-Z\d]/gi, "_").toLocaleLowerCase() +
                    "_" +
                    Math.random().toString(36).substring(2),
                };
                onEditWidget(cloneData);
              }}
              onDelete={() => {
                onDeleteWidget(widget.builderId);
              }}
              onSortingChange={(sortingColumnName, sortingType) => {
                const withExpressions: WithExpr[] = [];
                if (sortingColumnName) {
                  withExpressions.push({
                    key: ORDER_BY_METRICS,
                    value: {
                      [sortingColumnName]: sortingType,
                    },
                    is_arg: true,
                  });
                }
                builderView.requestData(
                  {
                    widget,
                    withExpressions: withExpressions,
                  },
                  "onSortingChange"
                );
              }}
              refreshData={() => {
                builderView.requestData(
                  {
                    widget,
                  },
                  "refreshData"
                );
              }}
              onPagination={(offset: number) => {
                const withExpressions: WithExpr[] = [];
                withExpressions.push({
                  key: SELECT_LIMIT,
                  value: {
                    n: 100,
                    offset: offset,
                  },
                  is_arg: false,
                });

                builderView.requestData(
                  {
                    widget,
                    withExpressions: withExpressions,
                  },
                  "onPagination"
                );
              }}
              resourceView={builderView.getChartData(String(widget.builderId))}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <ReactGridLayout
      className="grid-layout"
      rowHeight={30}
      cols={12}
      isDraggable={true}
      isResizable={true}
      draggableHandle=".mw-card-header-title"
      containerPadding={[0, 0]}
      margin={[10, 10]}
      isBounded={true}
      onLayoutChange={handleLayoutChange}
      // Uncomment this if we want free movement instead of vertical movement
      // verticalCompact={false}
    >
      {widgets.map((widget) => renderWidgetPanel(widget))}
    </ReactGridLayout>
  );
}

export interface DashboardViewProps {
  widgetGroups: string[];
  widgets: CustomWidget[];
  builderProps: BuilderProps;
  onEditWidget: (widget: CustomWidget) => void;
  onDeleteWidget: (widgetId: number) => void;
}

const DashboardView = ({
  widgetGroups,
  widgets,
  builderProps,
  onEditWidget,
  onDeleteWidget,
}: DashboardViewProps) => {
  const dispatch = useDispatch();

  const routeData = useContext(MwRouteContext);
  const builderView = builderViewDataManager(
    builderProps,
    BuilderDataType.Widget
  );

  const globalRefresh = useSelector(getGlobalRefresh);
  // refreshWidgetsData refresh the data for all the widgets.
  const refreshWidgetsData = (caller: string) => {
    widgets.forEach((widget) => {
      builderView.requestData({ widget }, caller);
    });
  };

  // getSubscribeRequest returns the request for subscribing the live data for the widget.
  const getSubscribeRequest = (
    builderId: string,
    widgetAppId: number,
    query: Query
  ): SubscrieDataRequest => {
    const withArgs = query.with || [];
    const filters: SubscribeFilter[] = [];

    let groupBy: string[] = [];
    withArgs.forEach((w) => {
      if (w.key === SELECT_DATA_BY && Array.isArray(w.value)) {
        groupBy = w.value;
      } else if (w.key === ATTRIBUTE_FILTER && w.value) {
        const queryFilters = w.value as Record<string, CustomObject>;

        Object.entries(queryFilters).forEach(([filterKey, filterValues]) => {
          let operator: FilterOp | undefined;
          let value: string | string[] | undefined;

          Object.keys(filterValues).forEach((key: string) => {
            operator = key as FilterOp;
            if (
              operator === "LIKE" ||
              operator === "NOT LIKE" ||
              operator === "ILIKE" ||
              operator === "NOT ILIKE"
            ) {
              value = filterValues[key] as string;
            } else {
              value = filterValues[key] as string[];
            }
          });

          if (!operator || !value) return;
          filters.push({
            attribute_name: filterKey,
            operator,
            value,
          });
        });
      }
    });

    return {
      uniqueId: builderId,
      resource: query.source.name,
      columns: query.columns,
      groupBy: groupBy,
      widgetAppId: widgetAppId,
      filters,
    };
  };

  // subscribeForLiveData subscribes for all the widgets to get the live data from the stream
  const subscribeForLiveData = () => {
    const widgetList = widgets;
    if (!widgetList.length) return;

    const subReq: SubscribeRequest = {
      event: "builder",
      type: "metric",
      requests: [],
    };

    widgetList.forEach((widget) => {
      if (!widget.builderConfig) return;

      if (!Array.isArray(widget.builderConfig)) {
        subReq.requests?.push(
          getSubscribeRequest(
            String(widget.builderId),
            widget.widgetAppId,
            widget.builderConfig
          )
        );
        return;
      }

      widget.builderConfig.forEach((query) => {
        subReq.requests?.push(
          getSubscribeRequest(
            String(widget.builderId),
            widget.widgetAppId,
            query
          )
        );
      });
    });

    dispatch(subscribeData({ body: subReq }));
  };

  useEffect(() => {
    // Cleanup
    return () => {
      if (routeData.params.isLive) {
        builderView.unSubscribeBuilderData();
      }
    };
  }, []);

  // Watch for addition or deletion of any widget and
  // Re-Subscribe if the "Live" mode is on.
  useEffect(() => {
    if (!routeData.params.isLive) return;
    subscribeForLiveData();
  }, [widgets]);

  // Watch for "Live/History" button change
  useDidMountEffect(() => {
    if (routeData.params.isLive) subscribeForLiveData();
    else builderView.unSubscribeBuilderData();
  }, [routeData.params.isLive]);

  // Refresh the data for all widgets when the "Global Refresh" button is clicked.
  useDidMountEffect(() => {
    refreshWidgetsData("isGlobalRefresh");
  }, [globalRefresh]);

  // Refresh the data for all widgets when the "Date Range" is changed.
  useDidMountEffect(() => {
    refreshWidgetsData("routeData.params.dateRange");
  }, [routeData.params.dateRange]);


  // Refresh the data of only those widget(s) that has been updated by the manage-widget dialog.
  const prevWidgetMaps = useRef<Record<number, CustomWidget>>();
  useEffect(() => {
    widgets.forEach((widget) => {
      if (
        prevWidgetMaps.current?.[widget.builderId] &&
        JSON.stringify(widget) !==
          JSON.stringify(prevWidgetMaps.current[widget.builderId])
      )
        builderView.requestData({ widget }, "widget config change");

      if (!prevWidgetMaps.current) prevWidgetMaps.current = {};
      prevWidgetMaps.current[widget.builderId] = widget;
    });
  }, [JSON.stringify(widgets)]);

  const renderGroups = () =>
    widgetGroups.map((group, idx) => (
      <SectionExpand title={group} key={idx} defaultExpanded>
        <WidgetGridView
          widgets={widgets.filter(
            (widget) => widget.builderMetaData?.group_name === group
          )}
          builderProps={builderProps}
          builderView={builderView}
          onEditWidget={onEditWidget}
          onDeleteWidget={onDeleteWidget}
        />
      </SectionExpand>
    ));

  const renderNonGroupWidgets = () => (
    <SectionExpand title="Your Widgets" defaultExpanded>
      <WidgetGridView
        widgets={widgets.filter(
          (widget) => !widget.builderMetaData?.group_name
        )}
        builderProps={builderProps}
        builderView={builderView}
        onEditWidget={onEditWidget}
        onDeleteWidget={onDeleteWidget}
      />
    </SectionExpand>
  );

  return (
    <div className="grid-layout-container">
      <div className="grid-view-section">
        {widgetGroups.length ? (
          <>
            {renderGroups()}
            {renderNonGroupWidgets()}
          </>
        ) : (
          <WidgetGridView
            widgets={widgets}
            builderProps={builderProps}
            builderView={builderView}
            onEditWidget={onEditWidget}
            onDeleteWidget={onDeleteWidget}
          />
        )}
      </div>
    </div>
  );
};

// React.memo is used to re-render DashboardView only when any of its props is changed.
export default React.memo(DashboardView);
